import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

function OrderChip({ val }) {
  // todo leeji - 주문상태에 따른 칩 확인
  let label = 'ERROR';
  let bgColor = '#000000';

  if (val === 'REQ_OK') {
    label = '픽업대기';
    bgColor = '#94D210';
  } else if (val === 'PICK_LATE') {
    label = '픽업지연';
    bgColor = '#858585';
  } else if (val === 'CANCEL') {
    label = '취소완료';
    bgColor = '#e5e5e5';
  } else if (val === 'EXC_WAIT') {
    label = '교환대기';
    bgColor = '#F7A600';
  } else if (val === 'EXC_WAIT_LATE') {
    label = '교환지연';
    bgColor = '#858585';
  } else if (val === 'RTN_WAIT') {
    label = '반품대기';
    bgColor = '#f26a2d';
  } else if (val === 'RTN_WAIT_LATE') {
    label = '반품지연';
    bgColor = '#858585';
  } else if (val === 'CO_BUY') {
    label = '공동구매';
    bgColor = '#9357E5';
  } else if (val === 'PLATFORM_PROMOTION_PRODUCT') {
    label = '프로모션';
    bgColor = '#9357E5';
  } else if (val === 'PLATFORM_PROMOTION_RESERVE') {
    label = '예약판매';
    bgColor = '#3CAE47';
  } else if (val === 'PRM_REQ_OK') {
    label = '예약판매 진행 중';
    bgColor = '#3CAE47';
  } else if (val === 'PRM_RSTATE_WAIT') {
    label = '주문마감(입고대기)';
    bgColor = '#3CAE47';
  } else if (val === 'PRM_RSTATE_CONFIRM') {
    label = '주문마감(입고대기)';
    bgColor = '#3CAE47';
  } else if (val === 'PRM_RSTATE_OK' || val === 'COBUYING_OK') {
    label = '픽업대기';
    bgColor = '#94D210';
  }

  return (
    <Chip
      size="small"
      label={label}
      sx={{ height: '20px', fontWeight: 600, color: '#ffffff', backgroundColor: bgColor, marginRight: '4px' }}
    />
  );
}

export default OrderChip;

OrderChip.propTypes = {
  val: PropTypes.string
};
