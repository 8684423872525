import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// project imports
import { InfoIcon } from 'components';

// material-ui
import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// 프로모션 카드 세로 높이
const PROMOTION_CARD_HEIGHT = 305;

/**
 * 1KMWINE 프로모션 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PromotionCard = ({ data, isDetailPage = false, promotionPdata = [] }) => {
  const navigate = useNavigate();

  // 프로모션 칩
  const promotinoChip = React.useMemo(() => {
    if (!data?.promoCustomState) return;

    const { promoCustomState } = data;

    // 입점샵이 예약 판매 상품을 모두 입고 요청했을 경우 상세페이지에서 칩 표시 제한
    if (isDetailPage && promotionPdata.length > 0) {
      let confirmCount = 0;

      promotionPdata.forEach((p) => {
        if (p.state === 'CONFIRM') confirmCount += 1;
      });

      if (confirmCount === promotionPdata.length) return;
    }

    // 판매 대기
    if (promoCustomState === 'wait') {
      const startDt = data?.period?.start_dt;

      const now = moment().format('YYYY-MM-DD');
      // 날짜 차이 구하기
      const diff = moment(startDt).diff(now, 'days');

      return (
        <Stack direction="row" spacing="4px">
          <PromotionContainedChip label="판매대기" />
          <PromotionOutlinedChip label={diff === 0 ? 'D-day' : `D-${diff}`} />
        </Stack>
      );
    }
    // 예약판매 진행 중
    if (promoCustomState === 'ing') {
      const startDt = data?.period?.start_dt;

      const now = moment().format('YYYY-MM-DD');
      // 날짜 차이 구하기
      const diff = moment(startDt).diff(now, 'days');

      return (
        <Stack direction="row" spacing="4px">
          <PromotionContainedChip label="예약판매 진행 중" />
          {diff === 0 ? <PromotionOutlinedChip label="D-day" /> : null}
        </Stack>
      );
    }
    // 종료(입고완료)
    if (promoCustomState === 'finish') return <PromotionContainedChip label="종료(입고완료)" />;
    // 주문 마감(입고대기)
    if (promoCustomState === 'end') {
      return <PromotionContainedChip label="주문마감(입고대기)" />;
    }
  }, [data, data?.promoCustomState]);

  // 프로모션 주문기간
  const promotionPeriodDate = React.useMemo(() => {
    if (!data?.promoCustomState) return;

    const {
      promoCustomState,
      period: { start_dt, end_dt }
    } = data;

    if (promoCustomState === 'wait' || promoCustomState === 'ing') {
      return (
        <Stack spacing={1}>
          <PromotionLabel>주문 기간</PromotionLabel>
          <PromotionContents>
            {moment(start_dt).format('YY.MM.DD(ddd) a hh:mm')} ~ {moment(end_dt).format('YY.MM.DD(ddd) a hh:mm')}
          </PromotionContents>
        </Stack>
      );
    }
  }, [data]);

  // 프로모션 상품
  const promotionProducts = React.useMemo(() => {
    if (!data) return;

    const { promotion_pdata } = data;

    // 데이터 오류 (프로모션 상품이 존재하지 않음)
    if (!promotion_pdata) return null;

    const productNameTitle = isDetailPage ? promotion_pdata[0]?.pdata?.name?.ko : promotion_pdata[0]?.product?.name?.ko;
    const otherProductCount = promotion_pdata.length - 1;

    // 프로모션 상품이 1개인 경우
    if (promotion_pdata.length === 1) {
      return (
        <PromotionContents noWrap sx={{ mt: 1 }}>
          {productNameTitle}
        </PromotionContents>
      );
    }

    return (
      <Stack direction="row" mt={1}>
        <PromotionContents sx={{ maxWidth: 'calc(100% - 75px)' }} noWrap>
          {productNameTitle}
        </PromotionContents>
        <PromotionContents component="span" sx={{ maxWidth: 75 }}>
          &nbsp;외 {otherProductCount}개 상품
        </PromotionContents>
      </Stack>
    );
  }, [data]);

  // 프로모션 하단 영역
  const PromotionBottom = React.useMemo(() => {
    if (!data?.promoCustomState) return;

    const { promoCustomState, promotion_vendor } = data;

    if (promoCustomState === 'wait' || promoCustomState === 'ing') {
      const text =
        promoCustomState === 'wait'
          ? '* 주문 기간이 되면 상품 판매가 시작됩니다.'
          : '주문 정보는 픽업 대기 내역에서 확인 하실 수 있습니다.';

      return (
        <Box sx={{ width: '100%', borderRadius: '8px', bgcolor: '#F2F9F2', p: '12px 16px' }}>
          <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', color: '#3CAE47' }}>{text}</Typography>
        </Box>
      );
    }

    if (promoCustomState === 'finish' || promotion_vendor.r_state === 'CONFIRM') {
      return (
        <Box sx={{ width: '100%', borderRadius: '8px', bgcolor: '#F2F9F2', p: '12px 16px' }}>
          <Stack direction="row" spacing="4px" mb={1}>
            <InfoIcon width={13} height={13} color="#3CAE47" />
            <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal' }}>픽업완료 처리 안내</Typography>
          </Stack>
          <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '15px' }}>
            상품 입고 처리가 완료되어 주문 고객이 샵에 방문할 예정입니다.
            <br />
            <br />
            <u>고객 방문 시</u> 상단의 <b>[픽업대기]</b>를 눌러 주문 내역을 확인 후 '픽업완료' 상태로 변경해 주세요.
          </Typography>
        </Box>
      );
    }

    if (promoCustomState === 'end') {
      return (
        <Box>
          <Box sx={{ width: '100%', borderRadius: '8px', bgcolor: '#F2F9F2', p: '12px 16px' }}>
            <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal', pb: '4px' }}>
              샵으로 상품이 순차 배송될 예정입니다.
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '15px', color: '#3CAE47' }}>
              상품을 잘 받으셨다면&nbsp;
              <u>
                <b>상품 정보와 수량을 정확히 확인</b>
              </u>
              하신 후 입고&nbsp;
              <u>
                <b>완료 처리</b>
              </u>
              를 진행해 주세요.
            </Typography>
            {isDetailPage ? (
              <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', mt: '4px' }}>
                ※ 모든 상품이 입고 완료 처리되어야 주문 고객에게 픽업 안내 메시지가 전송됩니다.
              </Typography>
            ) : null}
          </Box>
          {isDetailPage ? null : (
            <Button
              variant="outlined"
              type="button"
              sx={{
                border: '1px solid #E7E4EB',
                mt: '14px',
                p: '11px',
                width: '100%',
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 'normal'
              }}
              onClick={() => navigate(`/promotion/${data?.id}`)}
            >
              상품 입고 완료 처리
            </Button>
          )}
        </Box>
      );
    }
  }, [data]);

  // render
  return (
    <Card
      sx={{
        height: isDetailPage ? 'auto' : PROMOTION_CARD_HEIGHT,
        boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.04)',
        p: '20px 16px',
        boxSizing: 'border-box'
      }}
    >
      {/* 프로모션 이름 영역 */}
      <Box sx={{ width: '100%', borderBottom: '1px solid rgba(34, 3, 72, 0.10)', pb: '19px', textAlign: 'center' }}>
        <Typography sx={{ fontSize: 16, fontWeight: 800, lineHeight: 'normal' }} noWrap>
          {data?.name}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100% - 38px)' }}>
        <Box>
          {/* 프로모션 칩 영역 */}
          <Stack direction="row" alignItems="center" mt="20px" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing="4px">
              {/* 프로모션 조기마감 칩 */}
              {data?.cancel === 'true' ? (
                <Chip
                  size="small"
                  label="조기마감"
                  sx={{ bgcolor: '#F46553', fontSize: 11, fontWeight: 700, lineHeight: 'normal', color: '#FFF' }}
                />
              ) : null}
              {/* 프로모션 상태 칩 */}
              {promotinoChip}
            </Stack>
            {/* 상세보기 버튼 */}
            {isDetailPage ? null : (
              <Stack direction="row" alignItems="center" onClick={() => navigate(`/promotion/${data?.id}`)}>
                <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', color: '#00000099' }}>상세보기</Typography>
                <KeyboardArrowRightIcon sx={{ width: 18, height: 18, color: 'rgba(0, 0, 0, 0.50)' }} />
              </Stack>
            )}
          </Stack>

          {/* 프로모션 주문기간 */}
          <Box mt={2}>{promotionPeriodDate}</Box>

          {/* 프로모션 상품 */}
          <Box mt={2}>
            <PromotionLabel>예약 판매 상품</PromotionLabel>
            {promotionProducts}
          </Box>
        </Box>

        <Box sx={{ mt: isDetailPage ? 2 : 0 }}>
          {/* 프로모션 하단 영역 */}
          {PromotionBottom}
        </Box>
      </Box>
    </Card>
  );
};

export default PromotionCard;

PromotionCard.propTypes = {
  data: PropTypes.object,
  isDetailPage: PropTypes.bool,
  promotionPdata: PropTypes.array
};

// contained chip
const PromotionContainedChip = styled((props) => <Chip size="small" {...props} />)(() => ({
  backgroundColor: '#3CAE47',
  fontSize: 11,
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#FFF'
}));

// outlined chip
const PromotionOutlinedChip = styled((props) => <Chip size="small" {...props} />)(() => ({
  border: '1px solid #3CAE47',
  backgroundColor: '#FFF',
  fontSize: 11,
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#3CAE47'
}));

const PromotionLabel = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 'normal',
  color: 'rgba(0, 0, 0, 0.5)'
}));

const PromotionContents = styled((props) => <Typography {...props} />)(() => ({
  color: '#000',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 'normal'
}));
