import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Slide, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// For snackBar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SuccessSnackbar({ open, close, text }) {
  // snackbar transition
  const [transition, setTransition] = useState(undefined);

  // snackbar transition up
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  useEffect(() => {
    setTransition(() => TransitionUp);
  }, []);

  return (
    <Snackbar open={open} autoHideDuration={2000} onClose={close} TransitionComponent={transition} sx={{ bottom: { xs: 80, sm: 0 } }}>
      <Alert
        saverity="success"
        sx={{ width: '100%', fontSize: '15px', fontWeight: '600', borderRadius: 10, color: 'white !important', padding: '8px 15px' }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}

export default SuccessSnackbar;

SuccessSnackbar.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  text: PropTypes.string
};
