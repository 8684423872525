import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

/**
 * 상품 재고 수정 박스
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function QuantityBox({ value: valueProp, min = 0, step = 1, onChange = null, boxWidth = 40, boxHeight = 40, disabled = false }) {
  const [value, setValue] = React.useState(valueProp); // 수량

  React.useEffect(() => {
    setValue(valueProp < 0 ? 1 : valueProp);
  }, [valueProp]);

  const handleValue = (newVal) => {
    if (typeof onChange === 'function') {
      onChange(newVal, value);
    }
    setValue(newVal);
  };

  // 감소
  const decrease = () => handleValue(value - step);

  // 증가
  const increase = () => handleValue(value + step);

  // render
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.nativeEvent.stopPropagation();
      }}
      zIndex={1}
    >
      <ButtonGroup fullWidth disabled={disabled} variant="outlined" aria-label="outlined button group">
        <Button
          title="감소"
          edge="start"
          color="dark"
          aria-label="감소"
          onClick={() => {
            if (value > min) decrease();
          }}
          style={{ borderColor: '#E7E4EB', width: boxWidth, height: boxHeight, padding: '11px' }}
        >
          <span style={{ display: 'inline-block', height: '1px', width: '8px', backgroundColor: '#000' }} />
        </Button>
        <TextField
          value={value}
          size="small"
          sx={{
            width: 'calc(100% - 80px)',
            height: boxHeight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '1px solid #0000001F',
            borderBottom: '1px solid #0000001F',
            fontSize: 16,
            fontWeight: 700,
            color: '#484848',
            '& .MuiInputBase-input': { textAlign: 'center' },
            '& fieldset': { border: 'none' }
          }}
          onChange={(e) => {
            const { value } = e.target;
            if (!value) {
              onChange(0);
            } else {
              onChange(value);
            }
          }}
          // onChange={(e) => {
          //   let newQuantity = e.target.value;
          //   if (newQuantity === '') {
          //     newQuantity = 0;
          //   } else {
          //     newQuantity = parseInt(newQuantity.replace(/,/g, ''), 10);
          //   }
          //   return onChange(newQuantity);
          // }}
          InputProps={{ inputComponent: QuantityNumericFormat }}
        />
        <Button
          title="증가"
          edge="end"
          color="dark"
          aria-label="증가"
          disabled={disabled}
          onClick={increase}
          style={{ borderColor: '#0000001F', fontSize: '18px', width: boxWidth, height: boxHeight, padding: '11px' }}
        >
          +
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default QuantityBox;

QuantityBox.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  boxWidth: PropTypes.number,
  boxHeight: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

const QuantityNumericFormat = React.forwardRef(function NumericFormatCustom(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const { floatValue } = values;

        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: floatValue
          }
        });
      }}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 999999999;
      }}
      placeholder={0}
      allowNegative={false}
      thousandSeparator
      valueIsNumericString={false}
    />
  );
});
