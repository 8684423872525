import React from 'react';
import { useNavigate } from 'react-router-dom';

// import mui
import { Box, Container, Pagination, Skeleton } from '@mui/material';

// import components
import { WoAlert } from 'utils/kmwine-alerts';

// import icons
import NoResultImg from 'assets/images/no_result_img.png';

// import projects
import ExcWaitTimeline from './components/ExcWaitTimeline';
import * as OrderService from 'services/OrderService';
import { useScriptRef } from '../../../../hooks';

/**
 * 교환대기 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ExchangeWait() {
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  const [loading, setLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalResults, setTotalResults] = React.useState(0);

  // 교환대기 목록
  const [rows, setRows] = React.useState([]);
  // 교환대기 목록 없음 플래그
  const [emptyRows, setEmptyRows] = React.useState(false);

  React.useEffect(() => {
    getExcWaitList(1);
  }, []);

  // 교환 대기 목록 조회
  const getExcWaitList = async (pageNo) => {
    console.log('[매장 방문 대기] 교환대기 목록 조회 시작');
    setLoading(true);

    const body = {
      page: {
        current: pageNo,
        size: 50
      }
    };

    // ---------------------------------------------- 교환대기 목록 조회 [START]
    const excWaitList = await OrderService.excWaitList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[ExchangeWait][getExcWaitList] Unmounted component.');
      return undefined;
    }

    // 교환대기 목록 조회 중 오류 발생
    if (excWaitList.error) {
      console.error('[매장 방문 대기] 교환대기 목록 조회 중 오류 발생 > ', excWaitList.error);
      WoAlert.fire('매장 방문 대기', '교환대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    // 교환대기 목록 조회 중 잘못된 상태값이 넘어옴
    if (!excWaitList.data.success) {
      console.error('[매장 방문 대기] 교환대기 목록 조회 중 오류 발생');
      WoAlert.fire('매장 방문 대기', '교환대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    const excResult = excWaitList;
    setTotalPages(excResult.data.page.total_pages);
    setTotalResults(excResult.data.page.total_results);
    const excList = excResult.data.results;

    // 교환대기 목록 데이터 가공
    if (excList.length > 0) {
      setEmptyRows(false);

      const excTempArr1 = [];
      let excTempArr2 = [];
      let changeCount = 0;
      // 교환대기 데이터 날짜별로 배열 재생성
      excList.forEach((e, index) => {
        let changePickupTime;
        if (index > 0) {
          changePickupTime = excList[index - 1].exc_date;
          if (changePickupTime !== e.exc_date) {
            changeCount += 1;
            changePickupTime = e.exc_date;
            excTempArr2.push(e);
            excTempArr1.push(excTempArr2);
            excTempArr2 = [];
          } else {
            excTempArr1[changeCount].push(e);
          }
        } else {
          changePickupTime = e.exc_date;
          excTempArr1.push([e]);
        }
      });
      setRows(excTempArr1);
    } else {
      setEmptyRows(true);
      setRows([]);
    }

    await setLoading(false);
    console.log('[매장 방문 대기] 교환대기 목록 조회 종료');
  };

  // 페이지 변경 이벤트
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getExcWaitList(newPage);
  };

  return (
    <>
      <Container sx={{ pt: 2 }}>
        <main>
          {/* 교환대기 목록이 없을 시 */}
          {emptyRows && (
            <Box pt={7} textAlign="center">
              <Box component="img" src={NoResultImg} width={123} height={100} />
              <Box fontSize={20} color="#220348" mt={4}>
                교환대기 내역이 없습니다.
              </Box>
            </Box>
          )}

          {/* 로딩중 */}
          {loading && <Skeleton variant="rectangular" height="120px" sx={{ borderRadius: '8px' }} />}

          {/* 교환대기 목록이 있을 시 */}
          {!loading && !emptyRows && <ExcWaitTimeline rows={rows} />}

          {/* 페이지 > 교환대기 건이 50개 이상일 경우 페이지네이션 활성화 */}
          {!loading && !emptyRows && totalResults > 49 && (
            <Box width="100%" pb={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="circular"
                sx={{ padding: '0 12px', '& .MuiPagination-ul': { justifyContent: 'center' } }}
              />
            </Box>
          )}
        </main>
      </Container>
    </>
  );
}

export default ExchangeWait;
