import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, Box, Container, Typography, Toolbar, Backdrop } from '@mui/material';
import moment from 'moment';

// assets
import BackIcon from 'assets/images/arrow-back.png';
import StarsIcon from '@mui/icons-material/Stars';
import { WineBottleLoadingLottie } from 'components/spinner';
import BasicDialog from 'components/dialog/BasicDialog';
import AlertTriangle from 'assets/images/product/ic_alert_triangle.jpg';

// firebase
import { doc, getDoc } from 'firebase/firestore';
import { firebaseDB } from 'index';
import { WoAlert } from '../../../utils/kmwine-alerts';

function Notice() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = React.useState(false);
  // 공지사항 정보
  const [notice, setNotice] = React.useState(null);
  // error modal
  const [errorModal, setErrorModal] = React.useState(false);
  // 페이지 진입 시
  React.useEffect(async () => {
    // 공지사항 조회
    await getNoticeData();
    setLoading(false);
  }, [params]);

  // 공지사항 조회
  const getNoticeData = async () => {
    const noticeId = params.id;

    setLoading(true);
    // 공지사항정보 조회
    const noticeSnapShot = await getDoc(doc(firebaseDB, 'notice', noticeId)).catch((error) => ({ error }));

    // 공지사항정보 조회 중 오류 발생
    if (noticeSnapShot.error) {
      console.error(`[공지 상세] 공지사항 정보(${noticeId}) 조회 중 오류 발생 > `, noticeSnapShot.error);
      WoAlert.fire('공지 상세', '공지사항정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 공지사항정보가 올바르지 않음
    if (!noticeSnapShot.exists()) {
      console.error(`[공지 상세] 공지사항 정보(${noticeId})가 올바르지 않음`);
      WoAlert.fire('공지 상세', '공지사항 정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    setNotice(noticeSnapShot.data());
  };

  // 작성 일시 format
  const createdAtFormat = (val) => {
    const data = val.toDate();
    const date = moment(data).format('YYYY. MM. DD');
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(data).getDay()];
    const result = `${date}(${dayOfWeek})`;

    return result;
  };

  return (
    <>
      {/* loading */}
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">공지사항을 불러오고 있습니다</Typography>
        </Box>
      </Backdrop>
      {/* HEADER */}
      <AppBar position={'sticky'} sx={{ borderBottom: '1px solid #EEEEEE' }}>
        <Toolbar padding={'16px 8px'}>
          <Box justifySelf="left" component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
          <Typography varient="div" sx={{ display: 'flex', position: 'absolute', left: '38%' }}>
            <Typography component={'span'} fontSize={18} fontWeight={'bold'} marginLeft={0.5}>
              공지사항
            </Typography>
          </Typography>
        </Toolbar>
      </AppBar>
      {/* CONTENTS */}
      {notice !== null && (
        <>
          <Container sx={{ mt: 1, pb: 1, borderBottom: '1px solid #EEEEEE' }}>
            <Box>
              <Box display={'flex'} alignItems={'center'}>
                {notice.important && <StarsIcon sx={{ color: '#FEC466', marginRight: '10px' }} />}
                <Box className={'line-1-ellipsis'}>
                  <Typography variant={'span'} fontSize={12} fontWeight={700}>
                    {notice.title}
                  </Typography>
                </Box>
              </Box>
              <Box fontSize={12} color={'#B0B0B0'} mt={1}>
                {createdAtFormat(notice.created_at)}
              </Box>
            </Box>
          </Container>
          <Container sx={{ mt: 1, pb: 1 }}>
            <div id={'noticeDiv'} dangerouslySetInnerHTML={{ __html: notice.content }} />
          </Container>
        </>
      )}

      {/* 공지사항 조회 오류 모달 */}
      <BasicDialog
        open={errorModal}
        close={() => setErrorModal(false)}
        type={'error'}
        titleImg={<Box component={'img'} src={AlertTriangle} width={'16px'} height={'16px'} />}
        title={'공지사항 조회 오류'}
        content1={'공지사항 조회 중 오류가 발생했습니다.'}
        btnText={'뒤로가기'}
        action={() => navigate(-1)}
      />
    </>
  );
}

export default Notice;
