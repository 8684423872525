/* *********************************************************************************************************************
 * Elstic Search
 *  - https://github.com/elastic/app-search-javascript
 ******************************************************************************************************************** */

import * as ElasticAppSearch from '@elastic/app-search-javascript';

/**
 * Elastic App Search에 있는 Engine명
 *
 * @constant
 * @type {{VARIETY: string, PIN: string, COUNTRY: string, PRODUCT: string, AROMA: string, IMPORTER: string, PDATA: string, WINE_TYPE: string}}
 */
export const ENGINE = {
  BANNER: 'banner',
  PIN: 'pin',
  WINE_TYPE: 'code-wine-type',
  VARIETY: 'code-variety',
  COUNTRY: 'code-country',
  AROMA: 'code-aroma',
  IMPORTER: 'code-importer',
  PDATA: 'pdata',
  PRODUCT: 'product',
  VENDOR: 'vendor',
  NOTICE: 'notice'
};

/**
 * Elastic App Search client 생성
 * @param engineName 검색대상 엔진이름
 */
export const createElasticAppSearchClient = (engineName) => {
  return ElasticAppSearch.createClient({
    searchKey: process.env.REACT_APP_ELSTC_SRCH_API_KEY,
    endpointBase: process.env.REACT_APP_ELSTC_SRCH_ENDPOINT_BASE,
    cacheResponses: false,
    engineName
  });
};
