import palette from 'assets/scss/_palette.scss';
import { alpha } from '@mui/material/styles';

export const light = {
  alternate: {
    main: '#f7faff',
    dark: '#edf1f7'
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: palette.primary_main,
    light: palette.primary_light,
    dark: palette.primary_dark,
    contrastText: palette.primary_text
  },
  brand: {
    main: '#220348',
    light: '#882ef6',
    dark: '#110124',
    contrastText: '#defd9a'
  },
  secondary: {
    main: '#f26a2d',
    light: 'rgb(246, 115, 75)',
    dark: 'rgb(170, 56, 21)',
    contrastText: '#fff'
  },
  success: {
    main: palette.success_main,
    light: palette.success_light,
    dark: palette.success_dark,
    contrastText: palette.success_text
  },
  gray: {
    main: '#6c757d',
    light: '#828a91',
    dark: '#565e64',
    contrastText: '#fff'
  },
  dark: {
    main: '#212529',
    light: '#424649',
    dark: '#1a1e21',
    contrastText: '#fff'
  },
  bourgogne: {
    main: '#9c27b0',
    light: '#ba68c8',
    dark: '#7b1fa2',
    contrastText: '#fff'
  },
  blue: {
    main: '#1976d2',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff'
  },
  yellow: {
    main: '#FFEA41',
    light: '#fff4a0',
    dark: '#a08e00',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  pink: {
    main: '#ff80ab',
    light: '#f8bbd0',
    dark: '#ad1457',
    contrastText: '#fff'
  },
  white: {
    main: '#ffffff',
    light: '#ffffff',
    dark: '#f5f5f5',
    contrastText: '#140229'
  },
  text: {
    primary: '#140229',
    brand: '#defd9a',
    // secondary: palette.text_secondary,
    secondary: alpha('#140229', 0.5),
    link: '#1976d2',
    white: '#ffffff'
  },
  divider: 'rgba(45,21,77,0.1)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
    product: '#f4f0f8'
  },
  fontFamily: {
    main: `'ONE-Mobile-Regular', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    currency: `'D-Din', 'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`
  }
};

// todo dark theme 언젠가는 대응합니다리우스🥶
export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30'
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff'
  },
  mode: 'dark',
  primary: {
    main: '#1976d2',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff'
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  gray: {
    light: '#828a91',
    main: '#6c757d',
    dark: '#565e64',
    contrastText: '#fff'
  },
  dark: {
    light: '#424649',
    main: '#212529',
    dark: '#1a1e21',
    contrastText: '#fff'
  },
  bourgogne: {
    light: '#ba68c8',
    main: '#9c27b0',
    dark: '#7b1fa2',
    contrastText: '#fff'
  },
  blue: {
    main: '#1976d2',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff'
  },
  yellow: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
    link: '#1976d2'
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
    product: '#f7f7f8'
  }
};
