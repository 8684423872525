import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * 확인버튼 (공통 스타일)
 * @type {StyledComponent<PropsOf<React.JSXElementConstructor<React.ComponentProps<C>>> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const ConfirmButton = styled((props) => <Button variant="contained" color="primary" {...props} />)({});

export default ConfirmButton;
