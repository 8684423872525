import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Skeleton, Stack, Typography } from '@mui/material';

/**
 * 주문 상세 탭 헤더
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function TabHeader({ loading, selectTab, changeTabEvent }) {
  return (
    <Box padding="10px 20px">
      {/* 로딩 중 일때 보여줄 skeleton 영역 */}
      {loading && <Skeleton variant="rectangular" width="100%" height="45px" sx={{ borderRadius: '10px' }} />}

      {/* 주문 상태가 REQ(승인 대기)가 아닐 경우 */}
      {!loading && (
        <Box width="100%" height="45px" bgcolor="#F3F2F4" borderRadius="10px" padding="5px">
          <Stack direction="row" spacing={1}>
            <Box
              width="100%"
              height="35px"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={selectTab === 1 && '#FFFFFF'}
              boxShadow={selectTab === 1 && '0px 1px 8px 1px #00000014'}
              onClick={() => changeTabEvent(1)}
            >
              <Typography component="span" fontSize="13px" color="#130328">
                주문정보
              </Typography>
            </Box>
            <Box
              width="100%"
              height="35px"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={selectTab === 2 && '#FFFFFF'}
              boxShadow={selectTab === 2 && '0px 1px 8px 1px #00000014'}
              onClick={() => changeTabEvent(2)}
            >
              <Typography component="span" fontSize="13px" color="#130328">
                결제정보
              </Typography>
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default TabHeader;

TabHeader.propTypes = {
  loading: PropTypes.bool,
  selectTab: PropTypes.number,
  changeTabEvent: PropTypes.func
};
