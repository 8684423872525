// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;

export const FIREBASE_FUNCTION_URL = {
  /** 전체 주문 목록 */
  POST__ORDER_ALL_LIST: `${BASE_HOST}/call-pay-order-getAllList`,

  /** 주문 승인 대기(승인 대기, 교환 신청, 반품 신청), 매장 방문 대기(픽업 대기, 교환 대기, 반품 대기) 카운트 */
  POST__WAITING_ORDER_COUNT: `${BASE_HOST}/call-pay-order-vendorWaitCount`,

  /** 승인 대기 목록 */
  POST__REQ_WAIT_LIST: `${BASE_HOST}/call-pay-order-reqWaitList`,

  /** 교환 신청 목록 */
  POST__REQ_EXC_LIST: `${BASE_HOST}/call-pay-order-reqExcWaitList`,

  /** 반품 신청 목록 */
  POST__REQ_RTN_LIST: `${BASE_HOST}/call-pay-order-reqRtnWaitList`,

  /** 픽업 대기 목록 */
  POST__PICK_WAIT_LIST: `${BASE_HOST}/call-pay-order-pickupWaitList`,

  /** 교환 대기 목록 */
  POST__EXC_WAIT_LIST: `${BASE_HOST}/call-pay-order-excWaitList`,

  /** 반품 대기 목록 */
  POST__RTN_WAIT_LIST: `${BASE_HOST}/call-pay-order-rtnWaitList`,

  /** 주문 승인/거절/부분승인 통합 */
  POST__ORDER_REQ: `${BASE_HOST}/call-pay-process-reqOrderCheck`,

  /** 주문 승인 todo: 삭제(미사용) */
  POST__ORDER_REQ_OK: `${BASE_HOST}/call-pay-process-reqOk`,

  /** 주문 거절 todo: 삭제(미사용) */
  POST__ORDER_REQ_NO: `${BASE_HOST}/call-pay-process-reqNo`,

  /** 픽업 완료 처리 */
  POST__ORDER_PICK_OK: `${BASE_HOST}/call-pay-process-pickOk`,

  /** 교환 요청 승인 처리 */
  POST__ORDER_PRODUCT_REQ_EXC_OK: `${BASE_HOST}/call-pay-process-exchangeReqOk`,

  /** 교환 요청 거절 처리 */
  POST__ORDER_PRODUCT_REQ_EXC_NO: `${BASE_HOST}/call-pay-process-exchangeReqNo`,

  /** 교환 완료 처리 */
  POST__ORDER_PRODUCT_EXC_OK: `${BASE_HOST}/call-pay-process-exchangeOk`,

  /** 반품 요청 승인 처리 */
  POST__ORDER_PRODUCT_REQ_RTN_OK: `${BASE_HOST}/call-pay-process-refundReqOk`,

  /** 반품 요청 거절 처리 */
  POST__ORDER_PRODUCT_REQ_RTN_NO: `${BASE_HOST}/call-pay-process-refundReqNo`,

  /** 반품 완료 처리 */
  POST__ORDER_PRODUCT_RTN_OK: `${BASE_HOST}/call-pay-process-refundOk`
};

export * from './order'; // 주문정보
