import React from 'react';
import PropTypes from 'prop-types';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { NumericFormat } from 'react-number-format';

// material-ui
import { Alert, Box, Button, Collapse, Dialog, Skeleton, Stack, TextField, Typography } from '@mui/material';

// import projects
import { bottleCapacityToString, PRODUCT_TYPES } from 'config';
import * as ProductService from 'services/ProductService';
import { WoAlert } from 'utils/kmwine-alerts';
import LoadingLayout from 'components/loading/LoadingLayout';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import QuantityBox from './components';
import { deleteProduct } from 'services/ProductService';

/**
 * 상품수정모달
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */

function ProductUpdate({ open, onClose, selectedProduct, reloadTable, reloadArray }) {
  // loading
  const [loading, setLoading] = React.useState(false);

  // 최초 상품 정보
  const [initProduct, setInitProduct] = React.useState(null);

  // 상품 정보
  const [product, setProduct] = React.useState({ loaded: false, data: null });
  // 입점샵 접근제한 플래그
  const [vendorLockFlag, setVendorLockFlag] = React.useState(false);
  // 프로모션 상품 플래그
  const [promotionFlag, setPromotionFlag] = React.useState(false);
  // 오늘의 한 병 상품 플래그
  const [todayBottleFlag, setTodayBottleFlag] = React.useState(false);

  // 입점샵 접근 제한에 따른 상품 disabled
  const [productDisabled, setProductDisabled] = React.useState(true);
  React.useEffect(() => {
    getProduct(selectedProduct);
  }, [selectedProduct]);

  const getProduct = async (selectProduct) => {
    const productId = selectProduct._id;

    if (productId === null || productId === undefined) {
      onClose();
      console.error('[상품 수정] 상품 정보 조회 중 오류 발생');
      WoAlert.fire('상품 수정', '상품 정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    setProduct({ loaded: false, data: null });

    let _productId = productId;
    if (selectedProduct?.promotion?.state === 'ING' || selectedProduct?.promotion?.state === 'WAIT') {
      _productId = selectedProduct?.product?._id;
    }

    const productSnapshot = await getDoc(doc(getFirestore(), 'product', _productId)).catch((error) => ({ error }));

    if (productSnapshot.error) {
      onClose();
      console.error('[상품 수정] 상품 정보 조회 중 오류 발생');
      WoAlert.fire('상품 수정', '상품 정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const productData = productSnapshot.data();

    if (productData.promotion) {
      const { state, end_dt } = productData.promotion;

      if ((new Date() < end_dt.toDate() && state === 'ING') || state === 'WAIT') {
        setProductDisabled(true);
      }
    } else {
      setProductDisabled(false);
    }

    setInitProduct(productData);
    setProduct({ loaded: true, data: productData });
    // 입점샵 접근제한 플래그
    setVendorLockFlag(productData.pdata?.vendor_lock ?? false);
    // 프로모션 상품 플래그
    setPromotionFlag(productData?.promotion?.state === 'ING' || productData?.promotion?.state === 'WAIT');
    // 오늘의 한 병 상품 플래그
    setTodayBottleFlag(PRODUCT_TYPES.TDBOTTLE.value === productData?.product_type ?? false);
  };

  // 상품 수정
  const productUpdate = async () => {
    const { _id, discount, packing, price, capacity, vintage, quantity } = product.data;
    const { original } = price;

    // 데이터 타입 검사
    if (!original || typeof original === 'string' || original < 0) {
      WoAlert.fire('', '상품 가격이 올바르지 않습니다.', 'warning');
      return undefined;
    }
    if (typeof quantity === 'string') {
      WoAlert.fire('', '재고가 올바르지 않습니다.', 'warning');
      return undefined;
    }

    // 입점샵 접근 제한에 상품인 경우
    if (vendorLockFlag.flag) {
      WoAlert.fire('상품 수정', '잘못된 접근입니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        onClose();
      });
      return false;
    }

    // 프로모션 대기, 진행 중인 상품인 경우
    if (productDisabled) {
      const initOriginal = initProduct.price.original;

      if (original !== initOriginal) {
        console.error('[상품 수정] 가격 수정 불가 상품이 가격이 변경됨.');
        WoAlert.fire('상품 수정', '잘못된 접근입니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
          onClose();
        });
        return false;
      }
    }

    const body = {
      id: _id,
      capacity,
      discount,
      packing,
      price: {
        original,
        sale: original
      },
      quantity,
      vintage
    };

    setLoading(true);

    const result = await ProductService.updateProduct(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setLoading(false);
      });

    // 상품 수정 중 오류 발생
    if (result.error) {
      console.error('[상품수정] 상품 수정 중 오류 발생 > ', result.error);
      WoAlert.fire('상품 수정', '상품 수정 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      return undefined;
    }

    const { code, msg } = result.data.result;

    // 상품 수정 실패
    if (code !== 0) {
      console.error('[상품수정] 상품 수정 중 오류 발생 > ', msg);
      WoAlert.fire('상품 수정', `상품 수정 중 오류가 발생했습니다.<br />[${msg}]<br /><br />잠시 후 다시 시도해주세요`, 'error');
      return undefined;
    }

    WoAlert.fire('상품 수정', '상품이 수정되었습니다.', 'success').then(() => {
      onClose();
      reloadTable();
    });
  };

  const productDelete = async () => {
    // 해당 상품이 입점샵 접근 제한 상품 또는 프로모션 상품 또는 오늘의 한 병 상품인 경우 삭제 불가
    if (vendorLockFlag || promotionFlag || todayBottleFlag) return false;

    const { isConfirmed } = await WoAlert.fire({
      title: '상품 삭제 처리',
      showCancelButton: true,
      cancelButtonText: '취소',
      html: `<div style='line-height: 1.5rem; text-align: center'>상품을 삭제 할까요?<br />(삭제 처리된 상품은 복구할 수 없습니다.<br />판매 재개를 위해서는 상품을 다시 등록해 주셔야 합니다.)</div>`
    });

    if (!isConfirmed) return;

    const {
      data: { _id }
    } = product;

    if (!_id) {
      console.error('[ProductUpdate][productDelete] 상품 아이디가 존재하지 않음');
      WoAlert.fire('', '상품삭제 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      return undefined;
    }

    setLoading(true);

    const result = await deleteProduct(_id)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    // 상품 삭제 중 오류 발생
    if (result.error) {
      console.error(`[ProductUpdate][productDelete] 삭제 중 오류 발생 > `, result.error);
      WoAlert.fire('상품삭제', '상품삭제 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    // 상품 삭제의 Response값이 잘못됨.
    if (code !== 0) {
      console.error(`[ProductUpdate][productDelete] 삭제 중 오류 발생 > `, msg);
      WoAlert.fire('상품삭제', `상품삭제 중 오류가 발생했습니다.<br />[${msg}]<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    WoAlert.fire('상품삭제', '상품이 삭제되었습니다.', 'success').then(() => {
      onClose();
      reloadArray(_id);
    });
  };

  // render
  return (
    <Box>
      {/* Loading */}
      <LoadingLayout open={loading} text="상품정보를 수정하고 있습니다." />
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: '#F4F0F880'
          },
          '& .MuiPaper-root': {
            width: '90%',
            boxShadow: '3px #00000029',
            borderRadius: '12px'
          }
        }}
      >
        <Box padding="25px">
          <Box mt="10px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="18px" fontWeight={600} color="#000000" lineHeight={1}>
              가격/재고 수정
            </Typography>

            <Button
              variant="outlined"
              sx={{
                p: '6px 10px',
                border: '1px solid #FC735C99',
                bgcolor: '#FFF',
                borderRadius: '14px',
                '&.Mui-disabled': { border: '1px solid #BCB2C7' }
              }}
              disabled={vendorLockFlag || promotionFlag || todayBottleFlag}
            >
              <Typography
                fontSize="12px"
                fontWeight={600}
                color={vendorLockFlag || promotionFlag || todayBottleFlag ? '#BCB2C7' : '#E66352'}
                lineHeight="normal"
                onClick={productDelete}
              >
                상품 삭제
              </Typography>
            </Button>
          </Box>

          {/* 입점샵 접근 제한 상품 */}
          <Collapse in={vendorLockFlag.loaded && vendorLockFlag.flag}>
            <Box mt="26px">
              <Alert color="warning" sx={{ width: '100% !important' }}>
                입점샵 접근 제한 상품입니다.
              </Alert>
            </Box>
          </Collapse>

          {/* 상품명/빈티지/용량 정보 */}
          <Collapse in={product.loaded}>
            <Box mt="26px">
              <Box>
                <Typography component="span" fontSize="16px" color="#140229" lineHeight={1}>
                  {(product?.data?.promotion?.state === 'WAIT' ||
                    (product?.data?.promotion?.state === 'ING' && new Date() < product?.promotion?.end_dt.toDate())) && (
                    <Typography component="span" fontSize="16px" color="#9357E5" mr="4px">
                      [프로모션]
                    </Typography>
                  )}
                  {product?.data?.name?.ko ?? <Skeleton height="16px" variant="rectangular" />}
                </Typography>

                <Stack
                  mt="8px"
                  direction="row"
                  spacing="4px"
                  divider={
                    <Box display="flex" alignItems="center" py="1px">
                      <Box height={1} width="1px" bgcolor="#A2A2A3" />
                    </Box>
                  }
                >
                  {/* 빈티지 */}
                  <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                    {product?.data?.vintage ?? <Skeleton width="28px" />}
                  </Typography>
                  {/* 용량 */}
                  <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                    {product?.data?.capacity ? bottleCapacityToString(product.data?.capacity) : <Skeleton width="28px" />}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Collapse>

          {/* 수정 영역 */}
          <Box mt="24px" mb="32px">
            <Stack spacing="12px">
              <SpaceBetweenBox>
                <Typography variant="subtitle2" fontWeight={700} width="70px">
                  가격
                </Typography>
                <Box width="calc(100% - 70px)">
                  {product.loaded ? (
                    <TextField
                      fullWidth
                      size="small"
                      disabled={loading || productDisabled || promotionFlag || todayBottleFlag}
                      value={product?.data?.price?.original?.toLocaleString()}
                      InputProps={{
                        inputComponent: PriceNumericFormat,
                        inputProps: { style: { textAlign: 'right', color: '#16171999' } }
                      }}
                      onChange={(e) => {
                        let newVal = e.target.value;
                        if (e.target.value === '') {
                          newVal = 0;
                        }
                        setProduct({ ...product, data: { ...product.data, price: { ...product.data.price, original: newVal } } });
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root.Mui-disabled': { '& > fieldset': { border: '1px solid #E0E0E0' } },
                        '& .MuiOutlinedInput-root': { '& > fieldset': { border: '1px solid #E0E0E0' } },
                        bgcolor: productDisabled ? '#F4F4F4' : '#FFFFFF',
                        '& .MuiFormHelperText-root': {
                          color: 'red'
                        }
                      }}
                    />
                  ) : (
                    <Skeleton height="37px" variant="rectangular" />
                  )}
                </Box>
              </SpaceBetweenBox>
              <SpaceBetweenBox>
                <Typography variant="subtitle2" fontWeight={700} width="70px">
                  재고
                </Typography>
                <Box width="calc(100% - 70px)">
                  {product.loaded ? (
                    <QuantityBox
                      value={product?.data?.quantity}
                      onChange={(newVal) => setProduct({ ...product, data: { ...product.data, quantity: newVal } })}
                      disabled={loading || vendorLockFlag.flag}
                    />
                  ) : (
                    <Skeleton height="40px" variant="rectangular" sx={{ margin: 0 }} />
                  )}
                </Box>
              </SpaceBetweenBox>
            </Stack>
            {/* 프로모션 상품일 경우 안내문 */}
            {promotionFlag && (
              <Typography fontSize="12px" color="#9357E5" mt="8px" lineHeight={1}>
                * 프로모션 대기/진행 중인 상품은 '재고'만 수정 가능 합니다.
              </Typography>
            )}
          </Box>

          {/* 취소, 저장 버튼 */}
          <Box mt="32px">
            <Stack direction="row" spacing="10px" width="100%">
              <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ padding: '12px 0', border: '1px solid #60546E4D', backgroundColor: '#ffffff', borderRadius: '30px' }}
                onClick={onClose}
                disabled={!product.loaded || loading}
              >
                <Typography variant="h5" color="#707070">
                  취소
                </Typography>
              </Button>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{
                  padding: '12px 0',
                  border: '1px solid #60546E4D',
                  backgroundColor: '#140229',
                  borderRadius: '30px'
                }}
                disabled={!product.loaded || loading || vendorLockFlag}
              >
                <Typography variant="h5" color="#FFFFFF" fontWeight={600} onClick={() => productUpdate()}>
                  저장
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

ProductUpdate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedProduct: PropTypes.object,
  reloadTable: PropTypes.func,
  reloadArray: PropTypes.func
};

export default ProductUpdate;

const PriceNumericFormat = React.forwardRef(function NumericFormatCustom(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const { floatValue } = values;
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: floatValue
          }
        });
      }}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 999999999;
      }}
      allowNegative={false}
      thousandSeparator
      valueIsNumericString={false}
    />
  );
});
