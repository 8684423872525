import React from 'react';
import { Route } from 'react-router-dom';
import ManagementIndex from 'views/management';
import SettingPush from 'views/management/settingPush';
import OrderList from 'views/management/orderList';
import Inquiry from 'views/management/inquiry';
import Notice from 'views/management/notice';

/**
 * 운영관리 라우트
 */
export default function ManagementRoutes() {
  return (
    <Route path="/management">
      <Route index bottomNav element={<ManagementIndex />} />
      <Route path={'settingPush'} element={<SettingPush />} />
      <Route path={'orderList'} element={<OrderList />} />
      <Route path={'inquiry'} element={<Inquiry />} />
      <Route path={'notice/:id'} element={<Notice />} />
    </Route>
  );
}
