import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * 주문 상품 수량 선택 박스
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function QuantityBox({
  value: valueProp,
  min = 1,
  max = -1,
  step = 1,
  onChange = null,
  boxHeight = 32,
  disabled = false,
  product,
  handleSelectedItem
}) {
  const theme = useTheme();
  const [value, setValue] = React.useState(valueProp); // 수량

  React.useEffect(() => {
    setValue(valueProp < 0 ? 1 : valueProp);
  }, [valueProp]);

  const handleValue = (newVal) => {
    if (typeof onChange === 'function') {
      onChange(newVal, value);
    }
    setValue(newVal);
  };

  // 감소
  const decrease = () => handleValue(value - step);

  // 증가
  const increase = () => {
    let _value = value >> 0;

    // 0에서 1로 증가할 때 체크박스 활성화
    if (value === 0 && step > 0) {
      const _product = { ...product, ok_quantity: step };
      handleSelectedItem(true, _product);
    }

    if (max > 0 && value + step > max) {
      console.log(`[wineone] maxvalue에 닿았다. max=${max}`);
    } else {
      _value = value + step;
    }
    handleValue(_value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.nativeEvent.stopPropagation();
      }}
      zIndex={1}
    >
      <ButtonGroup disabled={disabled} variant="outlined" aria-label="outlined button group">
        <Button
          title="감소"
          edge="start"
          color="dark"
          aria-label="감소"
          onClick={() => {
            if (value > min) decrease();
          }}
          style={{ borderColor: '#E7E4EB', height: boxHeight, minWidth: 0, padding: '11px' }}
        >
          <span style={{ display: 'inline-block', height: '1px', width: '8px', backgroundColor: theme.palette.brand.main }} />
        </Button>
        <TextField
          id={`cart-item-${product.id}`}
          hiddenLabel
          variant="outlined"
          disabled={disabled}
          type="number"
          value={value}
          size="small"
          inputProps={{
            sx: {
              textAlign: 'center',
              fontSize: '16px',
              p: 0,
              fontWeight: 700,
              color: '#484848',
              letterSpacing: 'normal',
              lineHeight: '16px'
            },
            readOnly: true
          }}
          sx={{
            width: 61,
            height: '100%',
            textAlign: 'center',
            '& .MuiOutlinedInput-root': { borderRadius: 0, ml: '-1px', height: boxHeight },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              border: '1px solid #E7E4EB',
              borderRadius: 0,
              backgroundColor: 'rgba(0,0,0,0)'
            }
          }}
        />
        <Button
          title="증가"
          edge="end"
          color="dark"
          aria-label="증가"
          disabled={max > 0 && value >= max}
          onClick={increase}
          style={{ borderColor: '#E7E4EB', fontSize: '18px', height: boxHeight, minWidth: 0, padding: '11px' }}
        >
          +
        </Button>
      </ButtonGroup>
      <Typography fontSize="14px" color="#484848" ml="6px">
        ({value}/{product.quantity}병)
      </Typography>
    </Box>
  );
}

QuantityBox.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  boxHeight: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  product: PropTypes.object,
  handleSelectedItem: PropTypes.func
};

export default QuantityBox;
