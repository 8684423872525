import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  AppBar,
  Box,
  IconButton,
  TableBody,
  Typography,
  TableCell,
  Table,
  TableRow,
  Container,
  SwipeableDrawer,
  Skeleton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { firebaseDB } from 'index';
import { doc, getDoc } from 'firebase/firestore';

// assets
import NoResultImg from 'assets/images/no_result_img.png';

/**
 * 와인 상세 정보
 * 참조할 데이터 : pdata
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ProductDetail({ open: openProp, onClose, pid }) {
  // loading
  const [loading, setLoading] = React.useState(true);
  // 와인 정보
  const [pdata, setPdata] = React.useState(null);

  // erp 상품정보
  const [erpItemCd, setErpItemCd] = React.useState(null);

  React.useLayoutEffect(() => {
    if (pid !== '') {
      getData();
    }
  }, [pid]);

  const getData = async () => {
    /**
     * TODO 최효근
     * 현재는 ProductList에서 productId를 가져와서 pdataId를 조회합니다.
     * 추후에는 ProductList를 조회하는 쿼리를 수정하여 pdataId를 한번에 받아와야 합니다.
     */
    setLoading(true);
    setErpItemCd(null);

    const productSnapshot = await getDoc(doc(firebaseDB, 'product', pid)).catch((error) => ({ error }));

    // 상품 정보 조회 중 오류 발생
    if (productSnapshot.error) {
      console.error('[와인상세정보] 와인상세정보 조회 중 오류 발생 > ', productSnapshot.error);
      WoAlert.fire('와인상세정보', '와인상세정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        onClose();
      });
      return false;
    }

    // 상품 정보가 올바르지 않음
    if (!productSnapshot.exists()) {
      console.error('[와인상세정보] 올바르지 않은 상품 데이터');
      WoAlert.fire('와인상세정보', '올바르지 않은 상품 정보 입니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        onClose();
      });
      return false;
    }

    // ERP상품코드가 있을경우
    if (productSnapshot.data()?.item_cd) {
      // console.log('#productSnapshot.data(): ', productSnapshot.data());
      setErpItemCd(productSnapshot.data().item_cd);
    }

    const pdataId = productSnapshot.data().pdata._id;

    const pdataSnapshot = await getDoc(doc(firebaseDB, 'pdata', pdataId)).catch((error) => ({ error }));

    // 와인 정보 조회 중 오류 발생
    if (pdataSnapshot.error) {
      console.error('[와인상세정보] 와인 정보 조회 중 오류 발생 > ', pdataSnapshot.error);
      WoAlert.fire('와인상세정보', '와인 정보 조회 중 오류가 발생했습니다..<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        onClose();
      });
      return false;
    }

    // 올바르지 않은 와인 정보
    if (!pdataSnapshot.exists()) {
      console.error('[와인상세정보] 올바르지 않은 와인 정보 입니다.');
      WoAlert.fire(
        '와인상세정보',
        `올바르지 않은 와인 정보 입니다.<br />[pdataId = ${pdataId}]<br /><br />잠시 후 다시 시도해주세요.`,
        'error'
      ).then(() => {
        onClose();
      });
      return false;
    }
    const tmpPdata = pdataSnapshot.data();
    setPdata(tmpPdata);
    setLoading(false);
  };

  // 와인 타입
  const wineType = (val) => {
    let label = '-';
    if (val === 'RED') label = '레드';
    if (val === 'WHITE') label = '화이트';
    if (val === 'ROSE') label = '로제';
    if (val === 'SPARKLING') label = '스파클링';
    return label;
  };

  return (
    <SwipeableDrawer id="detail-drawer" anchor="bottom" open={openProp} onOpen={onClose} onClose={onClose}>
      <AppBar position="sticky" sx={{ display: 'flex', mt: 2, mb: 1, flexDirection: 'row' }}>
        <IconButton
          sx={{ pl: 2 }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography position="absolute" component="span" fontWeight="bold" alignSelf="center" fontSize={18} textAlign="center" left="35%">
          와인 상세 정보
        </Typography>
      </AppBar>
      <Container sx={{ mt: 2, height: 'calc(100vh - 54px - 16px)' }}>
        <Box height="100%" display="flex" justifyContent="space-between" flexDirection="column">
          <Box>
            {/* 와인 이미지 */}
            {loading && <Skeleton variant="rectangular" height={300} />}
            {!loading && pdata.bottle_img?.origin !== '' && (
              <Box
                component="img"
                width="100%"
                height={300}
                textAlign="center"
                src={pdata?.bottle_img?.origin}
                sx={{ objectFit: 'contain' }}
              />
            )}
            {!loading && pdata.bottle_img?.origin === '' && (
              <Box component="img" width="100%" height={300} textAlign="center" src={NoResultImg} sx={{ objectFit: 'contain' }} />
            )}
          </Box>
          <Box pb={3}>
            {/* 와인 이름 */}
            <Box mt={3}>
              {loading ? (
                <Skeleton variant="rectangular" height="52px" />
              ) : (
                <Box>
                  <Typography variant="h5" fontWeight={600}>
                    {pdata?.name?.ko}
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    {pdata?.name?.en}
                  </Typography>
                </Box>
              )}
              {erpItemCd !== null && (
                <Box mt={1.5}>
                  <Typography sx={{ userSelect: 'text' }}>ERP상품코드: {erpItemCd}</Typography>
                </Box>
              )}
            </Box>

            {/* 와인 상세 정보 */}
            <Box mt={3} borderBottom="1px solid #2D154D" pb={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                와인 상세 정보
              </Typography>
            </Box>
            <Table sx={{ mt: 1 }} size="small">
              <colgroup>
                <col width="25%" />
                <col width="75%" />
              </colgroup>
              <TableBody>
                {/* 와인 종류 */}
                <TableRow>
                  <TableCell sx={{ padding: 0, border: 'none' }}>
                    <Typography variant="body2" fontWeight={600}>
                      와인 종류
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography variant="body2">{`${wineType(pdata?.type)} (${pdata?.type})`}</Typography>
                    )}
                  </TableCell>
                </TableRow>

                {/* 생산자 */}
                <TableRow>
                  <TableCell sx={{ padding: 0, border: 'none' }}>
                    <Typography variant="body2" fontWeight={600}>
                      생산자
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography variant="body2">
                        {pdata?.producer?.ko || '-'} {pdata?.producer?.en ? `(${pdata.producer.en})` : null}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: 0, border: 'none' }}>
                    <Typography variant="body2" fontWeight={600}>
                      생산 국가
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography variant="body2">
                        {`${pdata?.country?.ko}`} {pdata?.place?.ko ? ` > ${pdata.place.ko}` : null}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: 0, border: 'none' }}>
                    <Typography variant="body2" fontWeight={600}>
                      주요 품종
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography variant="body2">{`${pdata?.variety?.ko || '-'} (${pdata?.variety?.en || '-'})`}</Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: 0, border: 'none' }}>
                    <Typography variant="body2" fontWeight={600}>
                      수입사
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    {loading ? <Skeleton variant="text" /> : <Typography variant="body2">{pdata?.importer?.name || '-'}</Typography>}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Container>
    </SwipeableDrawer>
  );
}

ProductDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pid: PropTypes.string
};

export default ProductDetail;
