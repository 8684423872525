import React from 'react';
import { Route } from 'react-router-dom';
import IntroIndex from 'views/intro/IntroIndex';
import AuthGuard from 'utils/route-guard/AuthGuard';

/**
 * 인덱스 라우트
 */
export default function IntroRoutes() {
  return (
    <Route
      path="/intro"
      element={
        <AuthGuard>
          <IntroIndex />
        </AuthGuard>
      }
    />
  );
}
