import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

// project import
import { ToolbarTitle } from 'components/toolbar';
import RequestWait from './tab/ReqWait/RequestWait';
import ExchangeReq from './tab/ReqExcWait/ExchangeReq';
import ReturnReq from './tab/ReqRtnWait/ReturnReq';

// material-ui
import { AppBar, Box, Container, Tab, Tabs, Toolbar, Typography } from '@mui/material';

// assets import
import BackIcon from 'assets/images/arrow-back.png';

// tab content
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// tab options
const tabsOption = [
  {
    label: '승인대기',
    icon: <Box sx={{ width: '6px', height: '6px' }} bgcolor="#94D210" borderRadius="50%" />
  },
  {
    label: '교환신청',
    icon: <Box sx={{ width: '6px', height: '6px' }} bgcolor="#F7A600" borderRadius="50%" />
  },
  {
    label: '반품신청',
    icon: <Box sx={{ width: '6px', height: '6px' }} bgcolor="#F5555A" borderRadius="50%" />
  }
];

/**
 * 주문 승인 대기 (승인대기, 교환신청, 반품신청) 탭
 * @constructs
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqTabs() {
  const navigate = useNavigate();

  const location = useLocation();

  // tab state
  const [tabValue, setTabValue] = React.useState(0);

  // param
  React.useEffect(() => {
    setTabValue(location.state.num);
  }, []);

  // tab change
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // tab change
  const tabHandleChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <Box>
      {/* HEADER */}
      <AppBar position="sticky">
        <Container maxWidth="xs" sx={{ p: [0, 0, 0, 0] }}>
          <Toolbar sx={{ p: '10px 44px 10px 20px', minHeight: ['54px', '54px', '54px', '54px'] }}>
            <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
            <ToolbarTitle>주문 승인 대기</ToolbarTitle>
          </Toolbar>
        </Container>
      </AppBar>
      {/* CONTENTS */}
      <Container maxWidth="xs" sx={{ p: [0, 0, 0, 0] }}>
        <Tabs variant="fullWidth" value={tabValue} onChange={tabHandleChange} sx={{ borderBottom: '1px solid #1303281A' }}>
          {tabsOption.map((tab, index) => (
            <Tab
              key={index}
              label={
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" fontWeight={index === tabValue ? 600 : 500} mr={1}>
                    {tab.label}
                  </Typography>
                  {tab.icon}
                </Box>
              }
            />
          ))}
        </Tabs>
        <SwipeableViews index={tabValue} onChangeIndex={tabHandleChangeIndex}>
          <TabPanel value={tabValue} index={0}>
            <RequestWait />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ExchangeReq />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ReturnReq />
          </TabPanel>
        </SwipeableViews>
      </Container>
    </Box>
  );
}

export default ReqTabs;
