import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function Container({ children, ...rest }) {
  return (
    <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin="0 auto" padding={2} {...rest}>
      {children}
    </Box>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
