import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

// project imports
import { useDispatch, useSelector } from 'store';
import { openAlarmDrawer, closeAlarmDrawer } from 'store/slices/alarm';
import { NotificationIcon } from 'components/icons';

// material-ui
import { Badge, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AlarmDrawer } from './components';

/**
 * Alarm button
 * @param {number|string} width
 * @param {number|string} height
 * @param {string} color
 * @param {number} count
 * @param {boolean} showBadge
 * @param {boolean|string} edge
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
function AlarmButton({ width = 34, height = 34, color: colorProp, count = 0, showBadge = false, edge = false, ...other }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const alarm = useSelector((state) => state.alarm);

  useEffect(() => {
    const alarmDrawerOpen = isAlarmOpen();
    if (alarmDrawerOpen) {
      dispatch(openAlarmDrawer());
      // todo George: Alarm open
    } else {
      dispatch(closeAlarmDrawer());
      // todo George: Alarm close
    }
  }, [searchParams.get('ado')]);

  function isAlarmOpen() {
    return searchParams.get('ado') === '1';
  }

  const handleClose = () => {
    navigate(-1);
    // const search = createSearchParams();
    // searchParams.forEach((val, key) => {
    //   search.set(key, val);
    // });
    // search.delete('ado');
    // navigate({ search: search.toString() });
  };

  return (
    <>
      <ButtonBase
        aria-label="알림"
        edge={edge}
        {...other}
        onClick={() => {
          const search = createSearchParams();
          searchParams.forEach((val, key) => {
            search.set(key, val);
          });

          if (isAlarmOpen()) {
            search.delete('ado');
          } else {
            search.set('ado', 1);
          }

          navigate({ search: search.toString() });
        }}
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={!showBadge || count === 0}
          sx={{ '& .MuiBadge-dot': { height: '5px', minWidth: '5px', top: '4px', right: '4px' } }}
        >
          <NotificationIcon color={colorProp ?? theme.palette.text.primary} width={34} height={34} />
        </Badge>
      </ButtonBase>

      {/* 알림 Drawer */}
      <AlarmDrawer open={alarm.open} onClose={handleClose} />
    </>
  );
}

AlarmButton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  count: PropTypes.number,
  showBadge: PropTypes.bool,
  edge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default AlarmButton;

const ButtonBase = styled((props) => <IconButton {...props} />)`
  border-radius: 50%;
  padding: 0;
  margin-left: 13px;
`;
