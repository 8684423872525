import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 *
 * @param {number} px
 * @param {string} color
 * @param {'fullWidth' | 'inset' | 'middle' | string}
 * @returns {JSX.Element}
 * @constructor
 */
function WoDivider({ px = 2.5, pt = 0, color }) {
  const theme = useTheme();
  return (
    <Box px={px} pt={pt}>
      <Divider color={color ?? theme.palette.brand.main} sx={{ height: '2px' }} />
    </Box>
  );
}

WoDivider.propTypes = {
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
};

export default WoDivider;
