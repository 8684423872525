import PropTypes from 'prop-types';
import moment from 'moment/moment';

/**
 * Firebase 픽업일시 format (YYYY.MM.DD (요일) 오전/오후 HH:mm)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const FDateformatYMDaHm = (timeStamp) => {
  const formatDate = timeStamp.toDate();
  const date = moment(formatDate).format('YYYY.MM.DD');
  const type = moment(formatDate).format('a');
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[moment(formatDate).toDate().getDay()];
  const time = moment(formatDate).format('hh:mm');

  return `${date} (${dayOfWeek}) ${type} ${time}`;
};

export default FDateformatYMDaHm;

FDateformatYMDaHm.propTypes = {
  seconds: PropTypes.number
};
