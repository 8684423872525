import axios from 'axios';
import { FIREBASE_FUNCTION_URL, NODE_URL } from './index';

export { getProduct };

/**
 * product 조회
 * @param id product 아이디
 * @returns {Promise<AxiosResponse<any>>}
 */
async function getProduct(id) {
  return axios.get(FIREBASE_FUNCTION_URL.GET__PRODUCT_ONE, { params: { id } });
}

/**
 * product list 조회
 */
export async function getProductList(productParams) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__PRODUCT_LIST, { ...productParams });
}

/**
 * 프로모션 상품 목록 조회
 */
export async function getPromotionProductList(params) {
  return axios.post(NODE_URL.POST__PROMO_PRODUCT_LIST, { ...params });
}

/**
 * product 생성
 */
export async function createProduct(productParams) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__CREATE_PRODUCT, { ...productParams });
}

/**
 * 상품 삭제
 */
export async function deleteProduct(productId) {
  return axios.delete(`${FIREBASE_FUNCTION_URL.DELETE__DELETE_PRODUCT}?id=${productId}`);
}

/**
 * 상품 수정
 */
export async function updateProduct(productParams) {
  return axios.put(FIREBASE_FUNCTION_URL.PUT__UPDATE_PRODUCT, { ...productParams });
}

/**
 * DB 등록 요청
 */
export async function requestCreatePdata(params) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__REQ_CREATE_PDATA, { ...params });
}
