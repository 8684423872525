import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/ko';
import moment from 'moment';
import PropTypes from 'prop-types';

// import mui
import { Box, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@mui/lab';

// import components
import ShadowBox from 'components/ShadowBox';
import OrderChip from 'components/chip/OrderChip';

// import icons
import { TimelineAfterIcon, TimelineBeforeIcon, TimelineNowIcon } from 'components/icons';

// import projects
import { bottleCapacityToString } from 'config';

/**
 * 교환대기 목록 타임라인
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ExcWaitTimeline({ rows }) {
  const navigate = useNavigate();

  // 교환대기 타임라인 아이콘 설정
  const timelineIconSetting = (val) => {
    const now = moment().format('YYYY-MM-DD');
    const excDate = moment(val).format('YYYY-MM-DD');

    let result = null;

    if (now > excDate) {
      result = (
        <Box margin="6.5px 0">
          <TimelineBeforeIcon />
        </Box>
      );
    } else if (now === excDate) {
      result = (
        <Box margin="6.5px 0">
          <TimelineNowIcon />
        </Box>
      );
    } else if (now < excDate) {
      result = (
        <Box margin="5.5px 0">
          <TimelineAfterIcon />
        </Box>
      );
    }

    return result;
  };

  // 교환대기 타임라인 타이틀 설정
  const timelineIconTitle = (val) => {
    const now = moment().format('YYYY. MM. DD');
    const excDate = moment(val).format('YYYY. MM. DD');

    // return 요일
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(moment(excDate, 'YYYY.MM.DD')).getDay()];

    let result = null;

    if (now > excDate) {
      result = (
        <Typography component="span" color={'#13032866'}>
          {excDate} {dayOfWeek}요일
        </Typography>
      );
    } else if (now === excDate) {
      result = (
        <Typography component="span" color={'#130328'} fontWeight={600}>
          {excDate} {dayOfWeek}요일
        </Typography>
      );
    } else if (now < excDate) {
      result = (
        <Typography component="span" color={'#130328B2'}>
          {excDate} {dayOfWeek}요일
        </Typography>
      );
    }

    return result;
  };

  // 교환지연 칩 설정
  const excWaitLateChip = (val) => {
    const now = moment().format('YYYY. MM. DD');
    const excDate = moment(val).format('YYYY. MM. DD');

    let result = null;

    if (now > excDate) {
      result = <OrderChip val="EXC_WAIT_LATE" />;
    }

    return result;
  };

  return (
    <Timeline id="visit-timeline">
      {rows.map((row, index) => (
        <TimelineItem key={index} sx={{ pb: 2 }}>
          <TimelineSeparator>
            {timelineIconSetting(rows[index][0].exc_date)}
            <TimelineConnector sx={{ backgroundColor: '#13032833', width: '1px' }} />
          </TimelineSeparator>
          <TimelineContent>
            <Box mb={1}>{timelineIconTitle(rows[index][0].exc_date)}</Box>
            {rows[index].map((item, idx) => (
              <ShadowBox
                key={idx}
                sx={{
                  marginTop: '11px',
                  backgroundColor: '#ffffff',
                  boxShadow: '0px 0px 26px #1303281A',
                  padding: '0 16px',
                  border: '1px solid #ffffff',
                  borderRadius: '10px !important'
                }}
              >
                <Box margin="12px 0">
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography component="span" fontWeight={600} mr={1}>
                      {item.user__uname}
                    </Typography>
                    <OrderChip val={item.state} />
                    {excWaitLateChip(rows[index][0].exc_date)}
                  </Box>
                  <Typography
                    className="line-1-ellipsis"
                    variant="body2"
                    color="#33333399"
                    onClick={() => navigate(`/order/${item.oid}/${item.id}`)}
                  >
                    {item.name__ko} {`${bottleCapacityToString(item.capacity)}`}/{`${item.vintage}년`} {item.quantity}개
                  </Typography>
                </Box>
              </ShadowBox>
            ))}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default ExcWaitTimeline;

ExcWaitTimeline.propTypes = {
  rows: PropTypes.array.isRequired
};
