import React from 'react';
import { Route } from 'react-router-dom';
import ReqTabs from 'views/order/ReqTabs';
import OrderDetailTab from 'views/order/detail/order/index';
import ExcRtnCancel from 'views/order/product/ExcRtnCancel';
import OrderProductDetailTab from 'views/order/detail/product/index';

/**
 * 주문관련 라우트
 */
export default function OrderRoutes() {
  return (
    <Route path="/order">
      <Route index element={<ReqTabs />} />
      <Route path={':id'} element={<OrderDetailTab />} />
      <Route path={':oid/:id/reject'} element={<ExcRtnCancel />} />
      <Route path={':oid/:id'} element={<OrderProductDetailTab />} />
    </Route>
  );
}
