// Setup: Firebase functions
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getFirestore as _getFirestore } from 'firebase/firestore';
import { getApp } from 'firebase/app';

/**
 * Firebase funcitons 가져오기
 * @returns {Functions} firebase functions
 */
export const getFirebaseFunctions = () => {
  const functions = getFunctions(getApp(), process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION);

  // 로컬 firebase emulator를 사용시
  if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
    const { REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_HOST: host, REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT: port } = process.env;
    // https://firebase.google.com/docs/emulator-suite/connect_functions#web-version-9
    connectFunctionsEmulator(functions, host, port);
  }

  return functions;
};

/**
 * Firestore 가져오기
 * @returns {Firestore}
 */
export const getFirestore = () => {
  /*
  const db = _getFirestore(getApp());
  // 로컬 firebase emulator를 사용시
  if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
    const { REACT_APP_FIREBASE_EMULATOR_FIRESTORE_HOST: host, REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT: port } = process.env;
    connectFirestoreEmulator(db, host, port);
  }
  return db;
*/
  return _getFirestore(getApp());
};
