import React from 'react';

// material-ui
import { Chip } from '@mui/material';

/**
 * 주문 상품의 상태 칩
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderProductStateChip(state) {
  let label = state;
  let bgColor;
  let fontColor = '#fff';

  // 승인대기 (REQ)
  if (state === 'REQ') {
    label = '승인대기';
    bgColor = '#0077FF';
  }
  // 픽업대기 (REQ_OK || REQ_PART_OK)
  else if (state === 'REQ_OK' || state === 'REQ_PART_OK') {
    label = '픽업대기';
    bgColor = '#94D210';
  }
  // 취소완료 (CANCEL || REQ_NO)
  else if (state === 'CANCEL' || state === 'REQ_NO') {
    label = '취소완료';
    bgColor = '#fff';
    fontColor = '#000';
  }
  // 교환 (REQ_EXC, EXC_WAIT, EXC_OK, EXC_NO)
  else if (state === 'REQ_EXC') {
    label = '교환신청';
    bgColor = '#F7A600';
  } else if (state === 'EXC_WAIT') {
    label = '교환대기';
    bgColor = '#F7A600';
  } else if (state === 'EXC_OK') {
    label = '교환완료';
    bgColor = '#F7A600';
  } else if (state === 'EXC_NO') {
    label = '교환거절';
    bgColor = '#F7A600';
  }
  // 반품 (REQ_RTN, RTN_WAIT, RTN_OK, RTN_NO)
  else if (state === 'REQ_RTN') {
    label = '반품신청';
    bgColor = '#F5555A';
  } else if (state === 'RTN_WAIT') {
    label = '반품대기';
    bgColor = '#F5555A';
  } else if (state === 'RTN_OK') {
    label = '반품완료';
    bgColor = '#F5555A';
  } else if (state === 'RTN_NO') {
    label = '반품거절';
    bgColor = '#F5555A';
  }
  // 픽업완료 (PICK_OK, CONFIRM)
  else if (state === 'PICK_OK') {
    label = '픽업완료';
    bgColor = '#94D210';
  } else if (state === 'CONFIRM') {
    label = '구매확정';
    bgColor = '#94D210';
  }
  // 공동구매
  else if (state === 'CO_BUY') {
    label = '공동구매';
    bgColor = '#9357E5';
  }
  // 플랫폼 프로모션 (reserve 타입)
  else if (state === 'PLATFORM_PROMOTION_RESERVE') {
    label = '예악판매';
    bgColor = '#3CAE47';
  }
  // 플랫폼 프로모션 (product 타입)
  else if (state === 'PLATFORM_PROMOTION_PRODUCT') {
    label = '프로모션';
    bgColor = '#9357E5';
  } else {
    bgColor = '#fff';
    fontColor = '#000';
    label = 'ERROR';
  }

  return (
    <Chip
      size="small"
      label={label}
      sx={{
        width: '68px',
        fontSize: '11px',
        fontWeight: 600,
        color: fontColor,
        backgroundColor: bgColor,
        border: (state === 'CANCEL' || state === 'REQ_NO') && '1px solid #000'
      }}
    />
  );
}

export default OrderProductStateChip;
