import React from 'react';
import { getAuth } from 'firebase/auth';

// projects import
import { useScriptRef } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import * as CalculateService from 'services/CalculateService';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ExclamationMark, ResetIcon } from 'components/icons';

// material-ui
import { Box, Card, Stack, Typography } from '@mui/material';

/**
 * [홈] - 정산정보
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>, 최효근<hkchoi@wineone.io>
 */
function CalculateInfo() {
  const scriptedRef = useScriptRef();
  const { currentUser } = getAuth();

  // i 아이콘 클릭
  const [infoMarkClicked, setInfoMarkClicked] = React.useState(false);
  // 정산 예정 내역 합계
  const [totalCalculateAmount, setTotalCalculateAmount] = React.useState(null);

  // refresh icon shake
  const [shake, setShake] = React.useState(false);

  React.useEffect(() => {
    if (currentUser) searchCalculate();
  }, []);

  // i 아이콘 클릭 핸들러
  const infoMarkClickHandler = () => {
    setInfoMarkClicked((prevState) => !prevState);
  };

  // 정산 예정 내역 합계 조회
  const searchCalculate = async () => {
    setTotalCalculateAmount(null);

    const body = {
      page: {
        current: 1
      },
      calculate_state: 'OK'
    };

    const result = await CalculateService.calculateGetList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[CalculateInfo][searchCalculate] Unmounted component');
      return;
    }

    if (result.error) {
      console.error('[CalculateInfo][searchCalculate] 정산 예정 내역 합계 조회 중 오류 발생 > ', result.error);
      WoAlert.fire('', '정산 정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      return undefined;
    }

    const {
      data: {
        total: { calculate_amount }
      }
    } = result;

    setTotalCalculateAmount(calculate_amount.toLocaleString());
  };

  // 새로고침 아이콘 클릭이벤트
  const refresh = async () => {
    setShake(true);
    await searchCalculate().finally(() => {
      setShake(false);
    });
  };

  // render
  return (
    <Box sx={{ position: 'relative', mb: '16px' }}>
      <Card sx={{ mt: '16px', boxShadow: '0px 2px 6px #0000000A', p: '16px' }}>
        <SpaceBetweenBox>
          <Stack direction="row" spacing="2px" alignItems="center">
            <Typography fontSize="12px" fontWeight="bold" lineHeight="normal">
              정산 예정 내역 합계
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              onClick={() => {
                infoMarkClickHandler();
              }}
            >
              <ExclamationMark />
            </Box>
          </Stack>

          <Stack direction="row" spacing="8px" alignItems="center" onClick={() => refresh()}>
            <Typography sx={{ fontSize: '12px', color: '#9357E5', lineHeight: 'normal' }}>
              {totalCalculateAmount ? `${totalCalculateAmount}원` : '- 원'}
            </Typography>
            <Box display="flex" alignItems="center" className={shake ? 'refresh_shake' : 'refresh'}>
              <ResetIcon width="13px" height="13px" />
            </Box>
          </Stack>
        </SpaceBetweenBox>
      </Card>

      {infoMarkClicked && (
        <Box position="absolute" top="70%" border="1px solid #c3b5d5" padding="4px" borderRadius="5px" sx={{ backgroundColor: '#fff' }}>
          <Typography color="#c3b5d5" fontSize="9px">
            자세한 정산 내역은 관리자웹에서 확인 할 수 있어요.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default CalculateInfo;
