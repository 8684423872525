import React from 'react';
import PropTypes from 'prop-types';

// project imports
import Footer from 'components/Footer';

// material-ui
import { Box } from '@mui/material';

// @Deprecated - <Main />을 사용하고 있는 페이지 모두 제거할 것
function Main({ children, showFooter = true }) {
  return (
    <Box>
      <main style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>{children}</main>
      {showFooter && <Footer />}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  showFooter: PropTypes.bool
};

export default Main;
