import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Slide } from '@mui/material';

export { BottomDialog };

// 하단 다이얼로그
const BottomDialog = styled((props) => <Dialog fullWidth variant="bottom" TransitionComponent={Transition} scroll={'paper'} {...props} />)({
  '& .MuiDialog-paper': {
    position: 'absolute',
    bottom: 0,
    maxWidth: 'sm',
    width: '100%',
    minHeight: 'calc(var(--vh, 1vh) * 10)',
    margin: 0,
    paddingBottom: ['constant(safe-area-inset-bottom)', 'env(safe-area-inset-bottom)'],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
