import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button } from '@mui/material';

// project imports
import LoadingLayout from 'components/loading/LoadingLayout';
import { WoAlert } from 'utils/kmwine-alerts';
import * as OrderService from 'services/OrderService';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';

/**
 * 주문상품 상태가 'EXC_WAIT'만 접근 가능함
 * 교환완료 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ExcWaitAction({ product, reloadOrder }) {
  const location = useLocation();

  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 교환 완료 컨펌 모달
  const excOkConfirm = () => {
    WoAlert.fire({
      title: '교환 완료 처리',
      html: `<div style='line-height: 1.5rem;'>고객님이 가져오신 상품을 확인했고,<br />교환이 완료되었다고 처리 됩니다</div>`,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '승인',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        procExcWait();
      }
    });
  };

  // 교환 완료 하기
  const procExcWait = async () => {
    console.log(`[교환완료] 주문번호(${product.oid})의 상품ID(${product.id}) 교환 완료 처리 시작`);
    setLoading(true);

    const body = {
      oid: product.oid,
      order_product_ids: [product.id],
      msg: ''
    };

    const result = await OrderService.orderProductExcOk(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setLoading(false);
      });

    // 교환 완료 처리 중 오류 발생
    if (result.error) {
      sendCloError(result.error);
      console.error(`[교환완료] 주문번호(${product.oid})의 상품ID(${product.id}) 교환 완료 처리 중 오류 발생 > `, result.error);
      WoAlert.fire('교환 완료', '교환 완료 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError(msg);
      console.error(`[교환완료] 주문번호(${product.oid})의 상품ID(${product.id}) 교환 완료 처리 중 오류 발생 > `, msg);
      WoAlert.fire('교환 완료', `교환 완료 처리 중 오류가 발생했습니다.<br />[${msg}]<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    console.log(`[교환완료] 주문번호(${product.oid})의 상품ID(${product.id}) 교환 완료 처리 완료`);
    WoAlert.fire('교환 완료', '교환 완료 처리되었습니다.', 'success').then(() => {
      reloadOrder();
    });
  };

  // CLO 에러 전송
  const sendCloError = (errorMsg) => {
    // clo
    const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
    sendCloError({
      code: CLO_CODE.ORDER_REQUEST_ERROR,
      title: `[VENDOR APP] 주문 상품 교환 완료 요청 오류 [oid=${product.oid} / orderProductId=${product.id}] [error=${errorMsg}]`,
      msg: `${JSON.stringify(errorMsg)}`,
      which: `${location.pathname}${location.search}`
    })
      .then(console.log)
      .catch(console.error);
  };

  return (
    <>
      <LoadingLayout open={loading} text="교환완료 처리 중 입니다." />
      <Box
        width="100%"
        height="70px"
        padding="10px 20px"
        position="fixed"
        bottom={0}
        right={0}
        bgcolor="#ffffff"
        boxShadow="0 15px 20px 3px rgba(0,0,0,0.8)"
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            height: '50px',
            backgroundColor: '#130328',
            borderRadius: '25px',
            color: '#ffffff',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={() => excOkConfirm()}
        >
          교환완료
        </Button>
      </Box>
    </>
  );
}

export default ExcWaitAction;

ExcWaitAction.propTypes = {
  product: PropTypes.object,
  reloadOrder: PropTypes.func
};
