import axios from 'axios';
import { FIREBASE_FUNCTION_URL } from './index';
import { CLO_CODE } from 'config';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { httpsCallable } from 'firebase/functions';

/**
 * 전체 주문 목록
 */
export async function getOrderAllList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_ALL_LIST, { ...body });
}

/**
 * 주문 승인 대기(승인대기, 교환신청, 반품신청), 매장 방문 대기(픽업대기, 교환대기, 반품대기) 카운트
 */
export async function waitingOrderCount() {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__WAITING_ORDER_COUNT)
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 주문승인대기/매장방문대기 카운트 조회 중 오류 발생 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `waitingOrderCount`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 승인대기 목록
 */
export async function requestWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__REQ_WAIT_LIST, { ...body });
}

/**
 * 교환신청 목록
 */
export async function requestExcWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__REQ_EXC_LIST, { ...body });
}

/**
 * 반품신청 목록
 */
export async function requestRtnWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__REQ_RTN_LIST, { ...body });
}

/**
 * 픽업대기 목록
 */
export async function pickupWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__PICK_WAIT_LIST, { ...body });
}

/**
 * 교환대기 목록
 */
export async function excWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__EXC_WAIT_LIST, { ...body });
}

/**
 * 반품대기 목록
 */
export async function rtnWaitList(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__RTN_WAIT_LIST, { ...body });
}

/**
 * 주문 승인/거절/부분승인 통합
 */
export async function orderReq(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_REQ, { ...body });
}
/*
/!**
 * 주문 승인 하기 todo: 삭제(미사용)
 *!/
export async function orderReqOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_REQ_OK, { ...body });
}

/!**
 * 주문 거절 하기 todo: 삭제(미사용)
 *!/
export async function orderReqNo(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_REQ_NO, { ...body });
}
*/

/**
 * 주문 픽업 처리 하기
 */
export async function orderPickOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PICK_OK, { ...body });
}

/**
 * 교환 요청 승인 처리
 */
export async function orderProductReqExcOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_REQ_EXC_OK, { ...body });
}

/**
 * 교환 요청 거절 처리
 */
export async function orderProductReqExcNo(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_REQ_EXC_NO, { ...body });
}

/**
 * 교환 완료 처리
 */
export async function orderProductExcOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_EXC_OK, { ...body });
}

/**
 * 주문 상품 반품 요청 승인 하기
 */
export async function orderProductReqRtnOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_REQ_RTN_OK, { ...body });
}

/**
 * 주문 상품 반품 요청 거절 하기
 */
export async function orderProductReqRtnNo(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_REQ_RTN_NO, { ...body });
}

/**
 * 주문 상품 반품 완료 처리 하기
 */
export async function orderProductRtnOk(body) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__ORDER_PRODUCT_RTN_OK, { ...body });
}
