import React from 'react';
import { Route } from 'react-router-dom';
import VisitTabs from 'views/visit';

/**
 * 매장 방문대기 관련 라우트
 */
export default function VisitRoutes() {
  return <Route path="/visit" element={<VisitTabs />} />;
}
