// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;

export const FIREBASE_FUNCTION_URL = {
  /** 키워드 알림 등록 */
  POST__KEYWORD_CREATE: `${BASE_HOST}/call-cdm-keyword-create`,
  /** 키워드 알림 해제 */
  POST__KEYWORD_DELETE: `${BASE_HOST}/call-cdm-keyword-delete`
};

export * from './terms'; // 약관 및 정책
