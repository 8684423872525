import React from 'react';
import { usePagination } from 'hooks';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography, Pagination, PaginationItem } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarsIcon from '@mui/icons-material/Stars';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// 전역변수 (전 페이지 state 저장)
let moreState = false;
let pageState = 1;

function ServiceCenter({ noticeData }) {
  const navigate = useNavigate();
  // pagination
  const [page, setPage] = React.useState(pageState);
  // 페이지 당 출력 갯수
  const PER_PAGE = 10;
  // 총 페이지 수
  const count = Math.ceil(noticeData.length / PER_PAGE);
  // 데이터
  const _DATA = usePagination(noticeData, PER_PAGE);
  // 더보기 버튼 상태
  const [more, setMore] = React.useState(moreState);

  // 더보기 버튼 클릭 이벤트
  const changeMore = () => {
    setMore(!more);
    moreState = !moreState;
  };

  const pageChange = (event, p) => {
    pageState = p;
    setPage(p);
    _DATA.jump(p);
  };

  React.useEffect(() => {
    if (noticeData !== []) {
      setPage(pageState);
      _DATA.jump(pageState);
    }
  }, [noticeData]);

  return (
    <>
      <Box
        height={'calc(100vh - 54px - 60px - 56px)'}
        paddingBottom={!more ? '108px' : '0px'}
        bgcolor={'#EEEEEE'}
        sx={{ transition: more && 'all .2s ease-in-out' }}
      >
        <div
          style={{
            backgroundColor: '#ffffff',
            height: '100%',
            overflow: more ? 'auto' : 'hidden',
            position: 'relative',
            transition: 'all .2s ease-in-out',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* 공지사항 */}
          <Box display={'flex'} alignItems={'center'} padding={'12px 20px'}>
            <NotificationsIcon sx={{ color: '#FEC466', width: '20px', height: '24px' }} />
            <Typography fontSize={16} fontWeight={'bold'} marginLeft={1}>
              Notice
            </Typography>
          </Box>
          {/* 공지사항 목록 */}
          <Box>
            {noticeData.length > 0 &&
              _DATA.currentData().map((item) => (
                <Box padding={'0 20px'} pb={2} key={item.id.raw}>
                  <Box
                    onClick={() => navigate(`notice/${item.id.raw}`)}
                    padding={'13px 20px'}
                    sx={{ boxShadow: '0 3px 6px 2px #00000029', borderRadius: '8px !important' }}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      {item.important.raw === 'true' && <StarsIcon sx={{ color: '#FEC466', marginRight: '10px' }} />}
                      <Box className={'line-1-ellipsis'}>
                        <Typography variant={'span'} fontSize={12} fontWeight={700}>
                          {item.title.raw}
                        </Typography>
                      </Box>
                    </Box>
                    <Box fontSize={12} color={'#B0B0B0'} mt={1}>
                      {item.created_at.raw}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box display={'flex'} sx={{ transition: 'all .2s ease-in-out' }} justifyContent={'center'} alignItems={'center'}>
            <Pagination
              size={'small'}
              count={count}
              page={page}
              onChange={pageChange}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: page === 1 ? null : NavigateBeforeIcon, next: page === count ? null : NavigateNextIcon }}
                  sx={{ mt: 1, mb: 1 }}
                  {...item}
                />
              )}
            />
          </Box>
          <Box
            marginTop={'auto'}
            border={'1px solid #EEEEEE'}
            position={'sticky'}
            width={'100%'}
            minHeight={'50px'}
            bgcolor={'#ffffff'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bottom={0}
            left={0}
            onClick={() => changeMore()}
          >
            <Typography variant={'span'} fontSize={12} fontWeight={700}>
              {more ? '결과 전체 보기 닫기' : '결과 더 보기'}
            </Typography>
            <ExpandMoreIcon sx={{ transform: more && 'rotate(180deg)', transition: 'all .2s ease-in-out' }} />
          </Box>
        </div>
      </Box>
      <Box
        display={more && 'none'}
        position={'fixed'}
        bottom={0}
        width={'100%'}
        bgcolor={'#ffffff'}
        padding={'20px'}
        mb={1}
        sx={{ transition: 'all .2s ease-in-out' }}
      >
        <Box
          sx={{ backgroundColor: '#220348' }}
          display={'flex'}
          padding={'12px'}
          alignItems={'center'}
          borderRadius={'8px'}
          justifyContent={'space-between'}
          onClick={() => {
            navigate('inquiry');
          }}
        >
          <EditIcon sx={{ color: '#FFFFFF' }} />
          <Typography component="span" fontSize={16} fontWeight={'500'} color={'#FFFFFF'}>
            1:1 문의 및 문의 내역 확인
          </Typography>
          <ArrowForwardIosIcon sx={{ color: '#FFFFFF' }} fontSize={'small'} />
        </Box>
      </Box>
    </>
  );
}

ServiceCenter.propTypes = {
  noticeData: PropTypes.array
};

export default ServiceCenter;
