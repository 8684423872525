import React from 'react';

// project imports
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import AlarmButton from './AlarmButton';

// material-ui
import { AppBar, Toolbar, Typography } from '@mui/material';

/**
 * 홈 헤더
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
export function Header() {
  // 알람버튼
  const alarmButtonComponent = React.useMemo(() => <AlarmButton showBadge aria-label="알림" />, []);

  // render
  return (
    <AppBar position="sticky">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SpaceBetweenBox sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 900,
              color: '#140229',
              letterSpacing: '0.18px',
              textShadow: '0px 0px 0.6px #140229'
            }}
          >
            1KMWINE 사장님
          </Typography>
          {/* 알림 */}
          {alarmButtonComponent}
        </SpaceBetweenBox>
      </Toolbar>
    </AppBar>
  );
}
