import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { AppBar, Box, Button, Stack, TextField, Toolbar, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackIcon from 'assets/images/arrow-back.png';
import { ToolbarTitle } from 'components/toolbar';
import { WoDivider } from 'components/dialog';

// firebase
import { query, collection, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import { firebaseDB } from 'index';

// axios
import axios from 'axios';
import { FIREBASE_HOSTNAME } from 'config';

// import loading
import LoadingLayout from 'components/loading/LoadingLayout';

// import snackbar
import SuccessSnackbar from 'components/snackbar/successSnackbar';
import ErrorSnackbar from 'components/snackbar/errorSnackbar';

// import icon
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ParkIcon from 'assets/images/serviceTag/ic_park.png';
import ValetIcon from 'assets/images/serviceTag/ic_valet.png';
import NightIcon from 'assets/images/serviceTag/ic_night.png';
import CoolIcon from 'assets/images/serviceTag/ic_cool.png';
import TasteIcon from 'assets/images/serviceTag/ic_taste.png';

// 서비스태그 스타일
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'block',
  '& .MuiToggleButtonGroup-grouped': {
    width: '70px',
    height: '28px',
    padding: 0,
    fontSize: '10px',
    color: '#140229',
    borderRadius: '15px',
    margin: '0 8px 8px 0',
    marginLeft: '0 !important',
    border: '1px solid #220348 !important',
    backgroundColor: '#FFFFFF',
    '&.Mui-selected': {
      border: '1px solid #220348 !important',
      backgroundColor: '#F4F0F8 !important',
      color: '#140229'
    },
    '&.MuiToggleButton-success': {
      // backgroundColor: '#F4F0F8'
    },
    '&:not(:first-of-type)': {
      borderRadius: '15px'
    },
    '&:first-of-type': {
      borderRadius: '15px'
    }
  }
}));

function MyInfoUpdate() {
  const { user } = useAuth();
  const navigate = useNavigate();

  // axios call token
  let HEADER_CONFIG;
  if (user) HEADER_CONFIG = { headers: { Authorization: `Bearer ${user.accessToken}` } };

  // 입점사 정보 조회 로딩
  const [loading, setLoading] = useState(false);
  // 입점사 정보 수정 로딩
  const [updateLoading, setUpdateLoading] = useState(false);
  // 서비스 태그 목록
  const [serviceTagList, setServiceTagList] = useState(null);
  // 입점사 정보
  const [vendor, setVendor] = useState(null);
  // 입점사 소개글
  const [vendorComment, setVendorComment] = useState('');
  // 입점사 서비스
  const [vendorService, setVendorService] = useState(() => []);
  // 입점사 수정 alert
  const [updateSnackbar, setUpdateSnackbar] = useState(false);
  // 입점사 수정 오류 alert
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  // 페이지 진입 시
  useEffect(() => {
    // 서비스 태그 조회
    getServiceTagList();
    // 입점사 정보 조회
    getVendorInformation();
  }, []);

  // 서비스 태그 조회ㅈ
  const getServiceTagList = async () => {
    const codeRef = query(collection(firebaseDB, 'code/v1.0.0/code_service_tag'), orderBy('order'));
    const codeSnapshot = getDocs(codeRef);
    const tagArray = [];

    codeSnapshot
      .then((res) => {
        res.forEach((doc) => {
          tagArray.push(doc.data());
        });
        setServiceTagList(tagArray);
      })
      .catch((e) => {
        console.error('[샵정보] 서비스 태그 목록 조회 오류 발생 > ', e);
      });
  };

  // 입점사 정보 조히
  const getVendorInformation = async () => {
    setLoading(true);
    const vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
    const vendorId = vendorReload.vendor;

    const vendorInformation = await getDoc(doc(firebaseDB, 'vendor', vendorId));

    if (vendorInformation.exists()) {
      setLoading(false);
      setVendor(vendorInformation.data());
      setVendorService(vendorInformation.data().shop.service_tag);
      setVendorComment(vendorInformation.data().shop.comment);
    } else {
      setLoading(false);
      console.error('[입점사정보 수정] 입점사 정보 조회 중 오류 발생 ');
    }
  };

  // 서비스 태그 변경
  const changeVendorService = (event, newVendorService) => {
    setVendorService(newVendorService);
  };

  // 입점사 수정
  const updateOk = () => {
    setUpdateLoading(true);

    const body = {
      id: vendor._id,
      shop: {
        biz_hours: vendor.shop.biz_hours,
        service_tag: vendorService,
        tel: vendor.shop.tel,
        comment: vendorComment
      },
      settlement: vendor.settlement,
      biz: {
        location_exp: {
          lat: vendor.biz.location_exp._lat,
          lon: vendor.biz.location_exp._long
        }
      }
    };

    axios
      .put(`${FIREBASE_HOSTNAME}/call-vendor-vendor-updateV`, body, HEADER_CONFIG)
      .then((res) => {
        if (res.data.result.code === 0) {
          setUpdateSnackbar(true);
          setTimeout(() => {
            setUpdateLoading(false);
            navigate(-1);
          }, 1000);
        } else {
          setUpdateLoading(false);
          setErrorSnackbar(true);
          console.error('[입점샵 정보] 입점샵 정보 수정 오류 발생 > ', res.data.result.msg);
        }
      })
      .catch((e) => {
        setUpdateLoading(false);
        setErrorSnackbar(true);
        console.error('[입점샵 정보] 입점샵 정보 수정 오류 발생 > ', e);
      });
  };

  // 영업시간 계산
  const operatingHours = (data, day) => {
    let className;
    let hours = '';

    // 휴무일 조회
    if (data.available) {
      className = 'detail-content-days';

      // 오픈시간 오전/오후 계산
      if (data.open_hour === 12) {
        const openAmHour = data.open_hour;
        let openAmMin = data.open_min;
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오후 ${openAmHour}:${openAmMin} ~ `;
      } else if (data.open_hour === 24) {
        let openAmMin = data.open_min;
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오전 00:${openAmMin} ~ `;
      } else if (data.open_hour < 12) {
        let openAmHour = data.open_hour;
        let openAmMin = data.open_min;
        if (openAmHour <= 10) openAmHour = String(openAmHour).padStart(2, '0');
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오전 ${openAmHour}:${openAmMin} ~ `;
      } else if (data.open_hour > 12) {
        let openPmHour = data.open_hour - 12;
        let openPmMin = data.open_min;
        if (openPmHour <= 10) openPmHour = String(openPmHour).padStart(2, '0');
        if (openPmMin === 0) openPmMin = String(openPmMin).padStart(2, '0');
        hours += `오후 ${openPmHour}:${openPmMin} ~ `;
      }

      // 클로즈시간 오전/오후 계산
      if (data.close_hour === 12) {
        const closeAmHour = data.close_hour;
        let closeAmMin = data.close_min;
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오후 ${closeAmHour}:${closeAmMin}`;
      } else if (data.close_hour === 24) {
        let closeAmMin = data.close_min;
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오전 00:${closeAmMin}`;
      } else if (data.close_hour < 12) {
        let closeAmHour = data.close_hour;
        let closeAmMin = data.close_min;
        if (closeAmHour <= 10) closeAmHour = String(closeAmHour).padStart(2, '0');
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오전 ${closeAmHour}:${closeAmMin}`;
      } else if (data.close_hour > 12) {
        let closePmHour = data.close_hour - 12;
        let closePmMin = data.close_min;
        if (closePmHour <= 10) closePmHour = String(closePmHour).padStart(2, '0');
        if (closePmMin === 0) closePmMin = String(closePmMin).padStart(2, '0');
        hours += `오후 ${closePmHour}:${closePmMin}`;
      }
    } else {
      className = 'detail-content-days holiday';
      hours = '휴무';
    }

    return (
      <>
        <Typography variant={'span'} className={className}>
          {day}
        </Typography>
        <HorizontalRuleIcon sx={{ rotate: '90deg', color: '#E8E5EC', fontSize: 'medium' }} />
        <Typography variant={'span'} className={'detail-content-days'}>
          {hours}
        </Typography>
      </>
    );
  };

  // success snackbar close
  const snackbarClose = () => {
    setUpdateSnackbar(false);
  };

  // error snackbar close
  const errorSnackbarClose = () => {
    setErrorSnackbar(false);
  };

  return (
    <>
      {/* update success snackbar */}
      <SuccessSnackbar open={updateSnackbar} close={snackbarClose} text={'입점사 정보 수정이 완료되었습니다.'} />

      {/* update error snackbar */}
      <ErrorSnackbar open={errorSnackbar} close={errorSnackbarClose} text={'입점사 수정 중 오류가 발생했습니다.'} />

      {/* vendor information search loading */}
      <LoadingLayout open={loading} text={'입점사 정보를 불러오고 있습니다.'} />

      {/* vendor information update loading */}
      <LoadingLayout open={updateLoading} text={`입점사 정보를 수정하고 있습니다.`} />

      <Box>
        {/* header */}
        <AppBar position="sticky">
          <Toolbar sx={{ padding: '10px 20px' }}>
            <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
            <ToolbarTitle>{vendor !== null && vendor.biz.name}</ToolbarTitle>
            <Box flexGrow={1} />
            <Box>
              <Button
                fullWidth
                size={'small'}
                variant={'outlined'}
                sx={{
                  backgroundColor: '#F4F0F8',
                  border: '1px solid #60546E26',
                  padding: '7px 9px',
                  color: '#140229',
                  fontSize: '11px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 700
                }}
                onClick={() => updateOk()}
              >
                저장하기
              </Button>
            </Box>
          </Toolbar>
          <WoDivider />
        </AppBar>

        {/* content */}
        <Box bgcolor={'#F4F0F8'}>
          <Box pt={'20px'} pb={'25px'}>
            {/* 샵정보 */}
            <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
              <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                샵 정보
              </Typography>

              {/* 상호명 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  상호명
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.biz.name}
                </Box>
              </Stack>

              {/* 주소 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  주소
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.biz.address1},&nbsp;
                  {vendor !== null && vendor.biz.address2 && vendor.biz.address2}
                </Box>
              </Stack>

              {/* 전화번호 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  전화번호
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.shop.tel}
                </Box>
              </Stack>

              {/* 운영시간 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  운영시간
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.MON, '월요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.TUE, '화요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.WED, '수요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.THU, '목요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.FRI, '금요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.SAT, '토요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.SUN, '일요일')}
                </Box>
                {/*
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.HOL, '공휴일')}
                </Box>
                */}
              </Stack>
            </Box>

            {/* 소개글 */}
            <Box pt={'10px'} pb={'10px'}>
              <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
                <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                  소개글
                </Typography>
                <Box mt={1.5}>
                  <TextField
                    fullWidth
                    rows={3}
                    multiline
                    value={vendor !== null ? vendorComment : ''}
                    onChange={(e) => setVendorComment(e.target.value)}
                    sx={{ fontSize: '16px' }}
                  />
                </Box>
              </Box>
            </Box>

            {/* 서비스 태그 */}
            <Box>
              <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
                <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                  서비스 태그
                </Typography>
                <Box mt={1.5}>
                  <Stack spacing={1}>
                    <StyledToggleButtonGroup size="large" value={vendorService} onChange={changeVendorService} color="success">
                      {serviceTagList !== null &&
                        serviceTagList.map((tag) => (
                          <ToggleButton value={tag._id} key={tag._id} className={'service-tag-box'}>
                            {tag._id === 'SERVICE_TAG_001' && <Box component={'img'} src={ParkIcon} width={20} height={20} mr={'2px'} />}
                            {tag._id === 'SERVICE_TAG_004' && <Box component={'img'} src={TasteIcon} width={11} height={20} mr={'2px'} />}
                            {tag._id === 'SERVICE_TAG_007' && <Box component={'img'} src={CoolIcon} width={15} height={20} mr={'2px'} />}
                            {tag._id === 'SERVICE_TAG_012' && <Box component={'img'} src={NightIcon} width={13} height={20} mr={'2px'} />}
                            {tag._id === 'SERVICE_TAG_013' && <Box component={'img'} src={ValetIcon} width={15} height={20} mr={'2px'} />}
                            {tag.value.ko}
                          </ToggleButton>
                        ))}
                    </StyledToggleButtonGroup>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyInfoUpdate;
