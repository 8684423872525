import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { Box } from '@mui/material';

// assets
import wineBottleLoadingJson from 'assets/lottie/loading_ani.json';

function WineBottleLoadingLottie() {
  const containerRef = useRef(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      lottie.loadAnimation({
        container: containerRef.current,
        loop: true,
        autoplay: true,
        animationData: wineBottleLoadingJson,
        rendererSettings: { clearCanvas: true }
      });
    }
  }, []);
  return <Box ref={containerRef} width={160} height={160} />;
}

export default WineBottleLoadingLottie;
