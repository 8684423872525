import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// import mui
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const now = moment();

/**
 * 알람 item component
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */

function AlarmItem({ alarm }) {
  // 알람 date formatting.
  const alarmDateStr = React.useMemo(() => {
    if (!alarm?.created_at) return '';

    const alarmDate = moment(alarm.created_at.toDate());
    const diff = now.diff(alarmDate, 'hours');

    let dateStr = '';

    if (diff <= 6) {
      dateStr = alarmDate.fromNow();
    } else {
      // 현재
      let dateFormat = '';
      if (now.year() !== alarmDate.year()) {
        dateFormat += 'YY.MM.DD a h:mm';
      } else {
        dateFormat += 'MM.DD a h:mm';
      }

      dateStr = alarmDate.format(dateFormat);
    }

    return dateStr;
  }, [alarm?.created_at]);

  // render
  return (
    <Box py="10px">
      <Grid container spacing="1">
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Typography variant="subtitle1" component="div" fontSize={12} fontWeight="800" lineHeight="2.09" letterSpacing="-0.11px">
              {alarm.title}
            </Typography>
            <Typography variant="body2" component="div" color="text.secondary" fontSize={12} lineHeight="1.92" letterSpacing="-0.12px">
              {alarmDateStr}
            </Typography>
          </SpaceBetweenBox>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" fontSize="12px" lineHeight="1.69" letterSpacing="-0.16px" sx={{ whiteSpace: 'pre-wrap' }}>
            {alarm.body}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

AlarmItem.propTypes = {
  alarm: PropTypes.object.isRequired
};

export default AlarmItem;

const SpaceBetweenBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});
