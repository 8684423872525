import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';

// import mui
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, Backdrop } from '@mui/material';

// import components
import UnixDateFormat from 'components/format/UnixDateFormat';

// import icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import assets
import { WineBottleLoadingLottie } from 'components/spinner';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ImageDrawer from './components/ImageDrawer';

// import firebase
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { firebaseDB } from 'index';

function InquiryList({ tabChange }) {
  const { user } = useAuth();

  const navigate = useNavigate();

  // loading
  const [loading, setLoading] = useState(false);
  // inquiry list
  const [inquiry, setInquiry] = useState([]);
  // Accordian 상태
  const [expanded, setExpanded] = useState(false);
  // image drawer open
  const [drawerOpen, setDrawerOpen] = useState(false);
  // image drawer img src
  const [drawerSrc, setDrawerSrc] = useState(null);

  let vendorId;
  if (user) {
    try {
      const vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
      vendorId = vendorReload.vendor;
    } catch (e) {
      navigate(0);
    }
  }

  // 페이지 진입 시
  useEffect(() => {
    getInquiryList();
  }, []);

  // 내 문의내역 조회
  const getInquiryList = () => {
    setLoading(true);
    const inquiryRef = collection(firebaseDB, 'inquiry_vendor');
    const inquiryQuery = query(inquiryRef, where('vendor._id', '==', vendorId), orderBy('created_at', 'desc'));
    const inquirySnapshot = getDocs(inquiryQuery);

    const tempArr = [];
    inquirySnapshot
      .then((res) => {
        setLoading(false);
        res.forEach((r) => {
          tempArr.push(r.data());
        });
        console.log('---- tempArr: ', tempArr);
        setInquiry(tempArr);
      })
      .catch((e) => {
        setLoading(false);
        console.error('[1:1 문의 > 내 문의내역] 문의내역 조회 중 오류 발생 > ', e);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // 이미지 드로어 열기
  const showDrawer = (val) => {
    setDrawerOpen(true);
    setDrawerSrc(val);
  };

  // 이미지 드로어 닫기
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">문의 내역을 불러오고 있습니다.</Typography>
        </Box>
      </Backdrop>
      <ImageDrawer isOpen={drawerOpen} closeImgDrawer={closeDrawer} imgSrc={drawerSrc} />
      <Box>
        {inquiry.length > 0 ? (
          inquiry.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === 'panel' + index}
              onChange={handleChange('panel' + index)}
              sx={{ bgcolor: expanded === 'panel' + index ? '#EEEEEE' : null }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel1bh-content'} id={'panel1bh-header'}>
                <Box>
                  <Box mb={1}>
                    <Box display={'flex'} fontSize={14} fontWeight={600}>
                      <span className={'line-1-ellipsis'}>
                        <strong style={{ marginRight: '8px' }}>{item.type === 'product' ? '[상품 관련]' : '[서비스 관련]'}</strong>
                        {item.content.replaceAll('\\n', ' ')}
                      </span>
                    </Box>
                  </Box>

                  {item.state === '답변대기' && (
                    <Box display={'flex'}>
                      <Typography fontSize={12} color={'#47C454'}>
                        답변 대기
                      </Typography>
                      <Typography fontSize={12} color={'#B0B0B0'} ml={0.5}>
                        | 답변 대기 중
                      </Typography>
                    </Box>
                  )}

                  {item.state === '답변완료' && (
                    <Box display={'flex'} fontSize={12}>
                      답변완료 | {UnixDateFormat(item?.reply?.created_at?.seconds, 'a')}
                    </Box>
                  )}
                  {expanded === 'panel' + index ? null : (
                    <Typography className={'line-1-ellipsis'} mt={1} fontSize={12}>
                      {item.contents}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {/* 더보기 문의내용 */}
                <Typography variant={'span'} fontSize={12} whiteSpace={'pre-wrap'}>
                  {item.content.replaceAll('\\n', '\n')}
                </Typography>

                {/* 더보기 첨부파일 */}
                {item.images && (
                  <Box sx={{ display: '-webkit-box', height: '136px', overflowX: 'auto', marginTop: '12px', overflowY: 'hidden' }}>
                    {item.images.map((img, index) => (
                      <Box key={index} width={136} height={136} marginRight={'11px'} position={'relative'} bgcolor={'#ffffff'}>
                        <Box component={'img'} src={img.thumb.url} width={'100%'} height={'100%'} sx={{ objectFit: 'contain' }} />
                        <ZoomInIcon sx={{ position: 'absolute', top: '3px', right: '3px' }} onClick={() => showDrawer(img.original.url)} />
                      </Box>
                    ))}
                  </Box>
                )}

                {item.reply && (
                  <Box mt={3}>
                    <Typography fontSize={14} fontWeight={'bold'} mb={1}>
                      답변
                    </Typography>

                    {/* 답변작성시간 */}
                    <Typography fontSize={12} color={'#B0B0B0'} mb={1}>
                      {UnixDateFormat(item.reply.created_at.seconds, 'a')}
                    </Typography>

                    {/* 답변 내용 */}
                    <Typography fontSize={12} mb={1} whiteSpace={'pre-wrap'}>
                      {item.reply.comment.replaceAll('\\n', '\n')}
                    </Typography>

                    <Typography fontSize={12} color={'#B0B0B0'} mb={1}>
                      도움이 더 필요하신가요?
                    </Typography>
                  </Box>
                )}
                <Box
                  width={'fit-content'}
                  display={'flex'}
                  bgcolor={'#47C454'}
                  padding={'7px 19px'}
                  borderRadius={2}
                  onClick={() => tabChange(true, item.type)}
                >
                  <Typography variant={'span'} fontSize={10} color={'#FFFFFF'}>
                    추가 문의하기
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box width="100%" textAlign="center" mt={3}>
            <Typography variant="span">등록된 문의내역이 없습니다.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default InquiryList;

InquiryList.propTypes = {
  tabChange: PropTypes.func
};
