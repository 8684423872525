import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';
import { ToolbarTitle } from 'components/toolbar';
import PropTypes from 'prop-types';

import BackIcon from 'assets/images/arrow-back.png';
import CloseIcon from 'assets/images/btn_close.png';

function Header(props) {
  const navigate = useNavigate();

  const { title, backType } = props;

  return (
    <AppBar position="sticky">
      <Toolbar sx={{ padding: '10px 20px' }}>
        {backType === 'close' ? (
          <Box component="img" src={CloseIcon} width={34} height={34} onClick={() => navigate(-1)} />
        ) : (
          <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
        )}
        <ToolbarTitle>{title}</ToolbarTitle>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

Header.propTypes = {
  backType: PropTypes.string,
  title: PropTypes.string
};
