// third-party
import { createSlice } from '@reduxjs/toolkit';

import { getFirestore } from 'utils/firebase-tools';
import { dispatch } from '../index';

import { collection, limit, orderBy, query, where, documentId, getDocs } from 'firebase/firestore';

// 1km wine 서비스 상수
// firestore > code > {version} > {collection}
export const initialState = {
  code: {
    version: null,
    ref: null
  },
  last_updated: null
};

const slice = createSlice({
  name: 'wo-constants',
  initialState,
  reducers: {
    // 코드 데이터 초기화
    resetCodeData(state) {
      state.code = {
        version: null
      };
    },

    // 코드 버전 세팅
    setCodeVersion(state, action) {
      state.code.version = action.payload;
      console.debug(`[wo-constants] code version = ${action.payload}`);
    },

    // 코드 데이터 세팅
    setCodeData(state, action) {
      const { name, data } = action.payload;
      state.code[name] = data;
    }
  }
});

// Reducer
export default slice.reducer;
const LABEL_STYLE = 'color: purple; background:pink; padding: 0 4px;';

/**
 * 사용할 코드버전 지정
 */

export function setCodeVersion(v) {
  return async (_, getState) => {
    const codeVersion = v;

    console.info(`%cwineone`, LABEL_STYLE, `'code' 지정버전 = `, codeVersion);
    const { woConstants } = getState();

    // 기존에 저장되어 있던 버전과 일치할 경우
    if (woConstants.code.version === codeVersion) {
      // 기존 코드 유지
      console.debug('todo 기존 코드 유지');
    } else {
      // 코드정보 초기화
      await dispatch(slice.actions.resetCodeData());
      await dispatch(slice.actions.setCodeVersion(codeVersion));
    }
  };
}
/**
 * 코드의 최신버전 가져오기
 */
export function getLatestCodeVersion() {
  return async (_, getState) => {
    const codeRef = collection(getFirestore(), 'code'); // firestore 'code' collection.
    const q = query(codeRef, where('use', '==', true), orderBy(documentId(), 'desc'), limit(1));
    const { docs } = await getDocs(q);
    const [codeDoc] = docs;
    const codeVersion = codeDoc.id;

    console.info(`%cwineone`, LABEL_STYLE, `'code' 최신버전 = `, codeVersion);
    const { woConstants } = getState();

    // 기존에 저장되어 있던 버전과 일치할 경우
    if (woConstants.code.version === codeVersion) {
      // 기존 코드 유지
      console.debug('todo 기존 코드 유지');
    } else {
      // 코드정보 초기화
      await dispatch(slice.actions.resetCodeData());
      await dispatch(slice.actions.setCodeVersion(codeVersion));
    }
  };
}

export function fetchCodes(codes = null) {
  // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ codes: ', codes);
  return async (_, getState) => {
    if (!Array.isArray(codes) && typeof codes !== 'string') {
      console.warn(`[wo-constants@fetchCodes] Invalid 'codes' parameter. '${codes}'`);
      return false;
    }

    const _codes = Array.isArray(codes) ? codes : [codes];

    const { woConstants } = getState();

    _codes.forEach((c) => {
      // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ c: ', c);
      // eslint-disable-next-line no-prototype-builtins
      if (!woConstants.code.hasOwnProperty(c)) {
        const codeRef = collection(getFirestore(), `code/${woConstants.code.version}/code_${c}`); // firestore 'code' collection.
        const q = query(codeRef, orderBy('order', 'asc'));
        getDocs(q).then((docsSnapshot) => {
          if (!docsSnapshot.empty) {
            const codeData = {};
            docsSnapshot.forEach((doc) => {
              if (c === 'capacity') {
                const { label, value } = doc.data();
                codeData[label] = value;
              } else {
                const { _id: code, value } = doc.data();
                codeData[code] = value;
              }
            });
            dispatch(slice.actions.setCodeData({ name: c, data: codeData }));
          }
        });
      } else {
        console.debug(`[fetchCodes] 이미 로드된 코드 '${c}'`, woConstants.code[c]);
      }
    });
  };
}
