import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Header from 'components/header/Header';
import { WineBottleLoadingLottie } from 'components/spinner';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import { FIREBASE_HOSTNAME } from 'config';
import AlertTriangle from 'assets/images/product/ic_alert_triangle.jpg';
import BasicDialog from 'components/dialog/BasicDialog';

function ExcRtnCancel() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  // 로딩
  const [loading, setLoading] = React.useState(false);
  // 라디오 버튼
  const [rejectState, setRejectState] = React.useState('고객 과실로 인한 상품 훼손');
  // 거절 기타 텍스트
  const [rejectText, setRejectText] = React.useState('');
  // 텍스트 필드 글자 수 카운트
  const [fieldMaxCount, setFieldMaxCount] = React.useState(0);
  // complete modal
  const [completeModal, setCompleteModal] = React.useState(false);
  // error detail modal
  const [errorDetailModal, setErrorDetailModal] = React.useState(false);
  // error detail modal text
  const [errorDetailText, setErrorDetailText] = React.useState('교환/반품 거절 오류가 발생했습니다.');

  // 라디오 버튼 변경 이벤트
  const handleChange = (e) => {
    setRejectState(e.target.value);
    if (e.target.value !== '기타') {
      setFieldMaxCount(0);
      setRejectText('');
    }
  };

  // 텍스트 필드 글자 수 구하기
  const useTextFieldCount = (e) => {
    const val = e.target.value;
    setRejectText(val);
    setFieldMaxCount(val.length);
  };

  // 교환/반품 거절 승인 클릭 이벤트
  const rejectOrder = () => {
    setLoading(true);
    let HEADER_CONFIG;

    if (user) {
      HEADER_CONFIG = { headers: { Authorization: `Bearer ${user.accessToken}` } };
    }

    const order_product_ids = [];
    order_product_ids.push(params.id);
    const body = {
      oid: params.oid,
      order_product_ids
    };

    if (rejectState === '기타') {
      body.msg = rejectText;
    } else {
      body.msg = rejectState;
    }

    if (state.type === '교환') {
      axios
        .post(`${FIREBASE_HOSTNAME}/call-pay-process-exchangeReqNo`, body, HEADER_CONFIG)
        .then((res) => {
          setLoading(false);

          const resStatus = res.status;
          const resResult = res.data.result;

          if (resStatus === 200) {
            if (resResult.code === 0) {
              setCompleteModal(true);
            } else {
              console.error('[교환거절] 교환 거절 오류 발생 > ', resResult.msg);
              setErrorDetailText(`[교환거절] ${resResult.msg}`);
              setErrorDetailModal(true);
            }
          } else {
            console.error('[교환거절] 교환 거절 오류 발생 > ', resResult.msg);
            setErrorDetailText('교환 거절 오류가 발생했습니다.');
            setErrorDetailModal(true);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error('[교환거절] 교환 거절 오류 발생 > ', e);
          setErrorDetailText('교환 거절 오류가 발생했습니다.');
          setErrorDetailModal(true);
        });
    } else if (state.type === '반품') {
      axios
        .post(`${FIREBASE_HOSTNAME}/call-pay-process-refundReqNo`, body, HEADER_CONFIG)
        .then((res) => {
          setLoading(false);

          const resStatus = res.status;
          const resResult = res.data.result;

          if (resStatus === 200) {
            if (resResult.code === 0) {
              setCompleteModal(true);
            } else {
              console.error('[반품거절] 반품 거절 오류 발생 > ', resResult.msg);
              setErrorDetailText(`[반품거절] ${resResult.msg}`);
              setErrorDetailModal(true);
            }
          } else {
            console.error('[반품거절] 반품 거절 오류 발생 > ', resResult.msg);
            setErrorDetailText('반품 거절 오류가 발생했습니다.');
            setErrorDetailModal(true);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error('[반품거절] 반품 거절 오류 발생 > ', e);
          setErrorDetailText('반품 거절 오류가 발생했습니다.');
          setErrorDetailModal(true);
        });
    }
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">교환/반품정보를 불러오고 있습니다.</Typography>
        </Box>
      </Backdrop>
      <Box>
        <Header title={`${state.type} 거절`} />
        <main>
          <Container>
            <Box fontSize={18} fontWeight={800} mt={2}>
              거절 사유를 입력 해 주세요.
            </Box>
            <Box mt={6}>
              <RadioGroup
                aria-labelledby="order-reject-buttons-group"
                name="rejectType"
                value={rejectState}
                onChange={(e) => handleChange(e)}
              >
                <FormControlLabel
                  value={'고객 과실로 인한 상품 훼손'}
                  control={<Radio />}
                  label="고객 과실로 인한 상품 훼손"
                  sx={{
                    marginBottom: '8px',
                    '& .MuiTypography-root': {
                      fontSize: '16px'
                    }
                  }}
                />
                <FormControlLabel
                  value={'기타'}
                  control={<Radio />}
                  label="기타(직접입력)"
                  sx={{
                    marginBottom: '8px',
                    '& .MuiTypography-root': {
                      fontSize: '16px'
                    }
                  }}
                />
              </RadioGroup>

              <TextField
                fullWidth
                rows={5}
                multiline
                value={rejectText}
                disabled={rejectState !== '기타'}
                onChange={useTextFieldCount}
                inputProps={{ maxLength: 200 }}
                sx={{
                  '& .Mui-disabled': {
                    backgroundColor: '#F4F0F8'
                  }
                }}
              />
              <Typography variant="span" fontSize={16} mt={0.5} sx={{ float: 'right' }}>
                {fieldMaxCount}/200
              </Typography>
            </Box>

            {/* 취소/승인 버튼 */}
            <Grid container mt={1} spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant={'outlined'}
                  sx={{
                    border: '1px solid #140229',
                    padding: '4px 0',
                    fontSize: '16px',
                    fontWeight: 700
                  }}
                  onClick={() => navigate(-1)}
                >
                  취소
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={'contain-btn'}
                  variant={'contained'}
                  disabled={rejectState === '기타' && fieldMaxCount === 0}
                  sx={{
                    backgroundColor: '#220348',
                    padding: '4px 0',
                    fontSize: '16px',
                    fontWeight: 700
                  }}
                  onClick={() => rejectOrder()}
                >
                  거절하기
                </Button>
              </Grid>
            </Grid>
          </Container>
        </main>
      </Box>

      {/* 교환/반품 거절 완료 모달 */}
      <Dialog
        open={completeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: '#F4F0F880'
          },
          '& .MuiPaper-root': {
            width: '80%',
            boxShadow: '0px 6px 3px 4px #00000029',
            borderRadius: '12px'
          }
        }}
      >
        <Box padding={'20px 25px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant={'span'} fontSize={18} fontWeight={900} color={'#140229'}>
            {state.type} 완료
          </Typography>
        </Box>
        <Box padding={'0 20px 20px 20px'} textAlign={'center'}>
          <Typography variant={'span'} fontSize={16} color={'#140229'}>
            {state.type} 거절처리가 완료되었습니다.
          </Typography>
        </Box>
        <Box padding={'0 20px 25px 20px'}>
          <Grid container>
            <Stack direction={'row'} spacing={1} width={'100%'}>
              <Grid item xs={12} textAlign={'center'}>
                <Button
                  sx={{
                    width: '130px',
                    padding: '13px 0',
                    borderRadius: '30px',
                    fontWeight: 600,
                    backgroundColor: '#220648',
                    color: '#FFFFFF'
                  }}
                  onClick={() => navigate(-2, { state: { num: 0 } })}
                >
                  확인
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Box>
      </Dialog>

      {/* 교환/반품 승인 처리 오류 모달 */}
      <BasicDialog
        open={errorDetailModal}
        close={() => setErrorDetailModal(false)}
        type={'error'}
        titleImg={<Box component={'img'} src={AlertTriangle} width={'16px'} height={'16px'} />}
        title={`${state.type} 거절 오류`}
        content1={errorDetailText}
        btnText={'닫기'}
        action={() => setErrorDetailModal(false)}
      />
    </>
  );
}

export default ExcRtnCancel;
