import { useNavigate } from 'react-router-dom';

// project imports
import { LogoIcon } from 'components/icons';

// material-ui
import { Box, Button, Container, Grid, Typography } from '@mui/material';

// image
import IntroImage from 'assets/images/intro/introImage.svg';

/**
 * 인트로화면
 */
function IntroIndex() {
  const navigate = useNavigate();

  return (
    <>
      <main style={{ width: '100vw', height: '100vh', display: 'table-cell', verticalAlign: 'middle' }}>
        <Container maxWidth="xs">
          <Box fontSize={34}>
            <Typography component="div" fontSize="inherit" fontWeight={600} letterSpacing="-0.34px">
              <Box fontSize={20} px="10px" display="flex" justifyContent="center" alignItems="center">
                <LogoIcon sx={{ width: 160, height: 44, pb: '4px' }} />
                &nbsp;파트너센터
              </Box>
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={6} height={198} textAlign="right" sx={{ paddingRight: '24px' }}>
              <Box
                component="img"
                src={IntroImage}
                sx={{
                  width: '75%',
                  height: '75%',
                  transform: 'scaleX(-1)'
                }}
                alt=""
              />
            </Grid>
            <Grid item xs={6} height={198} alignSelf="flex-end" sx={{ paddingLeft: '24px' }}>
              <Box
                component="img"
                src={IntroImage}
                sx={{
                  width: '75%',
                  height: '75%'
                }}
                alt=""
              />
            </Grid>

            <Grid item xs={12}>
              <Box color="#140229" fontWeight="300" textAlign="center">
                1KMWINE 사장님을 위한 주문 관리 서비스입니다. <br />
                주문을 확인하고 판매정보를 관리하세요.
              </Box>
            </Grid>
            <Grid item xs={12} mt={3.5}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => navigate('/auth/sign-in-with-id')}
                sx={{
                  padding: '8px 0',
                  fontWeight: '800',
                  fontSize: '16px'
                }}
              >
                로그인 화면으로 이동
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}

export default IntroIndex;
