import React from 'react';

// material-ui
import { Box, InputAdornment, TextField } from '@mui/material';

// project imports
import PropTypes from 'prop-types';

// assets
import SearchIcon from 'assets/images/img_search_textfield.png';

/**
 * 등록된 상품 검색바
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ProductSearchBar({ searchProductQry }) {
  return (
    <Box>
      <TextField
        variant="standard"
        fullWidth
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Box component="img" src={SearchIcon} width="28px" height="28px" />
            </InputAdornment>
          )
        }}
        placeholder="상품명으로 검색"
        size="small"
        onChange={(e) => searchProductQry(e.target.value)}
        sx={{
          padding: '11px 12px',
          backgroundColor: '#00000008',
          borderRadius: '100px',
          '& .MuiInputBase-input': {
            padding: 0
          }
        }}
      />
    </Box>
  );
}

export default ProductSearchBar;

ProductSearchBar.propTypes = {
  searchProductQry: PropTypes.func
};
