export { default } from './ProductList';

// 상품목록 테이블 헤더 속성 정의
export const headCells = [
  {
    id: 'name/vintage/capacity',
    label: '상품명/빈티지/용량',
    align: 'left',
    disableOrder: true
  },
  {
    id: 'price__original',
    label: '가격',
    align: 'center'
  },
  {
    id: 'quantity',
    label: '재고',
    align: 'center'
  }
];
