// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import wineOneReducer from './slices/wine-one';
import alarmReducer from './slices/alarm';
import woConstantReducer from './slices/wo-constants';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  alarm: alarmReducer,
  wineOne: persistReducer(
    {
      key: 'wineOne',
      storage
    },
    wineOneReducer
  ),
  woConstants: persistReducer(
    {
      key: 'wo-const',
      storage
    },
    woConstantReducer
  )
});

export default reducer;
