import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Container, Tabs, Tab, Toolbar, Grid, Typography, Backdrop, Dialog, Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';

import InquiryDo from './InquiryDo';
import InquiryList from './InquiryList';
import { WineBottleLoadingLottie } from 'components/spinner';
import { ToolbarTitle } from 'components/toolbar';

import AlertTriangle from 'assets/images/product/ic_alert_triangle.jpg';
import BackIcon from 'assets/images/arrow-back.png';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function Inquiry() {
  const navigate = useNavigate();
  const inquiryTabOption = [
    { tabIndex: 0, label: '문의하기' },
    { tabIndex: 1, label: '내 문의내역' }
  ];
  // tab state
  const [inquiryTabValue, setInquiryTabValue] = useState(0);
  // select tabIndex
  const [selectTabIndex, setSelectTabIndex] = useState(0);
  // inquiry tabChange
  const [inquiryTabChange, setInquiryTabChange] = useState(null);
  // inquiry data
  const [inquiryData, setInquiryData] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Loading message
  const [loadingMsg, setLoadingMsg] = useState('문의정보를 받아오는 중입니다');
  // 1:1 문의하기 이미지 목록
  const [imageList, setImageList] = useState([]);
  // 1:1 문의하기 값 입력 여부
  const [isWrite, setIsWrite] = useState(false);
  // 탭 이동 경고창 열기 여부
  const [tabWarningOpen, setTabWarningOpen] = useState(false);
  // 뒤로가기 경고창 열기 여부
  const [backWarningOpen, setBackWarningOpen] = useState(false);

  // 문의 내역 불러오기
  // todo 유저에 맞게 문의내역 불러오기
  const getInquiryData = async () => {
    setIsLoading(true);
    setLoadingMsg('문의정보를 받아오는 중입니다.');
    // firebase 데이터 불러오기
    setInquiryData([
      {
        type: 'product',
        state: false,
        time: null,
        contents: '상품가격 변경하는 방법에 대해서 알려주세요. 방법을 잘 모르겠습니다.',
        img: null,
        answerTitle: null,
        answerContents: null
      },
      {
        type: 'product',
        state: true,
        time: '2022.07.04 15:35:26',
        contents:
          '상품 신규 등록하려고 합니다. 어떻게 진행하면 좋은지 알려주시면 좋을 것 같아요. 혼자 시도는 해봤는데, 혹시 검색해서 나오지않는 상품은 신규 상품으로 등록이 어려운 건가요?',
        img: null,
        answerTitle: '상품 등록에 대한 문의 회신 드립니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 안타깝게도 현재는 검색되지 않은 상품의 경우 등록이 어려운 상 황입니다. 등록 원하는 상품이 있으시다면 문의로 와인이름, 생산자명, 종류, 품종 등에 대한 정보를 주시면 가능한 빠른 시일 내에 상품 등록 할 수 있도록 도와드리겠습니다. 언제나 고객님의 목소리에 귀 기울이며 더욱 편리한 서비스로 보답하겠습니다 :)'
      },
      {
        type: 'service',
        state: true,
        time: '2022.07.03 14:25:13',
        contents: '로그인 하는 계정 이메일 변경하려고 하는데, 어떻게 진행하면 좋을지 모르겠습니다. 알려주시면 감사합니다.',
        img: null,
        answerTitle: '이메일 계정 변경 문의 회신 드립니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 현재 이메일 변경은 어렵습니다. 감사합니다.'
      },
      {
        type: 'service',
        state: true,
        time: '2022.07.02 13:15:43',
        contents: '여러 이유로 계정 탈퇴하고 재가입하려고 하는데 기존 정보 이전이 가능한지 여쭤보고 싶습니다.',
        img: null,
        answerTitle: '계정 탈퇴 및 재가입시 이월 가능 여부 답변입니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 계정 탈퇴 시 기존정보 이용이 불가능하며 새로 모든 정보를 입력하셔야 합니다. 감사합니다.'
      },
      {
        type: 'product',
        state: true,
        time: '2022.07.01 16:25:34',
        contents:
          '쇼핑백 포장의 경우 샵에서 따로 금액을 받지 않아도 되는 것이 맞는지 궁금합니다. 따로 비용을 청구를 해야하는 것이 맞습니까?',
        img: null,
        answerTitle: '포장 관련 문의 회신 드립니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 쇼핑 백의 경우 포장 비용을 지불 하는 것은 입점사의 재량입니다. 편하게 이용해주시면 감사하겠습니다. 감사합니다.'
      },
      {
        type: 'product',
        state: true,
        time: '2022.07.01 13:15:24',
        contents:
          '현재 PC를 사용할 수 없는 상태인데 제가 올린 상품 중 몬테스의 가격을 변경하고자 합니다. 앱에서는 가격 변경을 할 수 있는지 궁금합니다.',
        img: null,
        answerTitle: '상품 가격 관련 문의 회신 드립니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 아직 앱에서의 상품 가격 변경에 대해서는 불가한 상태입니다. 앱 개발 중에 있으면 더 나은 서비스를 위해 노력하겠습니다. 감사합니다.'
      },
      {
        type: 'product',
        state: true,
        time: '2022.07.01 13:15:24',
        contents:
          '현재 PC를 사용할 수 없는 상태인데 제가 올린 상품 중 몬테스의 가격을 변경하고자 합니다. 앱에서는 가격 변경을 할 수 있는지 궁금합니다.',
        img: null,
        answerTitle: '상품 가격 관련 문의 회신 드립니다.',
        answerContents:
          '안녕하세요. 1KMWINE 입니다. 1KMWINE에 소중한 문의를 남겨 주셔서 감사드립니다. 아직 앱에서의 상품 가격 변경에 대해서는 불가한 상태입니다. 앱 개발 중에 있으면 더 나은 서비스를 위해 노력하겠습니다. 감사합니다.'
      }
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    getInquiryData();
  }, []);
  // tab change
  const inquiryTabHandleChange = (event, newValue) => {
    // todo inquiryDo 에서 내용 입력후 변경 시 데이터 유지 혹은 경고메세지
    if (isWrite) {
      setTabWarningOpen(true);
    } else {
      setInquiryTabValue(newValue);
    }
  };
  // 탭 이동 수동
  // todo firestore 이미지 저장 취소시키기
  const tabChangeToList = () => {
    setInquiryTabValue(1);
    setIsWrite(false);
    setTabWarningOpen(false);
  };
  // 뒤로가기 체크
  const backCheck = () => {
    if (isWrite) {
      setBackWarningOpen(true);
    } else {
      navigate(-1);
    }
  };
  // 뒤로가기 수동
  const backToSeriveCenter = () => {
    setBackWarningOpen(false);
    navigate(-1);
  };

  const tabSelectId = (e) => {
    const tabId = e.target.offsetParent.id;
    setSelectTabIndex(Number(tabId));
  };

  const tabChange = (type, categoryType) => {
    if (type) {
      setInquiryTabValue(0);
      setSelectTabIndex(0);

      if (categoryType === 'product') {
        setInquiryTabChange('product');
      } else {
        setInquiryTabChange('service');
      }
    }
  };
  // 로딩상태 변경
  const changeLoading = (boolData) => {
    setIsLoading(boolData);
  };
  // 로딩메세지 변경
  const changeLoadingMsg = (LoadingMsg) => {
    setLoadingMsg(LoadingMsg);
  };
  return (
    <Box>
      {/* Loading */}
      <Backdrop open={isLoading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">{loadingMsg}</Typography>
        </Box>
      </Backdrop>
      {/* HEADER */}
      <AppBar position="sticky">
        <Toolbar sx={{ padding: '10px 20px' }}>
          <Box component="img" src={BackIcon} width={34} height={34} onClick={backCheck} />
          <ToolbarTitle>1:1 문의</ToolbarTitle>
        </Toolbar>
        <Tabs id="wait-tabs" variant="fullWidth" value={inquiryTabValue} onChange={inquiryTabHandleChange}>
          {inquiryTabOption.map((tab, index) => (
            <Tab
              id={tab.tabIndex}
              key={index}
              label={
                <Grid container direction="column">
                  <Typography varient="span" fontSize={16} fontWeight={'bold'}>
                    {tab.label}
                  </Typography>
                </Grid>
              }
              onClick={(e) => tabSelectId(e)}
              sx={{
                '& .MuiGrid-container': {
                  width: '100% !important',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                },
                '& .MuiTypography-root': {
                  color: index === selectTabIndex ? '#220348' : '#B0B0B0'
                }
              }}
            />
          ))}
        </Tabs>
      </AppBar>
      {/* CONTENTS */}
      <main>
        <Container>
          <TabPanel value={inquiryTabValue} index={0}>
            <InquiryDo
              tabChange={inquiryTabChange}
              changeLoading={changeLoading}
              changeLoadingmsg={changeLoadingMsg}
              imageList={imageList}
              setImageList={setImageList}
              setIsWrite={setIsWrite}
            />
          </TabPanel>
          <TabPanel value={inquiryTabValue} index={1}>
            <InquiryList tabChange={tabChange} />
          </TabPanel>
        </Container>
      </main>
      {/* 탭 변경 경고 모달 */}
      <Dialog
        open={tabWarningOpen}
        onClose={() => setTabWarningOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: '#F4F0F880'
          },
          '& .MuiPaper-root': {
            width: '80%',
            boxShadow: '0px 6px 3px 4px #00000029',
            borderRadius: '12px'
          }
        }}
      >
        <Box padding={'20px 25px'} display={'flex'} alignItems={'center'}>
          <Box component={'img'} src={AlertTriangle} width={'16px'} height={'16px'} />
          <Typography variant={'span'} fontSize={18} fontWeight={900} color={'#FB6464'} ml={2}>
            작성 내용 저장 불가
          </Typography>
        </Box>
        <Box padding={'0 20px 20px 20px'}>
          <Typography variant={'span'} fontSize={16} color={'#140229'}>
            작성하시던 페이지에서 벗어나게 되면 작성된 내용이 모두 사라집니다. <br />
            작성한 내용이 사라지더라도 이동하시겠습니까?
          </Typography>
        </Box>
        <Box padding={'0 20px 25px 20px'}>
          <Grid container>
            <Stack direction={'row'} spacing={1} width={'100%'}>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '13px 0',
                    border: '1px solid #60546E4D',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff'
                  }}
                  onClick={() => setTabWarningOpen(false)}
                >
                  취소
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '13px 0',
                    borderRadius: '30px',
                    fontWeight: 600,
                    backgroundColor: '#220648',
                    color: '#FFFFFF'
                  }}
                  onClick={() => tabChangeToList(1)}
                >
                  탭이동
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Box>
      </Dialog>
      {/* 뒤로가기 경고 모달 */}
      <Dialog
        open={backWarningOpen}
        onClose={() => setBackWarningOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: '#F4F0F880'
          },
          '& .MuiPaper-root': {
            width: '80%',
            boxShadow: '0px 6px 3px 4px #00000029',
            borderRadius: '12px'
          }
        }}
      >
        <Box padding={'20px 25px'} display={'flex'} alignItems={'center'}>
          <Box component={'img'} src={AlertTriangle} width={'16px'} height={'16px'} />
          <Typography variant={'span'} fontSize={18} fontWeight={900} color={'#FB6464'} ml={2}>
            작성 내용 저장 불가
          </Typography>
        </Box>
        <Box padding={'0 20px 20px 20px'}>
          <Typography variant={'span'} fontSize={16} color={'#140229'}>
            작성하시던 페이지에서 벗어나게 되면 작성된 내용이 모두 사라집니다. <br />
            작성한 내용이 사라지더라도 이동하시겠습니까?
          </Typography>
        </Box>
        <Box padding={'0 20px 25px 20px'}>
          <Grid container>
            <Stack direction={'row'} spacing={1} width={'100%'}>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '13px 0',
                    border: '1px solid #60546E4D',
                    borderRadius: '30px',
                    backgroundColor: '#ffffff'
                  }}
                  onClick={() => setBackWarningOpen(false)}
                >
                  취소
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '13px 0',
                    borderRadius: '30px',
                    fontWeight: 600,
                    backgroundColor: '#220648',
                    color: '#FFFFFF'
                  }}
                  onClick={() => backToSeriveCenter()}
                >
                  뒤로가기
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
}

export default Inquiry;
