import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// import mui
import { Box, Typography } from '@mui/material';

// import components
import ShadowBox from 'components/ShadowBox';
import LapsOfTime from '../../../components/LapsOfTime';
import MDateFormatAhm from '../../../components/MDateFormatAhm';
import MDateFormatYMDHm from '../../../components/MDateFormatYMDHm';

// import icons
import { OrderOverIcons } from 'components/icons';

// import projects
import { bottleCapacityToString } from 'config';

/**
 * 반품신청 목록
 * @constructs
 *
 * @authors 이재일<leeji@wineone.io>
 */
function RequestRtnRows({ rows }) {
  const navigate = useNavigate();

  return (
    <Box paddingBottom="20px">
      {rows.map((r, index) => (
        <ShadowBox
          key={index}
          sx={{
            marginTop: '12px',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 26px #1303281A',
            padding: '0 16px',
            border: '1px solid #ffffff',
            borderRadius: '10px !important'
          }}
        >
          <Box margin="12px 0" onClick={() => navigate(`/order/${r.oid}/${r.id}`)}>
            {/* 신청경과 & 반품신청일시 */}
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <OrderOverIcons />
                <Typography component="span" fontSize="13px" color="#333333CC" ml={1}>
                  신청경과
                </Typography>
                {LapsOfTime(r.req_rtn_at)}
              </Box>
              <Box>
                <Typography components="span" color="#33333366" fontSize="11px">
                  {MDateFormatAhm(r.req_rtn_at)}
                </Typography>
              </Box>
            </Box>

            {/* 반품사유 */}
            <Box mt={2} mb={1} display="flex">
              <Typography className="line-1-ellipsis" component="span" color="#333333" fontSize="14px" fontWeight={700}>
                반품사유 :&nbsp;
                <Typography component="span" color="#FC735C" fontWeight={700}>
                  {r.msg}
                </Typography>
              </Typography>
            </Box>

            {/* 주문 상품 */}
            <Box mb={1}>
              <Typography component="span" fontSize="14px" color="#333333">
                {`${r.name__ko} ${r.vintage}/${bottleCapacityToString(r.capacity)} ${r.quantity}개` ?? '-'}
              </Typography>
            </Box>

            {/* 반품요청일시 */}
            <Box>
              <Typography component="span" color="#33333399" fontSize="12px">
                요청일시 : {MDateFormatYMDHm(r.req_rtn_at)}
              </Typography>
            </Box>

            {/* 결제금액 */}
            <Box>
              <Typography component="span" color="#33333399" fontSize="12px">
                결제금액 : {r.price__original?.toLocaleString() ?? '-'}원
              </Typography>
            </Box>
          </Box>
        </ShadowBox>
      ))}
    </Box>
  );
}

export default RequestRtnRows;

RequestRtnRows.propTypes = {
  rows: PropTypes.array.isRequired
};
