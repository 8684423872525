import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Container, Drawer, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// project imports
import { BackButton } from 'components/buttons';
import { WoAlert } from 'utils/kmwine-alerts';
import { ReqOkCard, ReqNoCard, WoDialog } from './components';
import LoadingLayout from 'components/loading/LoadingLayout';
import * as OrderService from 'services/OrderService';
import { PaymentInformation } from '../../components';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';

/**
 * 최종 주문 확인 페이지
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderConfirm({ open, onClose, order, reqOkProducts, reqNoProducts, resultProducts }) {
  const navigate = useNavigate();
  const location = useLocation();
  // loading
  const [loading, setLoading] = React.useState(false);

  // 주문 결제 금액 관련 정보(총 주문 금액, 승인 금액, 취소 금액, 최종 매출 금액)
  const [paymentInfo, setPaymentInfo] = React.useState({
    productAmount: order?.pay?.product ?? 0,
    reqOkAmount: 0,
    reqNoAmount: 0,
    resultAmount: 0
  });

  // 상품 승인 거절 사유
  const [reqNoReason, setReqNoReason] = React.useState({ msg: '', msg_code: '', msg_detail: '' });

  // 승인 완료/거절 다이얼로그 관련 정보
  const [resultDialog, setResultDialog] = React.useState({ open: false, title: '', content: '' });

  // 페이지 진입 시
  React.useEffect(() => {
    if (open) {
      // 선택된 상품 목록 또는 주문 상품 목록이 없을 경우 화면 닫기
      if (!order && reqOkProducts.length === 0 && reqNoProducts.length === 0 && resultProducts.length === 0) {
        console.error('[주문승인] 전 페이지에서 선택한 상품목록을 불러올 수 없음');
        WoAlert.fire('주문승인', '주문 상품 정보가 없습니다.', 'error').then(() => {
          onClose();
        });
      } else {
        getPaymentInfo();
      }
    }
  }, [open]);

  // 최종 결제 정보 조회
  const getPaymentInfo = () => {
    let reqOkAmount = 0;
    let reqNoAmount = 0;
    reqOkProducts.forEach((product) => {
      reqOkAmount += product.price.original * product.ok_quantity;
    });
    reqNoProducts.forEach((product) => {
      reqNoAmount += product.price.original * (product.quantity - product.ok_quantity);
    });
    const resultAmount = order.pay.product - reqNoAmount;

    // 최종 결제 정보 이중 체크 (최종 매출 금액이 올바르지 않을 경우)
    if (reqOkAmount !== resultAmount) {
      console.error('[주문 확인 - step2] 최종 결제 정보가 올바르지 않습니다.');
      WoAlert.fire(
        '주문 확인',
        '최종 매출 금액 정보가 올바르지 않습니다.<br />오류가 반복될 시 아래 번호로 문의해 주세요<br /><br />대표전화 : 1811-7716',
        'error'
      ).then(() => {
        onClose();
      });
      return false;
    }

    setPaymentInfo({ ...paymentInfo, reqOkAmount, reqNoAmount, resultAmount });
  };

  // 주문 거절 메세지 데이터
  const reqNoData = (data) => {
    const { reqNoCode, msg, reqNoText } = data;
    setReqNoReason({ msg, msg_code: reqNoCode, msg_detail: reqNoText });
  };

  // 주문 승인/거절 요청
  const reqApiCall = async () => {
    setLoading(true);
    const body = {
      oid: order.oid,
      order_products: resultProducts
    };

    // 주문 승인/거절 텍스트
    let reqText = '승인';
    if (reqOkProducts.length === 0) reqText = '취소';

    // 주문 거절할 상품이 있을 시
    if (reqNoProducts.length > 0) {
      if (reqNoReason.msg === '' && reqNoReason.msg_code === '') {
        WoAlert.fire('주문 확인', '거절 사유를 선택해 주세요.', 'error');
        return false;
      }
      if (reqNoReason.msg_code === 'D' && reqNoReason.msg_detail === '') {
        WoAlert.fire('주문 확인', '거절 사유를 입력해 주세요.', 'error');
        return false;
      }
      body.msg = reqNoReason.msg;
      body.msg_code = reqNoReason.msg_code;
      body.msg_detail = reqNoReason.msg_code === 'D' ? reqNoReason.msg_detail : '';
    }

    const result = await OrderService.orderReq(body)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    if (result.error) {
      sendCloError(result.error);
      console.error(`[주문확인] 주문 ${reqText} 중 오류 발생 > `, result.error);
      WoAlert.fire('주문확인', `주문 ${reqText} 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError(msg);
      console.error(`[주문확인] 주문 ${reqText} 중 오류 발생 > `, msg);
      WoAlert.fire('주문확인', `주문 ${reqText} 중 오류가 발생했습니다.<br />${msg}<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    setResultDialog({
      open: true,
      title: `주문 ${reqText} 완료`,
      content:
        reqText === '취소'
          ? `재고가 없거나 가격이 변동 된 상품은\n상품관리에서 바로 수정 부탁드립니다.`
          : `주문 승인 처리가 완료되었습니다.\n픽업 방문 시 꼭 신분증을 확인해주세요.`
    });
  };

  // CLO 에러 전송
  const sendCloError = (errorMsg) => {
    // clo
    const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
    sendCloError({
      code: CLO_CODE.ORDER_REQUEST_ERROR,
      title: `[VENDOR APP] 주문 승인/거절 요청 오류 [oid=${order.oid}] [error=${errorMsg}]`,
      msg: `${JSON.stringify(errorMsg)}`,
      which: `${location.pathname}${location.search}`
    })
      .then(console.log)
      .catch(console.error);
  };

  return (
    <Drawer anchor="right" open={open}>
      {/* 로딩 */}
      <LoadingLayout open={loading} text={`주문을 ${reqOkProducts.length === 0 ? '거절' : '승인'}하고 있습니다.`} />
      {/* 주문 승인/거절 모달 */}
      <WoDialog open={resultDialog.open} title={resultDialog.title} content={resultDialog.content} action={() => navigate(0)} />
      {/* header */}
      <Box position="fixed" top={0} left={0} right={0} zIndex={1}>
        <DrawerHeader sx={{ minHeight: '50px' }}>
          <BackButton color="inherit" onClick={onClose} />
          <DrawerTitle>주문확인</DrawerTitle>
          <Box flexGrow={1} />
        </DrawerHeader>
      </Box>
      <Container sx={{ pt: 'calc(56px + 12px)', pb: '12px', width: '100vw' }}>
        <Stack spacing={1.5}>
          {reqOkProducts.length > 0 && <ReqOkCard products={reqOkProducts} />}
          {reqNoProducts.length > 0 && <ReqNoCard products={reqNoProducts} reqNoData={reqNoData} />}
        </Stack>
      </Container>

      {/* 최종 결제 정보 */}
      <PaymentInformation paymentInfo={paymentInfo} />

      {/* 경고문구 */}
      <Box mt="13px" textAlign="center">
        <Typography fontSize="15px" color="#FF6850" fontWeight="bold">
          주문 내역을 꼼꼼히 확인 후 완료 버튼을 눌러주세요.
        </Typography>
      </Box>

      {/* 주문 승인/취소 완료 버튼 */}
      <Box mt="13px" px="20px" pb="40px">
        <Button
          variant="contained"
          fullWidth
          sx={{ borderRadius: '12px', height: '50px', color: '#FFFFFF', fontSize: '14px', fontWeight: 'bold' }}
          disabled={
            reqNoProducts.length > 0 &&
            (!reqNoReason.msg_code || (reqNoReason.msg_code === 'D' && reqNoReason.msg_detail.trim().length === 0))
          }
          onClick={() => reqApiCall()}
        >
          {reqOkProducts.length === 0 ? '취소 완료' : '승인/취소 완료'}
        </Button>
      </Box>
    </Drawer>
  );
}

export default OrderConfirm;

OrderConfirm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  order: PropTypes.object.isRequired,
  reqOkProducts: PropTypes.array.isRequired,
  reqNoProducts: PropTypes.array.isRequired,
  resultProducts: PropTypes.array.isRequired
};

// Drawer header.
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, '20px'),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

// Drawer title.
const DrawerTitle = styled((props) => (
  <Typography
    variant="div"
    noWrap
    lineHeight={1}
    fontSize="18px"
    fontWeight={800}
    letterSpacing="-0.18px"
    color="text.primary"
    {...props}
  />
))({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});
