import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

// project imports
import { bottleCapacityToString } from 'config';

// material-ui
import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

// 상품 이미지 가로 크기
const PRODUCT_IMG_WIDTH = 61;
// 상품 이미지 세로 크기
const PRODUCT_IMG_HEIGHT = 84;

/**
 * 프로모션 상세의 상품 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PromotionProductCard = ({ item, promoState, selectedPdata }) => {
  // 프로모션 상품 칩 표현
  const productChip = React.useMemo(() => {
    if (!item) return null;
    const { productCustomState } = item;

    if (!productCustomState || !promoState) return null;

    // 판매대기 또는 진행 중 또는 종료(입고완료)인 상태에서는 칩 표시 안함
    if (promoState === 'wait' || promoState === 'ing' || promoState === 'finish') return;

    // 해당 없음
    if (productCustomState === 'NONE') {
      return <PromotionProductChip label="해당없음" />;
    }

    // 주문마감(입고대기)
    if (productCustomState === 'ING') {
      return <PromotionProductChip label="주문마감(입고대기)" />;
    }

    // 입고 완료
    if (productCustomState === 'OK') {
      return <PromotionProductChip label="입고완료" />;
    }
  }, [item, item.productCustomState, promoState]);

  // 프로모션 입고 처리 버튼 표현
  const productButton = React.useMemo(() => {
    if (!item) return;
    const { productCustomState, order_quantity } = item;

    if (!productCustomState || !promoState) return;

    // 프로모션의 상태가 판매대기 또는 진행 중인 상태에서는 칩 표시 안함
    if (promoState === 'wait' || promoState === 'ing') return;

    // 프로모션의 커스텀 상태가 주문마감(입고대기)이고, 상품의 커스텀 상태가 ING인 경우
    if (promoState === 'end' && productCustomState === 'ING') {
      return (
        <Button
          variant="outlined"
          type="button"
          sx={{
            border: '1px solid #E7E4EB',
            mt: '14px',
            p: '11px',
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 'normal'
          }}
          onClick={() => selectedPdata(item)}
        >
          {order_quantity?.toLocaleString()}개 상품 입고 완료 처리
        </Button>
      );
    }
  }, [item, item.productCustomState, promoState]);

  // 프로모션 상품 납품가/마진 표현
  const productPriceInfo = React.useMemo(() => {
    if (!item) return;

    const {
      purchase_price = 0,
      price: { original = 0 }
    } = item;

    // 납품가(부가세 포함) 납품가 x 1.1
    const supplyPrice = purchase_price * 1.1;
    // 마진(일반 판매가 - 납품가)
    const marginPrice = original - supplyPrice;

    return (
      <Box sx={{ width: '100%', px: 2, pt: 2, borderTop: '1px solid #F3F2F5' }}>
        <Stack
          direction="row"
          spacing={1}
          divider={
            <Box display="flex" alignItems="center">
              <Box height="13px" width="1px" bgcolor="#A2A2A3" />
            </Box>
          }
          justifyContent="center"
        >
          <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal', color: '#A2A2A3' }}>
            납품가 {supplyPrice.toLocaleString()}원
          </Typography>
          <Typography sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal', color: '#A2A2A3' }}>
            마진 {marginPrice.toLocaleString()}원
          </Typography>
        </Stack>
      </Box>
    );
  }, [item]);

  // render
  return (
    <Card sx={{ mt: 2, p: '20px 16px', boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.04)' }}>
      {productChip}
      <Stack direction="row" alignItems="center" spacing="12px">
        {/* pdata 이미지 */}
        <ImageWrapper sx={{ width: PRODUCT_IMG_WIDTH, height: PRODUCT_IMG_HEIGHT }}>
          <LazyLoadImage
            placeholder={
              <img
                src={wineDefaultImg}
                alt=""
                draggable="false"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  objectPosition: 'center center'
                }}
              />
            }
            width="100%"
            height="100%"
            effect="blur"
            draggable={false}
            src={item?.pdata?.bottle_img?.thumb ?? wineDefaultImg}
            alt={`${item?.pdata?.name?.ko} 상품 이미지`}
            onError={(e) => {
              e.target.src = wineDefaultImg;
            }}
            style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center center' }}
          />
        </ImageWrapper>
        <Box sx={{ width: `calc(100% - ${PRODUCT_IMG_WIDTH}px - 12px)`, height: PRODUCT_IMG_HEIGHT, py: 1 }}>
          <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography className="line-2-ellipsis" sx={{ color: '#000', fontSize: 12, fontWeight: 700, lineHeight: 'normal' }}>
              {item?.pdata?.name?.ko}
            </Typography>
            <Box>
              {/* 빈티지/용량 */}
              <Stack
                mt="8px"
                direction="row"
                spacing="4px"
                divider={
                  <Box display="flex" alignItems="center" py="1px">
                    <Box height={1} width="0.75px" bgcolor="#A2A2A3" />
                  </Box>
                }
              >
                {/* 빈티지 */}
                <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                  {item?.vintage}
                </Typography>
                {/* 용량 */}
                <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                  {bottleCapacityToString(item?.capacity)}
                </Typography>
              </Stack>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography sx={{ fontSize: 12, color: '#000', lineHeight: 'normal' }}>
                  {item?.price?.original?.toLocaleString()}원
                </Typography>
                {/* 해당 상품에 주문이 들어온 (병 수)출력 */}
                {promoState !== 'wait' && (
                  <Typography sx={{ fontSize: 12, color: '#3CAE47', fontWeight: 700, lineHeight: 'normal' }}>
                    주문 수량 {item?.order_quantity?.toLocaleString()}개
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
      {productButton}
      <Box mt={2}>{productPriceInfo}</Box>
    </Card>
  );
};

export default PromotionProductCard;

PromotionProductCard.propTypes = {
  item: PropTypes.object,
  promoState: PropTypes.string,
  selectedPdata: PropTypes.func
};

const PromotionProductChip = styled((props) => <Chip size="small" label={props.label} {...props} />)(() => ({
  backgroundColor: '#3CAE47',
  fontSize: 11,
  fontWeight: 700,
  lineHeight: 'normal',
  color: '#FFF',
  marginBottom: '16px'
}));

const ImageWrapper = styled(Box)(
  ({ theme }) => `
  position: relative;
  border-radius: 6px;
  background-color: #F4F0F8;
  display: inline-block;
  overflow: hidden;
  padding: 12px 5px 11.2px;
  text-align: center;
  overflow: hidden;
  transition: ${theme.transitions.create(['border-color', 'background-color', 'box-shadow'])}
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
`
);
