import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';

// project imports
import { ProductImage, ProductName, ProductPrice } from 'components/product';
import { bottleCapacityToString } from 'config';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

const IMG_WIDTH = 68;
const IMG_HEIGHT = 88;

/**
 * 최종 주문 확인 페이지의 상품 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ProductCard({ type, product }) {
  return (
    <Card sx={{ boxShadow: '0px 4px 24px #1303281A', px: '16px', py: '14px' }}>
      <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
        <Box sx={{ mt: 0, mr: 1.25 }} minWidth={IMG_WIDTH} width={IMG_WIDTH} height={IMG_HEIGHT}>
          <ProductImage
            width="100%"
            height="100%"
            visibleByDefault
            src={product?.pdata?.bottle_img?.thumb ?? wineDefaultImg}
            alt="와인 이미지"
          />
        </Box>
        <ListItemText
          disableTypography
          sx={{
            m: 0,
            minHeight: IMG_HEIGHT,
            display: 'flex',
            justifyContent: 'space-between',
            flexFlow: 'column'
          }}
          primary={
            // 상품명
            <ProductName>
              {product?.name?.ko ? (
                `${product.name.ko}`
              ) : (
                <>
                  <Skeleton />
                  <Skeleton />
                </>
              )}
            </ProductName>
          }
          secondary={
            <Box>
              <Box pb="3px">
                {/* 빈티지 & 용량 */}
                <Box mt={1}>
                  <Typography fontSize="12px" color="#333333E5">
                    {product?.vintage && product.vintage === 'NV' ? product.vintage : product.vintage + '년'}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {bottleCapacityToString(product?.capacity ?? 0)}
                  </Typography>
                </Box>
                {/* 상품가격 */}
                <Box display="flex" justifyContent="flex-start" alignItems="center" height={22}>
                  {!product.price || product.price.original === null ? (
                    <Skeleton width="120px" />
                  ) : (
                    <Box display="flex" alignItems="baseline">
                      <ProductPrice fontSize="16px">{product.price.original.toLocaleString()}원</ProductPrice>
                      <Typography fontSize="14px" color="#333333E5" ml={0.5}>
                        {type === 'REQ_OK' && `${product.ok_quantity}병`}
                        {type === 'REQ_NO' && `${product.quantity - product.ok_quantity}병`}
                        {type === 'PICK_WAIT' && `${product.quantity}병`}
                        {type === 'CANCEL' && `${product.origin_quantity - product.quantity}병`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          }
        />
      </ListItem>
      <Box mt={1.5}>
        <Grid container>
          <Grid item>
            <Typography fontSize="12px" color="#333333E5">
              요청사항 :
            </Typography>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography fontSize="12px" color="#FF5236" pl={0.5}>
              {product.comment === '' ? '없음' : product.comment}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  type: PropTypes.string,
  product: PropTypes.object.isRequired
};
