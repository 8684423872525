import React from 'react';

// project imports
import Form from './components/Form';

// material-ui
import { Box, Container } from '@mui/material';
import { WoAlert } from 'utils/kmwine-alerts';
import Footer from '../../../../components/Footer';

/**
 * 1kmwine 아이디로 로그인 화면
 * @constructor
 */
function SignInWithIdPage() {
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '로그인 실패'
  });

  // 로그인 실패시
  const onLoginFailure = (error) => {
    let message = '로그인에 실패했습니다.';
    const { errorCode } = error;
    if (errorCode === 'not-existing-customer' || errorCode === 'invalid-password') {
      message = '아이디 또는 비밀번호가 올바르지 않습니다.';
    }

    WoAlert.fire(message, '', 'error');

    setSnackbar({
      ...snackbar,
      message,
      open: true
    });
  };

  return (
    <Box component="main">
      <Container maxWidth="xs" sx={{ px: [2.5, 2.5, 2.5, 2.5] }}>
        <Form onLoginFailure={onLoginFailure} />
      </Container>
    </Box>
  );
}

export default SignInWithIdPage;
