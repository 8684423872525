import axios from 'axios';

// project imports
import { CLO_CODE } from 'config';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { httpsCallable } from 'firebase/functions';
import { FIREBASE_FUNCTION_URL, NODE_URL } from './index';

/**
 * 공동 구매 목록
 */
export async function getCoBuyingList(body) {
  return axios
    .post(NODE_URL.POST__COBUYING_LIST, { ...body })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 공동 구매 목록 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCoBuyingList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 공동 구매 상세정보 조회
 */
export async function getCoBuyingDetail(id) {
  return axios
    .get(NODE_URL.GET__COBUYING_DETAIL, { params: { id } })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 공동 구매 상세 정보 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getCoBuyingDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 공동 구매 픽업 샵 상품 입고 처리
 */
export async function updateCoBuyProductIn(params) {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__COBUYING_PRODUCT_IN, { ...params })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 공동 구매 입고 요청 처리 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `updateCoBuyProductIn`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 1KMWINE 프로모션 목록 조회
 */
export async function get1kmPromotionList() {
  return axios
    .get(NODE_URL.GET__1KM_PROMOTION_LIST)
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 1KMWINE 프로모션 목록 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `get1kmPromotionList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 1KMWINE 프로모션 상세 조회
 */
export async function get1kmPromotionDetail(id) {
  return axios
    .get(NODE_URL.GET__1KM_PROMOTION_DETAIL.replace(':promotion_id', id))
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 1KMWINE 프로모션 상세 조회 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `get1kmPromotionDetail`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 1KMWINE 프로모션 픽업 샵 상품 입고 요청
 */
export async function update1kmProductIn(params) {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__1KM_PRODUCT_IN, { ...params })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 1KMWINE 프로모션 상품 입고 요청 처리 오류 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `update1kmProductIn`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
