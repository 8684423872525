import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Routes, Route } from 'react-router-dom';

// project imports

// 1kmwine routes
import IntroRoutes from './IntroRoutes';
import ProductRoutes from './ProductRoutes';
import HomeRoutes from './HomeRoutes';
import MyRoutes from './MyRoutes';
import ManagementRoutes from './ManagementRoutes';
import OrderRoutes from './OrderRoutes';
import VisitRoutes from './VisitRoutes';
import PromotionRoutes from './PromotionRoutes';
import ErrorRoutes from './ErrorRoutes';

// authentication
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| AUTHENTICATION ROUTES ||======================= //
import GuestGuard from '../utils/route-guard/GuestGuard';
import TermPage from '../views/misc/terms/TermPage';

import SignInWithIdPage from '../views/page/authentication/SignInWithIdPage';
// ==============================|| MISC ROUTES ||======================= //

// ==============================|| ROUTING RENDER ||============================== //

export default function BourgogneRoutes({ location }) {
  return (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <AuthGuard>
            <Navigate replace to="/intro" />
          </AuthGuard>
        }
      />
      {/* '인트로' 라우트  */}
      {IntroRoutes()}
      {/* '홈' 라우트 */}
      {HomeRoutes()}
      {/* '마이페이지' 라우트 */}
      {MyRoutes()}
      {/* '주문' 라우트 */}
      {OrderRoutes()}
      {/* '매장방문대기' 라우트 */}
      {VisitRoutes()}
      {/* '상품관리' 라우트 */}
      {ProductRoutes()}
      {/* '프로모션' 라우트 */}
      {PromotionRoutes()}
      {/* '운영관리' 라우트 */}
      {ManagementRoutes()}
      <Route path="/auth">
        {/* 아이디로 로그인 */}
        <Route
          path="sign-in-with-id"
          element={
            <GuestGuard>
              <SignInWithIdPage />
            </GuestGuard>
          }
        />
      </Route>

      {/* todo 약관 및 정책 / 문서 관련 라우트 */}
      <Route path="/term/:type/latest" element={<TermPage test="hahaha" />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
      {ErrorRoutes()}
    </Routes>
  );
}

BourgogneRoutes.propTypes = {
  location: PropTypes.object.isRequired
};
