import React from 'react';
import { Route } from 'react-router-dom';
import ProductList from '../views/product/ProductList';
/**
 * 상품관리 라우트
 */
export default function ProductRoutes() {
  return (
    <Route path="/product">
      <Route index bottomNav element={<ProductList />} />
    </Route>
  );
}
