import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { FIREBASE_FUNCTION_URL } from './index';

/**
 * 사용자 알림 전체삭제
 *
 * @returns {null}
 */
export function removeAllAlarms() {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  console.log('[1KMWINE] 로그인 사용자의 알림 전체삭제 요청');
  return axios.put(FIREBASE_FUNCTION_URL.PUT__CALL_AUTH_MEMBER_REMOVE_ALL_ALARM).then((response) => {
    console.log('[1KMWINE] 사용자 알림 전체삭제 요청결과: ', response);
    return response;
  });
}
