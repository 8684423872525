import React from 'react';
import PropTypes from 'prop-types';

// import mui
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// projects import
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { InformationIcon } from 'components';

/**
 * 결제정보 탭
 * 해당 페이지는 조회할 주문건의 '최초결제정보'를 다루고 있습니다.
 * @constructs
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PaymentTab({ order, products }) {
  return (
    <Box py={3}>
      <Box px="20px">
        {/* 주문 상품 병 수 */}
        <Box pb="6px" borderBottom="1px solid #F4F0F8">
          <Typography fontSize="12px" fontWeight="bold" color="#33333">
            주문 상품 (총 {order?.pay_origin?.quantity}병)
          </Typography>
        </Box>

        {/* 주문 상품 목록 */}
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ px: 0, py: 1, border: 'none', maxWidth: '150px' }}>
                    <Typography fontSize="12px" color="#333333">
                      상품명
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 1, border: 'none' }} align="center">
                    <Typography fontSize="12px" color="#333333">
                      수량
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 1, border: 'none' }} align="right">
                    <Typography fontSize="12px" color="#333333">
                      금액
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product, index) => (
                  <TableRow key={`product-${index}-row`}>
                    <TableCell sx={{ px: 0, py: 1, border: 'none', maxWidth: '150px' }}>
                      <Typography fontSize="14px" color="#333333">
                        {product?.name?.ko}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ px: 0, py: 1, border: 'none' }} align="center">
                      <Typography fontSize="14px" color="#333333">
                        {product?.origin_quantity}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ px: 0, py: 1, border: 'none' }} align="right">
                      <Typography fontSize="14px" color="#333333">
                        {(product?.origin_quantity * product?.price?.original).toLocaleString()}원
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {/* 총 주문 금액 */}
                <TableRow sx={{ borderTop: '2px solid #EEEEEE' }}>
                  <TableCell colSpan={2} sx={{ px: 0, py: 2, border: 'none' }}>
                    <Typography fontSize="14px" color="#333333" fontWeight="bold">
                      총 주문 금액
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ px: 0, py: 2, border: 'none' }} align="right">
                    <Typography fontSize="14px" color="#333333" fontWeight="bold">
                      {order?.pay_origin?.product?.toLocaleString()}원
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <EmptyArea />

      {/* 결제수단 */}
      <Box px="20px" py={2}>
        <Stack spacing={3}>
          <SpaceBetweenBox>
            <Typography fontSize="14px" fontWeight="bold" color="#333333">
              결제수단
            </Typography>
            <Typography fontSize="14px" color="#333333">
              {order?.payment?.p_fn_nm}
            </Typography>
          </SpaceBetweenBox>

          <Box display="flex">
            <InformationIcon sx={{ color: '#555555', width: '13px', height: '13px', marginRight: '2px' }} />
            <Typography component="span" color="#33333399" fontSize="13px" marginLeft="6px">
              고객 정보를 상품판매 목적 외 사용, 보관, 공개 시 법적 처벌을 받을 수 있습니다.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default PaymentTab;

PaymentTab.propTypes = {
  order: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
};

const EmptyArea = styled('div')({
  width: '100%',
  height: '8px',
  backgroundColor: '#1303280A'
});
