import React from 'react';
import PropTypes from 'prop-types';
import { common } from '@mui/material/colors';

// material-ui
import { Box } from '@mui/material';

const ShadowBox = React.forwardRef(({ children, content = true, sx }, ref) => {
  console.log();

  return (
    <Box
      ref={ref}
      sx={{
        boxShadow: '0px 0px 26px #1303281A',
        background: common.white,
        border: `1px solid ${common.white}`,
        borderRadius: '8px',
        ...sx
      }}
    >
      {/* box content */}
      {content && <Box height="100%">{children}</Box>}
    </Box>
  );
});

export default ShadowBox;

ShadowBox.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  sx: PropTypes.object
};
