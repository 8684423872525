import React from 'react';
import { Typography } from '@mui/material';
/* eslint-disable react/prop-types */
const ProductPrice = ({ children, fontSize = '22px', ...other }) => (
  <Typography component="span" className="font-currency" fontSize={fontSize} fontWeight={700} {...other}>
    {children}
  </Typography>
);

export default ProductPrice;
