import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import moment from 'moment';

// material-ui
import { Box, Typography, TextField, Stack, Pagination, CircularProgress, Container } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import * as OrderService from 'services/OrderService';
import Header from 'components/header/Header';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ShadowBox } from 'components';
import { OrderItem } from './components';

// assets
import RefreshIcon from 'assets/images/ic_refresh.png';
import NoResultImg from 'assets/images/no_result_img.png';

/**
 * 주문 내역
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderList() {
  const navigate = useNavigate();

  // loading
  const [loading, setLoading] = React.useState(true);

  // 조회 페이지
  const [page, setPage] = React.useState(0);
  // 한번에 보여줄 데이터 열 개수
  const rowsPerPage = 15;
  // 총 페이지 수
  const [totalPages, setTotalPages] = React.useState(0);
  // 총 아이템 수
  const [totalResults, setTotalResults] = React.useState(0);

  // 주문 내역 조회 날짜
  const [date, setDate] = useLocationState('date', new Date());
  // datepicker
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  // 주문 내역
  const [orderList, setOrderList] = React.useState([]);

  React.useEffect(() => {
    getOrderList(1);
  }, [date]);

  // 주문 내역 조회
  const getOrderList = async (pageNo) => {
    setOrderList([]);
    setLoading(true);

    // requset body
    const body = {
      page: {
        current: pageNo,
        size: rowsPerPage
      },
      order_by: 'created_at',
      order: 'DESC',
      created_start_dt: moment(date).format('YYYY-MM-DD'),
      created_end_dt: moment(date).add(1, 'days').format('YYYY-MM-DD')
    };

    const result = await OrderService.getOrderAllList(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setLoading(false);
      });

    // 주문 목록 조회 중 오류 발생
    if (result.error) {
      console.error('주문 내역 조회 중 오류 발생 > ', result.error);
      WoAlert.fire('주문내역', '주문 내역 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요. ', 'error').then(() => {
        navigate(-1);
      });
      return;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      console.error('주문 내역 조회 중 오류 발생 > ', msg);
      WoAlert.fire('주문내역', '주문 내역 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate(-1);
      });
      return;
    }

    // order list
    const { result: rows } = result.data.data;
    const { total_pages, total_results } = result.data.page;

    setPage(pageNo);
    setTotalPages(total_pages);
    setTotalResults(total_results);
    setOrderList(rows);
  };

  // 페이지 변경 이벤트
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    getOrderList(newPage);
  };

  // refresh icon shake
  const [shake, setShake] = React.useState(false);

  // 새로고침 아이콘 클릭이벤트
  const refresh = () => {
    setShake(true);
    getOrderList().then(() => {
      setShake(false);
    });
  };

  // datepicker 날짜 변경
  const handleChange = (newValue) => {
    setDate(newValue);
    setDatePickerOpen(false);
  };

  // 날짜변환
  const timeFormat = (val) => {
    const _date = moment(val).format('YYYY년 MM월 DD일');
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[moment(_date, 'YYYY.MM.DD').toDate().getDay()];

    return `${_date} (${dayOfWeek}요일)`;
  };

  // render
  return (
    <Box>
      {/* header */}
      <Header title="주문내역" />

      {/* contents */}
      <Container sx={{ minHeight: 'calc(100vh - 54px - 56px)', pt: '16px', pb: '40px', bgcolor: '#F3F2F5' }}>
        {/* DatePicker */}
        <ShadowBox sx={{ width: '100%', p: '8px 16px', boxShadow: '0px 2px 3px #0000000A' }}>
          <SpaceBetweenBox>
            <Box display="flex" alignItems="center">
              {/* 캘린더 아이콘 */}
              <CalendarTodayOutlinedIcon onClick={() => setDatePickerOpen(true)} />
              {/* 날짜 표시 */}
              <Typography fontSize={14} color="#333333" flexGrow={2} ml={1.7} onClick={() => setDatePickerOpen(true)}>
                {timeFormat(date)}
              </Typography>
            </Box>
            <Box
              component="img"
              className={shake ? 'refresh_shake' : 'refresh'}
              src={RefreshIcon}
              width={16}
              height={22}
              onClick={() => refresh()}
            />
          </SpaceBetweenBox>
          {/* display: none */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              open={datePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              showToolbar={false}
              disableFuture
              value={date}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} sx={{ display: 'none' }} />}
              componentsProps={{
                actionBar: {
                  actions: []
                }
              }}
            />
          </LocalizationProvider>
        </ShadowBox>

        {/* 주문 내역 조회 로딩 중 */}
        {loading && (
          <Box display="flex" justifyContent="center" mt={3}>
            <CircularProgress size={30} />
          </Box>
        )}

        {/* 주문 내역이 없을 경우 */}
        {!loading && orderList.length === 0 && (
          <Box pt={7} textAlign="center">
            <Box component="img" src={NoResultImg} width={123} height={100} />
            <Box fontSize={20} color="#220348" mt={4}>
              주문 내역이 없습니다.
            </Box>
          </Box>
        )}

        {/* 주문 아이템 */}
        {orderList.length > 0 && (
          <Stack spacing={1.5} mt={1.5}>
            {orderList.map((order, index) => (
              <Box key={`order-${index}-row`} onClick={() => navigate(`/order/${order?.oid}`)}>
                <OrderItem order={order} />
              </Box>
            ))}
          </Stack>
        )}

        {/* 페이지 > 주문내역 건이 15개 이상일 경우 페이지네이션 활성화 */}
        {!loading && orderList.length > 0 && totalResults >= 15 && (
          <Box width="100%" py={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="circular"
              sx={{ padding: '0 12px', '& .MuiPagination-ul': { justifyContent: 'center' } }}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default OrderList;
