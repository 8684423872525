// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const FIREBASE_FUNCTION_URL = {
  // 공동 구매 픽업 샵 상품 입고 요청
  POST__COBUYING_PRODUCT_IN: `${BASE_HOST}/call-cobuying-cobuying-vendorUpdate`,
  // 1KMWINE 픽업 샵 상품 입고 요청
  POST__1KM_PRODUCT_IN: `${BASE_HOST}/call-promotion-promotion-receiveConfirm`
};

export const NODE_URL = {
  // 1KMWINE 프로모션 목록
  GET__1KM_PROMOTION_LIST: `${API_SERVER_HOST}/vendor/promotion/1km/join/list`,
  // 1KMWINE 프로모션 상세
  GET__1KM_PROMOTION_DETAIL: `${API_SERVER_HOST}/vendor/promotion/1km/:promotion_id/detail`,
  // 공동 구매 API [START]
  // 공동 구매 목록 조회
  POST__COBUYING_LIST: `${API_SERVER_HOST}/cobuying/list`,
  // 공동 구매 상세정보 조회
  GET__COBUYING_DETAIL: `${API_SERVER_HOST}/cobuying/get`
  // 공동 구매 API [END]
};

export * from './promotion';
