// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

export const FIREBASE_FUNCTION_URL = {
  /** 벤더에서 다운로드 가능한 쿠폰 목록 */
  POST__VENDOR_COUPON_LIST: `${BASE_HOST}/call-pay-coupon-vendorCouponList`,
  /** 입점샵 본인이 입점샵 수정 */
  PUT__VENDOR_UPDATE_V: `${BASE_HOST}/call-vendor-vendor-updateV`
};

export const NODE_URL = {
  /** 입점샵 정보 조회 */
  POST__GET_VENDOR: `${API_SERVER_HOST}/vendor/getOneVendor`,
  /** 입점샵 휴무일 리스트 가지고오기 */
  GET__VENDOR__DAYOFF: `${API_SERVER_HOST}/vendor/dayoffList`,

  /** 입점샵 휴무일 생성하기 */
  POST__VENDOR__CREATE_DAYOFF: `${API_SERVER_HOST}/vendor/createVendorDayoff`,

  /** 입점샵 휴무일 지우기 */
  DELETE__VENDOR__DELETE_DAYOFF: `${API_SERVER_HOST}/vendor/deleteVendorDayoff`,

  /** 샵 기간 중 픽업대기중인 상품 조회 */
  POST__VENDOR__CHECK_PICKUP_ORDER: `${API_SERVER_HOST}/vendor/checkPickupOrder`
};

export * from './vendor';
