import React from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

/**
 * 전체보기, 더보기 버튼
 * @type {StyledComponent<PropsOf<React.JSXElementConstructor<React.ComponentProps<C>>> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const ShowMoreButton = styled((props) => <Button {...props} />)`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.11px;
  text-align: right;
  color: #140229;
  & .MuiButton-endIcon {
    margin-left: 0;
  }
  padding: 2px;
`;

export default ShowMoreButton;
