import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

// material-ui
import { Box, CircularProgress, Container, InputAdornment, Pagination, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// project imports
import { ShadowBox } from 'components';
import { useScriptRef } from 'hooks';
import * as OrderService from 'services/OrderService';
import { WoAlert } from 'utils/kmwine-alerts';
import PickupTimeline from './components/PickupTimeline';
import WaitDrawer from '../components/WaitDrawer';

// assets
import NoResultImg from 'assets/images/no_result_img.png';
import emptyImg from 'assets/images/empty.png';

/**
 * 픽업대기 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PickupWait() {
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  const [loading, setLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalResults, setTotalResults] = React.useState(0);

  // 검색어
  const [search, setSearch] = React.useState('');
  // 픽업지연 목록
  const [pickLateRows, setPickLateRows] = React.useState([]);
  // 픽업대기 목록 (오늘)
  const [todayPickRows, setTodayPickRows] = React.useState([]);
  // 픽업대기 목록 (미래)
  const [futurePickRows, setFuturePickRows] = React.useState([]);
  // 픽업요약 목록 (픽업지연)
  const [summaryPickLateRows, setSummaryPickLateRows] = React.useState([]);

  // 픽업지연 총 수량
  const [summaryPickLateRowsQuantity, setSummaryPickLateRowsQuantity] = React.useState(0);
  // 픽업요약 목록
  const [summaryRows, setSummaryRows] = React.useState([]);
  // 픽업요약 총 수량
  const [summaryQuantity, setSummaryQuantity] = React.useState(0);
  // 검색결과 없음 플래그
  const [noResultFlag, setNoResultFlag] = React.useState(false);

  // 픽업요약 영역 표시 플래그
  const [summaryFlag, setSummaryFlag] = React.useState(false);

  // 픽업대기 목록 없음 플래그
  const [emptyPickup, setEmptyPickup] = React.useState(false);

  React.useEffect(() => {
    getPickupWaitList(1);
  }, []);

  const getPickupWaitList = async (pageNo) => {
    console.log('[매장 방문 대기] 픽업대기 목록 조회 시작');
    setLoading(true);

    const body = { page: { current: pageNo, size: 50 }, search };

    // ---------------------------------------------- 픽업대기 목록 조회 [START]
    const pickupWaitList = await OrderService.pickupWaitList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PickUpWait][getPickupWaitList] Unmounted component.');
      return undefined;
    }

    // 픽업대기 목록 조회 중 오류 발생
    if (pickupWaitList.error) {
      // todo clo
      console.error('[매장 방문 대기] 픽업대기 목록 조회 중 오류 발생 > ', pickupWaitList.error);
      WoAlert.fire('매장 방문 대기', `픽업대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요`, 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    // 픽업대기 목록 조회 중 잘못된 상태값이 넘어옴
    if (!pickupWaitList.data.success) {
      console.error('[매장 방문 대기] 픽업대기 목록 조회 중 오류 발생');
      WoAlert.fire('매장 방문 대기', `픽업대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요`, 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    const { data: pickupResult } = pickupWaitList;
    const {
      page: { total_pages, total_results }
    } = pickupResult.result;

    setTotalPages(total_pages);
    setTotalResults(total_results);

    // 픽업요약 데이터
    const pickSummary = pickupResult.summary;

    // 픽업요약 데이터 가공
    if (pickSummary.length > 0) {
      const summaryWait = []; // 픽업지연
      let summaryWaitQuantity = 0; // 픽업지연 총 수량

      let totalQuantity = 0; // 픽업요약 총 수량

      const summaryTempArr1 = [];
      let summaryTempArr2 = [];
      let changeCount = 0;

      const now = moment();

      // 픽업요약 데이터 날짜별로 배열 재생성
      pickSummary.forEach((p, index) => {
        // 픽업지연 건
        if (now.format('YYYY-MM-DD') > moment(p.pickup_date).format('YYYY-MM-DD')) {
          if (p.quantity > 0) {
            summaryWaitQuantity += p.quantity;
            summaryWait.push(p);
          }
        }
        totalQuantity += p.quantity;

        let changePickupDate;
        if (index > 0) {
          changePickupDate = pickSummary[index - 1].pickup_date;
          if (changePickupDate !== p.pickup_date) {
            changeCount += 1;
            summaryTempArr2.push(p);
            summaryTempArr1.push(summaryTempArr2);
            summaryTempArr2 = [];
          } else {
            summaryTempArr1[changeCount].push(p);
          }
        } else {
          summaryTempArr1.push([p]);
        }
      });
      setSummaryRows(summaryTempArr1);
      setSummaryPickLateRows(summaryWait);
      setSummaryPickLateRowsQuantity(summaryWaitQuantity);
      setSummaryQuantity(totalQuantity);
    } else {
      setSummaryRows([]);
    }

    const pickupList = pickupWaitList.data.result.list;

    // 검색 결과가 없을 시
    setNoResultFlag(body.search !== '' && pickupList.length === 0);

    // 픽업리스트가 없을 시
    setEmptyPickup(body.search === '' && pickupList.length === 0);

    // 데이터 구분 (오늘 이전, 오늘, 오늘 이후)
    const pastList = [];
    const todayList = [];
    const futureList = [];

    const nowDate = moment().format('YYYY-MM-DD');

    pickupList.forEach((p) => {
      if (nowDate > moment(p.pickup_date_time).utc().format('YYYY-MM-DD')) {
        pastList.push(p);
      } else if (nowDate === moment(p.pickup_date_time).utc().format('YYYY-MM-DD')) {
        todayList.push(p);
      } else if (nowDate < moment(p.pickup_date_time).utc().format('YYYY-MM-DD')) {
        futureList.push(p);
      }
    });

    setPickLateRows(pastList);

    // 오늘 데이터 가공
    if (todayList.length > 0) {
      const todayorder = {};
      // 오늘 데이터 시간별로 배열 재생성
      todayList.forEach((t, index) => {
        const pickupDate = moment(t.pickup_date_time).utc().format('a hh:mm YYYY. MM. DD ddd요일');

        const prevPickupDate =
          index === 0
            ? pickupDate
            : moment(todayList[index - 1].pickup_date_time)
                .utc()
                .format('a hh:mm YYYY. MM. DD ddd요일');

        if (index > 0) {
          if (prevPickupDate === pickupDate) {
            todayorder[pickupDate].push(t);
          } else {
            todayorder[pickupDate] = [t];
          }
        } else {
          todayorder[pickupDate] = [t];
        }
        setTodayPickRows(todayorder);
      });

      // const todayTempArr1 = [];
      // let todayTempArr2 = [];
      // let changeCount = 0;
      // // 오늘 데이터 시간별로 배열 재생성
      // todayList.forEach((t, index) => {
      //   let changePickupTime;
      //   if (index > 0) {
      //     changePickupTime = todayList[index - 1].pickup_time;
      //     if (changePickupTime !== t.pickup_time) {
      //       changeCount += 1;
      //       changePickupTime = t.pickup_time;
      //       todayTempArr2.push(t);
      //       todayTempArr1.push(todayTempArr2);
      //       todayTempArr2 = [];
      //     } else {
      //       todayTempArr1[changeCount].push(t);
      //     }
      //   } else {
      //     changePickupTime = t.pickup_time;
      //     todayTempArr1.push([t]);
      //   }
      // });
      // setTodayPickRows(todayTempArr1);
    } else {
      setTodayPickRows([]);
    }

    // 오늘 이후 데이터 가공
    if (futureList.length > 0) {
      const futureOrder = {};
      // 오늘 이후 데이터 날짜별로 배열 재생성
      futureList.forEach((f, index) => {
        const pickupDate = moment(f.pickup_date_time).utc().format('YYYY. MM. DD ddd요일');

        const prevPickupDate =
          index === 0
            ? pickupDate
            : moment(futureList[index - 1].pickup_date_time)
                .utc()
                .format('YYYY. MM. DD ddd요일');

        if (index > 0) {
          if (prevPickupDate === pickupDate) {
            futureOrder[pickupDate].push(f);
          } else {
            futureOrder[pickupDate] = [f];
          }
        } else {
          futureOrder[pickupDate] = [f];
        }
      });
      setFuturePickRows(futureOrder);
    } else {
      setFuturePickRows([]);
    }

    setLoading(false);
    console.log('[매장 방문 대기] 픽업대기 목록 조회 종료');
  };

  // 페이지 변경 이벤트
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    getPickupWaitList(newPage);
  };

  // render
  return (
    <>
      {/* 픽업 요약 Drawer */}
      <WaitDrawer
        open={summaryFlag}
        setOpen={(val) => setSummaryFlag(val)}
        rows={summaryRows}
        lateRows={summaryPickLateRows}
        lateQuantity={summaryPickLateRowsQuantity}
        quantity={summaryQuantity}
      />

      {/* 픽업 요약 버튼 */}
      {summaryRows.length > 0 && (
        <Box
          sx={{
            backgroundColor: '#130328',
            borderRadius: '16px',
            padding: '6px 18px',
            fontWeight: 600,
            color: '#ffffff',
            top: '85%',
            position: 'absolute',
            right: 15,
            zIndex: 5001
          }}
          onClick={() => setSummaryFlag(!summaryFlag)}
        >
          픽업 요약
        </Box>
      )}

      <Container component="main" sx={{ pt: 2 }} style={{ height: 'calc(100vh - 106px)' }}>
        {/* 필터 */}
        {!emptyPickup && !loading && (
          <Box mb={2}>
            <ShadowBox sx={{ width: '100%' }}>
              <TextField
                size="small"
                fullWidth
                placeholder="구매자명을 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={({ key }) => {
                  if (key === 'Enter') {
                    getPickupWaitList();
                  }
                }}
                sx={{
                  '& fieldset': { border: 'none' }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" onClick={() => getPickupWaitList()}>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </ShadowBox>
          </Box>
        )}

        {/* 로딩중 */}
        {loading && (
          <Box textAlign="center" p="24px">
            <CircularProgress size={24} />
          </Box>
        )}

        {/* 픽업대기목록이 없을 시 */}
        {emptyPickup && (
          <Box pt={7} textAlign="center">
            <Box component="img" src={NoResultImg} width={123} height={100} />
            <Box fontSize={20} color="#220348" mt={4}>
              픽업대기 내역이 없습니다
            </Box>
          </Box>
        )}

        {/* 검색결과가 없을 시 */}
        {noResultFlag && (
          <Box pt={7} textAlign={'center'}>
            <Box component={'img'} src={emptyImg} width={110} height={110} />
            <Box mt={4}>
              <Typography variant="body2" color="#928C9B">
                검색 결과가 없습니다. 다시 검색해 주세요.
              </Typography>
            </Box>
          </Box>
        )}

        {/* 타임라인 */}
        {!loading && <PickupTimeline pickLateRows={pickLateRows} todayPickRows={todayPickRows} futurePickRows={futurePickRows} />}

        {/* 페이지 > 픽업대기 건이 50개 이상일 경우 페이지네이션 활성화 */}
        {!loading && !emptyPickup && !noResultFlag && totalResults >= 50 && (
          <Box width="100%" pb={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="circular"
              sx={{ padding: '0 12px', '& .MuiPagination-ul': { justifyContent: 'center' } }}
            />
          </Box>
        )}
        {/* 타임라인 [END] */}
      </Container>
    </>
  );
}

export default PickupWait;
