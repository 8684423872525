import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';

// mui, fontAwosome
import { Box, TextField, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// import project
import ImageDrawer from './components/ImageDrawer';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as yup from 'yup';
import { deleteObject, getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { FIREBASE_HOSTNAME } from 'config';
import BasicDialog from 'components/dialog/BasicDialog';
import AlertTriangle from 'assets/images/product/ic_alert_triangle.jpg';

function InquiryDo({ changeLoading, changeLoadingmsg, imageList, setImageList, setIsWrite }) {
  const inquiryItem = [
    {
      title: '상품 관련 문의',
      type: 'product'
    },
    {
      title: '서비스 관련 문의',
      type: 'service'
    }
  ];
  const { user } = useAuth();
  const navigate = useNavigate();
  let vendorReload;
  let vendorId;
  if (user) {
    try {
      vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
      vendorId = vendorReload.vendor;
    } catch (e) {
      navigate(0);
    }
  }
  // 화면에 보여지는 이미지
  const [skeletonImg, setSkeletonImg] = useState([]);
  // image drawer open
  const [drawerOpen, setDrawerOpen] = useState(false);
  // image drawer img src
  const [drawerSrc, setDrawerSrc] = useState(null);
  // 문의 등록 성공 dialog 띄우기
  const [dialogOpen, setDialogOpen] = useState(false);
  // dialog 메세지
  const [dialogMsg, setDialogMsg] = useState('문의 하기 등록 성공');
  // dialog type
  const [dialogType, setDialogType] = useState('success');
  // 시작 기본값.
  const initialValues = {
    type: 'product',
    contents:
      '와인명 (한글) :\n와인명 (영문) :\n국가 :\n수입사 :\n문의내용 :\n\nDB추가, 상품 정보 오류 수정 등은 위 양식에 맞추어 입력 해 주시면 더 빠른 응대를 받으실 수 있습니다.',
    img: []
  };
  // 유효성 검사
  const validationSchema = yup.object({
    contents: yup.string().when('type', {
      is: 'product',
      then: (schema) =>
        schema.notOneOf(
          [
            '와인명 (한글) :\n와인명 (영문) :\n국가 :\n수입사 :\n문의내용 :\n\nDB추가, 상품 정보 오류 수정 등은 위 양식에 맞추어 입력 해 주시면 더 빠른 응대를 받으실 수 있습니다.'
          ],
          '[상품 관련 문의] 내용을 입력해주세요.'
        ),
      otherwise: (schema) => schema.required('[서비스 관련 문의] 내용을 입력해주세요')
    })
  });
  // 제출
  const onSubmit = async (values) => {
    changeLoading(true);
    changeLoadingmsg('문의하기 등록중입니다.');

    // 이미지 저장 정보 가공
    const image_ids = [];

    imageList.forEach((image) => {
      image_ids.push(image.ref.fullPath);
    });

    // 입점사 정보
    let HEADER_CONFIG;
    if (user) {
      HEADER_CONFIG = { headers: { Authorization: `Bearer ${user.accessToken}` } };
    }
    // 데이터
    const body = {
      type: values.type,
      content: values.contents.replace(/\n/gi, '\\n'),
      image_ids
    };

    await axios
      .post(`${FIREBASE_HOSTNAME}/call-inquiry-vendor-create`, body, HEADER_CONFIG)
      .then((res) => {
        const resStatus = res.status;
        const resResult = res.data.result;
        if (resStatus === 200) {
          // 문의등록 성공시
          if (resResult.code === 0) {
            changeLoading(false);
            setDialogType('success');
            setDialogMsg('문의하기 등록 성공');
            setDialogOpen(true);
            setIsWrite(false);
          } else {
            console.error('[문의등록] 오류발생 err > ', resResult.msg);
            changeLoading(false);
            setDialogType('error');
            setDialogMsg('문의하기 등록 중 오류가 발생했습니다. <br /> 다시 시도해 주세요');
            setDialogOpen(true);
          }
        }
      })
      .catch((e) => {
        changeLoading(false);
        setDialogType('error');
        console.error('[문의하기] 문의 등록 중 에러 발생', e);
        setDialogMsg('문의하기 등록 중 오류가 발생했습니다. <br /> 다시 시도해 주세요');
        setDialogOpen(true);
      });
  };
  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  // 이미지 업로드
  const uploadImg = (e) => {
    if (imageList.length >= 3) {
      // 이미지 추가 불가 안내창 띄우기
      setDialogType('error');
      setDialogOpen(true);
      setDialogMsg('이미지는 최대 3개까지 등록가능합니다.');
    } else {
      const { files } = e.target;
      const imageFiles = [];
      setIsWrite(true);
      Array.from(files).forEach((file) => {
        imageFiles.push(file);
      });

      imageFiles.forEach((imgFile) => uploadToStorage(imgFile));
    }
  };

  // fireStore에 이미지 업로드
  const uploadToStorage = (imgFile) => {
    changeLoading(true);
    changeLoadingmsg('이미지 업로드 중');
    const logicalName = `${uuidv4()}`.replaceAll('-', '');
    const storage = getStorage();
    const storageRef = ref(storage, `gs://inquiry.1kmwine.com/${vendorId}/${new Date().getTime()}-${logicalName}`);

    const fileMeta = {
      customMetadata: {
        type: 'vendor-inquiry-image',
        vendorId,
        fileOriginName: imgFile.name
      }
    };
    // fireStore에 업로드
    uploadBytes(storageRef, imgFile, fileMeta)
      .then(({ metadata, ref }) => getDownloadURL(ref).then((url) => ({ src: url, metadata, ref })))
      .then(({ src, metadata, ref }) => {
        // setImgList 업데이트
        setImageList((images) => {
          return [...images, { src, metadata, ref }];
        });
        // firebase database에 올릴 form 내용 업데이트
        formik.setValues((prevState) => {
          return { ...prevState, img: [...imageList, { src, metadata, ref }] };
        });
        // 화면에 보여질 skeleton img 업데이트
        setSkeletonImg([...skeletonImg, imgFile]);
      })
      .catch((error) => {
        console.error('[1:1문의] 문의 이미지 업로드 중 오류가 발생했습니다.', error);
        return { error };
      })
      .finally(() => {
        changeLoading(false);
      });
  };

  const selectItem = (itemType) => {
    if (itemType === 'product') {
      formik.setValues((prevState) => {
        return {
          ...prevState,
          type: itemType,
          contents:
            '와인명 (한글) :\n와인명 (영문) :\n국가 :\n수입사 :\n문의내용 :\n\nDB추가, 상품 정보 오류 수정 등은 위 양식에 맞추어 입력 해 주시면 더 빠른 응대를 받으실 수 있습니다.'
        };
      });
    } else {
      formik.setValues((prevState) => {
        return { ...prevState, type: itemType, contents: '' };
      });
    }
  };

  useEffect(() => {
    if (formik.values.type === 'product') {
      if (
        formik.values.contents !==
        '와인명 (한글) :\n와인명 (영문) :\n국가 :\n수입사 :\n문의내용 :\n\nDB추가, 상품 정보 오류 수정 등은 위 양식에 맞추어 입력 해 주시면 더 빠른 응대를 받으실 수 있습니다.'
      ) {
        setIsWrite(true);
      } else {
        setIsWrite(false);
      }
    } else if (formik.values.type === 'service') {
      if (formik.values.contents !== '') {
        setIsWrite(true);
      } else {
        setIsWrite(false);
      }
    }
  }, [formik.values.contents]);

  // 이미지 드로어 열기
  const showDrawer = (val) => {
    setDrawerOpen(true);
    setDrawerSrc(val);
  };

  // 이미지 드로어 닫기
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  // 이미지 삭제
  const imgRemove = (id) => {
    changeLoading(true);
    changeLoadingmsg('이미지 삭제 중');
    const removingImg = imageList[id];
    const { ref: deleteImgRef } = removingImg;

    // firebase Storage에서의 삭제
    deleteObject(deleteImgRef)
      .then(() => {
        // 화면에서의 삭제
        setSkeletonImg(skeletonImg.filter((_, index) => index !== id));
        // form과 imgList에서의 삭제
        // todo 삭제 후 다시 이미지 첨부시 error
        imageList.splice(id, 1);
        formik.setValues((prevState) => {
          return { ...prevState, img: [...imageList] };
        });
      })
      .catch((e) => {
        console.error('[1:1문의] 문의 이미지 삭제 중 오류 발생 > ', e);
      })
      .finally(() => {
        changeLoading(false);
        if (imageList.length === 0) {
          setIsWrite(false);
        }
      });
  };
  // dialog 닫을 때 action
  const closeDialog = () => {
    if (dialogType === 'success') {
      setDialogOpen(false);
      navigate(0);
    } else {
      setDialogOpen(false);
    }
  };
  return (
    <>
      <ImageDrawer isOpen={drawerOpen} closeImgDrawer={closeDrawer} imgSrc={drawerSrc} />
      <form onSubmit={formik.handleSubmit}>
        <Box pb={2}>
          <Box mt={2} display={'flex'}>
            <Typography fontSize={16} fontWeight={'bold'} color={'#6B6666'} mr={0.5}>
              문의유형 선택
            </Typography>
            <FontAwesomeIcon icon={faStarOfLife} fontSize={6} color={'#FB6464'} />
          </Box>
          <Box>
            <Grid container marginTop={0.2} spacing={1}>
              {inquiryItem.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <Box
                    sx={{
                      backgroundColor: item.type === formik.values.type ? '#220348' : '#F2F2F2',
                      border: 'none',
                      fontSize: '16px',
                      fontWeight: 600,
                      color: item.type === formik.values.type ? '#FFFFFF' : '#000000',
                      padding: '8px 0',
                      textAlign: 'center',
                      borderRadius: '7px',
                      transition: 'background-color 250ms'
                    }}
                    onClick={() => selectItem(item.type)}
                  >
                    {item.title}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={2} display={'flex'}>
            <Typography fontSize={16} fontWeight={'bold'} color={'#6B6666'} mr={0.5}>
              문의 내용 입력
            </Typography>
            <FontAwesomeIcon icon={faStarOfLife} fontSize={6} color={'#FB6464'} />
            {formik.touched.contents && (
              <Typography ml={1} fontSize={12} color={'red'} alignSelf={'center'}>
                {formik.errors.contents}
              </Typography>
            )}
          </Box>
          <TextField
            name={'contents'}
            fullWidth
            multiline
            rows={18}
            placeholder="문의하실 내용을 입력 해 주세요"
            value={formik.values.contents}
            sx={{
              mt: 2,
              mb: 2,
              border: formik.touched.contents && Boolean(formik.errors.contents) ? '1px solid red' : '1px solid #707070',
              borderRadius: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              }
            }}
            onChange={formik.handleChange}
            error={formik.touched.contents && Boolean(formik.errors.contents)}
          />
          <Box mt={2} display={'flex'} alignItems={'center'}>
            <Typography fontSize={14} fontWeight={'bold'} color={'#6B6666'} mr={1}>
              이미지 첨부 (최대 3개)
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={'fileUpload'} style={{ cursor: 'pointer', display: 'flex', width: '16px', height: '16px' }}>
              {imageList.length < 3 && <AddCircleOutlineOutlinedIcon color={'#707070'} sx={{ width: '100%', height: '100%' }} />}
            </label>
            <input
              type={'file'}
              id={'fileUpload'}
              style={{ display: 'none' }}
              accept={'image/gif, image/jpeg, image/png'}
              onChange={(e) => uploadImg(e)}
            />
          </Box>
          {imageList.length > 0 && (
            <Box sx={{ display: '-webkit-box', height: '136px', overflowX: 'auto', marginTop: '12px', overflowY: 'hidden' }}>
              {skeletonImg.map((img, index) => (
                <Box key={index} width={136} height={136} marginRight={'11px'} position={'relative'} border={'1px solid #EEEEEE'}>
                  <Box
                    component={'img'}
                    src={URL.createObjectURL(img)}
                    width={'100%'}
                    height={'100%'}
                    sx={{
                      objectFit: 'contain'
                    }}
                  />
                  <ZoomInIcon
                    sx={{ position: 'absolute', top: '3px', right: '3px' }}
                    onClick={() => showDrawer(URL.createObjectURL(img))}
                  />
                  <DeleteForeverOutlinedIcon
                    sx={{ position: 'absolute', top: '3px', left: '3px', color: '#FB6464' }}
                    onClick={() => imgRemove(index)}
                  />
                </Box>
              ))}
            </Box>
          )}
          <LoadingButton
            loading={formik.isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            sx={{
              mt: 1.5,
              mb: 1,
              padding: '11px 0',
              bgcolor: '#220348',
              borderRadius: 2,
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 500,
              color: '#FFFFFF'
            }}
          >
            문의하기
          </LoadingButton>
        </Box>
      </form>
      {/* 문의 성공, 실패, 이미지 갯수 에러 Dialog */}
      <BasicDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
        type={dialogType}
        titleImg={
          dialogType === 'success' ? (
            <CheckCircleOutlineIcon color={'success'} sx={{ width: '40px', height: '40px' }} />
          ) : (
            <Box component={'img'} src={AlertTriangle} width={'16px'} height={'16px'} />
          )
        }
        title={'문의하기'}
        content1={dialogMsg}
        btnText={'확인'}
        action={closeDialog}
        reload={false}
      />
    </>
  );
}

export default InquiryDo;

InquiryDo.propTypes = {
  changeLoading: PropTypes.func,
  changeLoadingmsg: PropTypes.func,
  imageList: PropTypes.array,
  setImageList: PropTypes.any,
  setIsWrite: PropTypes.any
};
