import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, SwipeableDrawer, Toolbar } from '@mui/material';
import { ToolbarTitle } from 'components/toolbar';
import CloseIcon from 'assets/images/btn_close.png';
import { WoDivider } from 'components/dialog';

import Wine1 from 'assets/images/wine/montes_alpha.png';

function ImageDrawer({ isOpen, closeImgDrawer, imgSrc }) {
  // image drawer open state
  const [open, setOpen] = useState(false);

  // 이미지 확대 드로어 열기
  const openDrawer = () => {
    setOpen(true);
  };

  // 이미지 확대 드로어 닫기
  const closeDrawer = () => {
    setOpen(false);
    closeImgDrawer();
  };

  useEffect(() => {
    if (isOpen) {
      openDrawer();
    }
  }, [isOpen]);

  return (
    <SwipeableDrawer id={'alarm-drawer'} anchor={'bottom'} open={open} onOpen={openDrawer} onClose={closeDrawer}>
      {/* HEADER */}
      <AppBar position="sticky">
        <Toolbar sx={{ padding: '20px' }}>
          <Box component="img" src={CloseIcon} width={34} height={34} onClick={() => closeDrawer()} />
          <ToolbarTitle>이미지</ToolbarTitle>
          <Box flexGrow={1} />
        </Toolbar>
        <WoDivider />
      </AppBar>
      <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box component={'img'} src={imgSrc === undefined ? Wine1 : imgSrc} width={'100%'} height={'80%'} sx={{ objectFit: 'contain' }} />
      </Box>
    </SwipeableDrawer>
  );
}

export default ImageDrawer;

ImageDrawer.propTypes = {
  isOpen: PropTypes.bool,
  closeImgDrawer: PropTypes.func,
  imgSrc: PropTypes.string
};
