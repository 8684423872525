import React from 'react';

import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const ProductChip = styled((props) => <Chip variant="outlined" size="small" {...props} />)`
  border-radius: 4px;
  font-size: 10px;
  padding: 5px 8px;
  border-color: #2203481a;

  & .MuiChip-label {
    padding: 0;
  }
`;

export default ProductChip;
