import PropTypes from 'prop-types';
import MDateFormatAhm from './MDateFormatAhm';
import moment from 'moment/moment';

/**
 * mysql date format (YYYY년 MM월 DD일 HH:mm)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function MDateFormatYMDHm(val) {
  return moment.utc(val).format('YYYY년 MM월 DD일 HH:mm');
}

export default MDateFormatYMDHm;

MDateFormatAhm.propTypes = {
  val: PropTypes.instanceOf(Date)
};
