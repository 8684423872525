import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';

// project imports
import { bottleCapacityToString } from 'config';
import { WoAlert } from 'utils/kmwine-alerts';
import { get1kmPromotionDetail, update1kmProductIn } from 'services/PromotionService';
import { ToolbarTitle } from 'components/toolbar';
import { PromotionCard } from '../components';
import { PromotionProductCard } from './components';

// material-ui
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

// assets
import BackIcon from 'assets/images/arrow-back.png';

/**
 * 1KMWINE 프로모션 상세 페이지
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PromotionDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  // loading
  const [loading, setLoading] = React.useState(true);
  // error
  const [error, setError] = React.useState(false);
  // 프로모션 데이터
  const [promo, setPromo] = React.useState({ loaded: false, promotion: null, promotion_pdata: [], promotion_vendor: null });
  // 프로모션 카드 데이터 포맷
  const [promoCardData, setPromoCardData] = React.useState({ loaded: false, data: null });

  React.useEffect(() => {
    if (!params || !params?.id) return;

    getPromotionDetail();
  }, [params]);

  const getPromotionDetail = async () => {
    setLoading(true);
    setError(false);

    const result = await get1kmPromotionDetail(params.id)
      .catch((error) => ({ error }))
      .finally(() => {
        setLoading(false);
      });

    if (result.error) {
      console.error('[PromotionDetail][getPromotionDetail] 1kmwine 프로모션 상세 조회 중 오류 발생 > ', result.error);
      setError(true);
      return undefined;
    }

    const { code, data, message } = result;

    // 잘못된 Response 값이 반환됨
    if (code !== 0) {
      console.error('[PromotionDetail][getPromotionDetail] 1kmwine 프로모션 상세 조회 중 오류 발생 > ', message);
      setError(true);
      return undefined;
    }

    const { promotion: _promotion, promotion_pdata, promotion_vendor } = data;

    let promotion = _promotion;

    const {
      state,
      period: { start_dt, end_dt }
    } = _promotion;

    // 입고완료 (state 'FINISH')
    if (state === 'FINISH') {
      promotion = { ...promotion, promoCustomState: 'finish' };
    }
    // 입고대기 (state 'OK'이고, period.end_dt가 오늘 이전이면 '입고 대기')
    else if (state === 'OK' && moment(end_dt).isBefore(moment())) {
      promotion = { ...promotion, promoCustomState: 'end' };
    }
    // 진행 중 (state 'OK'이고, period.start_dt와 period.end_dt이 사이에 오늘이 포함되면 '판매중')
    else if (state === 'OK' && moment(start_dt).isSameOrBefore(moment()) && moment(end_dt).isSameOrAfter(moment())) {
      promotion = { ...promotion, promoCustomState: 'ing' };
    }
    // 판매대기 (state 'OK'이고, period.start_dt기 오늘 이후면 '판매대기')
    else if (state === 'OK' && moment(start_dt).isAfter(moment())) {
      promotion = { ...promotion, promoCustomState: 'wait' };
    }

    const promoDataFormat = {
      ...promotion,
      promotion_vendor,
      promotion_pdata
    };

    // promotion_pdata 배열 재 정렬
    const tempPdata = [];

    // productCustomState 추가
    promotion_pdata.forEach((p) => {
      const { state, order_quantity } = p;

      // 해당 없음
      if (order_quantity === 0) tempPdata.push({ ...p, productCustomState: 'NONE' });
      // 주문마감(입고대기)
      else if (state === 'WAIT' && order_quantity > 0) tempPdata.push({ ...p, productCustomState: 'ING' });
      // 입고 완료
      else if (state === 'CONFIRM' || state === 'OK') tempPdata.push({ ...p, productCustomState: 'OK' });
    });

    tempPdata.sort((a, b) => {
      if (a.productCustomState === 'ING') return -1;
      if (a.productCustomState === 'OK' && b.productCustomState !== 'ING') return -1;
      if (a.productCustomState === 'NONE') return 1;
      return 0;
    });

    setError(false);
    setPromo({ loaded: true, promotion, promotion_pdata: tempPdata, promotion_vendor });
    setPromoCardData({ loaded: true, data: promoDataFormat });
  };

  // 입고 완료 현황 카운트
  const productOkStateCount = React.useMemo(() => {
    if (!promo?.promotion || !promo?.promotion_pdata) return;

    const { promotion, promotion_pdata } = promo;

    const promotionCustomState = promotion?.promoCustomState;

    if (!promotionCustomState) return;

    // 입고 대기 상태에만 표시
    if (promotionCustomState === 'end') {
      let totalCount = 0;
      let okCount = 0;

      promotion_pdata.forEach((p) => {
        if (p.productCustomState !== 'NONE') totalCount += 1;
        if (p.productCustomState === 'OK') okCount += 1;
      });

      return (
        <Stack direction="row" spacing="4px" justifyContent="space-between">
          <Stack direction="row" spacing="4px" alignItems="center">
            <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: 'normal', color: 'rgba(0, 0, 0, 0.60)' }}>
              입고 완료 상품
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: 'normal', color: '#3CAE47' }}>
                {okCount?.toLocaleString()}
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: 'normal', color: 'rgba(0, 0, 0, 0.60)' }}>
                /{totalCount?.toLocaleString()}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      );
    }
  }, [promo]);

  // 입고 완료 처리할 상품 정보
  const [selectedItem, setSelectedItem] = React.useState(null);
  // 입고 완료 처리 컨펌 모달
  const [confirmModal, setConfirmModal] = React.useState(false);
  // 입고 완료 처리 로딩
  const [completeLoading, setCompleteLoading] = React.useState(false);
  // 입고 완료 성공 모달
  const [completeModal, setCompleteModal] = React.useState(false);

  // 상품 입고 처리할 데이터 확인
  const selectedPdata = (val) => {
    const { order_quantity, id } = val;

    if (!id || order_quantity === 0) {
      WoAlert.fire('', '상품 정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    setSelectedItem(val);
    setConfirmModal(true);
  };

  // 상품 입고처리 하기
  const productDelivOk = async () => {
    const body = {
      promotion_id: promo.promotion.id,
      promotion_vendor_pdata_ids: [selectedItem.promotion_vendor_pdata_id]
    };

    setCompleteLoading(true);
    const result = await update1kmProductIn(body)
      .catch((error) => ({ error }))
      .finally(() => setCompleteLoading(false));

    if (result.error) {
      console.error('[PromotionDetail][productDelivOk] 상품 입고처리 중 오류 발생 > ', result.error);
      WoAlert.fire('', '상품 입고 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 반환됨.
    if (code !== 0) {
      console.error('[PromotionDetail][productDelivOk] 상품 입고처리 중 오류 발생 > ', msg);
      WoAlert.fire('', '상품 입고 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    const promotionPdata = promo.promotion_pdata;
    let pdataOkCount = 0;

    // Firebase와 MySQL동기화가 안된 경우가 있으므로 상태를 강제로 변경해서 표현함
    promotionPdata.forEach((p, i) => {
      if (p.id === selectedItem.id) {
        promotionPdata[i].productCustomState = 'OK';
        pdataOkCount += 1;
      }
      if (p.productCustomState === 'OK') pdataOkCount += 1;
    });

    if (pdataOkCount === promotionPdata.length) {
      setPromoCardData({ ...promoCardData, data: { ...promoCardData.data, promoCustomState: 'finish' } });
    }

    setPromo({ ...promo, promotion_pdata: promotionPdata });

    await setConfirmModal(false);
    await setCompleteModal(true);
  };

  // 예약 판매 상품 수량 render
  const productCount = React.useMemo(() => {
    if (!promo.loaded) return;

    const { promotion, promotion_pdata } = promo;

    // 판매 대기 또는 판매 중일 경우의 수량 render (전체 promotion_pdata 개수)
    if (promotion.promoCustomState === 'wait' || promotion.promoCustomState === 'ing') {
      return promotion_pdata.length.toLocaleString();
    }

    // 입고 대기 또는 입고완료(종료)일 경우의 수량 render (주문이 들어온 promotion_pdata의 개수)
    if (promotion.promoCustomState === 'end' || promotion.promoCustomState === 'finish') {
      let count = 0;
      promotion_pdata.forEach((p) => {
        if (p.order_quantity > 0) count += 1;
      });
      return count.toLocaleString();
    }
  }, [promo]);

  // render
  return (
    <Box>
      <AppBar position="sticky" sx={{ px: '20px' }}>
        <Toolbar sx={{ p: 0 }}>
          <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
          <ToolbarTitle>예약 판매 주문 현황</ToolbarTitle>
        </Toolbar>
        <Divider />
      </AppBar>

      <Container sx={{ bgcolor: '#F3F2F5', py: '20px', minHeight: 'calc(100vh - 55px)' }}>
        {/* 로딩 영역 */}
        {loading && (
          <Box textAlign="center">
            <CircularProgress size={30} />
          </Box>
        )}

        {/* 오류 발생 */}
        {error && (
          <Box height="calc(100vh - 95px)" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography sx={{ mt: '12px' }}>프로모션 조회 중 오류가 발생 했습니다</Typography>
            <Button variant="contained" color="brand" startIcon={<RefreshOutlinedIcon />} onClick={getPromotionDetail} sx={{ mt: '50px' }}>
              다시 시도
            </Button>
          </Box>
        )}

        {/* 프로모션 카드 영역 */}
        {!loading && !error && promo.loaded && (
          <PromotionCard data={promoCardData?.data} isDetailPage promotionPdata={promo?.promotion_pdata} />
        )}

        {/* 프로모션 상품 영역 */}
        {!loading && !error && promo.loaded && (
          <Box mt="20px">
            <Stack direction="row" spacing="4px" justifyContent="space-between">
              <Stack direction="row" spacing="4px" alignItems="center">
                <Typography sx={{ fontSize: 16, fontWeight: 700, lineHeight: 'normal' }}>
                  {promo?.promotion?.state === 'FINISH' ? '입고 완료 상품' : '예약 판매 상품'}
                </Typography>
                {!promo.loaded ? (
                  <CircularProgress size={16} sx={{ color: '#3CAE47' }} />
                ) : (
                  <Typography sx={{ fontSize: 16, fontWeight: 700, lineHeight: 'normal', color: '#3CAE47' }}>{productCount}</Typography>
                )}
              </Stack>
              {productOkStateCount}
            </Stack>

            <Typography sx={{ mt: 2, color: 'rgba(0, 0, 0, 0.50)', fontSize: 12, fontWeight: 400, lineHeight: 'normal' }}>
              * 납품가는 부가세가 포함된 금액입니다.
            </Typography>

            {promo.loaded &&
              promo.promotion_pdata.map((item, index) => {
                return (
                  <PromotionProductCard
                    key={`promotion-product-${index}-card`}
                    index={index}
                    item={item}
                    promoState={promo?.promotion?.promoCustomState}
                    selectedPdata={(val, index) => selectedPdata(val, index)}
                  />
                );
              })}
          </Box>
        )}
      </Container>

      {/* 입고 완료 처리 컨펌 모달 */}
      <Dialog open={confirmModal} fullWidth size="sm">
        <DialogTitle sx={{ p: '35px 25px 25px 25px', fontSize: 18, fontWeight: 700, lineHeight: 'normal' }}>
          상품 입고 완료 처리
        </DialogTitle>
        <DialogContent sx={{ px: '25px', pb: '44px' }}>
          {/* 입고 완료 처리할 상품 명 */}
          <Typography className="line-2-ellipsis" sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal', height: 36 }}>
            {selectedItem?.pdata?.name?.ko}
          </Typography>

          {/* 빈티지/용량 */}
          <Stack
            mt="8px"
            direction="row"
            spacing="4px"
            divider={
              <Box display="flex" alignItems="center" py="1px">
                <Box height={1} width="0.75px" bgcolor="#A2A2A3" />
              </Box>
            }
          >
            <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
              {selectedItem?.vintage}
            </Typography>
            <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
              {bottleCapacityToString(selectedItem?.capacity)}
            </Typography>
          </Stack>

          {/* 입고 수량 */}
          <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal', mt: '20px' }}>
            입고 수량 : {selectedItem?.order_quantity?.toLocaleString()}개
          </Typography>

          {/* 입고 안내 문구 */}
          <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal', color: '#3CAE47', mt: '20px' }}>
            반드시 상품 정보와 입고 수량을 모두 확인하신 후 상태를 변경해 주세요
          </Typography>

          <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal', mt: '20px' }}>입고 상태를 변경하시겠습니까?</Typography>
        </DialogContent>

        <DialogActions sx={{ px: '25px', pb: '25px' }}>
          {completeLoading ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress size={45} />
            </Box>
          ) : (
            <Stack width="100%" direction="row" spacing="10px">
              <Button
                type="button"
                disabled={completeLoading}
                sx={{
                  border: '1px solid rgba(96, 84, 110, 0.30)',
                  borderRadius: '26px',
                  py: '16px',
                  width: '100%',
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: 'normal'
                }}
                onClick={() => setConfirmModal(false)}
              >
                취소
              </Button>
              <Button
                type="button"
                variant="contained"
                disabled={completeLoading}
                sx={{
                  backgroundColor: '#140229',
                  borderRadius: '26px',
                  py: '16px',
                  width: '100%',
                  fontSize: 16,
                  fontWeight: 700,
                  color: '#FFF',
                  lineHeight: 'normal'
                }}
                onClick={productDelivOk}
              >
                확인
              </Button>
            </Stack>
          )}
        </DialogActions>
      </Dialog>

      {/* 입고 완료 성공 모달 */}
      <Dialog open={completeModal} fullWidth size="sm">
        <DialogTitle sx={{ p: '35px 25px 25px 25px', fontSize: 18, fontWeight: 700, lineHeight: 'normal' }}>입고 처리 완료</DialogTitle>
        <DialogContent sx={{ px: '25px', pb: '25px' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal' }}>
            입고 처리가 완료 되었습니다.
            <br />
            <b>모든 상품이 입고 완료된 후</b> 주문 고객이 샵에 방문할 예정입니다.
          </Typography>

          <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: 'normal', color: '#3CAE47', mt: '20px' }}>
            픽업 마감일: {moment(promo?.promotion?.pickup?.end_dt).format('YYYY.MM.DD(ddd)')}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: '25px', pb: '25px' }}>
          <Button
            type="button"
            variant="contained"
            sx={{
              backgroundColor: '#140229',
              borderRadius: '26px',
              py: '16px',
              width: '100%',
              fontSize: 16,
              fontWeight: 700,
              color: '#FFF',
              lineHeight: 'normal'
            }}
            onClick={() => setCompleteModal(false)}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromotionDetail;
