import axios from 'axios';
import validator from 'validator/es';
import { FIREBASE_FUNCTION_URL } from './index';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from '../../utils/firebase-tools';
import { getAuth } from 'firebase/auth';
import * as WineOneSearch from '../WineOneSearch';
import { PDATA_CATEGORY } from '../../config';

/**
 * 로그인 사용자의 위치타입 변경
 * @param {'pin'|'current'} location_type
 * @returns {Promise<void>}
 */
export async function setLocationType(location_type) {
  console.log('@UserService.setLocationType: ', location_type);
  return axios.put(FIREBASE_FUNCTION_URL.PUT__SET_LOCATION_TYPE, { location_type });
}

/**
 * 로그인 사용자의 위치핀 정보 저장
 * @param pin_id 핀 아이디
 * @returns {Promise<void>}
 */
export async function saveLocationPin(pin_id) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__SET_PIN, { pin_id });
}

/**
 * 이메일 사용가능여부 확인
 * @param {string} email - 사용자 아이디(이메일)
 * @returns {boolean|Promise<AxiosResponse<any>>}
 */
export function checkEmail(email) {
  if (!validator.isEmail(email)) return false;
  return axios.post(FIREBASE_FUNCTION_URL.POST__EMAIL_CHECK, { email });
}

/**
 * 인증메일 발송요청
 * @param {string} email
 * @param {string} username
 */
export function sendCertEmail(email, username) {
  if (!validator.isEmail(email)) {
    throw new Error(`Invalid email address. [${email}]`);
  }
  if (validator.isEmpty(username)) {
    throw new Error(`'username' cannot be null or empty`);
  }
  return axios.post(FIREBASE_FUNCTION_URL.POST__SEND_CERT_EMAIL, { to: email, username });
}

/**
 * 인증메일 확인여부
 * @param code
 * @param email
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkCertEmail({ code, email }) {
  if (!validator.isEmail(email)) {
    throw new Error(`Invalid email address. [${email}]`);
  }

  if (validator.isEmpty(code)) {
    throw new Error(`'code' cannot be null or empty`);
  }

  return axios.post(FIREBASE_FUNCTION_URL.POST__CHECK_CERT_EMAIL, { code, email });
}

/**
 * 닉네임 사용가능여부 확인
 *
 * @param {string} nickname
 * @returns {Promise<void>}
 */
export function checkNickname(nickname) {
  if (validator.isEmpty(nickname)) return false;
  return axios.post(FIREBASE_FUNCTION_URL.POST__NICKNAME_CHECK, { nickname });
}

/**
 * 쿠폰 다운로드
 * @param {array<string>} ids - 쿠폰 아이디 배열
 * @returns {Promise<boolean>}
 */
export async function downloadCoupons(ids = []) {
  if (ids.length === 0) {
    throw new Error(`Coupon 'ids' array cannot be empty.`, { cause: 'Empty ids' });
  }

  return axios.put(FIREBASE_FUNCTION_URL.PUT__DOWNLOAD_COUPON, { ids });
}

/**
 * 사용자의 사용가능한 쿠폰 조회
 * @param {string | null} vendorId - 입점사(vendor) ID
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function fetchMyCouponList(vendorId) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__COUPON_LIST, { vendor_id: vendorId });
}

/**
 * 로그인한 사용자 정보 조회
 * @returns {Promise<DocumentData|null>}
 */
export async function getCurrentMember() {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  // firestore에서 로그인 사용자정보(member) 조회
  const memberSnapshot = await getDoc(doc(getFirestore(), 'member', auth.currentUser.uid)).catch((e) => {
    console.warn('[1kmwine] 로그인 사용자 정보조회 중 오류 [E859094].', e);
  });

  if (!memberSnapshot || !memberSnapshot.exists()) {
    return null;
  }
  return memberSnapshot.data(); // Login member data.
}

/**
 * 로그인 사용자의 FCM TOKEN값을 서버에 전달
 * @param token
 * @returns {null}
 */
export function setFcmToken(token) {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  console.info('[1kmwine] 사용자 fcm token 업데이트 요청. token=', token);
  axios
    .post(FIREBASE_FUNCTION_URL.POST__SET_FCM_TOKEN, { token })
    .then((response) => {
      console.log('[1kmwine] 사용자 fcm token 수정요청 응답.', response);
    })
    .catch((e) => console.error('[1kmwine] 사용자 fcm token 수정요청 실패.', e));
}

export function removeFcmToken() {
  const auth = getAuth();
  if (!auth.currentUser) return null;

  console.info('[1kmwine] 사용자 fcm token 삭제 요청.');
  axios
    .post(FIREBASE_FUNCTION_URL.POST__REMOVE_FCM_TOKEN)
    .then((response) => {
      console.log('[1kmwine] 사용자 fcm token 삭제요청 응답.', response);
    })
    .catch((e) => console.error('[1kmwine] 사용자 fcm token 삭제요청 실패.', e));
}

/**
 * 추천상품 목록 가져오기
 * @returns {Promise<{success: boolean, error: string}>}
 */
export async function requestSuggestProducts({ lat, lng }) {
  const auth = getAuth();
  if (!auth.currentUser)
    return {
      success: false,
      error: 'not_logged_in'
    };

  // 'Elastic App Search' client
  const client = WineOneSearch.createElasticAppSearchClient(WineOneSearch.ENGINE.PRODUCT);

  const filters = {
    all: [
      { available: 'true' },
      { remove: 'false' }, // 삭제되지 않은 상품
      { category: [PDATA_CATEGORY.WINE.value, PDATA_CATEGORY.WHISKEY.value, PDATA_CATEGORY.NIHONSHU.value, PDATA_CATEGORY.OFFER.value] },
      { vendor_closed: 'false' }, // 영업 정지된 vendor의 product는 제외
      { vendor_deleted: 'false' }, // 삭제된 vendor의 판매상품은 exclude
      { vendor_candidate: 'false' }, // 미입점 입점사의 판매상품은 제외
      {
        vendor_location: {
          center: `${lat},${lng}`,
          distance: 1, // 1km 반경에서 조회합니다.
          unit: 'km'
        }
      }
    ]
  };

  // 판매상품 group by
  const group = {
    field: 'group_a', // pdata_id, capacity
    collapse: true,
    sort: [
      {
        vendor_location: {
          center: `${lat}, ${lng}`,
          order: 'asc'
        }
      }
    ]
  };

  const options = {
    page: { size: 16 }, // 추천상품 16개 조회
    search_fields: {
      name_ko: {}
    },
    filters,
    group,

    // 정렬순서 (가까운순, 낮은가격순)
    sort: [
      {
        vendor_location: {
          center: `${lat},${lng}`,
          order: 'asc'
        }
      }
    ],

    record_analytics: false,
    analytics: {
      tags: ['1kmwine', 'suggest']
    }
  };

  // Elastic search에서 판매 상점 조회
  return client.search('', options);
}
