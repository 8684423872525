import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigationType } from 'react-router-dom';

// material-ui
import { Box, Skeleton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

/**
 * 상품 이미지
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function ProductImage(props) {
  const theme = useTheme();
  const navigationType = useNavigationType();

  const {
    category = 'default',
    width,
    height,
    ratio,
    src: srcProp = null,
    alt,
    borderRadius = theme.shape.productImageBorderRadius,
    wrapperProps,
    visibleByDefault: visibleByDefaultProp = false,
    children
  } = props;

  // 이미지 src
  const [src, setSrc] = React.useState(srcProp);

  React.useEffect(() => {
    setSrc(srcProp ?? wineDefaultImg);
  }, [srcProp]);

  // 이미지 이펙트 사용여부
  const visibleByDefault = React.useMemo(() => {
    return navigationType === 'POP' || navigationType === 'REPLACE' ? true : visibleByDefaultProp;
  }, [visibleByDefaultProp, navigationType]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setSrc(wineDefaultImg); // Replace to default wine product image.
  }, []);

  // render
  return (
    <ProductImageWrapper
      category={category}
      width={width}
      height={ratio ? 'auto' : height}
      ratio={ratio}
      borderRadius={borderRadius}
      {...wrapperProps}
    >
      {/* {image} - style.scss#product-image-wrapper */}
      <LazyLoadImage
        wrapperClassName="product-image-wrapper"
        placeholder={
          <img
            src={wineDefaultImg}
            alt=""
            draggable="false"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center center'
            }}
            onError={wineDefaultImg}
          />
        }
        width="100%"
        height="100%"
        visibleByDefault={visibleByDefault}
        effect="blur"
        draggable={false}
        src={src}
        alt={alt ?? '상품 이미지'}
        onError={onError}
      />
      {children}
    </ProductImageWrapper>
  );
}

ProductImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  visibleByDefault: PropTypes.bool,
  wrapperProps: PropTypes.object,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

// export
export default ProductImage;

/**
 * 상품 이미지 래퍼(wrapper)
 *
 * @constructor
 * @authors 조현권<hkcho@wineone.io>
 */
const ProductImageWrapper = styled((props) => {
  const { category = 'default', ratio, width, height, ...other } = props;
  let imgHeight = height;

  if (ratio) imgHeight = width * ratio;
  else if (Number.isNaN(imgHeight)) imgHeight = '100%';

  // 상품 이미지 배경색
  const bgcolor = '#EFEEF1';

  return (
    <Box
      width={width}
      height={imgHeight}
      ratio={ratio}
      minHeight={imgHeight}
      minWidth={width}
      overflow="hidden"
      bgcolor={bgcolor}
      draggable={false}
      {...other}
    />
  );
})(
  ({ theme }) => `
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 12px 5px 11.2px;
  text-align: center;
  overflow: hidden;
  transition: ${theme.transitions.create(['border-color', 'background-color', 'box-shadow'])}
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
`
);

// eslint-disable-next-line react/prop-types
function ProductImageSkeleton({ width = '100%', height = '100%' }) {
  // render
  return (
    <Box position="relative" width={width} height={height} borderRadius={(theme) => theme.shape.productImageBorderRadius} overflow="hidden">
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
  );
}
export { ProductImageSkeleton };
