import React from 'react';
import { Typography } from '@mui/material';

/* eslint-disable react/prop-types */
function ToolbarTitle({ children, ...other }) {
  return (
    <Typography
      variant="div"
      noWrap
      lineHeight={1}
      fontSize="18px"
      fontWeight={800}
      letterSpacing="-0.18px"
      color="text.primary"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default ToolbarTitle;
