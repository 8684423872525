import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useWineOne } from 'hooks';

// material-ui
import { BottomNavigation, BottomNavigationAction, Box, Paper, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// assets
import productNor from 'assets/images/navigation/gnb_product_default.png';
import productSel from 'assets/images/navigation/gnb_product_select.png';
import homeNor from 'assets/images/navigation/gnb_home_default.png';
import homeSel from 'assets/images/navigation/gnb_home_select.png';
import managementNor from 'assets/images/navigation/gnb_manage_default.png';
import managementSel from 'assets/images/navigation/gnb_manage_select.png';

const useStyles = makeStyles({
  nav: {
    '& .WoNav-icon': {
      display: 'flex',
      height: '22px',
      width: '22px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover'
    },
    '& .nav-button': {
      minWidth: '56px'
    },

    '& .nav-button-product .WoNav-icon': { backgroundImage: `url(${productNor})` },
    '& .nav-button-home .WoNav-icon': { backgroundImage: `url(${homeNor})` },
    '& .nav-button-management .WoNav-icon': { backgroundImage: `url(${managementNor})` },

    '& .nav-button-product.Mui-selected .WoNav-icon': { backgroundImage: `url(${productSel})` },
    '& .nav-button-home.Mui-selected .WoNav-icon': { backgroundImage: `url(${homeSel})` },
    '& .nav-button-management.Mui-selected .WoNav-icon': { backgroundImage: `url(${managementSel})` }
  }
});

/**
 * 하단 네비게이션 표현
 *
 * @authors 이재일<leeji@wineone.io>
 */
function BottomNav() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { bottomNav } = useWineOne();
  const classes = useStyles();

  const { isLoggedIn } = useAuth();

  const { show: bnShow } = bottomNav;
  // 하단 네비게이션 표현
  const show = typeof bnShow !== 'undefined' ? bnShow : matchedFirstPaths.indexOf(bottomNav.value) >= 0;
  document.body.classList.toggle('is-bottom-nav-show', show);

  const handleChange = (event, newValue) => {
    navigate(`/${newValue}`);
  };

  return (
    <Slide direction="up" in={isLoggedIn && show}>
      <Paper square elevation={3} sx={{ position: 'fixed', bottom: 0, left: 0, width: '100vw', zIndex: theme.zIndex.appBar + 10 }}>
        <BottomNavigation className={classes.nav} showLabels value={bottomNav.value} onChange={handleChange}>
          {navMenus.map((nav, i) => (
            <BottomNavigationAction
              key={`bottom-nav-action-${i}`}
              className={`nav-button nav-button-${nav.value}`}
              label={nav.label}
              value={nav.value}
              icon={<NavIcon />}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  pt: '1px'
                }
              }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Slide>
  );
}

export default BottomNav;

const NavIcon = () => {
  return <Box className="WoNav-icon" mb={1} />;
};

// 네비게이션 메뉴
const navMenus = Object.freeze([
  { label: '상품관리', value: 'product' },
  { label: '홈', value: 'home' },
  { label: '운영관리', value: 'management' }
]);

// 하단 네비를 표현할 path
const matchedFirstPaths = Object.freeze(['product', 'home', 'management']);
