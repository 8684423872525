import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

// projects import
import { WoAlert } from 'utils/kmwine-alerts';
import * as ProductService from 'services/ProductService';
import LoadingLayout from 'components/loading/LoadingLayout';

// material-ui
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';

/**
 * DB요청 페이지
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function RequestDBForm({ search, onClose }) {
  // Loading
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log('**** sarch: ', search);
    formik.setFieldValue('koName', search);
  }, [search]);

  // 요청제출
  const requestAddDB = async () => {
    setLoading(true);
    const body = {
      memo: formik.values.memo,
      name_ko: formik.values.koName,
      name_en: formik.values.enName
    };

    const response = await ProductService.requestCreatePdata(body).catch((error) => ({ error }));

    if (response.error) {
      console.error('[DB 등록 요청] DB 등록 요청 중 오류 발생 > ', response.error);
      WoAlert.fire('DB 등록 요청', 'DB 요청에 실패했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const resultCode = response.data.result.code;

    if (resultCode !== 0) {
      console.error('[DB 등록 요청] DB 등록 요청 중 오류 발생 > ', response.error);
      WoAlert.fire('DB 등록 요청', 'DB 요청에 실패했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    await setLoading(false);

    WoAlert.fire('DB 등록 요청', 'DB 등록 요청이 완료되었습니다.', 'success').then(() => {
      onClose();
    });
  };

  // 유효성 검사 실패할 시 띄워줄 문구
  const validationSchema = () =>
    yup.object({
      koName: yup.string().required('필수 항목입니다.'),
      enName: yup.string().required('필수 항목입니다.')
    });

  const formik = useFormik({
    initialValues: {
      koName: '',
      enName: '',
      memo: ''
    },
    validationSchema,
    onSubmit: () => {
      requestAddDB();
    }
  });

  return (
    <>
      {/* 로딩 */}
      <LoadingLayout open={loading} text="DB를 요청하고 있습니다." />
      {/* REQUSET FORM */}
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="#16171966" fontWeight="bold">
            상품명(국문)
          </Typography>
          <TextField
            id="koName"
            name="koName"
            size="small"
            sx={{ width: '70%' }}
            value={formik.values.koName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.koName && Boolean(formik.errors.koName)}
            helperText={formik.touched.koName && formik.errors.koName}
            InputProps={{
              inputProps: {
                style: { color: '#333333' }
              }
            }}
          />
        </Box>
        <Box marginTop="1rem" display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="#16171966" fontWeight="bold">
            상품명(영문)
          </Typography>
          <TextField
            id="enName"
            name="enName"
            size="small"
            sx={{ width: '70%' }}
            value={formik.values.enName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.enName && Boolean(formik.errors.enName)}
            helperText={formik.touched.enName && formik.errors.enName}
            InputProps={{
              inputProps: {
                style: { color: '#333333' }
              }
            }}
          />
        </Box>
        <Box marginTop="1.5rem" alignItems="center" justifyContent="space-between">
          <Typography color="#16171966" fontWeight="bold" marginBottom=".5rem">
            메모
          </Typography>
          <TextField
            id="memo"
            multiline
            rows={4}
            fullWidth
            value={formik.values.memo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.memo && Boolean(formik.errors.memo)}
            helperText={formik.touched.memo && formik.errors.memo}
            InputProps={{
              inputProps: {
                style: { color: '#333333' }
              }
            }}
          />
        </Box>

        {/* 취소, 요청 버튼 */}
        <Box marginTop="1.5rem">
          <Grid container>
            <Stack direction="row" spacing={1} width="100%">
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '6px 0',
                    border: '1px solid #60546E4D',
                    backgroundColor: '#ffffff',
                    fontSize: '1rem'
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  취소
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{
                    width: '100%',
                    padding: '6px 0',
                    border: '1px solid #60546E4D',
                    backgroundColor: '#140229',
                    color: '#ffffff',
                    fontSize: '1rem',
                    '&:hover': {
                      backgroundColor: '#140229'
                    }
                  }}
                  type="submit"
                >
                  요청하기
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Box>
      </form>
    </>
  );
}

RequestDBForm.propTypes = {
  search: PropTypes.string,
  onClose: PropTypes.func
};

export default RequestDBForm;
