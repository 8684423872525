import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// project imports
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ProductCard } from './index';

/**
 * 최종 주문 확인 페이지의 상품 거절 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqNoCard({ products, reqNoData }) {
  // 주문 취소항 상품의 총 수량
  const [quantityCount, setQuantityCount] = React.useState(0);

  // 주문 취소 코드
  const [reqNoMsgCode, setReqNoMsgCode] = React.useState('');
  // 주문 취소 사유(직접입력)
  const [reqNoMsgDetail, setReqNoMsgDetail] = React.useState('');

  React.useEffect(() => {
    getReqNoProductsQuantity();
  });

  // 주문 취소 사유 코드 또는 취소사유 상세 텍스트가 바뀔 시
  React.useEffect(() => {
    let reqNoMsg = '';

    if (reqNoMsgCode === 'A') reqNoMsg = '재고 소진으로 부득이하게 결제가 취소됩니다.';
    else if (reqNoMsgCode === 'B') reqNoMsg = '와인 가격 변동으로 부득이하게 결제가 취소됩니다.';
    else if (reqNoMsgCode === 'C') reqNoMsg = '임시휴무로 해당 픽업일에 준비가 어려워 부득이하게 결제가 취소됩니다.';
    else if (reqNoMsgCode === 'D') reqNoMsg = '직접 입력';

    reqNoData({ reqNoCode: reqNoMsgCode, msg: reqNoMsg, reqNoText: reqNoMsgDetail });
  }, [reqNoMsgCode, reqNoMsgDetail]);

  // 주문 취소할 상품의 총 수량
  const getReqNoProductsQuantity = () => {
    let count = 0;
    products.forEach((product) => {
      const value = product.quantity - product.ok_quantity;
      count += value;
    });
    setQuantityCount(count);
  };

  // 주문 거절 텍스트
  const getReqNoText = (e) => {
    const val = e.target.value;
    if (val.length > 200) return false;
    setReqNoMsgDetail(val);
  };

  return (
    <Box px="16px" pt="12px" pb="16px" border="1px solid #DCDCDC" borderRadius="4px">
      <SpaceBetweenBox>
        <Typography fontSize="14px" color="#130328" fontWeight="bold">
          취소 상품
        </Typography>
        <SpaceBetweenBox>
          <Typography fontSize="14px" color="#13032">
            총
          </Typography>
          <Typography fontSize="14px" color="#13032" fontWeight="bold" ml={0.5}>
            {quantityCount}
          </Typography>
          <Typography fontSize="14px" color="#13032">
            병
          </Typography>
        </SpaceBetweenBox>
      </SpaceBetweenBox>
      {/* 취소한 상품 목록 */}
      <Stack spacing={2.25} mt={2}>
        {products.map((product, index) => (
          <ProductCard type="REQ_NO" product={product} key={`product-${index}-row`} />
        ))}
      </Stack>

      {/* 취소 사유 */}
      <Box mt={2.5}>
        <Typography fontSize="13px" color="#130328" fontWeight="bold" mb={1.5}>
          취소 사유
        </Typography>
        <Box>
          <FormControl fullWidth>
            <Select
              value={reqNoMsgCode}
              size="small"
              onChange={(e) => setReqNoMsgCode(e.target.value)}
              displayEmpty
              sx={{
                '.MuiOutlinedInput-notchedOutline': {
                  border: '0.5px solid #140229',
                  borderWidth: '1px !important'
                }
              }}
              MenuProps={{ disablePortal: true }}
            >
              <MenuItem value="">사유를 선택해주세요.</MenuItem>
              <MenuItem value="A" sx={{ whiteSpace: 'pre-line' }}>
                재고 소진으로 부득이하게 결제가 취소됩니다.
              </MenuItem>
              <MenuItem value="B" sx={{ whiteSpace: 'pre-line' }}>
                와인 가격 변동으로 부득이하게 결제가 취소됩니다.
              </MenuItem>
              <MenuItem value="C" sx={{ whiteSpace: 'pre-line' }}>
                임시휴무로 해당 픽업일에 준비가 어려워 부득이하게 결제가 취소됩니다.
              </MenuItem>
              <MenuItem value="D" sx={{ whiteSpace: 'pre-line' }}>
                직접 입력
              </MenuItem>
            </Select>
          </FormControl>

          {reqNoMsgCode === 'D' && (
            <Box textAlign="right">
              <MultiLineTextField
                sx={{ mt: 2 }}
                fullWidth
                multiline
                rows={4}
                placeholder="자세한 사유를 입력해 주세요."
                inputProps={{ maxLength: 200 }}
                onChange={(e) => getReqNoText(e)}
              />
              <Typography color="#140229" fontSize="10px" mt={1.25}>
                {reqNoMsgDetail.length}/200
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ReqNoCard;

ReqNoCard.propTypes = {
  products: PropTypes.array,
  reqNoData: PropTypes.func
};

const MultiLineTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#140229'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#140229'
      },
      '&:hover fieldset': {
        borderColor: '#140229'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #140229'
      }
    }
  }
})(TextField);
