import React from 'react';
import PropTypes from 'prop-types';

// import mui
import { Alert, Box } from '@mui/material';

/**
 * 주문상품의 상태값 별로 나타낼 영역
 * @constructs
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderProductStateAction({ state }) {
  /**
   * state에 따라서 다른 컴포넌트를 보여준다.
   *  - REQ_RTN: 픽업완료(반품거절, 교환거절 포함) 후 유저가 환불을 신청한 상태
   *  - RTN_WAIT: 환불요청 후 환불완료를 대기하는 상태
   *  - RTN_OK: 환불완료된 상태
   *  - RTN_NO: 환불 요청이 거절된 상태
   *  - REQ_EXC: 픽업완료(반품거절, 교환거절 포함) 후 유저가 교환을 신청한 상태
   *  - EXC_WAIT: 교환요청 후 교환완료를 대기하는 상태
   *  - PICK_OK: 픽업완료된 상태
   */

  const stateAlert = (val) => {
    let result;
    switch (val) {
      case 'REQ_RTN':
        result = (
          <Alert severity="error" sx={{ padding: '0 16px' }}>
            반품 요청한 상품입니다.
          </Alert>
        );
        break;
      case 'RTN_WAIT':
        result = (
          <Alert severity="error" sx={{ padding: '0 16px' }}>
            반품 요청 승인 후 반품 완료 대기 중인 상품입니다.
          </Alert>
        );
        break;
      case 'REQ_EXC':
        result = (
          <Alert severity="warning" sx={{ padding: '0 16px' }}>
            교환 요청한 상품입니다.
          </Alert>
        );
        break;
      case 'EXC_WAIT':
        result = (
          <Alert severity="warning" sx={{ padding: '0 16px' }}>
            교환 요청 승인 후 교환 완료 대기 중인 상품입니다.
          </Alert>
        );
        break;
      case 'EXC_OK':
        result = (
          <Alert severity="warning" sx={{ padding: '0 16px' }}>
            교환 완료된 상품입니다.
          </Alert>
        );
        break;
      case 'EXC_NO':
        result = (
          <Alert severity="warning" sx={{ padding: '0 16px' }}>
            교환 요청이 거절된 상품입니다.
          </Alert>
        );
        break;
      case 'RTN_OK':
        result = (
          <Alert severity="success" sx={{ padding: '0 16px' }}>
            반품 완료된 상품입니다.
          </Alert>
        );
        break;
      case 'RTN_NO':
        result = (
          <Alert severity="error" sx={{ padding: '0 16px' }}>
            반품 요청이 거절된 상품입니다.
          </Alert>
        );
        break;
      case 'PICK_OK':
        result = (
          <Alert severity="success" sx={{ padding: '0 16px' }}>
            픽업 완료된 상품입니다.
          </Alert>
        );
        break;
      default:
        return null;
    }
    return result;
  };

  return <Box sx={{ padding: '0 20px 10px 20px' }}>{stateAlert(state)}</Box>;
}

export default OrderProductStateAction;

OrderProductStateAction.propTypes = {
  state: PropTypes.string.isRequired
};
