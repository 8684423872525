import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import moment from 'moment';

// project imports
import { useScriptRef } from 'hooks';
import { get1kmPromotionList } from 'services/PromotionService';
import PromotionCard from '../components/PromotionCard';

// material-ui
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  contentSwiper: {
    marginLeft: '-20px',
    marginRight: '-20px',
    paddingTop: '4px',
    paddingBottom: '32px',
    '--swiper-pagination-color': theme.palette.brand.main
  }
}));

/**
 * 1KMWINE 프로모션 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const PromotionList = () => {
  const scriptedRef = useScriptRef();
  const classes = useStyles();

  const [rows, setRows] = React.useState({ loaded: false, error: false, data: [] });

  React.useLayoutEffect(() => {
    getPromotionList();
  }, []);

  // 1kmwine 프로모션 목록 조회
  const getPromotionList = async () => {
    setRows({ loaded: false, error: false, data: [] });

    const result = await get1kmPromotionList().catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PromotionList][getPromotionList] Unmounted component');
      return;
    }

    // 1kmwine 프로모션 목록 조회 중 오류 발생
    if (result.error) {
      console.error('[PromotionList][getPromotionList] 1kmwine 프로모션 목록 조회 중 오류 발생 > ', result.error);
      setRows({ loaded: true, error: true, data: [] });
      return undefined;
    }

    let promotionList = result;

    if (result.length > 0) {
      // promoCustomState 추가
      // eslint-disable-next-line array-callback-return
      const temp = result.map((item) => {
        const {
          state,
          period: { start_dt, end_dt }
        } = item;

        // 1. 입고대기 (state 'OK'이고, period.end_dt가 오늘 이전이면 '입고 대기')
        if (state === 'OK' && moment(end_dt).isBefore(moment())) {
          return { ...item, promoCustomState: 'end' };
        }
        // 2. 입고완료 (state 'FINISH')
        if (state === 'FINISH') {
          return { ...item, promoCustomState: 'finish' };
        }
        // 3. 진행 중 (state 'OK'이고, period.start_dt와 period.end_dt이 사이에 오늘이 포함되면 '판매중')
        if (state === 'OK' && moment(start_dt).isSameOrBefore(moment()) && moment(end_dt).isSameOrAfter(moment())) {
          return { ...item, promoCustomState: 'ing' };
        }
        // 4. 판매대기 (state 'OK'이고, period.start_dt기 오늘 이후면 '판매대기')
        if (state === 'OK' && moment(start_dt).isAfter(moment())) {
          return { ...item, promoCustomState: 'wait' };
        }
      });
      // result 배열 재정렬
      // 1. 입고대기 (상태가 'OK'이고, 종료 기간이 오늘 이전), promoCustomState 'end' 추가
      // 2. 입고완료 (상태가 'FINISH'), promoCustomState 'finish' 추가
      // 3. 진행 중 (상태가 'OK'이고, 시작 기간과 종료 기간 사이에 오늘이 포함), promoCustomState 'ing' 추가
      // 4. 판매대기 (상태가 'OK'이고, 시작 기간이 오늘 이전), promoCustomState 'wait' 추가
      promotionList = temp.sort((a, b) => {
        const { state: stateA, period: periodA } = a;
        const { state: stateB, period: periodB } = b;

        if (stateA === 'OK' && moment(periodA.end_dt).isBefore(moment())) {
          return -1;
        }
        if (stateB === 'OK' && moment(periodB.end_dt).isBefore(moment())) {
          return 1;
        }
        if (stateA === 'FINISH') {
          return -1;
        }
        if (stateB === 'FINISH') {
          return 1;
        }
        if (stateA === 'OK' && moment(periodA.start_dt).isSameOrBefore(moment()) && moment(periodA.end_dt).isSameOrAfter(moment())) {
          return -1;
        }
        if (stateB === 'OK' && moment(periodB.start_dt).isSameOrBefore(moment()) && moment(periodB.end_dt).isSameOrAfter(moment())) {
          return 1;
        }
        if (stateA === 'OK' && moment(periodA.start_dt).isAfter(moment())) {
          return -1;
        }
        if (stateB === 'OK' && moment(periodB.start_dt).isAfter(moment())) {
          return 1;
        }
        return 0;
      });
    }

    setRows({ loaded: true, error: false, data: promotionList });
  };

  // render
  return (
    rows.loaded &&
    !rows.error &&
    rows.data.length > 0 && (
      <Box mt={4}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', pl: '4px', lineHeight: 'normal' }}>예약판매</Typography>

        <Box mt={2}>
          <Swiper
            className={classes.contentSwiper}
            slidesPerView="auto"
            loop={rows.data.length > 1}
            centeredSlidesBounds={rows.data.length > 1}
            centeredSlides={true}
            spaceBetween={12}
            pagination={{
              dynamicBullets: true
            }}
            modules={[Pagination]}
          >
            {rows.data.map((item, index) => {
              return (
                <SwiperSlide
                  style={{ width: rows.data.length > 1 ? 'calc(90% - 40px)' : 'calc(100% - 55px)' }}
                  key={`promotion-swiper-${index}-slide`}
                >
                  <PromotionCard data={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Box>
    )
  );
};

export default PromotionList;
