import React from 'react';
import { useNavigate } from 'react-router-dom';

// import mui
import { Box, Container, Pagination, Skeleton } from '@mui/material';

// import components
import { WoAlert } from 'utils/kmwine-alerts';

// import icons
import NoResultImg from 'assets/images/no_result_img.png';

// import projects
import RtnWaitTimeline from './components/RtnWaitTimeline';
import * as OrderService from 'services/OrderService';
import { useScriptRef } from '../../../../hooks';

/**
 * 반품대기 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReturnWait() {
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();

  const [loading, setLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalResults, setTotalResults] = React.useState(0);

  // 반품대기 목록
  const [rows, setRows] = React.useState([]);
  // 반품대기 목록 없음 플래그
  const [emptyRows, setEmptyRows] = React.useState(false);

  React.useEffect(() => {
    getRtnWaitList(1);
  }, []);

  // 반품 대기 목록 조회
  const getRtnWaitList = async (pageNo) => {
    console.log('[매장 방문 대기] 반품대기 목록 조회 시작');
    setLoading(true);

    const body = {
      page: {
        current: pageNo,
        size: 50
      }
    };

    // ---------------------------------------------- 반품대기 목록 조회 [START]
    const rtnWaitList = await OrderService.rtnWaitList(body).catch((error) => ({ error }));

    // Unmounted component
    if (!scriptedRef.current) {
      console.warn('[ReturnWaint][getRtnWaitList] Unmounted component.');
      return undefined;
    }

    // 반품대기 목록 조회 중 오류 발생
    if (rtnWaitList.error) {
      // todo clo
      console.error('[매장 방문 대기] 반품대기 목록 조회 중 오류 발생 > ', rtnWaitList.error);
      WoAlert.fire('매장 방문 대기', '반품대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    // 반품대기 목록 조회 중 잘못된 상태값이 넘어옴
    if (!rtnWaitList.data.success) {
      console.error('[매장 방문 대기] 반품대기 목록 조회 중 오류 발생 ');
      WoAlert.fire('매장 방문 대기', '반품대기 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    const rtnResult = rtnWaitList;
    setTotalPages(rtnResult.data.page.total_pages);
    setTotalResults(rtnResult.data.page.total_results);
    const rtnList = rtnResult.data.results;

    // 반품대기 목록 데이터 가공
    if (rtnList.length > 0) {
      setEmptyRows(false);

      const rtnTempArr1 = [];
      let rtnTempArr2 = [];
      let changeCount = 0;
      // 반품대기 데이터 날짜별로 배열 재생성
      rtnList.forEach((r, index) => {
        let changePickupTime;
        if (index > 0) {
          changePickupTime = rtnList[index - 1].rtn_date;
          if (changePickupTime !== r.rtn_date) {
            changeCount += 1;
            changePickupTime = r.rtn_date;
            rtnTempArr2.push(r);
            rtnTempArr1.push(rtnTempArr2);
            rtnTempArr2 = [];
          } else {
            rtnTempArr1[changeCount].push(r);
          }
        } else {
          changePickupTime = r.rtn_date;
          rtnTempArr1.push([r]);
        }
        setRows(rtnTempArr1);
      });
    } else {
      setEmptyRows(true);
      setRows([]);
    }

    await setLoading(false);
    console.log('[매장 방문 대기] 반품대기 목록 조회 종료');
  };

  // 페이지 변경 이벤트
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getRtnWaitList(newPage);
  };

  return (
    <>
      <Container sx={{ pt: 2 }}>
        <main>
          {/* 반품대기 목록이 없을 시 */}
          {emptyRows && (
            <Box pt={7} textAlign="center">
              <Box component="img" src={NoResultImg} width={123} height={100} />
              <Box fontSize={20} color="#220348" mt={4}>
                반품대기 내역이 없습니다.
              </Box>
            </Box>
          )}

          {/* 로딩 중 */}
          {loading && <Skeleton variant="rectangular" height="120px" sx={{ borderRadius: '8px' }} />}

          {/* 반품대기 목록이 있을 시 */}
          {!loading && !emptyRows && <RtnWaitTimeline rows={rows} />}

          {/* 페이지 > 교환대기 건이 50개 이상일 경우 페이지네이션 활성화 */}
          {!loading && !emptyRows && totalResults > 49 && (
            <Box width="100%" pb={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="circular"
                sx={{ padding: '0 12px', '& .MuiPagination-ul': { justifyContent: 'center' } }}
              />
            </Box>
          )}
        </main>
      </Container>
    </>
  );
}

export default ReturnWait;
