import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * mysql date format (a hh:mm)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function MDateFormatAhm(val) {
  const time = moment(val).utc().format('hh:mm');
  const amPm = moment(val).utc().format('a');
  return `${amPm} ${time}`;
}

export default MDateFormatAhm;

MDateFormatAhm.propTypes = {
  val: PropTypes.instanceOf(Date)
};
