import React from 'react';
import PropTypes from 'prop-types';

// import mui
import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// projects import
import OrderProductStateAction from './components/OrderProductStateAction';
import MskPhone from 'components/masking/MskPhone';
import FDateformatYMDaHm from '../../../components/FDateFormatYMDaHm';
import { InformationIcon } from 'components/icons';
import { bottleCapacityToString } from 'config';

/**
 * 주문 상품 정보 탭
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ProductTab({ order, product }) {
  return (
    <Box>
      <OrderProductStateAction state={product.state} />
      <EmptyArea />
      {/* 주문자 정보 */}
      <Box padding="20px">
        {/* 이름 */}
        <Box>
          <Typography component="span" fontSize="16px" color="#333333" fontWeight={800}>
            {order?.user?.uname}
          </Typography>
        </Box>
        {/* 연락처 */}
        <Box mt={2}>
          <Typography component="span" fontSize="16px" color="#333333">
            {MskPhone(order?.user?.phone)}
          </Typography>
        </Box>
      </Box>
      <Divider variant="middle" />

      {/* 상품 상태에 따른 반품/교환 요청 일시 */}
      <Box padding="20px" display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="span" color="#33333399" fontSize="13px">
          {(product.state === 'REQ_RTN' || product.state === 'RTN_WAIT' || product.state === 'RTN_OK') && '반품 요청일시'}
          {(product.state === 'REQ_EXC' || product.state === 'EXC_WAIT') && '교환 요청일시'}
        </Typography>
        <Typography component="span" color="#333333" fontSize="14px" textAlign="right">
          {(product.state === 'REQ_RTN' || product.state === 'RTN_WAIT' || product.state === 'RTN_OK') &&
            FDateformatYMDaHm(product.req_rtn_at)}
          {(product.state === 'REQ_EXC' || product.state === 'EXC_WAIT') && FDateformatYMDaHm(product.req_exc_at)}
        </Typography>
      </Box>

      <EmptyArea />

      {/* 상품 상태에 따른 반품/교환 정보 */}
      <Box padding="20px">
        <Typography component="span" color="#333333" fontSize="14px" fontWeight={600}>
          {(product.state === 'REQ_RTN' || product.state === 'RTN_WAIT' || product.state === 'RTN_OK') &&
            `반품상품(총 ${product.quantity}병)`}
          {(product.state === 'REQ_EXC' || product.state === 'EXC_WAIT') && `교환상품(총 ${product.quantity}병)`}
        </Typography>
        <Box mt={1}>
          <Box pt={0.5} pb={0.5} mb={1} borderBottom="1px solid #EEEEEE">
            <Stack spacing={1}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography component="span" color="#33333399" fontSize="13px">
                    상품명
                  </Typography>
                </Box>
                <Box width="75%" textAlign="right">
                  <Typography component="span" color="#333333" fontSize="14px">
                    {product.name.ko}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography component="span" color="#33333399" fontSize="13px">
                    수량
                  </Typography>
                </Box>
                <Box width="75%" textAlign="right">
                  <Typography component="span" color="#333333" fontSize="14px">
                    {product.quantity}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography component="span" color="#33333399" fontSize="13px">
                    빈티지
                  </Typography>
                </Box>
                <Box width="75%" textAlign="right">
                  <Typography component="span" color="#333333" fontSize="14px">
                    {product.vintage}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography component="span" color="#33333399" fontSize="13px">
                    용량
                  </Typography>
                </Box>
                <Box width="75%" textAlign="right">
                  <Typography component="span" color="#333333" fontSize="14px">
                    {bottleCapacityToString(product.capacity)}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box width="20%">
                  <Typography component="span" color="#33333399" fontSize="13px">
                    가격
                  </Typography>
                </Box>
                <Box width="75%" textAlign="right">
                  <Typography component="span" color="#333333" fontSize="14px">
                    {product.price.original.toLocaleString()}원
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>

      {/* 상품 상태에 따른 반품/교환 사유 */}
      <Box padding="0 20px 20px 20px">
        <Typography component="span" color="#333333" fontSize="14px" fontWeight={600}>
          {(product.state === 'REQ_RTN' || product.state === 'RTN_WAIT' || product.state === 'RTN_OK') && '반품사유'}
          {(product.state === 'REQ_EXC' || product.state === 'EXC_WAIT') && '교환사유'}
        </Typography>
        <Box mt={1}>
          <Typography component="span" color="#333333" fontSize="14px">
            {product.msg}
          </Typography>
        </Box>
      </Box>

      {/* 주의사항 */}
      <Box padding="0 20px" display="flex">
        <InformationIcon sx={{ color: '#555555', width: '13px', height: '13px', marginRight: '2px' }} />
        <Typography component="span" color="#33333399" fontSize="13px" marginLeft="6px">
          고객 정보를 상품판매 목적 외 사용, 보관, 공개 시 법적 처벌을 받을 수 있습니다.
        </Typography>
      </Box>
    </Box>
  );
}

export default ProductTab;

ProductTab.propTypes = {
  order: PropTypes.object,
  product: PropTypes.object
};

const EmptyArea = styled('div')({
  width: '100%',
  height: '8px',
  backgroundColor: '#1303280A'
});
