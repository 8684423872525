import React from 'react';
import PropTypes from 'prop-types';

// project imports
import { CartIcon } from '../icons';
import { WoAlert } from 'utils/kmwine-alerts';

// material-ui
import { Badge, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

/**
 * 장바구니 버튼
 *
 * @param {string} color
 * @param {boolean} showBadge
 * @param {'end'|'start'|false} edge
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
function CartButton({ color: colorProp, count = 0, showBadge = false, edge = false, ...other }) {
  const theme = useTheme();
  return (
    <ButtonBase
      aria-label="장바구니"
      edge={edge}
      onClick={() => {
        WoAlert.fire('준비 중 입니다.', '', 'warning');
      }}
      {...other}
    >
      <Badge
        color="secondary"
        variant="dot"
        invisible={!showBadge || count === 0}
        sx={{ '& .MuiBadge-dot': { height: '5px', minWidth: '5px', top: '4px', right: '4px' } }}
      >
        <CartIcon color={colorProp ?? theme.palette.text.primary} width={34} height={34} />
      </Badge>
    </ButtonBase>
  );
}

CartButton.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  showBadge: PropTypes.bool,
  edge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default CartButton;

const ButtonBase = styled((props) => <IconButton {...props} />)`
  padding: 0;
  margin-left: 13px;
`;

/* eslint-disable react/prop-types */
export function CartOutlinedButton({ onClick }) {
  const theme = useTheme();
  return (
    <IconButton aria-label="장바구니" sx={{ border: '1px solid #e7e4eb', borderRadius: '5px', p: '5px' }} onClick={onClick}>
      <CartIcon color={theme.palette.text.primary} width={34} height={34} />
    </IconButton>
  );
}
