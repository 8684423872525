import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const { pathname, search, hash } = location;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/auth/sign-in-with-id?from=${encodeURIComponent(pathname + search + hash)}`, { replace: true });
    } else {
      navigate('/home', { replace: true });
    }
    return null; // page 깜박거리는 현상 처리
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? children : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
