import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Dialog, Typography } from '@mui/material';

/**
 * 액션 후 처리 결과를 보여주는 팝업
 * @constructor
 */
function WoDialog({ open, title, content, action }) {
  return (
    <Dialog open={open} fullWidth>
      <Box p="26px">
        {/* title */}
        <Box textAlign="center">
          <Typography color="#140229" fontSize="16px" fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {/* contents */}
        <Box textAlign="center" my="22px">
          <Typography fontSize="14px" color="#140229" whiteSpace="pre-line">
            {content}
          </Typography>
        </Box>
        {/* actions */}
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{
              width: '127px',
              height: '50px',
              bgcolor: '#333333',
              borderRadius: '25px',
              color: '#fff',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
            onClick={action}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default WoDialog;

WoDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.func
};
