import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, Grid, Stack, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

function BasicDialog({ open, close, type, titleImg, title, content1, content2, btnText, action, reload }) {
  /*
   * open      -> 다이얼로그 오픈
   * close     -> 다이얼로그 닫기
   * type      -> 다이얼로그 타입 (success, info, warning, error)
   * titleImg  -> 타이틀 아이콘
   * title     -> 타이틀 텍스트
   * content1  -> 내용
   * content2  -> new line 내용
   * btnText   -> 버튼 텍스트
   * action    -> 버튼 클릭 이벤트
   * reload    -> 새로고침
   */

  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: '#F4F0F880'
        },
        '& .MuiPaper-root': {
          width: '80%',
          boxShadow: '0px 6px 3px 4px #00000029',
          borderRadius: '12px'
        }
      }}
    >
      <Box padding={'20px 25px'} display={'flex'} alignItems={'center'}>
        {titleImg}
        {type === 'error' ? (
          <Typography variant={'span'} fontSize={18} fontWeight={900} color={'#FB6464'} ml={2}>
            {title}
          </Typography>
        ) : (
          <Typography variant={'span'} fontSize={18} fontWeight={900} color={'#47C454'} ml={2}>
            {title}
          </Typography>
        )}
      </Box>
      <Box padding={'0 20px 20px 20px'}>
        <Typography variant={'span'} fontSize={16} color={'#140229'}>
          {content1}
          {content2 && (
            <>
              <br />
              {content2}
            </>
          )}
        </Typography>
      </Box>
      <Box padding={'0 20px 25px 20px'}>
        <Grid container>
          <Stack direction={'row'} spacing={1} width={'100%'}>
            <Grid item xs={12}>
              <Button
                sx={{
                  width: '100%',
                  padding: '13px 0',
                  borderRadius: '30px',
                  fontWeight: 600,
                  backgroundColor: '#220648',
                  color: '#FFFFFF'
                }}
                onClick={action}
              >
                {btnText}
                {reload && <RefreshIcon sx={{ width: '25px', height: '25px', marginLeft: '4px' }} onClick={() => navigate(0)} />}
              </Button>
            </Grid>
          </Stack>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default BasicDialog;

BasicDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  type: PropTypes.string,
  titleImg: PropTypes.element,
  title: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  btnText: PropTypes.string,
  action: PropTypes.func,
  reload: PropTypes.bool
};
