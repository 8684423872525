import React from 'react';
import { useAuth, useScriptRef } from 'hooks';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';

// material-ui
import { Box, Grid, IconButton, InputAdornment, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { LogoIcon } from 'components/icons';
import Footer from 'components/Footer';

// Sign in form validation schema
const validationSchema = yup.object({
  username: yup.string().trim().required('아이디를 입력해주세요.'),
  password: yup.string().required('비밀번호를 입력해주세요')
});

/**
 * 아이디 로그인 form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Form = (props) => {
  const { onLoginFailure } = props;
  const { login } = useAuth();
  const scriptedRef = useScriptRef();

  // 비밀번호 보임 여부
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // 시작 기본값.
  const initialValues = {
    username: '',
    password: ''
  };

  const onSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    login(values.username, values.password, (err /* , result */) => {
      if (err) {
        if (scriptedRef.current) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);

          onLoginFailure(err);
        }
        return;
      }

      if (scriptedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  return (
    <Box
      pt={2}
      pb="98px"
      position="relative"
      minHeight="calc(var(--vh, 1vh) * 100)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Typography component="div" fontSize="inherit" fontWeight={600} letterSpacing="-0.34px">
          <Box fontSize={20} px="10px" display="flex" justifyContent="center" alignItems="center">
            <LogoIcon sx={{ width: 160, height: 44 }} />
          </Box>
        </Typography>
        <Box fontSize={24} fontWeight={600} textAlign="center">
          통합 로그인
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} mt={8}>
            {/* Username field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                label="아이디 입력"
                sx={{ borderRadius: '12px' }}
              />
            </Grid>

            {/* Password field */}
            <Grid
              item
              xs={12}
              sx={{
                '& .MuiInputBase-root': {
                  fontFamily: 'auto'
                }
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                label="비밀번호 입력"
                name="password"
                type={showPassword ? 'text' : 'password'}
                // type="text"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            {/* login button */}
            <Grid item xs={12}>
              <LoadingButton
                loading={formik.isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ fontSize: '20px', fontWeight: '600' }}
              >
                로그인
              </LoadingButton>
            </Grid>

            {/* <Grid item container xs={12} mt={1}> */}
            {/*   <Typography */}
            {/*     color="#140229" */}
            {/*     fontSize={16} */}
            {/*     fontWeight="normal" */}
            {/*     onClick={() => { */}
            {/*       WoAlert.fire('준비 중 입니다.', '', 'warning'); */}
            {/*     }} */}
            {/*   > */}
            {/*     비밀번호 찾기 */}
            {/*   </Typography> */}
            {/* </Grid> */}
          </Grid>
        </form>
      </Box>

      <Box mx="-20px" mt={6} bgcolor="#220348" padding="12px" onClick={() => window.open('https://forms.gle/84CQD5KYfHBEZgM79', '_blank')}>
        <Box textAlign="center">
          <Typography variant="body2" fontWeight="bold" color="#ffffff">
            새로운 매출 채널 1KMWINE
          </Typography>
          <Typography variant="h5" fontWeight="bold" color="#ffffff">
            입점 문의
          </Typography>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

Form.propTypes = {
  onLoginFailure: PropTypes.func
};

export default Form;
