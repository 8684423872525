import React from 'react';

// assets
import ArrowBackImg from 'assets/images/arrow-back.png';
import { Box, ButtonBase } from '@mui/material';

/**
 * 뒤로가기 버튼
 * @param {function} onClick
 * @constructor
 */
/* eslint-disable react/prop-types */
function BackButton({ width = 34, height = 34, color = 'inherit', onClick }) {
  return (
    <ButtonBase onClick={onClick} edge="start" sx={{ color, width, height, ml: '-6px', borderRadius: '50%' }}>
      <Box
        component="img"
        src={ArrowBackImg}
        alt="뒤로가기"
        sx={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center center' }}
      />
    </ButtonBase>
  );
}

export default BackButton;
