import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Box, Grid, Stack, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';

/**
 * 주문 승인 대기 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqWait({ waitCounts, vendorStopState }) {
  const navigate = useNavigate();

  // 샵 영업 임시 중지 플래그
  const [stopFlag, setStopFlag] = React.useState(false);

  React.useEffect(() => {
    if (vendorStopState === null) return;
    setStopFlag(vendorStopState);
  }, [vendorStopState]);

  return (
    <Box mt="28px">
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', pl: '4px', lineHeight: 'normal' }}>주문 승인 대기</Typography>
      <Grid container spacing={2} mt={0}>
        {/* 승인 대기 */}
        <Grid item xs={4}>
          <BoxCover>
            {stopFlag && (
              <LockBox>
                <LockIcon style={{ color: '#D8D8D8', width: '3rem', height: '3rem' }} />
              </LockBox>
            )}

            {waitCounts?.REQ_COUNT > 0 && <DotBox />}

            <NumberBox onClick={() => (stopFlag ? null : navigate('/order', { state: { num: 0 } }))}>
              <BoxTitle noWrap>승인대기</BoxTitle>
              <Box borderBottom="2px solid #94D210">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.REQ_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>

        {/* 교환신청 */}
        <Grid item xs={4}>
          <BoxCover>
            {stopFlag && (
              <LockBox>
                <LockIcon style={{ color: '#D8D8D8', width: '3rem', height: '3rem' }} />
              </LockBox>
            )}

            {waitCounts?.REQ_EXC_COUNT > 0 && <DotBox />}
            <NumberBox onClick={() => (stopFlag ? null : navigate('/order', { state: { num: 1 } }))}>
              <BoxTitle noWrap>교환신청</BoxTitle>
              <Box borderBottom="2px solid #F7A600">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.REQ_EXC_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>

        {/* 반품신청 */}
        <Grid item xs={4}>
          <BoxCover>
            {stopFlag && (
              <LockBox>
                <LockIcon style={{ color: '#D8D8D8', width: '3rem', height: '3rem' }} />
              </LockBox>
            )}

            {waitCounts?.REQ_RTN_COUNT > 0 && <DotBox />}
            <NumberBox onClick={() => (stopFlag ? null : navigate('/order', { state: { num: 2 } }))}>
              <BoxTitle noWrap>반품신청</BoxTitle>
              <Box borderBottom="2px solid #333333E5">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.REQ_RTN_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReqWait;

ReqWait.propTypes = {
  waitCounts: PropTypes.object,
  vendorStopState: PropTypes.bool
};

const BoxCover = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '17px 15px',
  position: 'relative',
  boxShadow: '0px 2px 3px #0000000A',
  borderRadius: '8px',
  backgroundColor: '#FFF',
  aspectRatio: '1/1'
});

const BoxTitle = styled(Typography)({
  width: '100%',
  fontSize: '13px',
  color: '#333333CC',
  letterSpacing: '-0.13px'
});

const NumberText = styled(Typography)({
  fontSize: '22px',
  fontWeight: 'bold',
  fontFamily: 'Inter'
});

const NumberBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
});

const LockBox = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  position: 'absolute',
  borderRadius: '8px',
  margin: '-17px -15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: '0.8'
});

const DotBox = styled(Box)({
  width: '5px',
  height: '5px',
  backgroundColor: '#FC735C',
  borderRadius: '50%',
  position: 'absolute',
  top: '8px',
  right: '8px'
});
