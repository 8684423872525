import React from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import { WoAlert } from 'utils/kmwine-alerts';
import * as OrderService from 'services/OrderService';
import RequestExcRows from './components/RequestExcRows';

// material-ui
import { Box, Container, Pagination, CircularProgress } from '@mui/material';

// assets
import NoResultImg from 'assets/images/no_result_img.png';

/**
 * 교환신청 화면
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ExchangeReq() {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalResults, setTotalResults] = React.useState(0);

  // 교환신청 목록
  const [rows, setRows] = React.useState([]);
  // 교환신청 목록 없음 플래그
  const [emptyRows, setEmtpyRows] = React.useState(false);

  React.useEffect(() => {
    getRequestExchangeList(1);
  }, []);

  // 교환신청 목록 조회
  const getRequestExchangeList = async (pageNo) => {
    console.log('[주문 승인 대기] 교환신청 목록 조회 시작');
    setLoading(true);

    const body = {
      page: {
        current: pageNo,
        size: 50
      }
    };

    // ---------------------------------------------- 교환신청 목록 조회 [START]
    const reqExcWaitList = await OrderService.requestExcWaitList(body).catch((error) => ({ error }));

    // 교환신청 목록 조회 중 오류 발생
    if (reqExcWaitList.error) {
      console.error('[주문 승인 대기] 교환신청 목록 조회 중 오류 발생');
      WoAlert.fire('주문 승인 대기', '교환신청 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    // 교환신청 목록 조회 중 잘못된 상태값이 넘어옴
    if (!reqExcWaitList.data.success) {
      console.error('[주문 승인 대기] 교환신청 목록 조회 중 오류 발생');
      WoAlert.fire('주문 승인 대기', '교환신청 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        navigate('/home', { replace: true });
      });
      return false;
    }

    const reqResult = reqExcWaitList.data;
    setTotalPages(reqResult.page.total_pages);
    setTotalResults(reqResult.page.total_results);

    if (reqResult.page.total_results === 0) {
      setEmtpyRows(true);
    } else {
      setEmtpyRows(false);
    }

    setRows(reqResult.results);

    await setLoading(false);
    console.log('[주문 승인 대기] 교환신청 목록 조회 종료');
  };

  // 페이지 변경 이벤트
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getRequestExchangeList(newPage);
  };

  return (
    <>
      <Container sx={{ pt: 2 }} style={{ height: 'calc(100vh - 106px)' }}>
        <main>
          {/* 로딩중 */}
          {loading && (
            <Box width="100%" textAlign="center">
              <CircularProgress color="brand" />
            </Box>
          )}

          {/* 교환신청 목록이 없을 시 */}
          {emptyRows && (
            <Box pt={7} textAlign="center">
              <Box component="img" src={NoResultImg} widt={123} height={100} />
              <Box fontSize={20} color="#220348" mt={4}>
                교환신청 내역이 없습니다
              </Box>
            </Box>
          )}

          {/* 교환신청 목록이 있을 시 */}
          {!loading && !emptyRows && <RequestExcRows rows={rows} />}

          {/* 페이지 > 교환신청 건이 50개 이상일 경우 페이지네이션 활성화 */}
          {!loading && !emptyRows && totalResults > 49 && (
            <Box width="100%" pb={2}>
              <Pagination
                count={totalPages}
                page={page}
                ohChange={handleChangePage}
                variant="outlined"
                shape="circular"
                sx={{ padding: '0 12px', '& .MuiPagination-ul': { justifyContent: 'center' } }}
              />
            </Box>
          )}
        </main>
      </Container>
    </>
  );
}

export default ExchangeReq;
