import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// import mui
import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * 매장 방문 대기 컴포넌트
 *  - 픽업(Pickup), 교환(Exchange), 반품(Return) 대기 표현
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function VisitWait({ waitCounts }) {
  const navigate = useNavigate();

  return (
    <Box mt="28px">
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', pl: '4px', lineHeight: 'normal' }}>매장 방문 대기</Typography>
      <Grid container spacing={2} mt={0}>
        {/* 픽업대기 */}
        <Grid item xs={4}>
          <BoxCover>
            {waitCounts?.REQ_OK_COUNT > 0 && <DotBox />}
            <NumberBox onClick={() => navigate('/visit', { state: { num: 0 } })}>
              <BoxTitle noWrap>픽업대기</BoxTitle>
              <Box borderBottom="2px solid #94D210">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.REQ_OK_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>

        {/* 교환대기  */}
        <Grid item xs={4}>
          <BoxCover>
            {waitCounts?.EXC_WAIT_COUNT > 0 && <DotBox />}
            <NumberBox onClick={() => navigate('/visit', { state: { num: 1 } })}>
              <BoxTitle noWrap>교환대기</BoxTitle>
              <Box borderBottom="2px solid #F7A600">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.EXC_WAIT_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>

        {/* 반품대기 */}
        <Grid item xs={4}>
          <BoxCover>
            {waitCounts?.RTN_WAIT_COUNT > 0 && <DotBox />}
            <NumberBox onClick={() => navigate('/visit', { state: { num: 2 } })}>
              <BoxTitle noWrap>반품대기</BoxTitle>
              <Box borderBottom="2px solid #F5555A">
                <Stack direction="row" spacing={0.5}>
                  {waitCounts ? (
                    <Stack direction="row" alignItems="baseline" spacing="2px">
                      <NumberText>{waitCounts.RTN_WAIT_COUNT?.toLocaleString()}</NumberText>
                      <Typography color="#333333B2" fontWeight={600}>
                        건
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography color="#333333B2" fontWeight={600}>
                      -
                    </Typography>
                  )}
                </Stack>
              </Box>
            </NumberBox>
          </BoxCover>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VisitWait;

VisitWait.propTypes = {
  waitCounts: PropTypes.object
};

const BoxCover = styled(Box)({
  width: '100%',
  height: '100%',
  padding: '17px 15px',
  position: 'relative',
  boxShadow: '0px 2px 3px #0000000A',
  borderRadius: '8px',
  backgroundColor: '#FFF',
  aspectRatio: '1/1'
});

const BoxTitle = styled(Typography)({
  width: '100%',
  fontSize: '13px',
  color: '#333333CC',
  letterSpacing: '-0.13px'
});

const NumberText = styled(Typography)({
  fontSize: '22px',
  fontWeight: 'bold',
  fontFamily: 'Inter'
});

const NumberBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
});

const DotBox = styled(Box)({
  width: '5px',
  height: '5px',
  backgroundColor: '#FC735C',
  borderRadius: '50%',
  position: 'absolute',
  top: '8px',
  right: '8px'
});
