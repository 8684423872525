import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import OrderProductStateChip from 'components/chip/OrderProductStateChip';
import { ProductCard } from '../../ReqState/step2/components';

/**
 * 픽업 대기(REQ_OK)화면에서 표현 될 취소 상품 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function CancelList({ products }) {
  return (
    <Box>
      {/* 취소 상품 수량 */}
      <SpaceBetweenBox>
        {OrderProductStateChip('CANCEL')}
        <Typography fontSize="14px" color="#130328">
          총 {products.reduce((acc, cur) => acc + (cur.origin_quantity - cur.quantity), 0)}병
        </Typography>
      </SpaceBetweenBox>

      {/* 취소 상품 목록 */}
      <Stack spacing={2.25} my={2}>
        {products.map((product, index) => (
          <ProductCard key={`product-${index}-row`} product={product} type="CANCEL" />
        ))}
      </Stack>

      {/* 취소 사유 */}
      <Box>
        <Typography fontSize="13px" color="#130328" fontWeight="bold">
          취소 사유
        </Typography>
        <Typography mt={1.5} fontSize="13px" color="#130328">
          {products[0].msg}
        </Typography>
      </Box>
    </Box>
  );
}

export default CancelList;

CancelList.propTypes = {
  products: PropTypes.array.isRequired
};
