import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import { useWineOne } from 'hooks';
import Main from 'layouts/Main';
import Container from 'components/Container';

// material-ui
import { Box, Button, Grid, Typography, useTheme, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * 에러화면
 * @returns {JSX.Element}
 * @constructor
 */
const PageNotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { onNativeBackPressed } = useWineOne();

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`에러 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'backpressed'}}));
    onNativeBackPressed(pageBackFunc);
  }, [location]);

  return (
    <Main showFooter={false}>
      <Box
        bgcolor={theme.palette.alternate.main}
        position={'relative'}
        minHeight={'calc(var(--vh, 1vh) * 100)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
      >
        <Container maxWidth="sm" position="relative">
          <Box pb="56px">
            <Box height={1} width={1} textAlign="center">
              {networkErrorIcon}
            </Box>
            <Typography variant="h6" component="p" fontSize={18} fontWeight="bold" align={'center'}>
              페이지를 찾을 수 없습니다.
            </Typography>

            <Typography variant="body1" component="p" fontSize={18} fontWeight={300} align="center" sx={{ mt: '12px' }}>
              네트워크가 일시적으로 끊겼거나,
              <br />
              페이지를 불러오지 못했습니다.
              <br />
              잠시 후 다시 시도해주세요.
            </Typography>
          </Box>
        </Container>
      </Box>
      <BottomBox>
        <Container maxWidth="sm">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" onClick={pageBackFunc} sx={{ height: 46, fontSize: 17 }}>
                이전으로
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="brand"
                onClick={() => navigate('/', { replace: true })}
                sx={{ height: 46, fontSize: 17 }}
              >
                홈으로
              </Button>
            </Grid>
          </Grid>
        </Container>
      </BottomBox>
    </Main>
  );
};

export default PageNotFound;

const networkErrorIcon = (
  <SvgIcon viewBox="0 0 163 148" sx={{ width: 163, height: 148 }}>
    <path style={{ opacity: 0.01, fill: 'none' }} d="M0 0h163v148H0z" />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16800 4625.002)"
      style={{ fill: '#220648' }}
    />
    <g transform="translate(32 25)" style={{ stroke: '#220348', strokeWidth: '3px', fill: '#fff' }}>
      <circle cx="49.5" cy="49.5" r="49.5" style={{ stroke: 'none' }} />
      <circle cx="49.5" cy="49.5" r="48" style={{ fill: 'none' }} />
    </g>
    <g transform="translate(53 25)" style={{ stroke: '#220348', strokeWidth: '3px', fill: '#fff' }}>
      <ellipse cx="28.5" cy="49.5" rx="28.5" ry="49.5" style={{ stroke: 'none' }} />
      <ellipse cx="28.5" cy="49.5" rx="27" ry="48" style={{ fill: 'none' }} />
    </g>
    <path transform="translate(80 27)" style={{ fill: '#220648' }} d="M0 0h3v95H0z" />
    <path transform="rotate(-90 55 21)" style={{ fill: '#220648' }} d="M0 0h3v75H0z" />
    <path transform="rotate(-90 46 6)" style={{ fill: '#220648' }} d="M0 0h3v83H0z" />
    <g transform="translate(51 44)" style={{ stroke: '#220348', strokeWidth: '3px', fill: '#fff' }}>
      <circle cx="6.5" cy="6.5" r="6.5" style={{ stroke: 'none' }} />
      <circle cx="6.5" cy="6.5" r="5" style={{ fill: 'none' }} />
    </g>
    <g transform="translate(75 68)" style={{ fill: '#defd9a', stroke: '#220348', strokeWidth: '3px' }}>
      <circle cx="6.5" cy="6.5" r="6.5" style={{ stroke: 'none' }} />
      <circle cx="6.5" cy="6.5" r="5" style={{ fill: 'none' }} />
    </g>
    <g transform="translate(98 44)" style={{ fill: '#defd9a', stroke: '#220348', strokeWidth: '3px' }}>
      <circle cx="6.5" cy="6.5" r="6.5" style={{ stroke: 'none' }} />
      <circle cx="6.5" cy="6.5" r="5" style={{ fill: 'none' }} />
    </g>
    <path transform="translate(110 71)" style={{ fill: '#fff' }} d="M0 0h11v45H0z" />
    <path transform="translate(101 76)" style={{ fill: '#fff' }} d="M0 0h11v52H0z" />
    <path transform="translate(124 76)" style={{ fill: '#fff' }} d="M0 0h11v26H0z" />
    <path transform="translate(83 110)" style={{ fill: '#fff' }} d="M0 0h11v21H0z" />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16803 4563)"
      style={{ fill: '#220648' }}
    />
    <path
      d="M16820-4542v-3h3v3zm-6 0v-3h3v3zm3-3v-3h3v3zm3-3v-3h3v3zm-6 0v-3h3v3z"
      transform="translate(-16676 4597)"
      style={{ fill: '#220648' }}
    />
    <path transform="rotate(-90 70 30)" style={{ fill: '#220648' }} d="M0 0h3v58H0z" />
    <g transform="translate(51 92)" style={{ fill: '#defd9a', stroke: '#220348', strokeWidth: '3px' }}>
      <circle cx="6.5" cy="6.5" r="6.5" style={{ stroke: 'none' }} />
      <circle cx="6.5" cy="6.5" r="5" style={{ fill: 'none' }} />
    </g>
    <g style={{ fill: '#eadff5' }}>
      <path
        d="M63.77 57.5H5.23a1.473 1.473 0 0 1-1.302-.754c-.13-.226-.39-.826.007-1.503l29.27-50.057a1.475 1.475 0 0 1 1.295-.743c.259 0 .903.073 1.295.743l29.27 50.057c.396.677.137 1.277.007 1.503-.13.227-.517.754-1.301.754z"
        style={{ stroke: 'none' }}
        transform="translate(84 72)"
      />
      <path
        d="M34.5 5.943 5.23 56h58.54L34.5 5.943m0-3c1.005 0 2.01.495 2.59 1.486l29.27 50.057c1.17 2-.273 4.514-2.59 4.514H5.23c-2.317 0-3.76-2.514-2.59-4.514L31.91 4.429a2.972 2.972 0 0 1 2.59-1.486z"
        style={{ fill: '#220348', stroke: 'none' }}
        transform="translate(84 72)"
      />
    </g>
    <path transform="translate(117 94)" style={{ fill: '#220648' }} d="M0 0h3v18H0z" />
    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(116 116)" style={{ fill: '#220648' }} />
  </SvgIcon>
);

const BottomBox = styled(Box)({
  width: '100%',
  position: 'fixed',
  bottom: ['calc(8px + env(safe-area-inset-bottom))', 'calc(8px + constant(safe-area-inset-bottom))']
});
