import React from 'react';

// material-ui
import { Box, Card, FormControlLabel, Grid, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';

// project imports
import { Checkbox, QuantityBox } from './index';
import PropType from 'prop-types';
import { bottleCapacityToString } from 'config';
import { ProductImage, ProductName, ProductPrice } from 'components/product';
import SpaceBetweenBox from 'components/SpaceBetweenBox';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

const IMG_WIDTH = 68; // 상품 썸네일 이미지 너비
const IMG_HEIGHT = 136; // 상품 썸네일 이미지 높이

/**
 * 주문 상품 카드
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderProductCheck({ _products, selectEvent }) {
  const [products, setProducts] = React.useState(_products);

  React.useEffect(() => {
    settingSelectQty();
  }, []);

  // select Quantity 세팅
  const settingSelectQty = () => {
    const obj = {};
    products.forEach((element) => {
      obj[element.id] = { ...element, ok_quantity: element.quantity };
    });
    setSelectedProducts(obj);

    const temp = [];
    products.forEach((product) => {
      temp.push({ ...product, ok_quantity: product.quantity });
    });
    setProducts(temp);
  };

  // 체크박스에 선택된 상품 목록
  const [selectedProducts, setSelectedProducts] = React.useState({});
  // 체크박스에 선택된 상품 총 병 수
  const [selectedQty, setSelectedQty] = React.useState(0);

  React.useEffect(() => {
    getCheckQtyCounts();
  }, [selectedProducts]);

  const getCheckQtyCounts = () => {
    let selectedCount = 0;
    Object.keys(selectedProducts).forEach(function calculateSelectedCount(v) {
      selectedCount += selectedProducts[v].ok_quantity;
    });

    setSelectedQty(selectedCount);
  };

  // 전체선텍 체크박스 클릭 이벤트 콜백
  const allSelectedEvent = () => {
    const _selectedProducts = { ...selectedProducts };
    // 전체 선택
    if (Object.keys(_selectedProducts).length === 0) {
      products.forEach((product) => {
        _selectedProducts[product.id] = { ...product, ok_quantity: product.quantity };
      });
      setProducts((products) => {
        const _products = products;
        _products.forEach((p) => {
          p.ok_quantity = p.quantity;
        });
        return _products;
      });
    }
    // 전체 해제
    else {
      products.forEach((product) => {
        delete _selectedProducts[product.id];
      });
      setProducts((products) => {
        const _products = products;
        _products.forEach((p) => {
          p.ok_quantity = 0;
        });
        return _products;
      });
    }

    setSelectedProducts(_selectedProducts);
  };

  // 상품 체크박스 클릭 이벤트 콜백
  const handleSelectedItem = (checked, product) => {
    const _selectedProducts = { ...selectedProducts };
    // 체크박스 추가
    if (checked) {
      // 체크박스 해제 후 수량이 0 일 때 다시 체크박스 클릭 이벤트
      if (product.ok_quantity === 0) {
        setProducts((products) => {
          const _products = products;
          _products.forEach((p) => {
            if (p.id === product.id) p.ok_quantity = p.quantity;
          });
          return _products;
        });
      }
      _selectedProducts[product.id] = { ...product };
    }
    // 체크박스 해제
    else {
      delete _selectedProducts[product.id];

      setProducts((products) => {
        const _products = products;
        _products.forEach((p) => {
          p.ok_quantity = p.id === product.id ? 0 : p.ok_quantity;
        });
        return _products;
      });
    }

    setSelectedProducts(_selectedProducts);
  };

  // 아이템 수량 변경
  const changeQuantity = (productId, qty) => {
    const copiedProducts = JSON.parse(JSON.stringify(products));

    copiedProducts.find((product) => {
      if (product.id === productId) product.ok_quantity = qty;
      return setProducts(copiedProducts);
    });

    const _selectedProducts = { ...selectedProducts };
    if (_selectedProducts[productId]) {
      _selectedProducts[productId] = { ..._selectedProducts[productId], ok_quantity: qty };
      setSelectedProducts(_selectedProducts);
    }
  };

  React.useEffect(() => {
    selectEvent(selectedProducts);
  }, [selectedProducts]);

  return (
    <Box p="15px 10px 26px 10px" border="1px solid #DCDCDC" borderRadius="4px">
      <Typography fontSize="14px" color="#FF5236" fontWeight="bold">
        판매 불가 상품만 체크를 해지 해 주세요.
      </Typography>
      <SpaceBetweenBox sx={{ mt: 2, mb: 3 }}>
        <SpaceBetweenBox sx={{ '& .MuiButtonBase-root': { paddingLeft: 0 } }}>
          <Checkbox checked={Object.keys(selectedProducts).length > 0} onChange={allSelectedEvent} size="small" />
          <Typography fontSize="13px" color="#333333">
            전체 선택 ({Object.keys(selectedProducts).length}/{products.length ?? '-'})
          </Typography>
        </SpaceBetweenBox>
        <SpaceBetweenBox>
          <Typography fontSize="14px" color="#130328" display="flex">
            총&nbsp;
          </Typography>
          <Typography fontSize="14px" color="#130328" fontWeight={700}>
            {selectedQty}
          </Typography>
          <Typography fontSize="14px" color="#130328" display="flex">
            &nbsp;병
          </Typography>
        </SpaceBetweenBox>
      </SpaceBetweenBox>
      {products.map((product, index) => (
        <Box key={`product-${index}-row`} mb="16px" bgcolor="background.paper" boxShadow="0px 2px 8px #1303281A" borderRadius="10px">
          <Box display="flex" px={1}>
            <FormControlLabel
              sx={{
                m: 0,
                width: '30px',
                height: '30px',
                alignSelf: 'center',
                '& .MuiCheckbox-root': {
                  width: '100%',
                  height: '100%',
                  pl: 0
                }
              }}
              control={
                <Checkbox
                  checked={selectedProducts[product.id] !== undefined}
                  onChange={({ target: { checked } }) => {
                    handleSelectedItem(checked, product);
                  }}
                  size="small"
                  inputProps={{ 'aria-labelledby': 'order-confirm-term' }}
                />
              }
            />
            <Box width="calc(100% - 27px)" sx={{ py: 1, mt: '10px' }}>
              <Card elevation={0}>
                <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
                  <Box sx={{ mt: 0, mr: 1.25 }} minWidth={IMG_WIDTH} width={IMG_WIDTH} height={IMG_HEIGHT}>
                    <ProductImage
                      width="100%"
                      height="100%"
                      visibleByDefault
                      src={product?.pdata?.bottle_img?.thumb ?? wineDefaultImg}
                      alt="와인 이미지"
                    />
                  </Box>
                  <ListItemText
                    disableTypography
                    sx={{
                      m: 0,
                      minHeight: IMG_HEIGHT,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexFlow: 'column'
                    }}
                    primary={
                      // 상품명
                      <ProductName sx={{ mt: 0.5 }}>
                        {product?.name?.ko ? (
                          `${product.name.ko}`
                        ) : (
                          <>
                            <Skeleton />
                            <Skeleton />
                          </>
                        )}
                      </ProductName>
                    }
                    secondary={
                      <Box>
                        <Box pb="3px">
                          {/* 빈티지 & 용량 */}
                          <Box mt={1}>
                            <Typography fontSize="12px" color="#333333E5">
                              {product?.vintage && product.vintage === 'NV' ? product.vintage : product.vintage + '년'}
                              &nbsp;&nbsp;|&nbsp;&nbsp;
                              {bottleCapacityToString(product?.capacity ?? 0)}
                            </Typography>
                          </Box>
                          {/* 상품가격 */}
                          <Box display="flex" justifyContent="flex-start" alignItems="center" height={22}>
                            {!product.price || product.price.original === null ? (
                              <Skeleton width="120px" />
                            ) : (
                              <>
                                <ProductPrice fontSize="16px">{product.price.original.toLocaleString()}원</ProductPrice>
                              </>
                            )}
                          </Box>
                        </Box>
                        {/* 수량선택 */}
                        <Box display="flex" alignItems="center" mt={1}>
                          <QuantityBox
                            value={product.ok_quantity || 0}
                            product={product}
                            max={product.quantity >> 0}
                            onChange={(qty) => {
                              changeQuantity(product.id, qty);
                            }}
                            handleSelectedItem={handleSelectedItem}
                          />
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              </Card>
              <Box mt={1.5}>
                <Grid container>
                  <Grid item>
                    <Typography fontSize="12px" color="#333333E5">
                      요청사항 :
                    </Typography>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography fontSize="12px" color="#FF5236" pl={0.5}>
                      {product.comment === '' ? '없음' : product.comment}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default OrderProductCheck;

OrderProductCheck.propTypes = {
  _products: PropType.array.isRequired,
  selectEvent: PropType.func
};
