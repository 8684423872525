import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { AppBar, IconButton, Dialog, Container, Box, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// project imports
import ProductCreateForm from './components/ProductCreateForm';
import RequestDBForm from './components/RequestDBForm';
import { ToolbarTitle } from 'components/toolbar';

/**
 * 상품추가 & DB 등록 요청 모달
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function ProductCreate({ open: openProp, onClose }) {
  // 화면 타입 (product = 상품추가 / request = DB 등록요청)
  const [type, setType] = React.useState('product');
  // DB요청에 기본 세팅 될 상품명
  const [reqFormQuery, setReqFormQuery] = React.useState('');

  // DB 등록 요청 열기
  const openRequestForm = (qry) => {
    setReqFormQuery(qry);
    setType('request');
  };

  return (
    <Dialog open={openProp} onClose={onClose} fullScreen>
      {/* header */}
      <AppBar position="sticky">
        <Toolbar>
          <ToolbarTitle>{type === 'request' ? 'DB 등록 요청' : '상품추가'}</ToolbarTitle>
          <Box flexGrow={1} />
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* main */}
      <Container>
        <main>
          <Box pt={2}>
            {/* 상품 추가 폼 */}
            {type === 'product' && <ProductCreateForm openRequestForm={(qry) => openRequestForm(qry)} />}

            {/* DB 요청 폼 */}
            {type === 'request' && <RequestDBForm search={reqFormQuery} onClose={() => setType('product')} />}
          </Box>
        </main>
      </Container>
    </Dialog>
  );
}

ProductCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ProductCreate;
