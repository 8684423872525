// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')(
  ({ theme }) => `
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: ${theme.zIndex.appBar + 5},
  width: '100%'
`
);

// ==============================|| LOADER ||============================== //

function Loader() {
  return (
    <LoaderWrapper>
      <LinearProgress color="primary" />
    </LoaderWrapper>
  );
}

export default Loader;
