import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { AppBar, Box, Button, Dialog, FormControlLabel, Radio, RadioGroup, Stack, TextField, Toolbar, Typography } from '@mui/material';

// project imports
import LoadingLayout from 'components/loading/LoadingLayout';
import { WoAlert } from 'utils/kmwine-alerts';
import { ToolbarTitle } from 'components/toolbar';
import * as OrderService from 'services/OrderService';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';

// assets
import BackIcon from 'assets/images/arrow-back.png';

/**
 * 주문상품 상태가 'REQ_EXC'만 접근 가능함
 * 교환요청 승인/거절 컴포넌트
 * @constructs
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqExcAction({ product, reloadOrder }) {
  const location = useLocation();

  // 로딩
  const [loading, setLoading] = React.useState(false);
  // 로딩 텍스트
  const [loadingText, setLoadingText] = React.useState('');

  // 교환 요청 거절 모달
  const [reqNoModal, setReqNoModal] = React.useState(false);
  // 교환 요청 거절 타입
  const [reqNoType, setReqNoType] = React.useState('고객 과실로 인한 상품 훼손');
  // 교환 요청 거절 상세 텍스트
  const [reqNoText, setReqNoText] = React.useState('');
  // 교환 요청 거절 텍스트 필드 글자 수 카운트
  const [fieldCount, setFieldCount] = React.useState(0);

  // 교환요청 승인 컨펌 모달
  const procExcWaitConfirm = () => {
    WoAlert.fire({
      title: '교환 승인 처리',
      html: `<div style='line-height: 1.5rem;'>교환 승인 처리를 진행하시겠습니까?</div>`,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '승인',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        procExcWait();
      }
    });
  };

  // 교환 요청 승인 하기
  const procExcWait = async () => {
    console.log(`[교환승인] 주문번호(${product.oid})의 상품ID(${product.id}) 교환승인 처리 시작`);
    setLoadingText('교환을 승인하는 중 입니다.');
    setLoading(true);

    const body = {
      oid: product.oid,
      order_product_ids: [product.id]
    };

    const result = await OrderService.orderProductReqExcOk(body)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    // 교환 요청 승인 중 오류 발생
    if (result.error) {
      sendCloError('승인', result.error);
      console.error(`[교환승인] 주문번호(${product.oid})의 상품ID(${product.id}) 교환승인 처리 중 오류 발생 > `, result.error);
      WoAlert.fire('교환 승인', '교환 승인 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError('승인', msg);
      console.error(`[교환승인] 주문번호(${product.oid})의 상품ID(${product.id}) 교환승인 처리 중 오류 발생 > `, msg);
      WoAlert.fire('교환 승인', `교환 승인 처리 중 오류가 발생했습니다.<br />[${msg}]<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    console.log(`[교환승인] 주문번호(${product.oid})의 상품ID(${product.id}) 교환승인 처리 완료`);
    WoAlert.fire('교환 승인', '교환 승인 처리가 완료되었습니다.', 'success').then(() => {
      reloadOrder();
    });
  };

  // 교환 요청 거절 모달 열기
  const closeReqNoModal = () => {
    setReqNoModal(false);
    setReqNoType('고객 과실로 인한 상품 훼손');
    setReqNoText('');
    setFieldCount(0);
  };

  // 교환 요청 거절 텍스트
  const getReqNoText = (e) => {
    const val = e.target.value;
    setReqNoText(val);
    setFieldCount(val.length);
  };

  // 교환 요청 거절 하기
  const procReqNo = async () => {
    console.log(`[교환 거절] 주문번호(${product.oid})의 상품ID(${product.id}) 교환거절 처리 시작`);

    if (reqNoType === '기타' && reqNoText.length === 0) {
      WoAlert.fire('교환 거절', '교환 거절 사유를 입력해주세요', 'error');
      return false;
    }

    setLoadingText('교환 요청을 거절하는 중 입니다.');
    setLoading(true);

    const body = {
      oid: product.oid,
      order_product_ids: [product.id],
      msg: reqNoType === '기타' ? reqNoText : reqNoType
    };

    const result = await OrderService.orderProductReqExcNo(body)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    // 교환 요청 거절 중 오류 발생
    if (result.error) {
      sendCloError('거절', result.error);
      console.error(`[교환거절] 주문번호(${product.oid})의 상품ID(${product.id}) 교환거절 처리 중 오류 발생 > `, result.error);
      WoAlert.fire('교환 거절', '교환 거절 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError('거절', msg);
      console.error(`[교환거절] 주문번호(${product.oid})의 상품ID(${product.id}) 교환거절 처리 실패 > `, msg);
      WoAlert.fire('교환 거절', `교환 거절 처리 중 오류가 발생했습니다.<br />[${msg}]<br />잠시 후 다시 시도해주세요.<br />`, 'error');
      return false;
    }

    console.log(`[교환 거절] 주문번호(${product.oid})의 상품ID(${product.id}) 교환거절 처리 성공`);
    WoAlert.fire('교환 거절', '교환 거절 처리가 완료되었습니다.', 'success').then(() => {
      reloadOrder();
    });
  };

  // CLO 에러 전송
  const sendCloError = (type, errorMsg) => {
    // clo
    const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
    sendCloError({
      code: CLO_CODE.ORDER_REQUEST_ERROR,
      title: `[VENDOR APP] 주문 상품 교환 ${type} 요청 오류 [oid=${product.oid} / orderProductId=${product.id}] [error=${errorMsg}]`,
      msg: `${JSON.stringify(errorMsg)}`,
      which: `${location.pathname}${location.search}`
    })
      .then(console.log)
      .catch(console.error);
  };

  return (
    <>
      <LoadingLayout open={loading} text={loadingText} />
      <Box
        width="100%"
        height="70px"
        padding="10px 20px"
        position="fixed"
        bottom={0}
        right={0}
        bgcolor="#ffffff"
        boxShadow="0 15px 20px 3px rgba(0,0,0,0.8)"
      >
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              height: '50px',
              border: 'none',
              backgroundColor: '#E4E3DE',
              borderRadius: 0,
              color: '#000000',
              fontWeight: 700,
              fontSize: '16px'
            }}
            onClick={() => setReqNoModal(true)}
          >
            거절하기
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: '50px',
              backgroundColor: '#130328',
              borderRadius: '25px',
              color: '#ffffff',
              fontWeight: 700,
              fontSize: '16px'
            }}
            onClick={() => procExcWaitConfirm()}
          >
            승인하기
          </Button>
        </Stack>
      </Box>

      {/* 교환 거절 모달 */}
      <Dialog open={reqNoModal} fullWidth fullScreen>
        {/* HEADER */}
        <AppBar position="sticky">
          <Toolbar sx={{ padding: '10px 20px' }}>
            <Box component="img" src={BackIcon} width={34} height={34} onClick={() => closeReqNoModal()} />
            <ToolbarTitle>교환 거절</ToolbarTitle>
          </Toolbar>
        </AppBar>

        {/* CONTENTS */}
        <Box padding="10px 20px">
          <Typography component="span" fontSize="16px" fontWeight={600} pl={0.5}>
            교환 거절 사유를 입력 해 주세요.
          </Typography>

          {/* radioGroup */}
          <Box mt={3}>
            <RadioGroup name="rejectType" value={reqNoType} onChange={(e) => setReqNoType(e.target.value)}>
              <FormControlLabel
                value="고객 과실로 인한 상품 훼손"
                control={<Radio style={{ color: '#140229' }} />}
                label={<Typography component="span">고객 과실로 인한 상품 훼손</Typography>}
                sx={{
                  marginBottom: '18px',
                  '& .MuiTypography-root': {
                    fontSize: '16px'
                  }
                }}
              />
              <FormControlLabel
                value="기타"
                control={<Radio style={{ color: '#140229' }} />}
                label={<Typography component="span">기타 (직접입력)</Typography>}
                sx={{
                  marginBottom: '18px',
                  '& .MuiTypography-root': {
                    fontSize: '16px'
                  }
                }}
              />
            </RadioGroup>

            {/* textField */}
            {reqNoType === '기타' && (
              <Box>
                <Box>
                  <TextField
                    multiline
                    rows={4}
                    sx={{ mb: 1 }}
                    inputProps={{ maxLength: 200 }}
                    fullWidth
                    value={reqNoText}
                    onChange={(e) => getReqNoText(e)}
                  />
                </Box>
                <Box textAlign="right">
                  <Typography component="span">{fieldCount}/200</Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Button Group */}
          <Box mt={3}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                fullWidth
                disableElevation
                sx={{
                  height: '50px',
                  border: 'none',
                  backgroundColor: '#E4E3DE',
                  borderRadius: 0,
                  color: '#000000',
                  fontWeight: 700,
                  fontSize: '16px'
                }}
                onClick={() => closeReqNoModal()}
              >
                취소
              </Button>
              <Button
                variant="contained"
                disabled={reqNoType === '기타' && reqNoText.length === 0}
                disableElevation
                fullWidth
                sx={{
                  height: '50px',
                  backgroundColor: '#130328',
                  borderRadius: '25px',
                  color: '#ffffff',
                  fontWeight: 700,
                  fontSize: '16px'
                }}
                onClick={() => procReqNo()}
              >
                거절하기
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ReqExcAction;

ReqExcAction.propTypes = {
  product: PropTypes.object,
  reloadOrder: PropTypes.func
};
