import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Typography } from '@mui/material';
import { WineBottleLoadingLottie } from '../spinner';

function LoadingLayout({ open, text }) {
  return (
    <Backdrop open={open} sx={{ zIndex: 5001 }}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <WineBottleLoadingLottie />
        <Typography color="#ffffff">{text}</Typography>
      </Box>
    </Backdrop>
  );
}

export default LoadingLayout;

LoadingLayout.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string
};
