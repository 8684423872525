import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// material-ui
import { Box, Chip, Stack, Typography } from '@mui/material';

// project imports
import { ShadowBox } from 'components';
import OrderChip from './OrderChip';

/**
 * 주문 내역 아이템
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderItem({ order }) {
  // 날짜 포맷
  const dateToString = (val) => {
    return moment.utc(val).format('YYYY년 MM월 DD일 (ddd) a hh:mm');
  };

  // 픽업일시
  const pickupTime = React.useMemo(() => {
    if (!order) return;

    const { promotion_id } = order;

    if (!promotion_id) return moment.utc(order?.pickup_date_time).format('YYYY년 MM월 DD일 (ddd) a hh:mm');

    if (promotion_id) {
      return moment.utc(order?.promotion.pickup.end_dt).format('YYYY년 MM월 DD일 (ddd)');
    }
  }, [order]);

  // 교환/반품 칩 표현
  const [excRtnChip, setExcRtnChip] = React.useState({ loading: true, excChip: false, rtnChip: false });

  // 교환/반품 칩 표현 여부
  React.useEffect(() => {
    const orderStates = order.order_product_states;
    // 주문 상품 중 교환 요청/대기 상품이 있는지 확인
    const excChip = orderStates.some((state) => ['REQ_EXC', 'EXC_WAIT'].includes(state));
    // 주문 상품 중 반품 요청/대기/완료 상품이 있는지 확인
    const rtnChip = orderStates.some((state) => ['REQ_RTN', 'RTN_WAIT', 'RTN_OK'].includes(state));

    setExcRtnChip({ loading: false, excChip, rtnChip });
  }, [order]);

  const orderProductChip = React.useMemo(() => {
    if (!order) return;

    const { state_part } = order;

    let label;
    let bgcolor = '#94D210';
    const color = '#FFF';
    if (state_part === 'REQ') {
      label = '결제완료';
    } else if (state_part === 'REQ_OK') {
      label = '전체승인';
    } else if (state_part === 'REQ_PART_OK') {
      label = '부분승인';
    } else if (state_part === 'REQ_NO') {
      label = '전체거절';
      bgcolor = '#000';
    } else if (state_part === 'PICK_OK') {
      label = '픽업완료';
    } else if (state_part === 'CANCEL') {
      label = '주문취소';
      bgcolor = '#000';
    } else if (state_part === 'COBUYING_OK') {
      label = '픽업대기';
    } else if (state_part === 'PRM_REQ_OK') {
      label = '예약판매 진행 중';
      bgcolor = '#3CAE47';
    } else if (state_part === 'PRM_RSTATE_WAIT') {
      label = '주문마감(입고대기)';
      bgcolor = '#3CAE47';
    } else if (state_part === 'PRM_RSTATE_CONFIRM') {
      label = '주문마감(입고대기)';
      bgcolor = '#3CAE47';
    } else if (state_part === 'PRM_RSTATE_OK') {
      label = '픽업대기';
    } else {
      label = 'ERROR';
    }

    return <OrderChip label={label} color={color} bgcolor={bgcolor} />;
  }, [order]);

  // 프로모션 칩 표현 여부
  const platformPromoChip = React.useMemo(() => {
    if (!order) return;

    const { promotion_id } = order;

    if (!promotion_id) return null;

    const { type } = order.promotion;
    if (!type) return;

    if (type === 'reserve') return <OrderChip label="예약판매" color="#FFF" bgcolor="#3CAE47" />;
    if (type === 'product') return <OrderChip label="프로모션" color="#FFF" bgcolor="#9357E5" />;
  }, [order]);

  // render
  return (
    <ShadowBox sx={{ boxShadow: '0px 2px 3px #0000000A', p: '10px 16px' }}>
      {/* 구매자 명 & 주문 상태 & 교환/반품 상태 */}
      <Box display="flex" alignItems="center">
        <Typography color="#333333" fontWeight="bold" fontSize="14px">
          {order?.user__uname}
        </Typography>
        {/* 공동구매 칩 */}
        {order?.cobuy_id && <OrderChip label="공동구매" color="#fff" bgcolor="#9357E5" />}
        {platformPromoChip}
        {/* 주문 상태 칩 */}
        {orderProductChip}
        {/* 교환/반품 상태 칩 */}
        {!excRtnChip.loading && (
          <Stack direction="row" spacing="4px" ml="6px">
            {excRtnChip.excChip && (
              <Chip
                label="교환"
                sx={{ width: '60px', height: 'auto', py: '2px', fontSize: '11px', color: '#fff', bgcolor: '#F7A600', lineHeight: 'normal' }}
              />
            )}
            {excRtnChip.rtnChip && (
              <Chip
                label="반품"
                sx={{ width: '60px', height: 'auto', py: '2px', fontSize: '11px', color: '#fff', bgcolor: '#F5555A', lineHeight: 'normal' }}
              />
            )}
          </Stack>
        )}
      </Box>

      {/* 상품명 */}
      <Box mt={0.5}>
        <Typography color="#333333" fontWeight="bold" fontSize="14px">
          {order?.goods_name}
        </Typography>
      </Box>

      <Box mt={1}>
        <Stack spacing={0.5}>
          {/* 주문일시 */}
          <Typography color="#33333399" fontSize="12px">
            주문일시 : {dateToString(order?.created_at)}
          </Typography>
          {/* 픽업일시 */}
          <Typography color="#33333399" fontSize="12px">
            픽업일시 : {pickupTime}
          </Typography>
        </Stack>
      </Box>
    </ShadowBox>
  );
}

export default OrderItem;

OrderItem.propTypes = {
  order: PropTypes.object.isRequired
};
