import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

// material-ui
import { Box, CircularProgress } from '@mui/material';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';

/**
 * 상품 이미지
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ProductImage = (props) => {
  const { width = 117, height = 162, src: srcProp = null } = props;

  // 이미지 src
  const [src, setSrc] = React.useState(srcProp);

  React.useEffect(() => {
    setSrc(srcProp ?? wineDefaultImg);
  }, [srcProp]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setSrc(wineDefaultImg); // Replace to default wine product image.
  }, []);

  const imgComponent = React.useMemo(
    () =>
      src ? (
        <LazyLoadImage
          wrapperClassName="product-image-wrapper"
          src={src}
          alt="상품 이미지"
          placeholder={<CircularProgress size={8} />}
          visibleByDefault
          effect="blur"
          width="100%"
          height="100%"
          draggable={false}
          onError={onError}
        />
      ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="brand" size={8} />
        </Box>
      ),
    [width, height, src]
  );

  // render
  return (
    <Box
      sx={{
        width,
        height,
        bgcolor: '#F4F0F8',
        p: '12px 5px 11.2px',
        borderRadius: '6px',
        display: 'inline-block',
        overflow: 'hidden',
        position: 'relative'
      }}
      draggable={false}
    >
      {imgComponent}
    </Box>
  );
};

export default ProductImage;

ProductImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string
};
