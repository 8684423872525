import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';

// material-ui
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Stack, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@mui/lab';

// project imports
import OrderChip from 'components/chip/OrderChip';
import { WoAlert } from 'utils/kmwine-alerts';
import { SelectArrow, TimelineAfterIcon, TimelineBeforeIcon, TimelineNowIcon } from 'components/icons';
import { bottleCapacityToString } from 'config';
import { collection, getDocs } from 'firebase/firestore';
import { firebaseDB } from 'index';

/**
 * 픽업대기목록 타임라인
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PickupTimeline({ pickLateRows, todayPickRows, futurePickRows }) {
  const navigate = useNavigate();

  // 클릭한 주문건의 주문상품 정보 조회 로딩
  const [loading, setLoading] = React.useState(false);

  // 주문상품 목록
  const [productRows, setProductRows] = React.useState([]);
  // 주문상품 개수
  const [productCounts, setProductCounts] = React.useState(0);

  // 아코디언 열림여부
  const [expanded, setExpanded] = React.useState(false);

  // 아코디언 열림/닫힘
  const handleChange = (panel, oid) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setLoading(true);

      const productsSnapshot = await getDocs(collection(firebaseDB, `order/${oid}/order_products`)).catch((error) => ({ error }));

      // 주문 상품 목록 조회 중 오류 발생
      if (productsSnapshot.error) {
        WoAlert.fire('주문 상품', '주문상품 조회 중 오류가 발생했습니다.', 'error');
        return false;
      }

      const { docs, empty } = productsSnapshot;

      // 주문 상품이 조회되지 않을 경우
      if (empty) {
        WoAlert.fire('주문 상품', '주문상품 데이터가 올바르지 않습니다.', 'error');
        return false;
      }

      const tempProducts = [];
      let totalCounts = 0;
      docs.forEach((d) => {
        if (d.data().quantity > 0) tempProducts.push(d.data());
        totalCounts += d.data().quantity;
      });

      setProductRows(tempProducts);
      setProductCounts(totalCounts);
      await setLoading(false);
    }
  };

  // render
  return (
    <Timeline id="visit-timeline" style={{ paddingBottom: '110px' }}>
      {/* 픽업지연 */}
      {pickLateRows.length > 0 && (
        <TimelineItem sx={{ pb: 2 }}>
          <TimelineSeparator>
            <Box margin={'6.5px 0'}>
              <TimelineBeforeIcon />
            </Box>
            <TimelineConnector sx={{ backgroundColor: '#13032833', width: '1px' }} />
          </TimelineSeparator>
          <TimelineContent>
            <Box mb={1}>
              <Typography component="span" color={'#13032866'}>
                픽업지연
              </Typography>
            </Box>
            {pickLateRows.map((l, index) => {
              const { cobuy_state = 'NONE' } = l;
              const coBuy = cobuy_state !== 'NONE';

              // 플랫폼 프로모션 (RESERVE 타입)
              const platformPromotionReserve = l?.promotion_id && l?.promotion_type === 'reserve';
              // 플랫폼 프로모션 (PRODUCT 타입)
              const platformPromotionProduct = l?.promotion_id && l?.promotion_type === 'product';

              return (
                <Accordion
                  key={index}
                  expanded={expanded === 'pickup_late' + index}
                  onChange={handleChange('pickup_late' + index, l.oid)}
                  sx={{
                    marginTop: '11px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 0px 26px #1303281A',
                    border: '1px solid #ffffff',
                    borderRadius: '10px !important',
                    '& .Mui-expanded': {
                      margin: 0
                    },
                    '&:before': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<SelectArrow />}>
                    <Box>
                      <Box display="flex" alignItems="center" mb={1} mr={4} onClick={() => navigate(`/order/${l.oid}`)}>
                        <Typography component="span" fontWeight={600} mr={1}>
                          {l.user__uname}
                        </Typography>
                        {coBuy && <OrderChip val="CO_BUY" />}
                        {platformPromotionReserve && <OrderChip val="PLATFORM_PROMOTION_RESERVE" />}
                        {platformPromotionProduct && <OrderChip val="PLATFORM_PROMOTION_PRODUCT" />}
                        <OrderChip val={l.state} />
                        <OrderChip val="PICK_LATE" />
                      </Box>
                      <Box onClick={() => navigate(`/order/${l.oid}`)} mr={2}>
                        <Typography className="line-1-ellipsis" variant="body2" color="#33333399">
                          {l.goods_name}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box borderTop="1px solid #CBCBCB">
                      <Box mt={1}>
                        {loading ? (
                          <Skeleton variant="rectangular" height="21px" />
                        ) : (
                          <Typography variant="body1" fontWeight={600}>
                            상품 요약 (총 {productCounts.toLocaleString('ko-KR')}개)
                          </Typography>
                        )}
                        {loading ? (
                          <Skeleton variant="rectangular" height="21px" />
                        ) : (
                          productRows.map((p, index) => (
                            <Typography key={index} mb={0.5} variant="body2" color="#33333399">
                              {p.name.ko} {`${bottleCapacityToString(p.capacity)}`}/{`${p.vintage}년`} {p.quantity}개
                            </Typography>
                          ))
                        )}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </TimelineContent>
        </TimelineItem>
      )}

      {/* 현재 */}
      {Object.keys(todayPickRows).length > 0 &&
        Object.entries(todayPickRows).map(([key, value], rowIndex) => {
          return (
            <TimelineItem sx={{ pb: 2 }} key={rowIndex}>
              <TimelineSeparator className="select">
                <Box margin={'6.5px 0'}>
                  <TimelineNowIcon />
                </Box>
                <TimelineConnector
                  sx={{
                    backgroundColor: '#13032833',
                    width: '1px',
                    mb: rowIndex + 1 === Object.keys(todayPickRows).length ? 0 : '-26px',
                    mt: '-14px'
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Box mb={1}>
                  <Stack direction="row" spacing={1}>
                    <Typography component="span" color={'#130328'} fontWeight={600}>
                      {moment(key, 'a hh:mm').format('a hh:mm')}
                    </Typography>
                    <Typography component="span" color={'#130328B2'}>
                      {rowIndex === 0 && moment(key, 'A hh:mm YYYY. MM. DD dddd').format('YYYY. MM. DD dddd')}
                    </Typography>
                  </Stack>
                </Box>
                {value.map((t, index) => {
                  const { cobuy_state = 'NONE', promotion_id, promotion_type } = t;

                  // 공동구매 주문 플래그
                  const coBuy = cobuy_state !== 'NONE';
                  // 플랫폼 프로모션 (RESERVE 타입)
                  const platformPromotionReserve = promotion_id && promotion_type === 'reserve';
                  // 플랫폼 프로모션 (PRODUCT 타입)
                  const platformPromotionProduct = promotion_id && promotion_type === 'product';

                  return (
                    <Accordion
                      key={index}
                      expanded={expanded === `pickup-today-${rowIndex}-${index}`}
                      onChange={handleChange(`pickup-today-${rowIndex}-${index}`, t.oid)}
                      sx={{
                        marginTop: '11px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 0px 26px #1303281A',
                        border: '1px solid #ffffff',
                        borderRadius: '10px !important',
                        '& .Mui-expanded': {
                          margin: 0
                        },
                        '&:before': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      <AccordionSummary expandIcon={<SelectArrow />}>
                        <Box>
                          <Box display="flex" alignItems="center" mb={1} mr={4} onClick={() => navigate(`/order/${t.oid}`)}>
                            <Typography component="span" fontWeight={600} mr={1}>
                              {t.user__uname}
                            </Typography>
                            {coBuy && <OrderChip val="CO_BUY" />}
                            {platformPromotionReserve && <OrderChip val="PLATFORM_PROMOTION_RESERVE" />}
                            {platformPromotionProduct && <OrderChip val="PLATFORM_PROMOTION_PRODUCT" />}
                            <OrderChip val={t.state_part} />
                          </Box>
                          <Box onClick={() => navigate(`/order/${t.oid}`)} mr={2}>
                            <Typography
                              className="line-1-ellipsis"
                              variant="body2"
                              color="#33333399"
                              sx={{ textDecoration: t.state === 'CANCEL' && 'line-through' }}
                            >
                              {t.goods_name}
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box borderTop="1px solid #CBCBCB">
                          <Box mt={1}>
                            {loading ? (
                              <Skeleton variant="rectangular" height="21px" />
                            ) : (
                              <Typography variant="body1" fontWeight={600}>
                                상품 요약 (총 {productCounts.toLocaleString('ko-KR')}개)
                              </Typography>
                            )}
                            {loading ? (
                              <Skeleton variant="rectangular" height="21px" />
                            ) : (
                              productRows.map((p, index) => (
                                <Typography
                                  key={index}
                                  mb={0.5}
                                  variant="body2"
                                  color="#33333399"
                                  sx={{ textDecoration: p.state === 'CANCEL' && 'line-through' }}
                                >
                                  {p.name.ko} {`${bottleCapacityToString(p.capacity)}`}/{`${p.vintage}년`} {p.quantity}개
                                </Typography>
                              ))
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </TimelineContent>
            </TimelineItem>
          );
        })}

      {/* 미래 */}
      {Object.keys(futurePickRows).length > 0 &&
        Object.entries(futurePickRows).map(([key, value], rowIndex) => {
          return (
            <TimelineItem sx={{ pb: 2 }} key={`future-pick-list-${rowIndex}-row`}>
              <TimelineSeparator>
                <Box margin={'5.5px 0'}>
                  <TimelineAfterIcon />
                </Box>
                <TimelineConnector sx={{ backgroundColor: '#13032833', width: '1px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Box mb={1}>
                  <Typography component="span" color={'#130328B2'}>
                    {key}
                  </Typography>
                </Box>
                {value.map((f, index) => {
                  const { cobuy_state = 'NONE', promotion_id, promotion_type } = f;

                  // 공동구매 주문 플래그
                  const coBuy = cobuy_state !== 'NONE';
                  // 플랫폼 프로모션 (RESERVE 타입)
                  const platformPromotionReserve = promotion_id && promotion_type === 'reserve';
                  // 플랫폼 프로모션 (PRODUCT 타입)
                  const platformPromotionProduct = promotion_id && promotion_type === 'product';

                  return (
                    <Accordion
                      key={index}
                      expanded={expanded === `pickup-future-${rowIndex}-${index}`}
                      onChange={handleChange(`pickup-future-${rowIndex}-${index}`, f.oid)}
                      sx={{
                        marginTop: '11px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 0px 26px #1303281A',
                        border: '1px solid #ffffff',
                        borderRadius: '10px !important',
                        '& .Mui-expanded': {
                          margin: 0
                        },
                        '&:before': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      <AccordionSummary expandIcon={<SelectArrow />}>
                        <Box>
                          <Box display="flex" alignItems="center" mb={1} mr={4} onClick={() => navigate(`/order/${f.oid}`)}>
                            <Typography component="span" fontWeight={600} mr={1}>
                              {f.user__uname}
                            </Typography>
                            {coBuy && <OrderChip val="CO_BUY" />}
                            {platformPromotionReserve && <OrderChip val="PLATFORM_PROMOTION_RESERVE" />}
                            {platformPromotionProduct && <OrderChip val="PLATFORM_PROMOTION_PRODUCT" />}
                            <OrderChip val={f.state_part} />
                          </Box>
                          <Box onClick={() => navigate(`/order/${f.oid}`)} mr={2}>
                            <Typography
                              className="line-1-ellipsis"
                              variant="body2"
                              color="#33333399"
                              sx={{ textDecoration: f.state === 'CANCEL' && 'line-through' }}
                            >
                              {f.goods_name}
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box borderTop="1px solid #CBCBCB">
                          <Box mt={1}>
                            {loading ? (
                              <Skeleton variant="rectangular" height="21px" />
                            ) : (
                              <Typography variant="body1" fontWeight={600}>
                                상품 요약 (총 {productCounts.toLocaleString('ko-KR')}개)
                              </Typography>
                            )}
                            {loading ? (
                              <Skeleton variant="rectangular" height="21px" />
                            ) : (
                              productRows.map((p, index) => (
                                <Typography
                                  key={index}
                                  variant="body2"
                                  color="#33333399"
                                  mb={0.5}
                                  sx={{ textDecoration: f.state === 'CANCEL' && 'line-through' }}
                                >
                                  {p.name.ko} {`${bottleCapacityToString(p.capacity)}`}/{`${p.vintage}년`} {p.quantity}개
                                </Typography>
                              ))
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
}

export default PickupTimeline;

PickupTimeline.propTypes = {
  pickLateRows: PropTypes.array,
  todayPickRows: PropTypes.object,
  futurePickRows: PropTypes.object
};
