/**
 * FIREBASE API HOSTNAME
 */
export const FIREBASE_HOSTNAME = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;

const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

export const storage_bucket = {
  common: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}common.1kmwine.com`,
  vendor: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}vendor.1kmwine.com`,
  pdata: `${SERVICE_TYPE === 'production' ? '' : 'dev.'}pdata.1kmwine.com`
};

/**
 * 와인용량 number to string
 * @param {number} capacity 용량
 * @returns {string} 단위화된 용량
 */
export const bottleCapacityToString = (capacity) => {
  const _capacity = typeof capacity !== 'number' ? Number(capacity) : capacity;
  if (Number.isNaN(_capacity)) {
    // console.warn(`[Calculator:formatCapacity#1] 와인용량 formatting 실패. [capacity='${capacity}']`);
    return '';
  }
  let str;
  try {
    if (_capacity >= 1000) {
      if (_capacity % 1000 === 0) {
        // 1000, 2000, 3000 -> 1L, 2L, 3L
        str = `${(_capacity / 1000).toFixed(0)}L`;
      } else {
        // 1250 -> 1.2L
        // 1025 -> 1.02L
        str = `${(_capacity / 1000).toFixed(_capacity % 1000 >= 100 ? 1 : 2)}L`;
      }
    } else {
      str = `${_capacity}ml`;
    }
  } catch (e) {
    console.warn(`[Calculator:formatCapacity#2] 와인용량 formatting 실패. [capacity='${capacity}']`);
    str = '';
  }

  return str;
};

/**
 * product_type 상품 유형
 */
export const PRODUCT_TYPES = Object.freeze({
  NORMAL: {
    name: '일반상품',
    value: 'NORMAL'
  },
  TDBOTTLE: {
    name: '오늘의 한병',
    value: 'TDBOTTLE'
  },
  COBUY: {
    name: '공동구매',
    value: 'COBUY'
  }
});

/**
 * 상품 빈티지
 * 범위 : 1900 ~ 현재년도 + 1
 *
 * @returns {[]}
 */
export const WINE_VINTAGES = (() => {
  const date = new Date();
  const year = date.getFullYear();

  const START_VINTAGE = 1900;
  const END_VINTAGE = year + 1;

  const vintageSection = [];
  vintageSection.push({ value: 'NV', label: 'NV' });

  for (let i = END_VINTAGE; i >= START_VINTAGE; i -= 1) {
    const strYear = String(i);
    vintageSection.push({ value: strYear, label: strYear });
  }

  return vintageSection;
})();

/**
 * 개발관련
 * @type {{CONSOLE: {LABEL_STYLE: string}}}
 */
export const DEV = {
  CONSOLE: {
    LABEL_STYLE: 'color: purple; background:pink; padding: 0 4px;'
  }
};

export const CLO_CODE = {
  /* 7200 - 주요화면 오류 */
  /** 주요 컨텐츠 관련 오류 */
  IMPORTANT_CONTENT_ERROR: 7200,
  /** 주문관련 오류 */
  ORDER_REQUEST_ERROR: 7500,
  ORDER_LOAD_ERROR: 7201,
  ORDER_REFUND_FETCH_ERROR: 7301,
  ORDER_REFUND_COMMISION_FETCH_ERROR: 7302,
  /** 알 수 없거나 정의되지 않은 기타 에러 */
  UNEXPECTED_ERROR: 7999
};

const categoryTypes = {};

export const PDATA_CATEGORY = Object.freeze({
  /** 와인 */
  WINE: { value: 'wine', label: { ko: '와인', en: 'Wine' } },
  /** 위스키 */
  WHISKEY: { value: 'whiskey', label: { ko: '위스키', en: 'Whiskey' } },
  /** 일본소주 */
  NIHONSHU: { value: 'nihonshu', label: { ko: '일본주', en: 'Nihonshu' } },
  /** 티켓 */
  TICKET: { value: 'ticket', label: { ko: '티켓', en: 'Ticket' } },
  /** 기획상품 */
  OFFER: { value: 'offer', label: { ko: '기획상품', en: 'Offer' } }
});

/** 와인타입 */
export const WINE_TYPES = Object.freeze({
  /** 레드 */
  RED: { value: 'RED', label: { ko: '레드', en: 'Red' } },
  WHITE: { value: 'WHITE', label: { ko: '화이트', en: 'White' } },
  SPARKLING: { value: 'SPARKLING', label: { ko: '스파클링', en: 'Sparkling' } },
  ROSE: { value: 'ROSE', label: { ko: '로제', en: 'Rose' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 위스키 타입 */
export const WHISKEY_TYPES = Object.freeze({
  SCOTCH: { value: 'SCOTCH', label: { ko: '스카치', en: 'Scotch' } },
  SINGLE_MALT: { value: 'SINGLE_MALT', label: { ko: '싱글 몰트', en: 'Single malt' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 일본소주 타입 */
export const NIHONSHU_TYPES = Object.freeze({
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

/** 티켓 타입 */
export const TICKET_TYPES = Object.freeze({
  TASTING: { value: 'TASTING', label: { ko: '시음회', en: 'Tasting' } },
  CLASS: { value: 'CLASS', label: { ko: '클래스', en: 'Class' } },
  ETC: { value: 'ETC', label: { ko: '기타', en: 'Etc' } }
});

// 와인(wine)
categoryTypes[PDATA_CATEGORY.WINE.value] = [];
Object.keys(WINE_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.WINE.value].push(WINE_TYPES[key]);
});

// 위스키(whiskey)
categoryTypes[PDATA_CATEGORY.WHISKEY.value] = [];
Object.keys(WHISKEY_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.WHISKEY.value].push(WHISKEY_TYPES[key]);
});

// 일본소주(nihonshu)
categoryTypes[PDATA_CATEGORY.NIHONSHU.value] = [];
Object.keys(NIHONSHU_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.NIHONSHU.value].push(NIHONSHU_TYPES[key]);
});

// 티켓(ticket)
categoryTypes[PDATA_CATEGORY.TICKET.value] = [];
Object.keys(TICKET_TYPES).forEach((key) => {
  categoryTypes[PDATA_CATEGORY.TICKET.value].push(TICKET_TYPES[key]);
});

export const CATEGORY_TYPES = Object.freeze(categoryTypes);
