import React from 'react';
import PropTypes from 'prop-types';

// project imports
import vendorDefaultImg from 'assets/images/default_vendor.png';
import { useNavigationType } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// material-ui
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  frame: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    display: 'inline-block',
    overflow: 'hidden',
    padding: '0',
    position: 'relative'
  }
}));

/**
 * 와인샵 이미지 V2
 *
 * @authors 조현권<hkcho@wineone.io>
 */
function VendorImage({ children, width, height, src: srcProp = vendorDefaultImg, wrapperProps = {}, borderRadius = '10px', ...others }) {
  const navigationType = useNavigationType();
  const classes = useStyles();

  const [src, setSrc] = React.useState(srcProp);
  React.useLayoutEffect(() => {
    setSrc(srcProp);
  }, [srcProp]);

  // 이미지 조회 실패시 기본 상품 이미지로 교체
  const onError = React.useCallback(() => {
    setSrc(vendorDefaultImg); // Replace to default wine product image.
  }, []);

  const imgComponent = React.useMemo(
    () =>
      src ? (
        <LazyLoadImage
          wrapperClassName="vendor-image-wrapper"
          src={src}
          alt={others.alt ?? '와인샵 이미지'}
          placeholder={<CircularProgress size={8} />}
          visibleByDefault={navigationType === 'POP'}
          effect={navigationType === 'POP' ? 'none' : 'blur'}
          width="100%"
          height="100%"
          wrapperProps={wrapperProps}
          draggable={false}
          onError={onError}
          style={{ borderRadius }}
        />
      ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="brand" size={8} />
        </Box>
      ),
    [width, height, src]
  );

  return (
    <Box
      className={classes.frame}
      width={width}
      minWidth={width}
      height={height}
      minHeight={height}
      borderRadius={borderRadius}
      draggable={false}
      sx={{ overflow: 'hidden' }}
    >
      {imgComponent}
      {children}
    </Box>
  );
}

// export
export default VendorImage;

VendorImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperProps: PropTypes.object,
  children: PropTypes.node
};
