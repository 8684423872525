import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// 상품명
const ProductName = styled((props) => <Typography className={'product-name'} {...props} />)(({ noWrap = true }) => ({
  textAlign: 'left',
  fontSize: '14px',
  color: '#333333',
  lineHeight: 'normal',
  display: noWrap ? '-webkit-box' : 'block',
  overflow: noWrap ? 'hidden' : 'inherit',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  // WebkitLineClamp: 2,
  wordBreak: 'break-all'
}));

export default ProductName;
