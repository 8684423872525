import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, Typography } from '@mui/material';

// projects import
import { ProductCard } from './index';

/**
 * 승인 된 상품 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ApprovalList({ order, products }) {
  return (
    <Box p="16px" border="1px solid #DCDCDC" borderRadius="4px">
      <Typography fontSize="14px" color="#130328" fontWeight="bold">
        승인 내역
      </Typography>
      <Stack spacing={2.25} mt={2}>
        {products.length > 0 ? (
          products.map((product, index) => <ProductCard key={`product-${index}-row`} order={order} product={product} type="APPROVAL" />)
        ) : (
          <Typography color="#333333" fontSize="12px">
            내역이 없습니다.
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default ApprovalList;

ApprovalList.propTypes = {
  order: PropTypes.object,
  products: PropTypes.array
};
