import React, { useState } from 'react';
import { AppBar, Box, Typography, Switch, styled } from '@mui/material';
import Header from 'components/header/Header';
import palette_ from 'assets/scss/_palette.scss';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 54,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: '#DEFD9A',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#220348',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '11px',
      fontFamily: palette_.font_family,
      fontWeight: '500'
    },
    '&:before': {
      content: '"ON"',
      color: '#fff',
      left: 7
    },
    '&:after': {
      content: '"OFF"',
      color: '#fff',
      right: 5
    },
    backgroundColor: theme.palette.mode === 'light' ? '#A19AA9' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function SettingPush() {
  const [pushState, setPushState] = useState(true);
  const pushStateChange = () => {
    setPushState(!pushState);
  };

  return (
    <Box>
      {/* HEADER */}
      <AppBar position="sticky">
        <Header title={'푸시알림'} />
      </AppBar>
      {/* CONTENTS */}
      <Box sx={{ backgroundColor: '#FAF5FF', padding: 2 }}>
        <Typography variant={'span'} fontSize={16} fontWeight={600}>
          푸시알림
        </Typography>
      </Box>
      <Box sx={{ padding: 2 }} display={'flex'} justifyContent={'space-between'}>
        <Typography varient={'span'} fontSize={16} fontWeight={500}>
          푸시알림 받기
        </Typography>
        <Box sx={{ display: 'flex-end' }}>
          <IOSSwitch checked={pushState} onChange={pushStateChange} />
        </Box>
      </Box>
    </Box>
  );
}

export default SettingPush;
