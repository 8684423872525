import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { AppBar, Backdrop, Box, Button, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material';
import BackIcon from 'assets/images/arrow-back.png';
import { ToolbarTitle } from 'components/toolbar';
import { WoDivider } from 'components/dialog';

// firebase
import { query, collection, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import { firebaseDB } from 'index';

// loading import
import { WineBottleLoadingLottie } from 'components/spinner';

import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import ParkIcon from 'assets/images/serviceTag/ic_park.png';
import ValetIcon from 'assets/images/serviceTag/ic_valet.png';
import NightIcon from 'assets/images/serviceTag/ic_night.png';
import CoolIcon from 'assets/images/serviceTag/ic_cool.png';
import TasteIcon from 'assets/images/serviceTag/ic_taste.png';
import { styled } from '@mui/material/styles';

// 서비스태그 스타일
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'block',
  '& .MuiToggleButtonGroup-grouped': {
    width: '70px',
    height: '28px',
    padding: 0,
    fontSize: '10px',
    color: '#140229',
    borderRadius: '15px',
    margin: '0 8px 8px 0',
    marginLeft: '0 !important',
    border: '1px solid #220348 !important',
    backgroundColor: '#FFFFFF',
    '&.Mui-selected': {
      border: '1px solid #220348 !important',
      backgroundColor: '#F4F0F8',
      color: '#140229'
    },
    '&.Mui-disabled': {
      border: '1px solid #220348 !important',
      backgroundColor: '#F4F0F8',
      color: '#140229'
    },
    '&:not(:first-of-type)': {
      borderRadius: '15px'
    },
    '&:first-of-type': {
      borderRadius: '15px'
    }
  }
}));

function MyInfo() {
  const { user } = useAuth();
  const navigate = useNavigate();

  // 로딩
  const [loading, setLoading] = useState(false);
  // 서비스 태그 목록
  const [serviceTagList, setServiceTagList] = useState(null);
  // 입점사 정보
  const [vendor, setVendor] = useState(null);

  // 페이지 진입 시
  useEffect(() => {
    // 서비스 태그 조회
    getServiceTagList();
    // 입점샵 정보 조회
    getVendorInformation();
  }, []);

  // 서비스 태그 조회
  const getServiceTagList = async () => {
    const codeRef = query(collection(firebaseDB, 'code/v1.0.0/code_service_tag'), orderBy('order'));
    const codeSnapshot = getDocs(codeRef);
    const tagArray = [];

    codeSnapshot
      .then((res) => {
        res.forEach((doc) => {
          tagArray.push(doc.data());
        });
        setServiceTagList(tagArray);
      })
      .catch((e) => {
        console.error('[샵정보] 서비스 태그 목록 조회 오류 발생 > ', e);
      });
  };

  // 입점사 정보 조회
  const getVendorInformation = async () => {
    setLoading(true);
    const vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
    const vendorId = vendorReload.vendor;

    const vendorInformation = await getDoc(doc(firebaseDB, 'vendor', vendorId));

    if (vendorInformation.exists()) {
      setLoading(false);
      setVendor(vendorInformation.data());
    } else {
      setLoading(false);
      console.error('[매장정보확인] 매장 정보 조회 중 오류 발생');
    }
  };

  // 영업시간 계산
  const operatingHours = (data, day) => {
    let className;
    let hours = '';

    // 휴무일 조회
    if (data.available) {
      className = 'detail-content-days';

      // 오픈시간 오전/오후 계산
      if (data.open_hour === 12) {
        const openAmHour = data.open_hour;
        let openAmMin = data.open_min;
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오후 ${openAmHour}:${openAmMin} ~ `;
      } else if (data.open_hour === 24) {
        let openAmMin = data.open_min;
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오전 00:${openAmMin} ~ `;
      } else if (data.open_hour < 12) {
        let openAmHour = data.open_hour;
        let openAmMin = data.open_min;
        if (openAmHour <= 10) openAmHour = String(openAmHour).padStart(2, '0');
        if (openAmMin === 0) openAmMin = String(openAmMin).padStart(2, '0');
        hours += `오전 ${openAmHour}:${openAmMin} ~ `;
      } else if (data.open_hour > 12) {
        let openPmHour = data.open_hour - 12;
        let openPmMin = data.open_min;
        if (openPmHour <= 10) openPmHour = String(openPmHour).padStart(2, '0');
        if (openPmMin === 0) openPmMin = String(openPmMin).padStart(2, '0');
        hours += `오후 ${openPmHour}:${openPmMin} ~ `;
      }

      // 클로즈시간 오전/오후 계산
      if (data.close_hour === 12) {
        const closeAmHour = data.close_hour;
        let closeAmMin = data.close_min;
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오후 ${closeAmHour}:${closeAmMin}`;
      } else if (data.close_hour === 24) {
        let closeAmMin = data.close_min;
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오전 00:${closeAmMin}`;
      } else if (data.close_hour < 12) {
        let closeAmHour = data.close_hour;
        let closeAmMin = data.close_min;
        if (closeAmHour <= 10) closeAmHour = String(closeAmHour).padStart(2, '0');
        if (closeAmMin === 0) closeAmMin = String(closeAmMin).padStart(2, '0');
        hours += `오전 ${closeAmHour}:${closeAmMin}`;
      } else if (data.close_hour > 12) {
        let closePmHour = data.close_hour - 12;
        let closePmMin = data.close_min;
        if (closePmHour <= 10) closePmHour = String(closePmHour).padStart(2, '0');
        if (closePmMin === 0) closePmMin = String(closePmMin).padStart(2, '0');
        hours += `오후 ${closePmHour}:${closePmMin}`;
      }
    } else {
      className = 'detail-content-days holiday';
      hours = '휴무';
    }

    return (
      <>
        <Typography variant={'span'} className={className}>
          {day}
        </Typography>
        <HorizontalRuleIcon sx={{ rotate: '90deg', color: '#E8E5EC', fontSize: 'medium' }} />
        <Typography variant={'span'} className={'detail-content-days'}>
          {hours}
        </Typography>
      </>
    );
  };

  // vendor servicetag
  const vendorServiceTag = (tags) => {
    const results = [];
    tags.forEach((tag) => {
      results.push(serviceTagList.find((item) => item._id === tag));
    });

    return results.map((tag, index) => (
      <ToggleButton key={index} value={tag._id} disabled className={'service-tag-box'} bgcolor={'#F4F0F8'}>
        {tag._id === 'SERVICE_TAG_001' && <Box component={'img'} src={ParkIcon} width={20} height={20} mr={'2px'} />}
        {tag._id === 'SERVICE_TAG_004' && <Box component={'img'} src={TasteIcon} width={11} height={20} mr={'2px'} />}
        {tag._id === 'SERVICE_TAG_007' && <Box component={'img'} src={CoolIcon} width={15} height={20} mr={'2px'} />}
        {tag._id === 'SERVICE_TAG_012' && <Box component={'img'} src={NightIcon} width={13} height={20} mr={'2px'} />}
        {tag._id === 'SERVICE_TAG_013' && <Box component={'img'} src={ValetIcon} width={15} height={20} mr={'2px'} />}
        {tag.value.ko}
      </ToggleButton>
    ));
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">입점사 정보를 불러오고 있습니다</Typography>
        </Box>
      </Backdrop>
      <Box>
        <AppBar position="sticky">
          <Toolbar sx={{ padding: '10px 20px' }}>
            <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
            <ToolbarTitle>{vendor !== null && vendor.biz.name}</ToolbarTitle>
            <Box flexGrow={1} />
            <Button
              size={'small'}
              variant={'outlined'}
              sx={{
                width: '58px',
                backgroundColor: '#ffffff',
                border: '1px solid #60546E26',
                padding: '7px 9px',
                color: '#140229',
                fontSize: '11px',
                borderRadius: '20px',
                boxShadow: 'none'
              }}
              onClick={() => navigate('update')}
            >
              편집하기
            </Button>
          </Toolbar>
          <WoDivider />
        </AppBar>
        <Box bgcolor={'#F4F0F8'}>
          <Box pt={'20px'} pb={'25px'}>
            {/* 샵정보 */}
            <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
              <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                샵 정보
              </Typography>

              {/* 상호명 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  상호명
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.biz.name}
                </Box>
              </Stack>

              {/* 주소 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  주소
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.biz.address1},&nbsp;
                  {vendor !== null && vendor.biz.address2 && vendor.biz.address2}
                </Box>
              </Stack>

              {/* 전화번호 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  전화번호
                </Box>
                <Box component={'span'} className={'detail-content'}>
                  {vendor !== null && vendor.shop.tel}
                </Box>
              </Stack>

              {/* 운영시간 */}
              <Stack mt={1.5}>
                <Box component={'span'} className={'detail-title'}>
                  운영시간
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.MON, '월요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.TUE, '화요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.WED, '수요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.THU, '목요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.FRI, '금요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.SAT, '토요일')}
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.SUN, '일요일')}
                </Box>
                {/*
                <Box display={'flex'} alignItems={'center'}>
                  {vendor !== null && operatingHours(vendor.shop.biz_hours.HOL, '공휴일')}
                </Box>
                */}
              </Stack>
            </Box>

            {/* 소개글 */}
            <Box pt={'10px'} pb={'10px'}>
              <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
                <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                  소개글
                </Typography>
                <Box mt={1.5}>
                  <Typography variant={'span'} fontSize={'16px'}>
                    {vendor !== null && vendor.shop.comment}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* 서비스 태그 */}
            <Box>
              <Box bgcolor={'#ffffff'} padding={'10px 20px'}>
                <Typography variant={'span'} fontSize={'20px'} fontWeight={800}>
                  서비스 태그
                </Typography>
                <Box mt={1.5}>
                  <Stack spacing={1} direction={'row'}>
                    <StyledToggleButtonGroup size={'large'} color={'primary'}>
                      {vendor !== null && serviceTagList !== null && vendorServiceTag(vendor.shop.service_tag)}
                    </StyledToggleButtonGroup>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyInfo;
