import React from 'react';
import { Route } from 'react-router-dom';
import HomeIndex from 'views/home';
import AuthGuard from 'utils/route-guard/AuthGuard';

/**
 * 홈 관련 라우트
 */
export default function HomeRoutes() {
  return (
    <Route
      path="/home"
      bottomNav
      element={
        <AuthGuard>
          <HomeIndex />
        </AuthGuard>
      }
    />
  );
}
