import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// project imports
import { store, persister } from 'store';
import App from './App';

// 프로덕션 환경에서 도메인검사
// if (process.env.NODE_ENV === 'production') {
//   if (window.location.origin !== process.env.REACT_APP_SERVICE_DOMAIN) {
//     window.location.replace(process.env.REACT_APP_SERVICE_DOMAIN);
//   }
// }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename="">
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

// Performance Monitoring - It only works in production environments.
export const perf = getPerformance(firebaseApp);
perf.dataCollectionEnabled = process.env.NODE_ENV === 'production';
perf.instrumentationEnabled = process.env.NODE_ENV === 'production';

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if (process.env.NODE_ENV === 'development') {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

/* todo 개발서버에 배포할 일이 있을 경우
 * self.FIREBASE_APPCHECK_DEBUG_TOKEN = '4704E721-7794-458B-961F-87DEC93A5C39';
 */

// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6LfVoNUgAAAAAOZYcjsUnbQRnNWXqJ34XAjZmNwj'),
//   isTokenAutoRefreshEnabled: true // Optional argument. If true, the SDK automatically refreshes App Check tokens as needed.
// });
