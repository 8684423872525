import axios from 'axios';
import { httpsCallable } from 'firebase/functions';

// project imports
import { CLO_CODE } from 'config';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { FIREBASE_FUNCTION_URL, NODE_URL } from './index';

/**
 * 입점사(벤더)에서 다운로드 가능한 쿠폰 목록
 * @param {string} vendorId - 입점사 아이디
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getCouponList(vendorId) {
  return axios.post(FIREBASE_FUNCTION_URL.POST__VENDOR_COUPON_LIST, { id: vendorId });
}

/**
 * 입점사 본인이 입점사 수정
 */
export async function updateVendorV(vendorParams) {
  return axios
    .put(FIREBASE_FUNCTION_URL.PUT__VENDOR_UPDATE_V, { ...vendorParams })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 입점샵 정보 수정 중 오류 발생 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `updateVendorV`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 입점샵 정보 조회
 */
export async function getVendorInformation(params) {
  return axios
    .post(NODE_URL.POST__GET_VENDOR, { ...params })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 입점샵 정보 조회 중 오류 발생 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `getVendorInformation`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}

/**
 * 입점샵 휴무일 리스트 가지고오기
 */
export async function getVendorDayoffList(body) {
  return axios.get(NODE_URL.GET__VENDOR__DAYOFF, { params: { ...body } }).then(({ data }) => data);
}

/**
 * 입점샵 휴무일 리스트 생성하기
 */
export async function createVendorDayoff(body) {
  return axios.post(NODE_URL.POST__VENDOR__CREATE_DAYOFF, { ...body }).then(({ data }) => data);
}

/**
 * 입점샵 휴무일 리스트 삭제하기
 */
export async function deleteVendorDayoff(body) {
  return axios.delete(NODE_URL.DELETE__VENDOR__DELETE_DAYOFF, { data: { ...body } }).then(({ data }) => data);
}

/**
 * 샵 기간 중 픽업대기중인 상품 조회
 */
export async function checkPickupOrder(body) {
  return axios.post(NODE_URL.POST__VENDOR__CHECK_PICKUP_ORDER, { ...body }).then(({ data }) => data);
}
