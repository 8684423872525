import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Divider, Typography } from '@mui/material';

// projects import
import SpaceBetweenBox from 'components/SpaceBetweenBox';

/**
 * 결제 정보 영역 (총 주문금액 / 승인 금액 / 취소 금액 / 최종 매출 금액)
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PaymentInformation({ paymentInfo }) {
  return (
    <Box px="20px">
      <Box border="1px solid #DCDCDC" borderRadius="10px">
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            총 주문 금액
          </Typography>
          <Typography fontSize="14px" color="#333333">
            {paymentInfo.productAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            승인 금액
          </Typography>
          <Typography fontSize="14px" color="#333333">
            {paymentInfo.reqOkAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            취소 금액
          </Typography>
          <Typography fontSize="14px" color="#FC735C" fontWeight="bold">
            {paymentInfo.reqNoAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
        <SpaceBetweenBox sx={{ py: '12px', px: '16px' }}>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            최종 매출 금액
          </Typography>
          <Typography fontSize="14px" color="#333333" fontWeight="bold">
            {paymentInfo.resultAmount.toLocaleString() ?? '-'}원
          </Typography>
        </SpaceBetweenBox>
        <Divider />
      </Box>
    </Box>
  );
}

export default PaymentInformation;

PaymentInformation.propTypes = {
  paymentInfo: PropTypes.object.isRequired
};
