// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;

/**
 * @constant
 * @type {{GET__GET_ME: string}}
 */
export const FIREBASE_FUNCTION_URL = {
  /** 로그인 유저정보 조회 */
  GET__GET_ME: `${BASE_HOST}/call-auth-member-getMe`,

  /** 로그인 유저 위치타입(location_type) 수정 */
  PUT__SET_LOCATION_TYPE: `${BASE_HOST}/call-auth-member-setLocationType`,

  /** 로그인 유저의 위치핀 정보저장 */
  POST__SET_PIN: `${BASE_HOST}/call-auth-member-setPin`,

  /** 이메일 중복검사 */
  POST__EMAIL_CHECK: `${BASE_HOST}/call-auth-signup-emailCheck`,

  /** 인증메일 발송요청 */
  POST__SEND_CERT_EMAIL: `${BASE_HOST}/call-auth-signup-sendCertEmail`,

  /** 이메일 인증 여부 확인 */
  POST__CHECK_CERT_EMAIL: `${BASE_HOST}/call-auth-signup-checkCertEmail`,

  /** 닉네임 중복검사 */
  POST__NICKNAME_CHECK: `${BASE_HOST}/call-auth-signup-nicknameCheck`,

  /** 쿠폰 다운로드 */
  PUT__DOWNLOAD_COUPON: `${BASE_HOST}/call-pay-coupon-download`,

  /** 사용가능한 쿠폰 목록 조회 */
  POST__COUPON_LIST: `${BASE_HOST}/call-pay-coupon-userCouponList`,

  /** 사용자 FCM TOKEN 업데이트 */
  POST__SET_FCM_TOKEN: `${BASE_HOST}/call-auth-member-setFcmToken`,

  /** 사용자 FCM TOKEN 삭제 */
  POST__REMOVE_FCM_TOKEN: `${BASE_HOST}/call-auth-member-removeFcmToken`,

  /** 로그인 사용자 알림 전체삭제 */
  PUT__CALL_AUTH_MEMBER_REMOVE_ALL_ALARM: `${BASE_HOST}/call-auth-member-removeAllAlarm`
};

export * from './user'; // 사용자 관련기능
export * from './alarm'; // 사용자 알림(alarm) 관련기능
