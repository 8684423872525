import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDebounce } from 'use-debounce';
import { NumericFormat } from 'react-number-format';

// projects import
import { WoAlert } from 'utils/kmwine-alerts';
import { dispatch, useSelector } from 'store';
import { fetchCodes } from 'store/slices/wo-constants';
import { PDATA_CATEGORY, WINE_VINTAGES } from 'config';
import * as ProductService from 'services/ProductService';
import * as WineOneSearch from 'services/WineOneSearch';
import LoadingLayout from 'components/loading/LoadingLayout';

// material-ui
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';

// elastic client
const pdataClient = WineOneSearch.createElasticAppSearchClient(WineOneSearch.ENGINE.PDATA);

/**
 * 상품추가 폼
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function ProductCreateForm({ openRequestForm }) {
  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 선택한 와인
  const [selectPdata, setSelectPdata] = React.useState(null);
  // 와인명 검색어
  const [search, setSearch] = React.useState('');
  // 와인명 검색어 디바운스
  const [searchDebounced] = useDebounce(search, 400);
  // 조회된 와인 목록
  const [pdataList, setPdataList] = React.useState([]);

  // 와인명 디바운스
  React.useEffect(() => {
    searchPdataList();
  }, [searchDebounced]);

  // 와인명 검색
  const searchPdataList = () => {
    // elastic 검색 조건
    const filters = {
      all: [{ show: 'true' }, { setup: 'true' }],
      none: [{ vendor_lock: 'true' }, { category: [PDATA_CATEGORY.TICKET.value] }]
    };

    const options = { filters, query: search };

    pdataClient
      .search('', options)
      .then((res) => {
        setPdataList(res.rawResults);
      })
      .catch((e) => {
        console.error('[상품 추가] pdata 검색 중 오류 발생 > ', e);
        WoAlert.fire('상품 검색', ' 와인명 검색 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      });
  };

  // 빈티지 범위
  const vintageSection = WINE_VINTAGES;

  // 상품추가 버튼 클릭 시
  const [submitBtnFlag, setSubmitBtnFlag] = React.useState(false);
  // 빈티지 validation custom check flag
  const [vintageCheck, setVintageCheck] = React.useState(false);

  // 용량
  const [capacityList, setCapacityList] = React.useState([]);
  // 서비스 상수
  const { woConstants } = useSelector((state) => state);

  // 용량 코드 초기화
  React.useLayoutEffect(() => {
    dispatch(fetchCodes(['capacity']));
  }, [woConstants]);

  React.useLayoutEffect(() => {
    if (woConstants?.code?.capacity) {
      const _capacityList = [];
      Object.keys(woConstants.code.capacity).forEach((capacityCode) => {
        _capacityList.push({ label: capacityCode, value: woConstants.code.capacity[capacityCode] });
      });
      setCapacityList(_capacityList);
    }
  }, [woConstants.code]);

  const validationSchema = yup.object().shape({
    name: yup.string().required('필수 항목입니다.'),
    capacity: yup.string().required('필수 항목입니다.'),
    price: yup.number().moreThan(0, '0보다 커야합니다').required('필수 항목입니다.'),
    quantity: yup.number().required('필수 항목입니다.')
  });

  const formik = useFormik({
    initialValues: {
      name: null,
      vintage: { label: '', value: '' },
      capacity: 750,
      price: 0,
      quantity: 0,
      packingBag: false,
      packingBox: false
    },
    validationSchema,
    onSubmit: (values) => {
      setSubmitBtnFlag(true);
      if (submitBtnFlag && !vintageCheck) return false;
      createProduct(values);
    }
  });

  // 상품 등록
  const createProduct = async (v) => {
    // 데이터 이중검사 [start]
    // 빈티지 정보가 잘못됨
    if (typeof v.vintage.value !== 'string' || !v.vintage.value || v.vintage.value === '') {
      WoAlert.fire('상품 추가', '빈티지를 선택해주세요.', 'warning');
      return false;
    }

    // 입점샵 접근 제한 상품 등록 이중 체크
    if (selectPdata.vendor_lock?.raw === true) {
      WoAlert.fire('상품 추가', '입점샵 접근이 제한된 상품입니다.<br />다른 상품을 선택해주세요.', 'warning');
      return false;
    }

    // 가격 정보가 잘못됨
    if (typeof v.price === 'string' || v.price < 0) {
      console.error('[상품 추가] 가격 정보가 잘못됨.');
      WoAlert.fire('', '가격 정보가 잘못되었습니다.<br />다시 입력해주세요.', 'error').then(() => {
        formik.setFieldValue('price', 0);
      });
      return false;
    }

    // 수량 정보가 잘못됨
    if (typeof v.quantity === 'string') {
      console.error('[상품 추가] 수량 정보가 잘못됨.');
      WoAlert.fire('', '수량 정보가 잘못되었습니다.<br />다시 입력해주세요.', 'error').then(() => {
        formik.setFieldValue('quantity', 0);
      });
      return false;
    }

    // 용량 이중체크
    if (typeof v.capacity === 'string') {
      console.error('[상품 추가] 용량 정보가 잘못됨.');
      WoAlert.fire('', '용량 정보가 잘못되었습니다.<br />다시 선택해주세요.', 'error').then(() => {
        formik.setFieldValue('capacity', '');
      });
      return false;
    }

    // pdata 정보가 잘못됨
    if (!selectPdata.id.raw) {
      console.error('[상품 추가] pdata 정보가 잘못됨.');
      WoAlert.fire('', '상품 정보가 잘못되었습니다.<br />다시 선택해주세요.', 'error').then(() => {
        formik.setFieldValue('pdata', null);
      });
      return false;
    }

    // category 정보가 잘못됨
    if (!selectPdata.category.raw) {
      console.error('[상품 추가] category 정보가 잘못됨.');
      WoAlert.fire('', '카테고리 정보가 잘못되었습니다.<br />다시 선택해주세요.', 'error').then(() => {
        formik.setFieldValue('pdata', null);
      });
      return false;
    }

    // request body
    const body = {
      pdata_id: selectPdata.id.raw,
      vintage: v.vintage.value,
      capacity: v.capacity,
      price: v.price,
      quantity: v.quantity,
      category: selectPdata.category.raw,
      packing: {
        bag: v.packingBag,
        box: v.packingBox
      },
      available: true
    };
    // 데이터 이중검사 [end]

    setLoading(true);

    const response = await ProductService.createProduct(body)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    // 상품 생성 중 오류 발생
    if (response.error) {
      console.error('[상품 추가] 상품 추가 중 오류 발생 > ', response.error);
      WoAlert.fire('상품 추가', '상품 추가 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = response.data.result;

    if (code !== 0) {
      console.error('[상품 추가] 상품 추가 중 오류 발생 > ', msg);
      WoAlert.fire('상품 추가', `상품 추가 중 오류가 발생했습니다.<br />${msg}<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    WoAlert.fire('상품 추가', '상품이 추가되었습니다.', 'success').then(() => {
      formik.resetForm({
        values: {
          capacity: '',
          packingBag: false,
          packingBox: false,
          price: '',
          quantity: 0,
          vintage: '',
          name: ''
        }
      });
      setSelectPdata(null);
    });
  };

  return (
    <>
      {/* Loading */}
      <LoadingLayout open={loading} text="상품을 추가하고 있습니다." />
      {/* 등록 form */}
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="#16171966" fontWeight="bold">
              상품명
            </Typography>
            <Autocomplete
              onChange={(e, v) => setSelectPdata(v)}
              options={pdataList}
              value={selectPdata}
              size="small"
              sx={{ width: '80%' }}
              getOptionLabel={(option) => `${option.name_ko.raw} (${option.name_en.raw})`}
              isOptionEqualToValue={(option, value) => option.id.raw === value.id.raw}
              onSelect={formik.handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="name"
                  onChange={(e) => setSearch(e.target.value)}
                  helperText={formik.touched.name && formik.errors.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  label="상품명"
                />
              )}
              noOptionsText={
                search.length > 0 &&
                pdataList.length === 0 && (
                  <Box>
                    <Typography color="black" fontWeight={600}>
                      검색 결과가 없습니다.
                    </Typography>
                    <br />
                    <Typography color="black">
                      DB 등록 요청 해 주시면 담당자 확인 후 상품을 등록 할 수 있도록 DB를 제작해드리겠습니다.
                    </Typography>
                    <Typography marginTop="1rem" color="black">
                      DB 추가는 최대 2주까지 소요 될 수 있습니다.
                    </Typography>
                    <Button
                      onClick={() => openRequestForm(formik.values.name)}
                      sx={{ border: '2px solid black', marginTop: '1rem', fontWeight: '900' }}
                    >
                      DB 등록 요청
                    </Button>
                  </Box>
                )
              }
            />
          </Box>

          {/* 선택한 상품이 있을 시 */}
          {selectPdata && (
            <Box>
              <Stack spacing={2}>
                {/* 빈티지 */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color="#16171966" fontWeight="bold">
                    빈티지
                  </Typography>
                  <Autocomplete
                    name="vintage"
                    size="small"
                    sx={{ width: '80%' }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setVintageCheck(true);
                        formik.setFieldValue('vintage', { label: newValue.value, value: newValue.value });
                      } else {
                        setVintageCheck(false);
                        formik.setFieldValue('vintage', { label: '', value: '' });
                      }
                    }}
                    options={vintageSection}
                    value={formik.values.vintage}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onSelect={formik.handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={submitBtnFlag && !vintageCheck && '필수 항목 입니다.'}
                        error={submitBtnFlag && !vintageCheck}
                        label="빈티지"
                      />
                    )}
                  />
                </Box>

                {/* 용량 */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color="#16171966" fontWeight="bold">
                    용량
                  </Typography>
                  <FormControl sx={{ width: '80%' }} size="small">
                    <InputLabel>용량</InputLabel>
                    <Select
                      label="용량 *"
                      onChange={(e) => formik.setFieldValue('capacity', e.target.value)}
                      value={formik.values.capacity}
                    >
                      {capacityList.map((capacity, i) => (
                        <MenuItem key={`opt-capacity-${i}`} value={capacity.value}>
                          {capacity.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {/* 가격 */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color="#16171966" fontWeight="bold">
                    가격
                  </Typography>
                  <TextField
                    size="small"
                    name="price"
                    label="가격"
                    placeholder="가격 입력"
                    sx={{ width: '80%' }}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                    InputProps={{
                      inputComponent: PriceNumericFormat,
                      endAdornment: <InputAdornment position="start">원</InputAdornment>,
                      inputProps: { style: { textAlign: 'right' } } // the change is here
                    }}
                  />
                </Box>

                {/* 재고 */}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color="#16171966" fontWeight="bold">
                    재고
                  </Typography>
                  <TextField
                    size="small"
                    name="quantity"
                    label="재고"
                    value={formik.values.quantity}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!value) {
                        formik.setFieldValue('quantity', 0);
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                    helperText={formik.touched.quantity && formik.errors.quantity}
                    placeholder="재고"
                    sx={{ width: '80%' }}
                    InputProps={{
                      inputComponent: QuantityNumericFormat,
                      endAdornment: <InputAdornment position="start">개</InputAdornment>,
                      inputProps: { style: { textAlign: 'right' } } // the change is here
                    }}
                  />
                </Box>
              </Stack>

              {/* 상품추가 버튼 */}
              <Box marginTop="3rem">
                <Button
                  sx={{
                    width: '100%',
                    padding: '6px 0',
                    border: '1px solid #60546E4D',
                    backgroundColor: '#140229',
                    color: '#ffffff',
                    fontSize: '1rem',
                    borderRadius: '2rem',
                    '&:hover': {
                      backgroundColor: '#140229'
                    }
                  }}
                  type="submit"
                >
                  추가하기
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      </form>
    </>
  );
}

ProductCreateForm.propTypes = {
  openRequestForm: PropTypes.func
};

export default ProductCreateForm;

const PriceNumericFormat = React.forwardRef(function NumericFormatCustom(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const { floatValue } = values;
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: floatValue
          }
        });
      }}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 999999999;
      }}
      allowNegative={false}
      thousandSeparator
      valueIsNumericString={false}
    />
  );
});

const QuantityNumericFormat = React.forwardRef(function NumericFormatCustom(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const { floatValue } = values;

        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: floatValue
          }
        });
      }}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 999999999;
      }}
      placeholder={0}
      allowNegative={false}
      thousandSeparator
      valueIsNumericString={false}
    />
  );
});
