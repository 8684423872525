import React from 'react';
import { Route } from 'react-router-dom';
import PromotionDetail from 'views/home/components/Promotion/PromotionDetail/PromotionDetail';

/**
 * 프로모션 라우트
 */
export default function PromotionRoutes() {
  return (
    <Route path="/promotion">
      <Route path={':id'} element={<PromotionDetail />} />
    </Route>
  );
}
