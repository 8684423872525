import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// project imports
import { useScriptRef } from 'hooks';
import { getCoBuyingList } from 'services/PromotionService';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { CoBuyCard } from './components';

// material-ui
import { Box, ButtonBase, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// 공동 구매 카드 세로 크기
const COBUY_CARD_HEIGHT = 259;

const useStyles = makeStyles((theme) => ({
  contentSwiper: {
    marginLeft: '-20px',
    marginRight: '-20px',
    paddingTop: '4px',
    paddingBottom: '32px',
    '--swiper-pagination-color': theme.palette.brand.main
  }
}));

/**
 * 공동 구매 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const CoBuy = () => {
  const scriptedRef = useScriptRef();
  const classes = useStyles();

  // 공동 구매 목록
  const [rows, setRows] = React.useState({ loaded: false, data: [] });
  const [show, setShow] = React.useState(false);

  React.useLayoutEffect(() => {
    getCoBuying();
  }, []);

  // 공동 구매 목록 조회
  const getCoBuying = async () => {
    setShow(false);
    setRows({ loaded: false, data: [] });

    // request body
    const body = {
      page: {
        current: 1,
        size: 100
      },
      show: true,
      sort: [{ state_sort: 'ASC' }, { sale_start_dt: 'ASC' }]
    };

    const result = await getCoBuyingList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[CoBuy][getCoBuying] Unmounted component');
      return;
    }

    // 공동 구매 목록 조회 중 오류 발생
    if (result.error) {
      console.error('[CoBuy][getCoBuying] 공동 구매 목록 조회 중 오류 발생 > ', result.error);
      setRows({ loaded: true, data: [] });
      return undefined;
    }

    const { success, cobuyings } = result;

    // 잘못된 Response 값이 반환됨
    if (!success) {
      console.error('[CoBuy][getCoBuying] 잘못된 Response 값이 반환됨');
      setRows({ loaded: true, data: [] });
      return undefined;
    }

    setRows({ loaded: true, data: cobuyings });
    setShow(true);
  };

  // render
  return (
    rows.loaded &&
    rows.data.length > 0 && (
      <Box sx={{ mt: 2 }}>
        <SpaceBetweenBox>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold', pl: '4px', lineHeight: 'normal' }}>공동구매</Typography>
          <ButtonBase
            sx={{ width: 23, height: 23, border: '1px solid #E9E7ED', bgcolor: '#FFF', borderRadius: '50%' }}
            onClick={() => setShow(!show)}
          >
            {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ButtonBase>
        </SpaceBetweenBox>
        <Collapse in={show}>
          <Box mt={2}>
            <Swiper
              className={classes.contentSwiper}
              slidesPerView="auto"
              loop={rows.data.length > 1}
              centeredSlidesBounds={rows.data.length > 1}
              centeredSlides={true}
              spaceBetween={12}
              pagination={{
                dynamicBullets: true
              }}
              modules={[Pagination]}
            >
              {rows.data.length > 0 &&
                rows.data.map((row, index) => (
                  <SwiperSlide key={`cobuy-${index}-card`} style={{ width: 'calc(90% - 40px)', maxHeight: COBUY_CARD_HEIGHT }}>
                    <CoBuyCard row={row} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </Box>
        </Collapse>
      </Box>
    )
  );
};

export default CoBuy;
