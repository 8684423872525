import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import SpaceBetweenBox from 'components/SpaceBetweenBox';

// project imports
import { ProductCard } from './index';

/**
 * 최종 주문 확인 페이지의 상품 승인 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqOkCard({ products }) {
  // 주문 승인할 상품의 총 수량
  const [quantityCount, setQuantityCount] = React.useState(0);

  React.useEffect(() => {
    getReqOkProductsQuantity();
  });

  // 주문 승인할 상품의 총 수량
  const getReqOkProductsQuantity = () => {
    let count = 0;
    products.forEach((product) => {
      count += product.ok_quantity;
    });
    setQuantityCount(count);
  };

  return (
    <Box px="16px" pt="12px" pb="16px" border="1px solid #DCDCDC" borderRadius="4px">
      <SpaceBetweenBox>
        <Typography fontSize="14px" color="#130328" fontWeight="bold">
          승인 상품
        </Typography>
        <SpaceBetweenBox>
          <Typography fontSize="14px" color="#13032">
            총
          </Typography>
          <Typography fontSize="14px" color="#13032" fontWeight="bold" ml={0.5}>
            {quantityCount}
          </Typography>
          <Typography fontSize="14px" color="#13032">
            병
          </Typography>
        </SpaceBetweenBox>
      </SpaceBetweenBox>
      {/* 승인할 상품 목록 */}
      <Stack spacing={2.25} mt={2}>
        {products.map((product, index) => (
          <ProductCard type="REQ_OK" product={product} key={`product-${index}-row`} />
        ))}
      </Stack>
    </Box>
  );
}

export default ReqOkCard;

ReqOkCard.propTypes = {
  products: PropTypes.array
};
