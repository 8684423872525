import React from 'react';
import PropTypes from 'prop-types';

// projects import
import { ReqOrderTab } from './ReqState/step1';
import { ReqOkOrderTab } from './ReqOkState';
import { OtherOrderTab } from './OtherState';
import { PaymentTab } from './components';

// material-ui
import { Box } from '@mui/material';

/**
 * 주문 상세 탭 컨텐츠
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function TabContents({ selectTab, order, products, history }) {
  return (
    <Box>
      {/* 주문 상태가 승인 대기(REQ)일 경우 */}
      {order.state === 'REQ' && selectTab === 1 && <ReqOrderTab order={order} products={products} />}

      {/* 주문 상태가 픽업 대기(REQ_OK)일 경우 */}
      {order.state === 'REQ_OK' && selectTab === 1 && <ReqOkOrderTab order={order} products={products} history={history} />}

      {/* 주문 상태가 승인 대기(REQ)또는 픽업 대기(REQ_OK)가 아닐 경우 */}
      {order.state !== 'REQ' && order.state !== 'REQ_OK' && selectTab === 1 && <OtherOrderTab order={order} products={products} />}

      {/* 결제 정보 탭 */}
      {selectTab === 2 && <PaymentTab order={order} products={products} />}
    </Box>
  );
}

export default TabContents;

TabContents.propTypes = {
  selectTab: PropTypes.number,
  order: PropTypes.object,
  products: PropTypes.array,
  history: PropTypes.array
};
