import moment from 'moment';

/**
 * Unix TimeStamp 포맷
 *
 * type === a : YYYY. MM. DD (day) a hh:mm [2022년 10월 31일 (월) 오전 11:36]
 *
 *
 * @param val
 * @param type
 * @returns {`${string} (${string}) ${string} ${string}`}
 * @constructor
 */
export default function UnixDateFormat(val, type) {
  // return 날짜
  const date = moment(val * 1000).format('YYYY. MM. DD');

  // return 요일
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[new Date(moment(date, 'YYYY.MM.DD')).getDay()];

  // return 오전/오후
  const amPm = moment(val * 1000).format('a') === 'pm' ? '오후' : '오전';

  // return 시간
  const time = moment(val * 1000).format('hh:mm');

  // return 값
  let result;

  // type에 따른 return 할 값
  if (type === 'a') result = `${date} (${dayOfWeek}) ${amPm} ${time}`;

  return result;
}
