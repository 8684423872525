import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import BourgogneRoutes from './routes';

import { WineOneProvider } from './contexts/WineOneContext';
import { WineOneAuthProvider as AuthProvider } from 'contexts/AuthContext';

import './assets/scss/style.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'animate.css';

import Page from './components/Page';
import BottomNav from './components/BottomNav';

import ScrollToTop from './routes/components/ScrollToTop';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

import { analytics } from './index';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';

import { setCodeVersion } from 'store/slices/wo-constants';
import { useDispatch } from './store';
import { ErrorBoundary } from 'react-error-boundary';

function App() {
  const location = useLocation();

  const sendPageViewEvent = (docTitle) => {
    if (process.env.NODE_ENV === 'production' && docTitle !== process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE) {
      logEvent(analytics, 'page_view', {
        page_title: docTitle,
        page_location: window.location.origin,
        page_path: `${location.pathname}${location.search}`
      });
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // 1km wine 코드정보 가져오기
    dispatch(setCodeVersion(process.env.REACT_APP_WO_CODE_VERSION));
  }, []);

  // // 서비스 상수
  // const { woConstants } = useSelector((state) => state);

  // useEffect(() => {
  //   dispatch(fetchCodes(['wine_type']));
  // }, [woConstants]);

  const bottomNavigation = useMemo(() => <BottomNav />, [location]);

  return (
    <WineOneProvider location={location}>
      <Helmet
        onChangeClientState={(newState) => {
          const docTitle = newState.title ?? process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
          document.title = docTitle;
          sendPageViewEvent(docTitle);
        }}
      />
      <Page>
        <ScrollToTop />
        <AuthProvider>
          <ErrorBoundary>
            <BourgogneRoutes location={location} />
          </ErrorBoundary>

          {/* 하단 네비게이션 */}
          {bottomNavigation}
        </AuthProvider>
      </Page>
    </WineOneProvider>
  );
}

export default App;

// iOS Safari 100vh issue
// 100vh -> calc(var(--vh, 1vh) * 100)
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Axios header global setting.
axios.defaults.headers.common['x-wineone-client'] = '1kmwine';

// Axios' authentication header setting.
axios.interceptors.request.use(async (config) => {
  const auth = getAuth();

  if (auth.currentUser) {
    const { accessToken, stsTokenManager } = auth.currentUser;
    let bearerToken = accessToken;

    // 토큰이 만료되었을 경우 갱신
    if (stsTokenManager.isExpired) {
      console.debug('[App] 사용자 토큰이 만료되어 갱신합니다.');
      const { token } = await auth.currentUser.getIdTokenResult(true);
      bearerToken = token;

      console.debug(`[App]  ㄴ 갱신된 토큰=${bearerToken}`);
    }

    config.headers.Authorization = `Bearer ${bearerToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

// todo Provider 안쪽으로 옮길 것...
window.onload = function windowOnload() {
  // check the visiblility of the page
  let hidden, visibilityState, visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
    visibilityState = 'visibilityState';
  } else if (typeof document.mozHidden !== 'undefined') {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
    visibilityState = 'mozVisibilityState';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
    visibilityState = 'msVisibilityState';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
    visibilityState = 'webkitVisibilityState';
  }

  if (typeof document.addEventListener === 'undefined' || typeof hidden === 'undefined') {
    /* DO NOTHING */
  } else {
    const auth = getAuth();

    // todo 실제 오픈 후 삭제할 것[start]
    if (auth.currentUser) {
      console.log(auth.currentUser.accessToken);
    }
    // todo 실제 오픈 후 삭제할 것[end]

    document.addEventListener(
      visibilityChange,
      function () {
        console.debug(`[wineone] visibility changed. state=${visibilityState}`);

        switch (document[visibilityState]) {
          case 'visible':
            // todo 실제 오픈 후 삭제할 것[start]
            if (auth.currentUser) {
              console.debug('#auth.currentUser: ', auth.currentUser.accessToken);
            }
            // todo 실제 오픈 후 삭제할 것[end]

            if (auth.currentUser && auth.currentUser.stsTokenManager.isExpired) {
              console.debug('[wineone] 토큰을 갱신합니다.');
              // auth.currentUser.getIdToken(true).then(() => {
              //   /* token refreshed */
              // });
            }
            break;
          case 'hidden':
            // hidden
            break;
          default: // nothing
        }
      },
      false
    );
  }
  /* if (document[visibilityState] === 'visible') {
    // visible
  } */
};
