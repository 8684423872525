import React from 'react';

// material-ui
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * 주문내역 칩 > todo 모든 칩 컴포넌트화 해야함...
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const OrderChip = ({ label, color = '#FFF', bgcolor = '#000' }) => {
  return (
    <Chip
      label={label}
      size="small"
      sx={{
        ml: '4px',
        height: 'auto',
        p: '2px 8px',
        fontSize: '11px',
        letterSpacing: '-0.11px',
        color,
        bgcolor,
        lineHeight: 'normal',
        '& .MuiChip-label': {
          padding: 0
        }
      }}
    />
  );
};

export default OrderChip;

OrderChip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  bgcolor: PropTypes.string
};
