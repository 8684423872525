import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import { useSelector } from 'store';
import BackButton from 'components/buttons/BackButton';
import { WoDivider } from 'components/dialog';

// material-ui
import { Box, Button, CircularProgress, Container, Drawer, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

function AlarmDrawer({ open = false, onClose }) {
  const navigate = useNavigate();
  const alarm = useSelector((state) => state.alarm);
  const { initialized, loading, totalCount, alarms } = alarm;

  // 로딩표현
  const loadingComponent = useMemo(() => {
    return loading ? (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={30} color="success" />
      </Box>
    ) : null;
  }, [loading]);

  // 알림 없음
  const alarmNotExists = useMemo(() => {
    console.log('#', initialized, loading, totalCount);
    if (!initialized || loading) return null;
    if (totalCount > 0) return null;

    return (
      <NoAlarmBox
        onClick={() => {
          // 주문하기 페이지로 넘어가기
          navigate('/mall', { replace: true });
        }}
      />
    );
  }, [initialized, loading, totalCount]);

  // 알림 목록, todo 알림목록 표현작업
  const alarmList = useMemo(() => {
    if (!initialized || loading) return null;
    if (alarms.length === 0) return null;
    return <Box>알림 목록 </Box>;
  }, [alarms, initialized, loading]);

  return (
    <Drawer anchor="right" open={open} ModalProps={{ keepMounted: true }}>
      <DrawerHeader>
        <DrawerTitle>알림</DrawerTitle>
        <BackButton color="inherit" onClick={onClose} />
        <Box flexGrow={1} />
      </DrawerHeader>
      <WoDivider />
      <Container sx={{ width: '100vw' }}>
        {alarmList}

        {alarmNotExists}
        {loadingComponent}
      </Container>
    </Drawer>
  );
}

export default AlarmDrawer;

AlarmDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

// Drawer header.
const DrawerHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, '20px'),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

// Drawer title.
const DrawerTitle = styled((props) => (
  <Typography
    variant="div"
    noWrap
    lineHeight={1}
    fontSize="18px"
    fontWeight={800}
    letterSpacing="-0.18px"
    color="text.primary"
    {...props}
  />
))({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

/* eslint-disable react/prop-types */
function NoAlarmIcon({ height = 86, width = 86, color = '#e5dcef' }) {
  return (
    <SvgIcon htmlColor={color} viewBox="0 0 86 86" sx={{ width, height }}>
      <g style={{ stroke: 'currentcolor', strokeWidth: '4px', fill: 'none' }}>
        <path d="M25 0a25 25 0 0 1 25 25v29H0V25A25 25 0 0 1 25 0z" style={{ stroke: 'none' }} transform="translate(18 14)" />
        <path d="M25 2a23 23 0 0 1 23 23v27H2V25A23 23 0 0 1 25 2z" style={{ fill: 'none' }} transform="translate(18 14)" />
      </g>
      <path transform="translate(12 64)" style={{ fill: 'currentcolor' }} d="M0 0h62v4H0z" />
      <path transform="translate(39 73)" style={{ fill: 'currentcolor' }} d="M0 0h8v4H0z" />
      <path transform="translate(41 9)" style={{ fill: 'currentcolor' }} d="M0 0h4v6H0z" />
    </SvgIcon>
  );
}

/**
 * 알림없음 표현영역
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
/* eslint-disable react/prop-types */
const NoAlarmBox = ({ onClick }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt="80px">
    <NoAlarmIcon />
    <Box textAlign="center" mt="60px">
      <Typography fontSize={18} fontWeight="bold">
        새로운 알림이 없습니다.
      </Typography>
      <Typography fontSize={18} fontWeight={300}>
        지금 와인을 주문 해보세요.
      </Typography>
    </Box>
    <Container sx={{ position: 'fixed', bottom: '40px' }}>
      {/* todo iOS safety area bottom */}
      <Button
        fullWidth
        variant="contained"
        color="brand"
        size="large"
        sx={{ height: 50, fontSize: 17, fontWeight: 'bold', letterSpacing: '-0.17px' }}
        onClick={onClick}
      >
        와인 주문하기
      </Button>
    </Container>
  </Box>
);
