import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// material-ui
import { AppBar, Avatar, Box, Container, Grid, SwipeableDrawer, Toolbar, Typography } from '@mui/material';

// project imports
import { ToolbarTitle } from 'components/toolbar';
import { SelectArrow } from 'components/icons';
import { bottleCapacityToString } from 'config';

/**
 * 매장 방문 대기 드로어
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function WaitDrawer({ open, setOpen, rows, lateRows, lateQuantity, quantity }) {
  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  // 픽업날짜 포맷
  const formatToPickupDate = (val) => {
    // return 날짜
    const date = moment(val).format('YYYY. MM. DD');

    // return 요일
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(moment(date, 'YYYY.MM.DD')).getDay()];

    return `${date} ${dayOfWeek}요일`;
  };

  return (
    <SwipeableDrawer id="wait-drawer" anchor="bottom" open={open} onOpen={openDrawer} onClose={closeDrawer}>
      {/* HEADER */}
      <AppBar position="sticky" sx={{ boxShadow: '0px 20px 20px -20px #1303281a', padding: '12px 12px 0 12px' }}>
        <Toolbar>
          <Box display="flex" onClick={() => setOpen(!open)}>
            <SelectArrow width={20} height={20} />
          </Box>
          <ToolbarTitle>픽업 요약</ToolbarTitle>
          <Box flexGrow={1} />
          <Box display="flex">
            <Typography variant="subtitle1">총</Typography>
            <Typography variant="h6" fontWeight={600}>
              &nbsp;{quantity.toLocaleString('ko-KR')}병
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ paddingBottom: '36px' }}>
        <main>
          {/* 픽업지연 목록 */}
          {lateRows.length > 0 && (
            <Box mt={3}>
              <Typography variant="body2" fontWeight={600} color="#13032866">
                픽업지연
              </Typography>
              <Box mt={2} pb={2} borderBottom="2px solid #EFEDF1">
                {lateRows.map((l, index) => (
                  <Box key={index} display="flex" alignItems="center" mt={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar
                          id="pickup-summary-wine-img"
                          alt="wine_img"
                          src={l.bottle_img}
                          sx={{
                            backgroundColor: '#F3F0F7',
                            borderRadius: '8px',
                            '& .MuiAvatar-img': {
                              objectFit: 'contain',
                              padding: '3px'
                            }
                          }}
                          onError={(e) => {
                            console.log('-- e: ', e);
                          }}
                        />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="body2" className="line-1-ellipsis">
                          {l.name__ko}
                        </Typography>
                        <Typography align="left" variant="body2" className="line-1-ellipsis">
                          {l.vintage === 'NV' ? l.vintage : `${l.vintage}년`} | {bottleCapacityToString(l.capacity)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="left" variant="body2" className="line-1-ellipsis">
                          {`총 ${l.quantity.toLocaleString('ko-KR')}병`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
              <Box pt={1} display="flex" justifyContent="space-between">
                <Typography variant="body2">합&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;계</Typography>
                <Box display="flex">
                  <Typography variant="subtitle2">총</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    &nbsp;{lateQuantity?.toLocaleString('ko-KR')}병
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {/* 날짜별 픽업 상품 목록 */}
          {rows.map((row, rowIndex) => {
            let quantityCount = 0;
            row.forEach((data) => {
              quantityCount += data.quantity;
            });
            const lateFlag = moment().format('YYYY-MM-DD') > moment(row[0].pickup_date).format('YYYY-MM-DD');
            if (!lateFlag)
              return (
                <Box mt={3} key={rowIndex}>
                  <Box display="flex">
                    <Typography variant="body2" fontWeight={600}>
                      {formatToPickupDate(row[0].pickup_date)}
                    </Typography>
                    {moment(row[0].pickup_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') && (
                      <Typography variant="body2" fontWeight={600} color="#13032866" ml={1}>
                        픽업지연
                      </Typography>
                    )}
                  </Box>
                  <Box mt={2} pb={2} borderBottom="2px solid #EFEDF1">
                    {row.map((r, index) => {
                      const { quantity } = r;

                      if (quantity > 0)
                        return (
                          <Box key={index} mt={1}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Avatar
                                  id="pickup-summary-wine-img"
                                  alt="wine_img"
                                  src={r.bottle_img}
                                  sx={{
                                    backgroundColor: '#F3F0F7',
                                    borderRadius: '8px',
                                    '& .MuiAvatar-img': {
                                      objectFit: 'contain',
                                      padding: '3px'
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="body2" className="line-1-ellipsis">
                                  {r.name__ko}
                                </Typography>
                                <Typography align="left" variant="body2" className="line-1-ellipsis">
                                  {r.vintage === 'NV' ? r.vintage : `${r.vintage}년`} | {bottleCapacityToString(r.capacity)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography align="left" variant="body2" className="line-1-ellipsis">
                                  {`총 ${quantity.toLocaleString('ko-KR')}병`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        );

                      return null;
                    })}
                  </Box>
                  <Box pt={1} display="flex" justifyContent="space-between">
                    <Typography variant="body2">합&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;계</Typography>
                    <Box display="flex">
                      <Typography variant="subtitle2">총</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        &nbsp;{quantityCount.toLocaleString('ko-KR')}병
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            return null;
          })}
        </main>
      </Container>
    </SwipeableDrawer>
  );
}

export default WaitDrawer;

WaitDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  rows: PropTypes.array,
  lateRows: PropTypes.array,
  lateQuantity: PropTypes.number,
  quantity: PropTypes.number
};
