import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Typography, AppBar, Box, Tab, Tabs, Grid, Toolbar, Backdrop } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

// import project
import Management from './Management';
import ServiceCenter from './ServiceCenter';
import { ToolbarTitle } from 'components/toolbar';
import * as WineOneSearch from 'services/WineOneSearch';
import moment from 'moment';
import { WineBottleLoadingLottie } from 'components/spinner';

// firebase
import { doc, getDoc } from 'firebase/firestore';
import { firebaseDB } from 'index';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// 전역변수 (전 페이지 state 저장)
let memTab = false;

// WineOneSearch
const notice = WineOneSearch.createElasticAppSearchClient(WineOneSearch.ENGINE.NOTICE);

/**
 * 운영관리 메인화면
 */
function ManagementIndex() {
  // 유저 정보
  const { user } = useAuth();

  const navigate = useNavigate();

  const [vendorName, setVendorName] = React.useState('');
  let vendorReload;
  let vendorId;
  // 입점샵 정보 조회
  const getVendorInformation = async () => {
    setIsLoading(true);
    try {
      vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
      vendorId = vendorReload.vendor;
      const vendorInformation = await getDoc(doc(firebaseDB, 'vendor', vendorId));
      setVendorName(vendorInformation.data().biz.name);
    } catch (e) {
      setIsLoading(false);
      console.error('[홈] 입점사 정보 조회 중 오류 발생');
      navigate(0);
    }
  };
  // tab option
  const myTapOption = [
    { tabIndex: 0, label: '운영관리' },
    { tabIndex: 1, label: '고객센터' }
  ];
  // tab state
  const [myTabValue, setMyTabValue] = React.useState(0);
  // select tabindex
  const [selectTabIndex, setSelectTabIndex] = React.useState(0);
  // 로딩상태
  const [isLoading, setIsLoading] = React.useState(false);
  // 공지사항 데이터
  const [noticeData, setNoticeData] = React.useState([]);

  // tab Change
  const myTabHandleChange = (event, newValue) => {
    setMyTabValue(newValue);
    memTab = !memTab;
  };
  const myTabHandleChangeIndex = (index) => {
    setMyTabValue(index);
  };
  React.useEffect(() => {
    getVendorInformation();
    getNoticeList();
    if (memTab) {
      setMyTabValue(1);
      setSelectTabIndex(1);
    } else {
      setMyTabValue(0);
      setSelectTabIndex(0);
    }
  }, []);
  const tabSelectId = (e) => {
    const tabId = e.target.offsetParent.id;
    setSelectTabIndex(Number(tabId));
  };
  // time formatting
  const timeformat = (val) => {
    const createdAt = moment(val).format('YYYY-MM-DD HH:mm:ss');
    const date = moment(createdAt).format('YYYY. MM. DD');
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const dayOfWeek = week[new Date(val).getDay()];
    const result = `${date}(${dayOfWeek})`;

    return result;
  };
  // 공지사항 목록 불러오기
  const getNoticeList = () => {
    setIsLoading(true);
    const filters = {
      type: 'shop'
    };
    const options = {
      filters
    };
    notice
      .search('', options)
      .then((res) => {
        const noticeArr = res.rawResults;
        NoticeSort(noticeArr);
        const formattedData = res.rawResults.map((data) => {
          return {
            ...data,
            created_at: {
              raw: timeformat(data.created_at.raw)
            }
          };
        });
        setNoticeData(formattedData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error('[공지사항] 공지 사항목록 조회 오류 발생 > ', e);
      });
  };
  // 공지사항 정렬
  const NoticeSort = (arr) => {
    arr.sort((a, b) => {
      if (a.created_at.raw > b.created_at.raw) return -1;
      if (a.created_at.raw < b.created_at.raw) return 1;
      return 0;
    });
    arr.sort((a, b) => {
      if (a.important.raw > b.important.raw) return -1;
      if (a.important.raw < b.important.raw) return 1;
      return 0;
    });
  };
  return (
    <Box>
      {/* Loading */}
      <Backdrop open={isLoading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">정보를 받아오는 중입니다</Typography>
        </Box>
      </Backdrop>
      {/* HEADER */}
      <AppBar position="sticky">
        <Toolbar>
          <ToolbarTitle>{vendorName}</ToolbarTitle>
        </Toolbar>
        <Tabs id="wait-tabs" variant="fullWidth" value={myTabValue} onChange={myTabHandleChange}>
          {myTapOption.map((tab, index) => (
            <Tab
              id={tab.tabIndex}
              key={index}
              label={
                <Grid container direction="column">
                  <Typography variant={'span'} fontSize={16} fontWeight={'bold'}>
                    {tab.label}
                  </Typography>
                </Grid>
              }
              onClick={(e) => tabSelectId(e)}
              sx={{
                '& .MuiGrid-container': {
                  width: '100% !important',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                },
                '& .MuiTypography-root': {
                  color: index === selectTabIndex ? '#220348' : '#B0B0B0'
                }
              }}
            />
          ))}
        </Tabs>
      </AppBar>
      {/* CONTENTS */}
      <SwipeableViews index={myTabValue} onChangeIndex={myTabHandleChangeIndex}>
        <TabPanel value={myTabValue} index={0}>
          <Management />
        </TabPanel>
        <TabPanel value={myTabValue} index={1}>
          <ServiceCenter noticeData={noticeData} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

export default ManagementIndex;
