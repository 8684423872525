import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

// project imports
import { bottleCapacityToString } from 'config';
import { WoAlert } from 'utils/kmwine-alerts';
import { updateCoBuyProductIn } from 'services/PromotionService';
import { InfoIcon } from 'components';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ProductImage } from './index';

// material-ui
import {
  Box,
  ButtonBase,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';

// 공동 구매 카드 세로 크기
const COBUY_CARD_HEIGHT = 259;

// 공동 구매 상품 이미지 기본 가로 크기
const COBUY_IMAGE_WIDTH = 117;
// 공동 구매 상품 이미지 기본 세로 크기
const COBUY_IMAGE_HEIGHT = 162;

// 공동 구매 상품 이미지 작은 가로 크기
const COBUY_IMAGE_S_WIDTH = 48;
// 공동 구매 상품 이미지 작은 세로 크기
const COBUY_IMAGE_S_HEIGHT = 66;

/**
 * 공동 구매 상품 카드
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const CoBuyCard = ({ row }) => {
  const navigate = useNavigate();

  // loading
  const [loading, setLoading] = React.useState(false);

  // 선택한 공동 구매 정보
  const [selectCoBuy, setSelectCoBuy] = React.useState(null);

  // 입고 완료 처리 dialog
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  // 입고 요청 완료 dialog
  const [completeDialog, setCompleteDialog] = React.useState(false);

  // 공동 구매 카드 헤더
  const coBuyCardHeader = React.useMemo(() => {
    if (!row) return;

    const { state_sort, success_rate, sale_start_dt, sale_end_dt, delivery_end_dt, pick_end_dt } = row;

    let chipLabel; // 상태 칩 라벨
    let successRateLabel; // 달성률 칩 라벨
    let timeLabel; // 상태별 시간 라벨

    // 달성률 null값 대응
    const successRate = Number(success_rate) ?? 0;

    // 달성률 포맷 (99 ~ 100 : 소수점 버림, 그 외 : 올림)
    if (successRate >= 99 && successRate <= 100) {
      successRateLabel = Math.floor(successRate);
    } else {
      successRateLabel = Math.ceil(successRate);
    }

    // 주문 시작 시간
    const saleStartDt = moment(sale_start_dt).format('MM.DD(ddd) a hh:mm');
    // 주문 마감 시간
    const saleEndDt = moment(sale_end_dt).format('MM.DD(ddd) a hh:mm');
    // 배송 마감 시간
    const deliveryEndDt = moment(delivery_end_dt).format('MM.DD(ddd)');
    // 픽업 마감 시간
    const pickEndDt = moment(pick_end_dt).format('YY.MM.DD(ddd)');

    /**
     * state_sort에 따라 공동구매 상태가 정의됨
     * 1. 입고대기
     * 2. 공동구매 진행 중
     * 3. 입고완료(입고 승인 요청)
     * 4. 미달성 취소
     * 5. 진행 대기
     */
    if (state_sort >= 1 && state_sort <= 6) {
      switch (state_sort) {
        case 1:
          chipLabel = '입고대기';
          timeLabel = `배송 마감 : ${deliveryEndDt}`;
          break;
        case 2:
          chipLabel = '공동구매 진행 중';
          timeLabel = `주문 마감 : ${saleEndDt}`;
          break;
        case 3:
          chipLabel = '입고완료(픽업대기)';
          timeLabel = `${pickEndDt}까지 고객이 샵에 방문할 예정입니다.`;
          break;
        case 4:
          chipLabel = '입고대기';
          timeLabel = `배송 마감 : ${deliveryEndDt}`;
          break;
        case 5:
          chipLabel = '미달성 취소';
          timeLabel = `자동 취소 : ${saleEndDt}`;
          break;
        case 6:
          chipLabel = '진행 대기';
          timeLabel = `주문 시작 : ${saleStartDt}`;
          break;
        default:
          chipLabel = '알 수 없음';
          timeLabel = '알 수 없음';
      }
    }

    return (
      <Box>
        <Box display="flex">
          <Chip
            size="small"
            label={chipLabel}
            sx={{
              color: '#fff',
              fontSize: '11px',
              fontWeight: 'bold',
              letterSpacing: '-0.11px',
              bgcolor: '#9357E5',
              mr: '4px'
            }}
          />

          {/* 시작 대기 데이터가 아닐 경우에만 표시 */}
          {state_sort !== 6 && (
            <Chip
              size="small"
              label={`${successRateLabel}% 달성`}
              sx={{
                color: '#9357E5',
                fontSize: '11px',
                fontWeight: 'bold',
                letterSpacing: '-0.11px',
                bgcolor: '#FFF',
                border: '1px solid #9357E5'
              }}
            />
          )}
        </Box>

        {state_sort === 3 ? (
          <Box ml="4px" mt="10px" lineHeight={1}>
            <Typography
              component="span"
              sx={{
                fontSize: '12px',
                letterSpacing: '-0.12px',
                background: 'linear-gradient(to top, #DEFD9A 60%, transparent 50%)',
                lineHeight: 'normal'
              }}
            >
              {pickEndDt}까지 고객이 샵에 방문할 예정입니다.
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ ml: '4px', mt: '12px', fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3', lineHeight: 'normal' }}>
            {timeLabel}
          </Typography>
        )}
      </Box>
    );
  }, [row]);

  // 공동 구매 카드 콘텐츠
  const coBuyContents = () => {
    const { state_sort, bottle_img, name__ko, vintage, capacity: _capacity, price__original, my_shop_order_cnt } = row;

    // 상품 이미지
    const src = bottle_img?.thumb ?? null;

    // 용량 format
    const capacity = bottleCapacityToString(_capacity);

    // 로그인한 샵의 공동 구매 주문 수량
    const myShopOrderCnt = my_shop_order_cnt ? Number(my_shop_order_cnt).toLocaleString() : 0;

    // 주문 수량이 1개 이상
    const isOrderCnt = Number(my_shop_order_cnt) > 0;

    // 공동 구매 진행 중 또는 진행 대기 상태
    if (state_sort === 2 || state_sort === 6) {
      return (
        <Stack direction="row" spacing="12px" width="100%">
          <ProductImage width={COBUY_IMAGE_WIDTH} height={COBUY_IMAGE_HEIGHT} src={src} />

          <Box sx={{ pt: 1, pb: 0.5, width: `calc(100% - ${COBUY_IMAGE_WIDTH}px - 12px)` }}>
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexFlow: 'column' }}>
              <Box>
                {/* 상품명 */}
                <Typography className="line-2-ellipsis" sx={{ fontSize: '12px', letterSpacing: '-0.24px', lineHeight: 1.2 }}>
                  {name__ko}
                </Typography>

                {/* 빈티지/용량 */}
                <Stack
                  direction="row"
                  spacing="4px"
                  mt={1}
                  divider={
                    <Box display="flex" alignItems="center" py="2px">
                      <Box height={1} width="1px" bgcolor="#A2A2A3" />
                    </Box>
                  }
                >
                  <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3', lineHeight: 'normal' }}>
                    {vintage}
                  </Typography>
                  <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3', lineHeight: 'normal' }}>
                    {capacity}
                  </Typography>
                </Stack>

                {/* 가격 */}
                <Box>
                  <Typography sx={{ lineHeight: 'normal', letterSpacing: '-0.28px', mt: 2, fontSize: '12px' }}>판매가</Typography>
                  <Typography sx={{ lineHeight: 'normal', letterSpacing: '-0.28px', mt: '4px' }}>
                    {price__original?.toLocaleString()}원
                  </Typography>
                </Box>
              </Box>

              <Box>
                {/* 우리 샵 주문 수량 */}
                {state_sort === 2 && (
                  <Typography sx={{ mt: '30px', fontSize: '12px', lineHeight: 'normal', color: '#9357E5', letterSpacing: '-0.24px' }}>
                    우리샵 주문 수량 {myShopOrderCnt}개
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Stack>
      );
    }

    // 입고 대기, 입고 완료, 미달성 취소 상태
    return (
      <Box width="100%">
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', flexFlow: 'column' }}>
          <Stack direction="row" spacing="12px" width="100%">
            <ProductImage width={COBUY_IMAGE_S_WIDTH} height={COBUY_IMAGE_S_HEIGHT} src={src} />

            <Box sx={{ py: 1, width: `calc(100% - ${COBUY_IMAGE_S_WIDTH}px - 12px)` }}>
              <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', flexFlow: 'column' }}>
                <Box>
                  {/* 상품명 */}
                  <Typography className="line-2-ellipsis" sx={{ fontSize: '12px', letterSpacing: '-0.24px', lineHeight: 1.2 }}>
                    {name__ko}
                  </Typography>
                </Box>
                <Box>
                  <SpaceBetweenBox sx={{ mt: 1 }}>
                    {/* 빈티지/용량 */}
                    <Stack
                      direction="row"
                      spacing="4px"
                      divider={
                        <Box display="flex" alignItems="center" py="2px">
                          <Box height={1} width="1px" bgcolor="#A2A2A3" />
                        </Box>
                      }
                    >
                      <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3', lineHeight: 'normal' }}>
                        {vintage}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3', lineHeight: 'normal' }}>
                        {capacity}
                      </Typography>
                    </Stack>

                    {/* 우리 샵 주문 수량 */}
                    <Typography sx={{ fontSize: '12px', lineHeight: 'normal', color: '#9357E5', letterSpacing: '-0.24px' }}>
                      우리샵 주문 수량 {myShopOrderCnt}개
                    </Typography>
                  </SpaceBetweenBox>
                </Box>
              </Box>
            </Box>
          </Stack>

          {/* 입고 대기 상태일 경우 */}
          {state_sort === 1 && (
            <Box mt={isOrderCnt ? '10px' : '12px'}>
              {/* 들어온 주문 건으로 분기처리 */}
              {isOrderCnt ? <WaitGuide1 /> : <WaitGuide2 />}

              <ButtonBase
                sx={{
                  border: `1px solid ${isOrderCnt ? '#140229' : '#B8B2BE'}`,
                  borderRadius: '5px',
                  width: '100%',
                  py: '12px',
                  mt: '14px'
                }}
                disabled={!isOrderCnt}
                onClick={() => handleCobuyRequest(row)}
              >
                <Typography
                  sx={{ letterSpacing: '-0.14px', fontWeight: 'bold', color: isOrderCnt ? '#130328' : '#A099A8', lineHeight: 'normal' }}
                >
                  {myShopOrderCnt}
                </Typography>
                <Typography sx={{ letterSpacing: '-0.14px', color: isOrderCnt ? '#130328' : '#A099A8', lineHeight: 'normal' }}>
                  개 상품 입고 완료 처리
                </Typography>
              </ButtonBase>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // 공동 구매 입고 요청 처리 버튼 클릭 이벤트
  const handleCobuyRequest = async (val) => {
    setSelectCoBuy(val);
    setConfirmDialog(true);
  };

  // 공동 구매 입고 요청 처리 취소 버튼 클릭 이벤트
  const handleCobuyCancel = () => {
    setSelectCoBuy(null);
    setConfirmDialog(false);
  };

  // 공동 구매 입고 요청 처리
  const requestCobuy = async () => {
    setLoading(true);

    // 공동구매 id
    const { _id, co_buying_vendor_id } = selectCoBuy;

    const body = {
      co_buying_id: _id,
      co_buying_vendor_ids: [co_buying_vendor_id],
      state: 'CONFIRM'
    };

    const result = await updateCoBuyProductIn(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setConfirmDialog(false);
        setLoading(false);
      });

    // 입고 완료 처리 중 오류 발생
    if (result.error) {
      console.error('[CoBuyCard][handleCobuyRequest] 입고 요청 처리 중 오류 발생: ', result.error);
      WoAlert.fire('', '입고 완료 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      setConfirmDialog();
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response 값이 넘어옴
    if (code !== 0) {
      console.error('[CoBuyCard][handleCobuyRequest] 잘못된 Response 값이 넘어옴: ', msg);
      WoAlert.fire('', '입고 완료 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    setCompleteDialog(true);
  };

  // render
  return (
    <Box>
      <Box maxHeight={COBUY_CARD_HEIGHT}>
        <Card
          sx={{
            height: '100%',
            boxShadow: '0px 2px 6px #0000000A',
            p: '16px',
            pb: row?.state_sort === 4 || row?.state_sort === 3 ? '20px' : '16px',
            boxSizing: 'border-box'
          }}
        >
          {/* 공동구매 상태 / 달성률 / 부가정보 */}
          {coBuyCardHeader}

          {/* 공동구매 상품 정보 */}
          <Box mt={2}>
            <Stack direction="row" spacing="12px">
              {/* 상품 정보 */}
              {coBuyContents()}
            </Stack>
          </Box>
        </Card>

        {/* 픽업 대기 안내 문구 */}
        {row?.state_sort === 3 && (
          <Card sx={{ mt: 1, height: '84px', boxShadow: '0px 2px 6px #0000000A', p: '13px 12px 16px 12px', boxSizing: 'border-box' }}>
            <PickupGuide />
          </Card>
        )}

        {/* 미달성 취소 안내 문구 */}
        {row?.state_sort === 5 && (
          <Card sx={{ mt: 1, height: '84px', boxShadow: '0px 2px 6px #0000000A', p: '13px 12px 16px 12px', boxSizing: 'border-box' }}>
            <CancelGuide />
          </Card>
        )}
      </Box>

      {/* 상품 입고 요청 처리 Dialog */}
      <Dialog open={confirmDialog} fullWidth>
        <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold', letterSpacing: '-0.18px' }}>상품 입고 완료 처리</DialogTitle>

        <DialogContent>
          <Box>
            <Typography sx={{ fontSize: '16px', letterSpacing: '-0.16px', wordBreak: 'keep-all' }}>{selectCoBuy?.name__ko}</Typography>
            <Stack
              mt="8px"
              direction="row"
              spacing="4px"
              divider={
                <Box display="flex" alignItems="center" py="4px">
                  <Box height={1} width="1px" bgcolor="#A2A2A3" />
                </Box>
              }
              sx={{ mt: 1 }}
            >
              <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3' }}>{selectCoBuy?.vintage}</Typography>
              <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', color: '#A2A2A3' }}>
                {bottleCapacityToString(selectCoBuy?.capacity)}
              </Typography>
            </Stack>

            <Typography sx={{ fontSize: '16px', letterSpacing: '-0.16px', mt: '20px' }}>
              입고 수량 : {selectCoBuy?.my_shop_order_cnt ? Number(selectCoBuy.my_shop_order_cnt) : 0}개
            </Typography>
          </Box>

          <Box mt="20px">
            <Typography sx={{ fontSize: '14px', letterSpacing: '-0.14px', color: '#9357E5', wordBreak: 'keep-all' }}>
              반드시 상품 정보와 입고 수량을 모두 확인 후 상태를 변경해주세요.
              <br />
            </Typography>
            <br />
            <Typography sx={{ fontSize: '14px', letterSpacing: '-0.14px', color: '#9357E5', wordBreak: 'keep-all' }}>
              상품 입고 상태를 변경하시겠습니까?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: '6px', mb: '18px' }}>
          {loading ? (
            <CircularProgress size={53} />
          ) : (
            <Stack direction="row" spacing="10px">
              <Box sx={{ minWidth: 128 }}>
                <ButtonBase
                  sx={{
                    p: 2,
                    width: '100%',
                    height: '100%',
                    borderRadius: '26px',
                    border: '1px solid #60546E4D',
                    fontSize: '16px'
                  }}
                  onClick={handleCobuyCancel}
                >
                  취소
                </ButtonBase>
              </Box>

              <Box sx={{ minWidth: 128 }}>
                <ButtonBase
                  sx={{
                    p: 2,
                    width: '100%',
                    height: '100%',
                    borderRadius: '26px',
                    border: '1px solid #60546E4D',
                    bgcolor: '#140229',
                    fontSize: '16px',
                    color: '#FFF',
                    fontWeight: 'bold'
                  }}
                  onClick={requestCobuy}
                >
                  확인
                </ButtonBase>
              </Box>
            </Stack>
          )}
        </DialogActions>
      </Dialog>

      {/* 상품 입고 요청 완료 Dialog */}
      <Dialog open={completeDialog} fullWidth>
        <DialogTitle sx={{ fontSize: '18px', fontWeight: 'bold', letterSpacing: '-0.18px' }}>입고 처리 완료</DialogTitle>

        <DialogContent>
          <Box>
            <Typography sx={{ fontSize: '16px', letterSpacing: '-0.16px', wordBreak: 'keep-all' }}>
              상품 입고 처리 상태 변경이 완료되어 고객이 샵에 방문할 예정입니다.
            </Typography>
          </Box>

          <Box mt="20px">
            <Typography sx={{ fontSize: '14px', letterSpacing: '-0.14px', color: '#9357E5', wordBreak: 'keep-all' }}>
              픽업 마감일 : {selectCoBuy?.pick_end_dt ? moment(selectCoBuy.pick_end_dt).format('YY.MM.DD(ddd)') : '-'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: '6px', mb: '18px', px: '26px' }}>
          <ButtonBase
            sx={{
              p: 2,
              width: '100%',
              height: '100%',
              borderRadius: '26px',
              border: '1px solid #60546E4D',
              bgcolor: '#140229',
              fontSize: '16px',
              color: '#FFF',
              fontWeight: 'bold'
            }}
            onClick={() => navigate(0)}
          >
            확인
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CoBuyCard;

CoBuyCard.propTypes = {
  row: PropTypes.object
};

// 입고대기 상태일 경우의 안내문구 1 -> 주문 건이 있음
const WaitGuide1 = React.memo(() => {
  return (
    <Box lineHeight={0} maxHeight="30px">
      <Typography component="span" sx={{ fontSize: '12px', letterSpacing: '-0.24px' }}>
        상품을 잘 받으셨다면&nbsp;
      </Typography>

      <Typography
        component="span"
        sx={{
          fontSize: '12px',
          letterSpacing: '-0.12px',
          background: 'linear-gradient(to top, #DEFD9A 60%, transparent 50%)',
          wordBreak: 'keep-all'
        }}
      >
        상품 정보와 수량을 정확히 확인
      </Typography>
      <Typography component="span" sx={{ fontSize: '12px', letterSpacing: '-0.24px', wordBreak: 'keep-all', lineHeight: 'normal' }}>
        하신 후 입고 완료 처리를 진행해 주세요.
      </Typography>
    </Box>
  );
});

// 입고대기 상태일 경우의 안내문구 2 -> 주문 건이 없음
const WaitGuide2 = React.memo(() => {
  return (
    <Typography sx={{ fontSize: '12px', letterSpacing: '-0.24px', lineHeight: 1.17, height: '28px' }}>
      공동구매가 성공으로 마무리 되었으나, 아쉽게도 이번에는 상품 주문이 없습니다. 다음 공동구매를 기대해주세요.
    </Typography>
  );
});

// 입고완료(픽업대기) 안내 문구
const PickupGuide = React.memo(() => {
  return (
    <Box>
      <Stack direction="row" spacing="4px" alignItems="center">
        <InfoIcon width={15} height={18} />
        <Typography sx={{ fontSize: 11, fontWeight: 'bold', letterSpacing: '-0.11px' }}>공동구매 픽업 완료 처리 안내</Typography>
      </Stack>
      <Typography sx={{ mt: 1, fontSize: 11, letterSpacing: '-0.11px', wordBreak: 'keep-all' }}>
        아래의 <b>'픽업대기'</b>내역에서 주문 확인 후 픽업 완료 처리를 진행해 주세요.
      </Typography>
    </Box>
  );
});

// 미달성 취소 안내 문구
const CancelGuide = React.memo(() => {
  return (
    <Box>
      <Stack direction="row" spacing="4px" alignItems="center">
        <InfoIcon width={15} height={18} />
        <Typography sx={{ fontSize: 11, fontWeight: 'bold', letterSpacing: '-0.11px' }}>미달성 취소 안내</Typography>
      </Stack>
      <Typography sx={{ mt: 1, fontSize: 11, letterSpacing: '-0.11px', wordBreak: 'keep-all' }}>
        공동구매 최소 주문 수량 미달성으로 결제 취소 및 자동 환불 처리되었습니다.
      </Typography>
    </Box>
  );
});
