import React from 'react';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

// project imports
import { useLocalStorage } from '../hooks';
import { WoAlert } from '../utils/kmwine-alerts';
import { setFcmToken } from '../services/UserService';
import isNull from '../utils/isNull';

// slices
import wineOneReducer, {
  APP_TYPE,
  fireNativeBackPressed,
  onNativeBackPressed as _onNativeBackPressed,
  setApplicationType,
  setBottomNavigationValue,
  toggleBottomNavigation,
  USER_AGENT,
  WINEONE_INITIAL_STATE
} from 'store/slices/wine-one';

// ----------------------------------------------------------------------

const WineOneContext = React.createContext(WINEONE_INITIAL_STATE);

/**
 * 와인원(1km wine)서비스 관련 provider
 * @constructor
 */
function WineOneProvider({ location, children }) {
  const [state, dispatch] = React.useReducer(wineOneReducer, WINEONE_INITIAL_STATE);
  const [settings, setSettings] = useLocalStorage('wine-one-app', {
    appType: null, // 앱타입 (web / Android / iOS )
    userLocation: null
  });

  const navigate = useNavigate();

  // 화면 주소 변경
  React.useEffect(() => {
    // 하단 네비게이션 선택값
    if (location && location.pathname) {
      const [_, firstPath] = location.pathname.split('/');
      dispatch(setBottomNavigationValue(firstPath));
    }

    return () => {
      // 기기의 뒤로가기 버튼콜백 초기화
      dispatch(_onNativeBackPressed(null));
      // 열려있던 SweetAlert닫기
      if (WoAlert.isVisible()) WoAlert.close();
    };
  }, [location]);

  // 어느 어플리케이션에서 호출했는지 확인
  React.useEffect(() => {
    const { navigator } = window;
    const { userAgent } = navigator;
    let appType = APP_TYPE.WEB;
    const isApplication = userAgent.includes(USER_AGENT.PREFIX);

    // 어플리케이션에서 접근
    if (isApplication) {
      // 안드로이드에서 접근
      if (userAgent.includes(USER_AGENT.AOS)) {
        appType = APP_TYPE.AOS;
      }
      // iOS에서 접근
      else if (userAgent.includes(USER_AGENT.IOS)) {
        appType = APP_TYPE.IOS;
      }
    }

    setSettings({
      ...settings,
      appType
    });

    dispatch(setApplicationType(appType));
    console.debug(`[wineone] 감지된 어플리케이션 타입 = '${appType}'`);

    // 웹뷰앱에서 접근했을 경우 javascript interface를 위한 메시지 핸들러를 생성
    window.addEventListener('wo.application', receiveMessage, false);

    // 어플리케이션 하드웨어 이벤트 핸들러
    if (isApplication || process.env.NODE_ENV === 'development') {
      window.addEventListener('wo.hardware', hardwareEvent, false);
    }

    // 프로바이더 언마운트 됨
    return () => {
      console.debug(`[wineone] WineOne context unmount.`);
      window.removeEventListener('wo.application', receiveMessage);
      if (isApplication) {
        window.removeEventListener('wo.hardware', hardwareEvent);
      }
    };
  }, []);

  // 하단 네비게이션 보이기(수동)
  const showBottomNav = () => {
    dispatch(toggleBottomNavigation(true));
  };
  // 하단 네비게이션 감추기(수동)
  const hideBottomNav = () => {
    dispatch(toggleBottomNavigation(false));
  };
  // 하단 네비게이션 노출여부 초기화(자동)
  const resetBottomNav = () => {
    dispatch(toggleBottomNavigation(undefined));
  };
  // 하단 네비게이션 선택값
  const setBottomNavVal = (value) => {
    dispatch(setBottomNavigationValue(value));
  };

  // 기기의 '뒤로가기'버튼 콜백 정의
  const onNativeBackPressed = (callback) => {
    // console.log('@WineOneContext.onNativeBackPressed: ', callback);
    if (typeof callback === 'function') {
      dispatch(_onNativeBackPressed(callback));
    } else {
      dispatch(_onNativeBackPressed(null));
      console.warn(`[wineone] 'onNativeBackPressed'의 'callback'이 'function'이 아닙니다. [typeof callback = ${typeof callback}]`);
    }
  };

  return (
    <WineOneContext.Provider value={{ ...state, showBottomNav, hideBottomNav, resetBottomNav, setBottomNavVal, onNativeBackPressed }}>
      {children}
    </WineOneContext.Provider>
  );
  // PRIVATE FUNCTIONS

  /**
   * TODO 기기에서 어플리케이션 이벤트 메시지 수신
   * @param {window.CustomEvent} event
   * @returns {string|boolean}
   */
  async function receiveMessage(event) {
    console.log('[development]---------------------------------------------- event from application [start]');
    console.log('[wineone] 모바일 기기에서 수신된 어플리케이션 이벤트.');
    // console.log('- event name: ', event.type);
    console.log('ㄴ event detail: ', event.detail);
    // console.log('- isTrusted: ', event.isTrusted);
    // console.log('- timeStamp: ', event.timeStamp);

    // event 처리 시작
    const { detail } = event;

    if (!detail) {
      console.warn(`Payload 'detail'를 통해 수신된 정보가 없습니다. 송신 데이터를 확인해주세요.`);
      return false;
    }

    const { type } = detail;

    // 네이티브의 푸시메시지 수신 이벤트
    if (type === 'receive-push-msg') {
      console.debug(`-- 푸시메시지 수신됨. detail=`, detail);
    }

    // FCM token changed, todo 웹뷰에서 동작하는지 테스트 필요
    else if (type === 'fcm-token-changed') {
      console.debug(`변경된 FCM token 수신됨. detail.token=${detail.token}`);

      // 현재 로그인사용자 확인
      const { currentUser } = getAuth();
      if (currentUser) {
        console.info('로그인 사용자의 갱신된 fcm token정보를 업데이트합니다.');
        if (detail.token) {
          setFcmToken(detail.token);
        } else {
          console.warn('[1kmwine] 토큰이 존재하지 않아 fcm token을 업데이트하지 않습니다.');
        }
      } else {
        console.warn('로그인하지 않은 상태입니다.');
      }
      return;
    }

    // Location changed
    else if (type === 'location-changed') {
      console.debug(`-- todo 변경된 location 정보 수신됨. detail=`, detail);
    }

    // App version received
    else if (type === 'app-version') {
      console.log('수신된 app-version 이벤트 정보 -> detail: ', detail);
      console.debug(`-- todo 앱 버전정보 수신됨. detail.version=`, detail.version);
    }

    // Health check
    else if (type === 'health-check') {
      // console.info(`%cwineone`, 'color: purple; background:pink; padding: 0 4px;', '이벤트(wo.application) 리스너가 정상 동작 중 입니다.');
      console.info('이벤트(wo.application) 리스너가 정상 동작 중 입니다.');
    }
    // 지정되지 않은 메시지 타입
    else if (type === 'help') {
      const helpTxt = `[development] 현재 테스트 가능한 타입.
  - receive-push-msg: 네이티브의 푸시 수신 데이터 전달
  - fcm-token-changed: 네이티브의 fcm token 갱신될 때마다 전달
  - health-check: 웹뷰의 스크립트 정상여부 확인(테스트용)
      `;
      console.log(helpTxt);
    }

    // 주문상세 바로가기
    else if (type === 'order') {
      const { data: oid } = detail;
      if (isNull(oid)) return;

      navigate(`/order/${oid}`);
    }

    // 주문상품상세 바로가기
    else if (type === 'orderPrd') {
      const { data: pid } = detail;
      if (isNull(pid)) return;
      navigate(`/order/${pid}`);
    }
    // 공지사항 바로가기
    else if (type === 'notice') {
      const { data: noticeId } = detail;
      if (isNull(noticeId)) return;

      navigate(`/management/notice/${noticeId}`);
    }
    // 플랫픔 프로모션 상세 바로가기
    else if (type === 'platformPromotion') {
      const { data: promotionId } = detail;
      if (isNull(promotionId)) return;

      navigate(`/promotion/${promotionId}`);
    } else {
      console.warn(`지정되지 않은 메시지 타입입니다.[type=${type}]`);
    }

    console.log('[development]---------------------------------------------- event from application [end]');

    return null;
  }

  // 기기에서 하드웨어 이벤트 메시지 수신
  function hardwareEvent(event) {
    const { detail } = event;
    if (detail) {
      const { action } = detail;

      // 기기에서 뒤로가기 버튼 눌렸을 때
      if (action === 'backpressed') {
        console.log('[wineone] 모바일 기기의 뒤로가기 이벤트가 감지되었습니다.');
        dispatch(fireNativeBackPressed());
      }
      // 앱에 foreground로 진입
      // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'foreground'}}));
      else if (action === 'foreground') {
        const auth = getAuth();

        if (auth.currentUser) {
          console.log('old token: ', auth.currentUser.accessToken);
          try {
            auth.currentUser.getIdToken(true).then((token) => {
              console.log('refreshed token3: ', token);
            });
          } catch (e) {
            console.warn('[wineone] Failed to get authentication token.', e);
          }
        }
      } else {
        console.warn('[wineone] 정의되지 않은 이벤트가 수신되었습니다.', event);
      }
    } else {
      console.warn('[wineone] 정의되지 않은 이벤트가 수신되었습니다.', event);
    }
  }
}

WineOneProvider.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node
};

export { WineOneProvider, WineOneContext };
