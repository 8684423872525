import { responsiveFontSizes } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';
import { koKR } from '@mui/material/locale';

import shadows from './shadows';
import { light, dark } from './palette';
import palette_ from 'assets/scss/_palette.scss';

import payboocBoldOtf from '../assets/fonts/paybooc-Bold.otf';
import payboocExtraBoldOtf from '../assets/fonts/paybooc-ExtraBold.otf';
import payboocMediumOtf from '../assets/fonts/paybooc-Medium.otf';
import payboocLightOtf from '../assets/fonts/paybooc-Light.otf';

import nanumLWoff from '../assets/fonts/NanumSquareL.woff';
import nanumRWoff from '../assets/fonts/NanumSquareR.woff';
import nanumBWoff from '../assets/fonts/NanumSquareB.woff';
import nanumEBWoff from '../assets/fonts/NanumSquareEB.woff';
import nanumLOtf from '../assets/fonts/NanumSquareOTF_acL.otf';
import nanumROtf from '../assets/fonts/NanumSquareOTF_acR.otf';
import nanumBOtf from '../assets/fonts/NanumSquareOTF_acB.otf';
import nanumEBOtf from '../assets/fonts/NanumSquareOTF_acEB.otf';

import onemBotf from '../assets/fonts/ONEMobileOTFBold.otf';
import onemRotf from '../assets/fonts/ONEMobileOTFRegular.otf';
import onemLotf from '../assets/fonts/ONEMobileOTFLight.otf';

import ddinROtf from 'assets/fonts/D-DIN.otf';
import ddinBOtf from 'assets/fonts/D-DIN-Bold.otf';

const getTheme = (mode, themeToggler) => {
  const palette = mode === 'light' ? light : dark;
  return responsiveFontSizes(
    createTheme({
      palette,
      shadows: shadows(mode),
      shape: {
        productImageBorderRadius: '14px'
      },
      typography: {
        fontSize: 13,
        fontFamily: palette_.font_family,
        button: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300
      },
      components: {
        MuiAppBar: {
          defaultProps: {
            elevation: 0
          },
          styleOverrides: {
            root: {
              color: palette.text.primary,
              backgroundColor: palette.background.paper,
              minHeight: 54,
              borderBottom: 'none',
              paddingTop: ['constant(safe-area-inset-top)', 'env(safe-area-inset-top)']
            }
          }
        },
        MuiBackdrop: {
          styleOverrides: {
            root: {
              // background: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(1px)'
            }
          }
        },
        MuiBottomNavigation: {
          styleOverrides: {
            root: {
              // height: 56
              height: ['calc( 56px + constant(safe-area-inset-bottom) )', 'calc( 56px + env(safe-area-inset-bottom) )']
            }
          }
        },
        MuiBottomNavigationAction: {
          styleOverrides: {
            root: {
              borderRadius: '24px',
              color: '#220348',
              '&.Mui-selected': {
                color: palette.dark.main
              },
              minHeight: 56,
              paddingBottom: ['constant(safe-area-inset-bottom)', 'env(safe-area-inset-bottom)']
            },
            label: {
              fontSize: '10px',
              '&.Mui-selected': {
                fontSize: '10px',
                fontWeight: 800
              }
            }
          }
        },
        MuiButton: {
          variants: [
            {
              props: { size: 'x-large' },
              style: {
                height: 50,
                fontSize: '17px',
                paddingRight: '20px',
                paddingLeft: '20px',
                '& .MuiLoadingButton-loadingIndicator': {
                  '& .MuiCircularProgress-root': {
                    width: '20px !important',
                    height: '20px !important'
                  }
                }
              }
            },
            {
              props: { color: 'brand' },
              style: {
                '&.Mui-disabled': {
                  color: alpha(palette.brand.contrastText, 0.5),
                  backgroundColor: alpha(palette.brand.main, 0.75)
                },
                '& .MuiLoadingButton-loadingIndicator': {
                  color: palette.brand.contrastText
                }
              }
            }
          ],
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: '5px',
              letterSpacing: '-0.17px'
            },
            sizeMedium: {
              // paddingTop: '1rem',
              // paddingTop: '1rem'
            },
            outlinedSizeSmall: {
              // padding: '2px 4px'
            },
            containedSecondary: mode === 'light' ? { color: '#ffffff' } : {}
          }
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              paddingLeft: '20px',
              paddingRight: '20px'
            }
          }
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: '#2D154D19'
            }
          }
        },
        MuiFab: {
          styleOverrides: {
            root: {
              width: '52px',
              height: '52px'
            }
          }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
              fontSize: 14
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5
            },
            input: {
              borderRadius: 5
            }
          }
        },
        MuiSnackbar: {
          styleOverrides: {
            root: {
              '& .MuiAlert-root': {
                borderRadius: '31px',
                paddingTop: '18px',
                paddingBottom: '18px',
                '& .MuiAlertTitle-root': {
                  fontSize: '16px',
                  fontWeight: 'bold'
                },
                '& .MuiTypography-caption': {
                  fontSize: '13px',
                  lineHeight: 1.46
                }
              },
              '& .MuiAlert-filledSuccess': {
                border: '1px solid #3cae47',
                backgroundColor: alpha('#47c454', 0.95)
              }
            }
          }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8
            }
          }
        },
        MuiCardHeader: {
          styleOverrides: {
            title: {
              fontSize: '16px',
              fontWeight: 'bold'
            }
          }
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              '&:last-child': {
                paddingBottom: 16
              }
            }
          }
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 24
            },
            paperFullScreen: {
              borderRadius: 0
            }
          },
          variants: [
            {
              props: { variant: 'bottom' },
              style: {
                '& .MuiPaper-root': {
                  position: 'absolute',
                  bottom: 0,
                  marginBottom: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0
                }
              }
            },
            {
              props: { variant: 'bottom', fullWidth: true },
              style: {
                '& .MuiPaper-root': {
                  width: '100%'
                }
              }
            }
          ]
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: '20px 20px'
            }
          }
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              paddingTop: 20,
              paddingRight: 20,
              paddingBottom: 20,
              paddingLeft: 20,
              fontSize: 18,
              fontWeight: 800,
              color: '#140229'
            }
          }
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              minHeight: 54,
              paddingLeft: 20,
              paddingRight: 20
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              letterSpacing: '-0.18px'
            },
            subtitle1: {
              fontSize: 16
            },
            subtitle2: {
              fontSize: 14
            },
            body1: {
              fontSize: 14
            },
            body2: {
              fontSize: 12
            },
            dialogTitle: {
              fontSize: 18,
              fontWeight: 800,
              fontStretch: 'normal',
              lineHeight: 'normal',
              display: 'inline-block'
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: palette.text.link
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'paybooc';
              src: url(${payboocLightOtf}) format('opentype');
              font-weight: 100;
              font-weight: 200;
              font-weight: 300;
              font-weight: lighter;
            };
            
            @font-face {
              font-family: 'paybooc';
              src: url(${payboocMediumOtf}) format('opentype');
              font-weight: 400;
              font-weight: 500;
              font-weight: 600;
              font-weight: normal;
            };
            
            @font-face {
              font-family: 'paybooc';
              src: url(${payboocBoldOtf}) format('opentype');
              font-weight: 700;
              font-weight: 800;
              font-weight: bold;
            };
            
            @font-face {
              font-family: 'paybooc';
              src: url(${payboocExtraBoldOtf}) format('opentype');
              font-weight: 900;
              font-weight: bolder;
            };
            
            
            @font-face {
              font-family: 'NanumSquare';
              src: url(${nanumLOtf}) format('opentype'), url(${nanumLWoff}) format('woff');
              font-weight: 100;
            };
            
            @font-face {
              font-family: 'NanumSquare';
              src: url(${nanumROtf}) format('opentype'), url(${nanumRWoff}) format('woff');
              font-weight: 400;
            };
            
            @font-face {
              font-family: 'NanumSquare';
              src: url(${nanumBOtf}) format('opentype'), url(${nanumBWoff}) format('woff');
              font-weight: 700;
            };
            
            @font-face {
              font-family: 'NanumSquare';
              src: url(${nanumEBOtf}) format('opentype'), url(${nanumEBWoff}) format('woff');
              font-weight: 900;
            };
            
            
            
            
            @font-face {
              font-family: 'OneMobile';
              src: url(${onemRotf}) format('opentype');
              font-weight: 100;
            };
            
            @font-face {
              font-family: 'OneMobile';
              src: url(${onemLotf}) format('opentype');
              font-weight: 400;
            };
            
            @font-face {
              font-family: 'OneMobile';
              src: url(${onemBotf}) format('opentype');
              font-weight: 700;
            };
            
            
            
            
            
            @font-face {
              font-family: 'D-Din';
              src: url(${ddinROtf}) format('opentype');
              font-weight: 400;
            };
            
             @font-face {
              font-family: 'D-Din';
              src: url(${ddinBOtf}) format('opentype');
              font-weight: 700;
            };
          `
        }
      },
      themeToggler
    }),
    koKR
  );
};

export default getTheme;
