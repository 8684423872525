// Firebase functions base host.
const BASE_HOST = process.env.REACT_APP_FIREBASE_FUNCTIONS_HOST;
// API 서버 호스트
const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;

/**
 * @constant
 * @type {{GET__PDATA_ONE: string, GET__PRODUCT_ONE: string}}
 */
export const FIREBASE_FUNCTION_URL = {
  /** pdata 조회 */
  GET__PDATA_ONE: `${BASE_HOST}/call-product-pdata-one`,
  /** product 조회 */
  GET__PRODUCT_ONE: `${BASE_HOST}/call-product-product-one`,

  /** product list 조회 */
  POST__PRODUCT_LIST: `${BASE_HOST}/call-product-product-getList`,

  /** product 등록 */
  POST__CREATE_PRODUCT: `${BASE_HOST}/call-product-product-create`,
  /** product 삭제 */
  DELETE__DELETE_PRODUCT: `${BASE_HOST}/call-product-product-delete`,

  /** 상품 수정 */
  PUT__UPDATE_PRODUCT: `${BASE_HOST}/call-product-product-update`,

  /** DB 등록 요청 */
  POST__REQ_CREATE_PDATA: `${BASE_HOST}/call-product-pdata-createRequestPdata`
};

export const NODE_URL = {
  /** 프로모션 상품 목록 조회 */
  POST__PROMO_PRODUCT_LIST: `${API_SERVER_HOST}/promotion/product/list`
};

export * from './product'; // 판매상품 정보
