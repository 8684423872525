import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Stack, Typography } from '@mui/material';

// projects import
import OrderProductStateChip from 'components/chip/OrderProductStateChip';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { ProductCard } from '../../ReqState/step2/components';

/**
 * 픽업 대기(REQ_OK)화면에서 표현 될 승인 상품 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function PickWaitList({ order, products }) {
  // 공동구매칩 표현 여부
  const cobuyChip = React.useMemo(() => {
    const { cobuy_state = 'NONE' } = order;

    if (cobuy_state === 'NONE') return null;

    return OrderProductStateChip('CO_BUY');
  }, [order]);

  // 플랫폼 프로모션칩 표현 여부
  const platformPromoChip = React.useMemo(() => {
    const { promotion_id } = order;

    if (promotion_id) {
      const {
        promotion: { type }
      } = order;

      if (!type) return;

      if (type === 'reserve') return OrderProductStateChip('PLATFORM_PROMOTION_RESERVE');
      if (type === 'product') return OrderProductStateChip('PLATFORM_PROMOTION_PRODUCT');
    }
  }, [order]);

  // 주문 상태 칩 표현
  const orderProductChip = React.useMemo(() => {
    const { promotion_id } = order;

    // 플랫폼 프로모션이 종료되지 않은 경우 다른 칩 표현
    if (promotion_id) {
      const {
        promotion: { type, state }
      } = order;

      if (!type || !state) return;

      console.log('@@@@@ order: ', order);

      // 플랫폼 프로모션이 종료 시간
      const endDt = order.promotion.period.end_dt.toDate();

      // state가 OK이고 endDt가 현재 시간보다 큰 경우
      if (state === 'OK' && endDt > new Date()) {
        return (
          <Chip
            size="small"
            label="예약판매 진행 중"
            sx={{ backgroundColor: '#3CAE47', fontSize: 11, fontWeight: 700, lineHeight: 'normal', color: '#FFF' }}
          />
        );
      }

      // state가 OK이고 endDt가 현재 시간보다 작은 경우
      if (state !== 'FINISH') {
        return (
          <Chip
            size="small"
            label="주문마감(입고대기)"
            sx={{ backgroundColor: '#3CAE47', fontSize: 11, fontWeight: 700, lineHeight: 'normal', color: '#FFF' }}
          />
        );
      }

      // 플랫폼 프로모션이 종료된 경우
      return OrderProductStateChip('REQ_OK');
    }
    return OrderProductStateChip('REQ_OK');
  }, [order]);

  return (
    <Box>
      <SpaceBetweenBox>
        <Stack direction="row" spacing="4px">
          {cobuyChip}
          {platformPromoChip}
          {orderProductChip}
        </Stack>

        <Typography fontSize="14px" color="#130328">
          총 {products.reduce((acc, cur) => acc + cur.quantity, 0)}병
        </Typography>
      </SpaceBetweenBox>

      {/* 승인된 상품 목록 */}
      <Stack spacing={2.25} mt={2}>
        {products.map((product, index) => (
          <ProductCard key={`product-${index}-row`} product={product} type="PICK_WAIT" />
        ))}
      </Stack>
    </Box>
  );
}

export default PickWaitList;

PickWaitList.propTypes = {
  order: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
};
