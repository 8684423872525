import React from 'react';
import { Route } from 'react-router-dom';
import MyIndex from 'views/my';
import MyInfoUpdate from 'views/my/MyInfoUpdate';

/**
 * 마이페이지 라우트
 */
export default function MyRoutes() {
  return (
    <Route path="/my">
      <Route index element={<MyIndex />} />
      <Route path={'update'} element={<MyInfoUpdate />} />
    </Route>
  );
}
